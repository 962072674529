import React, { useEffect } from 'react';
import { Modal, Form, InputNumber, DatePicker, } from 'antd';
import dayjs from 'dayjs';
function BillingBatchInfoEditModal({ open, setOpen, initialValues, editBillingBatchInfo, loading }) {

    const [form] = Form.useForm();


    const handleOk = () => {
        form.validateFields().then(values => {
            editBillingBatchInfo({
                invoice_id: initialValues.id,
                actual_billed_amount: values.actual_billed_amount,
                actual_billed_time: values.actual_billed_time.format('YYYY-MM-DD'),
            });
        });
    }

    useEffect(() => {
        if (open && initialValues) {
            // get today's date
            form.setFieldsValue({
                actual_billed_amount: initialValues ? initialValues.actual_billed_amount ? initialValues.actual_billed_amount : initialValues.billed_amount : null,
                actual_billed_time: initialValues?.actual_bill_date ? dayjs(initialValues?.actual_bill_date) : dayjs(),
            });
        }
    }, [open, initialValues]);

    return (
        <Modal
            open={open}
            onCancel={() => setOpen(false)}
            title="修改信息"
            onOk={handleOk}
            confirmLoading={loading}
        >
            <Form form={form} layout='vertical'>
                <Form.Item label='Actual Billed Amount' name='actual_billed_amount'>
                    <InputNumber style={{ width: '60%' }} />
                </Form.Item>
                <Form.Item label='Actual Billed Time' name='actual_billed_time'>
                    <DatePicker
                        style={{ width: '60%' }} />
                </Form.Item>
            </Form>
        </Modal >
    );
}

export default BillingBatchInfoEditModal;