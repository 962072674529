import React, { useState, useEffect } from 'react';
import ClientSearchBar from './ClientSearchBar';
import ClientInfoEditModal from './ClientInfoEditModal';
import ClientCreateModal from './ClientCreateModal';
import ClientDeleteModal from './ClientDeleteModal';
import ClientStatusEditModal from './ClientStatusEditModal';
import RateTemplateCreateModal from './RateTemplateCreateModal';
import ClientEditDateIntervalModal from './ClientEditDateIntervalModal';
import { Table, Button, Space, notification, Switch } from 'antd';
import { 
    useGetClientQuery,
    useGetUserByDepartmentQuery,
    useGetRateTemplateQuery,
    useUpdateClientMWTMutation,
    useUpdateClientMutation,
} from '../../features/api/apiSlice';
import { renderDateTime } from '../utils';

const initialEditModalState = {
    open: false,
    updated_at: undefined,
    id: undefined,
    uid: undefined,
    username: undefined,
    status: undefined,
    userAssignedTo: undefined,
    rateTemplate: undefined,
    comments1: undefined,
    comments1Other: undefined,
    comments2: undefined,
    contact: undefined,
}

function ClientPage(props) {
    const [editModalState, setEditModalState] = useState(initialEditModalState);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [createRateTemplateModalOpen, setCreateRateTemplateModalOpen] = useState(false);
    const [deleteModalStateOpen, setDeleteModalStateOpen] = useState(false);
    const [editStatusModalOpen, setEditStatusModalOpen] = useState(false);
    const [MWTLoading, setMWTLoading] = useState(false);
    const [searchData, setSearchData] = useState({});
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const { data, error, isLoading, isFetching, refetch } = useGetClientQuery({
        ...searchData,
        pageSize: pageSize,
        currentPage: currentPage
    });
    const { data: billingUserData, error: billingUserError, isLoading: billingUserIsLoading, refetch: refetchBillingUserData } = useGetUserByDepartmentQuery(2);
    const { data: kaUserData, error: kaUserError, isLoading: kaUserIsLoading, refetch: refetchKaUserData } = useGetUserByDepartmentQuery(1);
    const { data: rateTemplates, refetch: refetchRateTemplates } = useGetRateTemplateQuery();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [editDateIntervalModalOpen, setEditDateIntervalModalOpen] = useState(false);
    useEffect(() => {
        refetch();
        refetchBillingUserData();
        refetchKaUserData();
        refetchRateTemplates();
    }, [])
    useEffect(() => {
        setSelectedRowKeys([]);
    }, [searchData])
    const [UpdateClient, {
        isSuccess: isUpdateClientSuccess,
        isError: isUpdateClientError,
        error: UpdateClientError,
        isLoading: isUpdateClientLoading,
        isFetching: isUpdateClientFetching
    }] = useUpdateClientMutation();
    const [UpdateClientMWT, {
        isSuccess: isUpdateClientMWTSuccess,
        isError: isUpdateClientMWTError,
        error: UpdateClientMWTError,
        isLoading: isUpdateClientMWTLoading,
        isFetching: isUpdateClientMWTFetching
    }] = useUpdateClientMWTMutation();
    useEffect(() => {
        if (isUpdateClientSuccess) {
            notification.success({
                message: "Success",
                description: '修改成功!',
            });
        }
        if (isUpdateClientError) {
            notification.error({
                message: "Error",
                description: UpdateClientError?.data?.error || "修改失败!",
            });
        }
    }, [isUpdateClientSuccess, isUpdateClientError])
    useEffect(() => {
        if (isUpdateClientMWTSuccess) {
            notification.success({
                message: "Success",
                description: '修改成功!',
            });
        }
        if (isUpdateClientMWTError) {
            notification.error({
                message: "Error",
                description: UpdateClientMWTError?.data?.error || "修改失败!",
            });
        }
    }, [isUpdateClientMWTSuccess, isUpdateClientMWTError])
    const editButtonHandler = (record) => {
        const {
            id,
            uid,
            username,
            status,
            user: { id: userAssignedTo },
            contact,
            business_type,
            billing_cycle,
            rate_template,
            credit_line,
            comments1,
            updated_at,
        } = record;
        setEditModalState(prevState => ({
            ...prevState,
            open: true,
            id,
            uid,
            username,
            status: status === 'Active' ? '1' : '0',
            userAssignedTo,
            rateTemplate: rate_template?.id ?? null,
            contact: contact?.id ?? null,
            business_type,
            billing_cycle,
            credit_line,
            comments1,
            updated_at,
        }))
        setSelectedRowKeys([]);
        setSelectedRows([]);
    }

    const createButtonHandler = () => {
        setCreateModalOpen(true);
    }

    const createRateTemplateButtonHandler = () => {
        setCreateRateTemplateModalOpen(true);
    }
    const deleteButtonHandler = () => {
        if (selectedRowKeys.length === 0) {
            return notification.error({
                message: "Error",
                description: "请选择要删除的客户",
            })
        }
        setDeleteModalStateOpen(true);
    }
    const editStatusButtonHandler = () => {
        if (selectedRowKeys.length === 0) {
            return notification.error({
                message: "Error",
                description: "请选择要修改状态的客户",
            })
        }
        // check selectedRows, if they are not all Active or all Idle,
        // then return error
        const status = selectedRows[0]?.status;
        for (let i = 1; i < selectedRows.length; i++) {
            if (selectedRows[i].status !== status) {
                return notification.error({
                    message: "Error",
                    description: "所选择客户状态不一致，无法进行批量修改!",
                })
            }
        }
        setEditStatusModalOpen(true);
    }

    const editShipmentSplitDateIntervalHandler = () => {
        if (selectedRowKeys.length !== 1) {
            return notification.error({
                message: "Error",
                description: "请选择单一客户进行修改!",
            })
        }
        if (selectedRows[0].mwt_status !== '启用中') {
            return notification.error({
                message: "Error",
                description: "所选择客户未启用MWT,无法进行修改!",
            })
        }
        setEditDateIntervalModalOpen(true);
    }
    const handleUpdateEnable = (record, status) => {
        setMWTLoading(true);
        try {
            const data = {
                id: record.id,
                mwt_status: status,
                updated_at: record.updated_at,
            }
            UpdateClient(data)
        } catch (e) {
            console.log(e)
        } finally {
            setMWTLoading(false);
        }
    }

    const columns = [
        {
            title: '客户ID',
            dataIndex: 'uid',
            key: 'uid',
        },
        {
            title: '客户名',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: '客户分类',
            dataIndex: ['rate_template', 'name'],
            key: 'category',
        },
        {
            title: '客户状态',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: '客户对接人',
            dataIndex: ['contact', 'username'],
            key: 'contact',
        },
        {
            title: '账单负责人',
            dataIndex: ['user', 'username'],
            key: 'userAssignedTo',
        },
        {
            title: '业务类型',
            dataIndex: 'business_type',
            key: 'business_type',
        },
        {
            title: '账期',
            dataIndex: 'billing_cycle',
            key: 'billing_cycle',
            render: (text, record) => {
                if (text === '预充') {
                    return <div>预充</div>
                } else {
                    return <div>{text} 天</div>
                }
            }
        },
        {
            title: '客户授信额度',
            dataIndex: 'credit_line',
            key: 'credit_line',
            render: (text, record) => {
                if (text) {
                    return <div>$ {text}</div>
                } else {
                    return '无额度'
                }
            }
        },
        {
            title: '最新发送报价表版本',
            dataIndex: 'latestRateVersion',
            key: 'latestRateVersion',
            render: (text, record) => (
                <div></div>
            ),
        },
        {
            title: '最新出账批次',
            dataIndex: ['last_invoice', 'name'],
            key: 'latestBillBatch',
        },
        {
            title: '最新出账日期',
            dataIndex: ['last_invoice', 'created_at'],
            key: 'latestBillDate',
            render: (date) => renderDateTime(date),
        },
        {
            title: '备注',
            dataIndex: 'comments1',
            key: 'comments1',
        },
        {
            title: '合票天数规则',
            dataIndex: 'shipment_split_date_interval',
            key: 'shipment_split_date_interval',
            render: (text, record) => {
                if (record.mwt_status === '启用中') {
                    return <div>{text} 天之内合票</div>;
                } else {
                    return <div>非MWT客户不支持</div>
                }
            },
            width: 180,
        },
        {
            title: '启用MWT',
            dataIndex: "mwt_status",
            key: 'action',
            render: (text, record) => {
                return (
                    <Space size="middle">
                        <Switch
                            checkedChildren="启用中"
                            unCheckedChildren="未启用"
                            defaultChecked={text === '启用中'}
                            loading={MWTLoading}
                            onChange={(checked) => handleUpdateEnable(record, checked ? "1" : "0")}
                        />
                    </Space>
                )
            },
        },
        {
            title: '操作',
            render: (text, record) => (
                <Button type='link' onClick={() => editButtonHandler(record)}>
                    编辑客户信息
                </Button>
            ),
        },
    ]
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
            setSelectedRows(selectedRows);
        },
    };

    return (
        <div>
            <Space direction="vertical"
                size="middle"
                style={{
                    display: 'flex',
                }}>
                <ClientSearchBar
                    billingUserData={billingUserData}
                    rateTemplates={rateTemplates}
                    setSearchData={setSearchData}
                    setCurrentPage={setCurrentPage}
                    setPageSize={setPageSize} />
                <Space direction="vertical"
                    size="small"
                    style={{
                        display: 'flex',
                    }}>
                    <Space style={{ display: "flex" }}>
                        {props.currUser.button_permission?.includes("create_new_user_user_page") ?
                            <Button type="primary" onClick={createButtonHandler}>新建客户</Button> : null}
                        {props.currUser.button_permission?.includes("create_new_user_user_page") ?
                            <Button type="primary" onClick={createRateTemplateButtonHandler}>新建客户分类</Button> : null}
                        <Button onClick={editStatusButtonHandler}>修改客户状态</Button>
                        <Button onClick={editShipmentSplitDateIntervalHandler}>修改合票天数规则</Button>
                    </Space>
                    <Table
                        rowKey={(record) => record.id}
                        loading={isLoading || isFetching}
                        columns={columns}
                        dataSource={data?.results}
                        rowSelection={rowSelection}
                        pagination={{
                            showSizeChanger: true,
                            pageSize: pageSize,
                            current: currentPage,
                            total: data?.count,
                            onChange: (page, pageSize) => {
                                setCurrentPage(page);
                                setPageSize(pageSize);
                                setSelectedRowKeys([]);
                            },
                            onShowSizeChange: (current, size) => {
                                setCurrentPage(current);
                                setPageSize(size);
                                setSelectedRowKeys([]);
                            },
                            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                        }}
                        scroll={{ x: 'max-content' }}
                    />
                </Space>
            </Space>
            <ClientInfoEditModal
                state={editModalState}
                setState={setEditModalState}
                rateTemplates={rateTemplates}
                billingUserData={billingUserData}
                kaUserData={kaUserData}
            />
            <ClientCreateModal
                open={createModalOpen}
                setOpen={setCreateModalOpen}
                rateTemplates={rateTemplates}
                billingUserData={billingUserData}
                kaUserData={kaUserData}
            />
            <ClientDeleteModal
                open={deleteModalStateOpen}
                setOpen={setDeleteModalStateOpen}
                selectedRowKeys={selectedRowKeys}
                selectedRows={selectedRows}
                setSelectedRowKeys={setSelectedRowKeys}
                setSelectedRows={setSelectedRows}
            />
            <ClientStatusEditModal
                open={editStatusModalOpen}
                setOpen={setEditStatusModalOpen}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                setSelectedRowKeys={setSelectedRowKeys}
            />
            <RateTemplateCreateModal
                open={createRateTemplateModalOpen}
                setOpen={setCreateRateTemplateModalOpen}
            />
            <ClientEditDateIntervalModal
                open={editDateIntervalModalOpen}
                setOpen={setEditDateIntervalModalOpen}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                setSelectedRowKeys={setSelectedRowKeys}
            />
        </div>
    );
}

export default ClientPage;