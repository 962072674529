import React, { useState } from 'react';
import { ProTable } from '@ant-design/pro-table';
import { Select, DatePicker, theme } from 'antd';
import {
    useGetAdvancePaymentQuery,
    useGetCustomerUidWithBillingCycleQuery,
    useGetUserQuery,
} from '../../features/api/apiSlice';
const { Option } = Select;
const { RangePicker } = DatePicker;
function AdvancePaymentDetailsTab() {
    const { token } = theme.useToken();
    const [searchData, setSearchData] = useState({});
    const { data, error, isLoading, isFetching, refetch } = useGetAdvancePaymentQuery({...searchData});
    const { data: customerUidData } = useGetCustomerUidWithBillingCycleQuery('预充');
    const { data: kaUserData, error: kaUserError, isLoading: kaUserIsLoading, refetch: refetchKaUserData } = useGetUserQuery();
    
    const columns = [
        {
            title: 'UID',
            dataIndex: 'uid',
            key: 'uid',
            search: true,
            width: '20%',
            renderFormItem: (item, { defaultRender, ...rest }, form) => {
                return <Select
                    showSearch
                    allowClear
                    placeholder="Select a UID"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    mode="multiple"
                    style={{ textAlign: "left" }}
                >
                    {customerUidData && customerUidData.map((item) => {
                        return <Option key={item.uid} value={item.id}>{item.uid}</Option>
                    })}
                </Select>
            }
        },
        {
            title: 'system',
            dataIndex: 'system',
            key: 'system',
            width: '20%',
            search: false,
        },
        {
            title: 'payment_amount',
            dataIndex: 'payment_amount',
            key: 'payment_amount',
            width: '20%',
            search: false,
            render: (text, record) => {
                return <div>{text} {record.currency}</div>
            }
        },
        {
            title: '操作人',
            dataIndex: 'issuer',
            key: 'issuer',
            width: '20%',
            search: true,
            renderFormItem: (item, { defaultRender, ...rest }, form) => {
                return <Select
                    showSearch
                    allowClear
                    placeholder="请选择"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ textAlign: "left" }}
                >
                    {kaUserData && kaUserData.map((item) => {
                        return <Option key={item.id} value={item.id}>{item.username}</Option>
                    })}
                </Select>
            }
        },
        {
            title: '操作时间',
            dataIndex: 'issue_time',
            key: 'issue_time',
            width: '20%',
            search: true,
            renderFormItem: (item, { defaultRender, ...rest }, form) => {
                return <RangePicker />
            }
        },
    ]


    return (
        <ProTable
            rowKey={(record) => record.id}
            search={{
                labelWidth: 'auto',
                defaultCollapsed: false,
                style: {
                    maxWidth: 'none',
                    background: token.colorFillAlter,
                    borderRadius: token.borderRadiusLG,
                    padding: 24,
                }
            }}
            columns={columns}
            dataSource={data?.results}
            request={(params) => {
                setSearchData(params);
            }}
            pagination={{
                defaultPageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '50', '100'],
                total: data?.count,
            }}
            loading={isLoading || isFetching}
            scroll={{ x: 'max-content' }}
            options={{
                reload: () => refetch(),
            }}
        />
    )
}

export default AdvancePaymentDetailsTab;