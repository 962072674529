import React, { useState, useEffect, useRef } from 'react';
import ClientInfoEditModal from './ClientInfoEditModal';
import ClientCreateModal from './ClientCreateModal';
import RateTemplateCreateModal from './RateTemplateCreateModal';
import ClientEditDateIntervalModal from './ClientEditDateIntervalModal';
import { Input, Button, Space, notification, Switch, Select, theme, Modal } from 'antd';
import { 
    useGetClientQuery,
    useGetUserQuery,
    useGetRateTemplateQuery,
    useUpdateClientMutation,
    useGetSinglePieceInvoiceTemplatesQuery,
    useGetMultiPieceInvoiceTemplatesQuery,
    useGetAllCustomerWithGroupQuery,
} from '../../features/api/apiSlice';
import { renderDateTime } from '../utils';
import ProTable from '@ant-design/pro-table';
import axios from 'axios';
import { useSelector } from 'react-redux';
function ClientPage() {
    const { token } = theme.useToken();
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [editModalData, setEditModalData] = useState({});
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [createRateTemplateModalOpen, setCreateRateTemplateModalOpen] = useState(false);
    const [MWTLoading, setMWTLoading] = useState(false);
    const [searchData, setSearchData] = useState({});
    const { data, error, isLoading, isFetching, refetch } = useGetClientQuery({ ...searchData });
    const { data: userData, refetch: refetchUserData } = useGetUserQuery();
    const { data: rateTemplates, refetch: refetchRateTemplates } = useGetRateTemplateQuery();
    const { data: singlePieceInvoiceTemplates, refetch: refetchSinglePieceInvoiceTemplates } = useGetSinglePieceInvoiceTemplatesQuery();
    const { data: multiPieceInvoiceTemplates, refetch: refetchMultiPieceInvoiceTemplates } = useGetMultiPieceInvoiceTemplatesQuery();
    const [editDateIntervalModalOpen, setEditDateIntervalModalOpen] = useState(false);
    const [editDateIntervalModalData, setEditDateIntervalModalData] = useState({});
    const { data: customerGroupData, refetch: refetchCustomerGroup } = useGetAllCustomerWithGroupQuery();
    const [logModalOpen, setLogModalOpen] = useState(false);
    const [logModalData, setLogModalData] = useState({});
    const currUser = useSelector((state) => state.auth.user);
    const actionRef = useRef();
    useEffect(() => {
        refetch();
        refetchUserData();
        refetchRateTemplates();
        refetchSinglePieceInvoiceTemplates();
        refetchMultiPieceInvoiceTemplates();
        refetchCustomerGroup();
    }, [])
    useEffect(() => {
            if (logModalData.id) {
                actionRef.current?.reload();
            }
        }, [logModalData]);
    const [UpdateClient, {
        isSuccess: isUpdateClientSuccess,
        isError: isUpdateClientError,
        error: UpdateClientError,
        isLoading: isUpdateClientLoading,
    }] = useUpdateClientMutation();

    useEffect(() => {
        if (isUpdateClientSuccess) {
            notification.success({
                message: "Success",
                description: '修改成功!',
            });
        }
        if (isUpdateClientError) {
            notification.error({
                message: "Error",
                description: UpdateClientError?.data?.error || "修改失败!",
            });
        }
    }, [isUpdateClientSuccess, isUpdateClientError])

    const editButtonHandler = (record) => {
        setEditModalData(record);
        setEditModalOpen(true);
    }

    const createButtonHandler = () => {
        setCreateModalOpen(true);
    }

    const createRateTemplateButtonHandler = () => {
        setCreateRateTemplateModalOpen(true);
    }

    const editShipmentSplitDateIntervalHandler = (record) => {
        setEditDateIntervalModalData(record);
        setEditDateIntervalModalOpen(true);
    }
    const handleUpdateEnable = (record, status) => {
        setMWTLoading(true);
        try {
            const data = {
                id: record.id,
                mwt_status: status,
                updated_at: record.updated_at,
            }
            UpdateClient(data)
        } catch (e) {
            console.log(e)
        } finally {
            setMWTLoading(false);
        }
    }

    const handleUpdateStatusEnable = (record, status) => {
        setMWTLoading(true);
        try {
            const data = {
                id: record.id,
                is_active: status,
                updated_at: record.updated_at,
            }
            UpdateClient(data)
        } catch (e) {
            console.log(e)
        } finally {
            setMWTLoading(false);
        }
    }

    const logButtonHandler = (record) => {
        setLogModalData(record);
        setLogModalOpen(true);
    }

    const columns = [
        {
            title: '客户ID',
            dataIndex: 'uid',
            key: 'customerId',
            search: true,
            renderFormItem: (item, { type, defaultRender, ...rest }, form) => {
                return <Input placeholder="请输入内容" />
            },
        },
        {
            title: '客户名',
            dataIndex: 'username',
            key: 'customerName',
            search: true,
            renderFormItem: (item, { type, defaultRender, ...rest }, form) => {
                return <Input placeholder="请输入内容" />
            },
        },
        {
            title: '客户组',
            dataIndex: 'customer_group',
            key: 'customerGroup',
            hidden: currUser.is_superuser ? false : true,
            hiddenInForm: true,
            search: false,
        },
        {
            title: '客户分类',
            dataIndex: ['rate_template', 'name'],
            key: 'customerCategory',
            search: true,
            renderFormItem: (item, { type, defaultRender, ...rest }, form) => {
                return <Select allowClear style={{ textAlign: "left" }}>
                    {rateTemplates && rateTemplates.map((rateTemplate) => (
                        <Select.Option key={rateTemplate.id} value={rateTemplate.id}>
                            {rateTemplate.name}
                        </Select.Option>
                    ))}
                </Select>
            }
        },
        // {
        //     title: '客户状态',
        //     dataIndex: 'status',
        //     key: 'customerStatus',
        //     search: true,
        //     render: (text, record) => {
        //         return (
        //             <Switch
        //                 checkedChildren="Active"
        //                 unCheckedChildren="Idle"
        //                 defaultChecked={text === 'Active'}
        //                 loading={MWTLoading}
        //                 onChange={(checked) => handleUpdateStatusEnable(record, checked ? "1" : "0")}
        //             />
        //         )
        //     },
        //     renderFormItem: (item, { type, defaultRender, ...rest }, form) => {
        //         return <Select allowClear style={{ textAlign: "left" }}>
        //             <Select.Option value="1">Active</Select.Option>
        //             <Select.Option value="0">Idle</Select.Option>
        //         </Select>
        //     }
        // },
        {
            title: '客户对接人',
            dataIndex: ['contact', 'username'],
            key: 'contact',
            search: false,
        },
        {
            title: '账单负责人',
            dataIndex: ['user', 'username'],
            key: 'customerAssignedTo',
            search: true,
            renderFormItem: (item, { type, defaultRender, ...rest }, form) => {
                return <Select allowClear style={{ textAlign: "left" }}>
                    {userData && userData.map(user => (
                        <Select.Option key={user.id} value={user.id}>
                            {user.username}
                        </Select.Option>
                    ))}
                </Select>
            }
        },
        {
            title: '业务类型',
            dataIndex: 'business_type',
            key: 'businessType',
            search: true,
            renderFormItem: (item, { type, defaultRender, ...rest }, form) => {
                return <Select allowClear style={{ textAlign: "left" }}>
                    <Select.Option value="渠道">渠道</Select.Option>
                    <Select.Option value="转运">转运</Select.Option>
                    <Select.Option value="代发">代发</Select.Option>
                    <Select.Option value="提柜">提柜</Select.Option>
                    <Select.Option value="海运">海运</Select.Option>
                    <Select.Option value="清派">清派</Select.Option>
                </Select>
            }
        },
        {
            title: '账期',
            dataIndex: 'billing_cycle',
            key: 'billing_cycle',
            search: false,
            render: (text, record) => {
                if (text === '预充') {
                    return <div>预充</div>
                } else {
                    return <div>{text} 天</div>
                }
            }
        },
        {
            title: '币种',
            dataIndex: 'currency',
            key: 'currency',
            search: false,
            render: (text, record) => {
                if (text === 0) {
                    return <div>USD</div>
                } else if (text === 1) {
                    return <div>CNY</div>
                } else if (text === 2) {
                    return <div>EUR</div>
                } else if (text === 3) {
                    return <div>CAD</div>
                }
            }
        },
        {
            title: '客户授信额度',
            dataIndex: 'credit_line',
            key: 'credit_line',
            search: false,
            render: (text, record) => {
                if (text) {
                    return <div>$ {text}</div>
                } else {
                    return '无额度'
                }
            }
        },
        {
            title: '最新发送报价表版本',
            dataIndex: 'latestRateVersion',
            key: 'latestRateVersion',
            search: false,
        },
        {
            title: '最新出账批次',
            dataIndex: ['last_invoice', 'name'],
            key: 'latestBillBatch',
            search: false,
        },
        {
            title: '最新出账日期',
            dataIndex: ['last_invoice', 'created_at'],
            key: 'latestBillDate',
            render: (text, record) => {
                return renderDateTime(record.last_invoice?.created_at) || '-';
            },
            search: false,
        },
        {
            title: '备注',
            dataIndex: 'comments1',
            key: 'comments1',
            search: false,
        },
        {
            title: '合票天数规则',
            dataIndex: 'shipment_split_date_interval',
            key: 'shipment_split_date_interval',
            search: false,
            render: (text, record) => {
                if (record.mwt_status === '启用中') {
                    return <div>{text} 天之内合票</div>;
                } else {
                    return <div>非MWT客户不支持</div>
                }
            },
            width: 180,
        },
        {
            title: '启用MWT',
            dataIndex: "mwt_status",
            key: 'action',
            search: false,
            render: (text, record) => {
                if (currUser.button_permission?.includes('edit_client_mwt_status')) {
                    return <Space size="middle">
                            <Switch
                                checkedChildren="启用中"
                                unCheckedChildren="未启用"
                                defaultChecked={text === '启用中'}
                                loading={MWTLoading}
                                onChange={(checked) => handleUpdateEnable(record, checked ? "1" : "0")}
                            />
                        </Space>
                } else {
                    return <div>{text === '启用中' ? '启用中' : '未启用'}</div>
                }
            },
        },
        {
            title: '单包裹出账模版',
            dataIndex: ['single_piece_invoice_template', 'name'],
            key: 'single_piece_invoice_template',
            search: false,
        },
        {
            title: '多包裹出账模版',
            dataIndex: ['multi_piece_invoice_template', 'name'],
            key: 'multi_piece_invoice_template',
            search: false,
        },
        {
            title: '操作',
            search: false,
            fixed: 'right',
            hidden: !currUser.button_permission?.includes('edit_client_info') && !currUser.button_permission?.includes('edit_client_mwt_date_interval') && !currUser.button_permission?.includes('get_customer_log'),
            hiddenInForm: !currUser.button_permission?.includes('edit_client_info') && !currUser.button_permission?.includes('edit_client_mwt_date_interval') && !currUser.button_permission?.includes('get_customer_log'),
            render: (text, record) => (
                <Space>
                    {currUser.button_permission?.includes('edit_client_info') && <Button type='link' onClick={() => editButtonHandler(record)}>
                        编辑
                    </Button>}
                    {currUser.button_permission?.includes('edit_client_mwt_date_interval') && <Button type='link' disabled={record.mwt_status !== '启用中'} onClick={() => editShipmentSplitDateIntervalHandler(record)}>
                        修改合票天数规则
                    </Button>}
                    {currUser.button_permission?.includes('get_customer_log') && <Button type="link" onClick={() => logButtonHandler(record)}>
                        日志
                    </Button>}
                </Space>
            ),
        },
    ]
    const logColumns = [
        {
            title: '操作类型',
            dataIndex: 'log_type',
            key: 'log_type',
        },
        {
            title: '操作内容',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: '操作人',
            dataIndex: ['user', 'username'],
            key: 'user',
        },
        {
            title: '操作时间',
            dataIndex: 'time',
            key: 'time',
            render: renderDateTime,
        }
    ]
    return (
        <div>
            <Space direction="vertical"
                size="middle"
                style={{
                    display: 'flex',
                }}>
                <Space direction="vertical"
                    size="small"
                    style={{
                        display: 'flex',
                    }}>
                    <ProTable
                        search={{
                            labelWidth: 'auto',
                            defaultCollapsed: false,
                            collapseRender: false,
                            style: {
                                maxWidth: 'none',
                                background: token.colorFillAlter,
                                borderRadius: token.borderRadiusLG,
                                padding: 24,
                            }
                        }}
                        rowKey={(record) => record.id}
                        size='small'
                        loading={{
                            spinning: isLoading || isFetching || isUpdateClientLoading,
                            tip: '数据加载中...'
                        }}
                        columns={columns}
                        dataSource={data?.results}
                        request={(params) => {
                            setSearchData(params);
                        }}
                        pagination={{
                            defaultPageSize: 10,
                            showSizeChanger: true,
                            pageSizeOptions: ['10', '20', '50', '100'],
                            total: data?.count,
                        }}
                        scroll={{ x: 'max-content' }}
                        options={{
                            reload: () => refetch(),
                        }}
                        toolbar={{
                            actions: [
                                    currUser.button_permission?.includes("create_new_customer") && 
                                    <Button type="primary" onClick={createButtonHandler}>新建客户</Button>,
                                    currUser.button_permission?.includes("create_new_rate_template") &&
                                    <Button type="primary" onClick={createRateTemplateButtonHandler}>新建客户分类</Button>,
                            ]
                        }}
                    />
                </Space>
            </Space>
            <ClientInfoEditModal
                open={editModalOpen}
                setOpen={setEditModalOpen}
                data={editModalData}
                rateTemplates={rateTemplates}
                billingUserData={userData}
                kaUserData={userData}
                singlePieceInvoiceTemplates={singlePieceInvoiceTemplates}
                multiPieceInvoiceTemplates={multiPieceInvoiceTemplates}
            />
            <ClientCreateModal
                open={createModalOpen}
                setOpen={setCreateModalOpen}
                billingUserData={userData}
                kaUserData={userData}
            />
            <RateTemplateCreateModal
                open={createRateTemplateModalOpen}
                setOpen={setCreateRateTemplateModalOpen}
                customerGroupData={customerGroupData}
            />
            <ClientEditDateIntervalModal
                open={editDateIntervalModalOpen}
                setOpen={setEditDateIntervalModalOpen}
                data={editDateIntervalModalData}
            />
             <Modal
                title='操作记录'
                open={logModalOpen}
                footer={null}
                onCancel={() => setLogModalOpen(false)}
                width={1000}
            >
                <ProTable
                    search={false}
                    rowKey={record => record.id}
                    actionRef={actionRef}
                    request={async (params) => {
                        const request = {
                            customer: logModalData.id,
                            currentPage: params.current,
                            pageSize: params.pageSize,
                        }
                        return axios.get(`/accounts/get-customer-management-operation-log/`, { params: request }).then((res) => {
                            return {
                                data: res.data.results,
                                total: res.data.count
                            }
                        })
                    }}
                    pagination={{
                        defaultPageSize: 10,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', '50', '100'],
                    }}
                    columns={logColumns}
                    options={false}
                    toolBarRender={false}
                    scroll={{ x: 'max-content' }}
                />
            </Modal>
        </div>
    );
}

export default ClientPage;