import React, { useState, useEffect } from 'react';
import ConfirmModal from "./ConfirmModal";
import { Button, Space, notification, Modal } from 'antd';
import {
    useGetPendingCustomerQuery,
    useRejectTheReviewMutation
} from '../../features/api/apiSlice';
import ProTable from '@ant-design/pro-table';
function CreateClientReviewPage(props) {
    const [verifyData, setVerifyData] = useState({});
    const [searchData, setSearchData] = useState({});
    const { data, error, isLoading, isFetching, refetch } = useGetPendingCustomerQuery(searchData);
    const [rejectOpen, setRejectOpen] = useState(false);
    const [verifyOpen, setVerifyOpen] = useState(false);
    const [rejectId, setRejectId] = useState(null);
    useEffect(() => {
        refetch();
    }, [])
    const [RejectTheReview, {
        isSuccess: isRejectTheReviewSuccess,
        isError: isRejectTheReviewError,
        error: RejectTheReviewError,
        isLoading: RejectTheReviewLoading,
    }] = useRejectTheReviewMutation();

    useEffect(() => {
        if (isRejectTheReviewSuccess) {
            notification.success({
                message: "Success",
                description: '驳回成功!',
            });
            setRejectOpen(false);
        }
        if (isRejectTheReviewError) {
            notification.error({
                message: "Error",
                description: RejectTheReviewError?.data?.error || "驳回失败!",
            });
        }
    }, [isRejectTheReviewSuccess, isRejectTheReviewError])

    const rejectModalOpen = (id) => {
        setRejectId(id)
        setRejectOpen(true);
    }
    const VerifyHandler = (record) => {
        setVerifyData(record);
        setVerifyOpen(true);
    }
    const handleReject = () => {
        RejectTheReview({ id: rejectId, review_status: 1 })
    }

    const columns = [
        {
            title: '申请提交人',
            dataIndex: 'applicant',
            key: 'applicant',
            search: false,
        },
        {
            title: '客户ID',
            dataIndex: 'uid',
            key: 'customerId',
            search: true,
        },
        {
            title: '客户名',
            dataIndex: 'username',
            key: 'customerName',
            search: true,
        },
        {
            title: '客户对接人',
            dataIndex: 'contact_name',
            key: 'contact',
            search: false,
        },
        {
            title: '账单负责人',
            dataIndex: 'user_name',
            key: 'customerAssignedTo',
            search: true,
        },
        {
            title: '业务类型',
            dataIndex: 'business_type',
            key: 'businessType',
            search: true,
        },
        {
            title: '账期',
            dataIndex: 'billing_cycle',
            key: 'billing_cycle',
            search: false,
            render: (text, record) => {
                if (text === '预充') {
                    return <div>预充</div>
                } else {
                    return <div>{text} 天</div>
                }
            }
        },
        {
            title: '币种',
            dataIndex: 'currency',
            key: 'currency',
            search: false,
            render: (text, record) => {
                if (text === 0) {
                    return <div>USD</div>
                } else if (text === 1) {
                    return <div>CNY</div>
                } else if (text === 2) {
                    return <div>EUR</div>
                } else if (text === 3) {
                    return <div>CAD</div>
                }
            }
        },
        {
            title: '客户授信额度',
            dataIndex: 'credit_line',
            key: 'credit_line',
            search: false,
            render: (text, record) => {
                if (text) {
                    return <div>$ {text}</div>
                } else {
                    return '无额度'
                }
            }
        },
        {
            title: '备注',
            dataIndex: 'comments1',
            key: 'comments1',
            search: false,
        },
        {
            title: '操作',
            search: false,
            fixed: 'right',
            render: (text, record) => (
                <Space>
                    <Button type='link' onClick={() => VerifyHandler(record)}>
                        审核确认
                    </Button>
                    <Button type='link' onClick={() => rejectModalOpen(record.id)}>
                        审核驳回
                    </Button>
                </Space>
            ),
        },
    ]
    return (
        <div>
            <ProTable
                search={false}
                rowKey={(record) => record.id}
                size='small'
                loading={{
                    spinning: isLoading || isFetching || RejectTheReviewLoading,
                    tip: '数据加载中...'
                }}
                columns={columns}
                dataSource={data?.results}
                request={(params) => {
                    setSearchData(params);
                }}
                pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50', '100'],
                    total: data?.count,
                }}
                scroll={{ x: 'max-content' }}
                options={{
                    reload: () => refetch(),
                }}

            />
            <ConfirmModal 
                setOpen={setVerifyOpen} 
                open={verifyOpen} 
                data={verifyData} 
                />
            <Modal
                open={rejectOpen}
                onCancel={() => setRejectOpen(false)}
                onOk={handleReject}
                okText="确认"
                cancelText="取消"
            >
                <p>是否确认驳回？</p>
            </Modal>
        </div>
    );
}

export default CreateClientReviewPage;