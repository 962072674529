import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { ApiProvider } from '@reduxjs/toolkit/dist/query/react';
import { apiSlice } from './features/api/apiSlice';
import axios from 'axios';
import { App as AntdApp } from 'antd';
import 'nprogress/nprogress.css';
const root = ReactDOM.createRoot(document.getElementById('root'));

// change the base URL to the backend server
axios.defaults.baseURL = process.env.REACT_APP_AXIOS_BASE_URL;
root.render(
  <React.StrictMode>
    <AntdApp>
      <ApiProvider api={apiSlice}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ApiProvider>
    </AntdApp>
  </React.StrictMode>
);

