import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Select, notification } from 'antd';
import { useUpdateClientMutation } from '../../features/api/apiSlice';
function ClientInfoEditModal({ state, setState, billingUserData, kaUserData, rateTemplates }) {
    const [updateClient, { isLoading, isError, error, isSuccess }] = useUpdateClientMutation();
    useEffect(() => {
        if (isError) {
            if (error.data && 'uid' in error.data) {
                notification.error({
                    message: "Error",
                    description: '客户ID已存在',
                });
            } else if (error.data && 'non_field_errors' in error.data) {
                notification.error({
                    message: "Error",
                    description:error.data.non_field_errors,
                });
                setState(prevState => ({
                    ...prevState,
                    open: false,
                }))
            } else {
                notification.error({
                    message: "Error",
                    description: "Unknown error",
                });
            }
        }
    }, [isError])
    const PositiveNumberOnly = (rule, value, callback) => {
        return new Promise((resolve, reject) => {
            if (value && !/^[0-9]+(.[0-9]{1,2})?$/.test(value)) {
              reject('请输入可识别的正数!');
            } else if (value && value.length > 20) {
                reject('最多20位!');
            } else {
                resolve();
            }
        });
    }
    useEffect(() => {
        if (isSuccess) {
            notification.success({
                message: "Success",
                description: "客户信息更新成功",
            });
            setState(prevState => ({
                ...prevState,
                open: false,
            }))
        }
    }, [isSuccess])
    const handleCancel = () => {
        setState(prevState => ({
            ...prevState,
            open: false,
        }))
    }
    const handleOk = () => {
        const data = {
            id: state.id,
            uid: state.uid,
            username: state.username,
            is_active: state.status,
            user: state.userAssignedTo,
            rate_template: state.rateTemplate,
            business_type: state.business_type,
            billing_cycle: state.billing_cycle,
            credit_line: state.credit_line,
            contact: state.contact,
            comments1: state.comments1,
            updated_at: state.updated_at,
        }
        updateClient(data);
    }
    return (
        <Modal
            title="编辑客户信息"
            open={state.open}
            onOk={handleOk}
            onCancel={handleCancel}
            confirmLoading={isLoading}
            okText="保存"
            cancelText="取消"
        >
            <Form
                labelCol={{
                    span: 5,
                }}
                wrapperCol={{
                    span: 19,
                }}
                style={{
                    marginTop: '20px'
                }}>
                <Form.Item label="客户ID">
                    <Input
                        style={{ width: '80%' }}
                        value={state.uid}
                        disabled
                    />
                </Form.Item>
                <Form.Item label="客户名">
                    <Input
                        style={{ width: '80%' }}
                        value={state.username}
                        disabled
                    />
                </Form.Item>
                <Form.Item label="客户分类">
                    <Select
                        allowClear
                        style={{ width: '80%' }}
                        value={state.rateTemplate}
                        onChange={(value) => {
                            setState(prevState => ({ ...prevState, rateTemplate: value }))
                        }}
                    >
                        {rateTemplates && rateTemplates.map((rateTemplate) => (
                            <Select.Option key={rateTemplate.id} value={rateTemplate.id}>
                                {rateTemplate.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label="客户状态">
                    <Select
                        style={{ width: '80%' }}
                        value={state.status}
                        onChange={(value) => {
                            setState(prevState => ({ ...prevState, status: value }))
                        }}>
                        <Select.Option value="1">Active</Select.Option>
                        <Select.Option value="0">Idle</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item label="客户对接人">
                    <Select
                        style={{ width: '80%' }}
                        value={state.contact}
                        onChange={(value) => {
                            setState(prevState => ({ ...prevState, contact: value }))
                        }}
                    >
                        {kaUserData && kaUserData.map(user => (
                            <Select.Option key={user.id} value={user.id}>
                                {user.username}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label="账单负责人">
                    <Select
                        style={{ width: '80%' }}
                        value={state.userAssignedTo}
                        onChange={(value) => {
                            setState(prevState => ({ ...prevState, userAssignedTo: value }))
                        }}
                    >
                        {billingUserData && billingUserData.map(user => (
                            <Select.Option key={user.id} value={user.id}>
                                {user.username}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label="业务类型">
                    <Select
                        style={{ width: '80%' }}
                        allowClear
                        value={state.business_type}
                        onChange={(value) => {
                            setState(prevState => ({ ...prevState, business_type: value }))
                        }}
                    >
                        <Select.Option value="渠道">渠道</Select.Option>
                        <Select.Option value="转运">转运</Select.Option>
                        <Select.Option value="代发">代发</Select.Option>
                        <Select.Option value="提柜">提柜</Select.Option>
                        <Select.Option value="海运">海运</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item label="账期">
                    <Select
                        style={{ width: '80%' }}
                        value={state.billing_cycle}
                        onChange={(value) => {
                            setState(prevState => ({ ...prevState, billing_cycle: value }))
                        }}
                    >
                        <Select.Option value="0">0 天</Select.Option>
                        <Select.Option value="7">7 天</Select.Option>
                        <Select.Option value="15">15 天</Select.Option>
                        <Select.Option value="25">25 天</Select.Option>
                        <Select.Option value="30">30 天</Select.Option>
                        <Select.Option value="60">60 天</Select.Option>
                        <Select.Option value="90">90 天</Select.Option>
                        <Select.Option value="预充">预充</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item label="客户授信额度" rules={[{ validator: PositiveNumberOnly}]}>
                    <Input
                        style={{ width: '80%' }}
                        value={state.credit_line}
                        onChange={
                            (e) => {
                                const value = e.target.value;
                                setState(prevState => ({ ...prevState, credit_line: value }))
                            }
                        }/>
                </Form.Item>
                <Form.Item label="备注">
                    <Input.TextArea
                        style={{ width: '80%' }}
                        value={state.comments1}
                        onChange={(e) => {
                            const value = e.target.value;
                            setState(prevState => ({ ...prevState, comments1: value }))
                        }}
                    />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default ClientInfoEditModal;