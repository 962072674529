import {Button, Space, Input, Tag } from 'antd';
import React, { useState, useEffect } from "react";
import AddLocationModal from "./AddLocationModal";
import EditLocationModal from "./EditLocationModal";
import { renderDateTime } from "../utils";
import ProTable from '@ant-design/pro-table';
import {
    useGetLocationQuery,
    useGetCustomerGroupQuery,
} from "../../features/api/apiSlice";
function LocationManagementPage() {
    const [add_open, setAddOpen] = useState(false)
    const [edit_open, setEditOpen] = useState(false)
    const [editData, setEditData] = useState(null);
    const [searchData, setSearchData] = useState({});
    const { data, isLoading, isFetching, refetch } = useGetLocationQuery({ ...searchData }, { refetchOnMountOrArgChange: true });
    const { data: customerGroup } = useGetCustomerGroupQuery({ refetchOnMountOrArgChange: true });

    const OpenEditPage = (record) => {
        setEditData(record);
        setEditOpen(true);
    }

    const columns = [
        {
            title: '序号',
            dataIndex: 'id',
            key: 'id',
            search: false,
        },
        {
            title: '地区名称',
            dataIndex: 'name',
            key: 'name',
            renderFormItem: (item, { defaultRender, ...rest }, form) => {
                return <Input placeholder="请输入地区名称" />;
            }
        },
        {
            title: "客户组",
            dataIndex: 'customer_groups',
            key: 'customer_groups',
            search: false,
            render: (text, record) => (
                <Space size="small">
                    {record?.customer_groups?.map((group, index) => (
                        <Tag color="blue" key={index}>
                            {group}
                        </Tag>
                    ))}
                </Space>
            ),
        },
        {
            title: "更新人",
            dataIndex: 'updated_by',
            key: 'updated_by',
            search: false,
        },
        {
            title: "更新时间",
            dataIndex: 'updated_at',
            key: 'updated_at',
            render: renderDateTime,
            search: false,
        },
        {
            title: "操作",
            render: (text, record) => (
                <Space size="middle">
                    <Button type="link" onClick={() => OpenEditPage(record)}>编辑</Button>
                </Space>
            ),
            search: false,
        },

    ]
    return (
        <div>
            <ProTable
                rowKey={(data) => data.id}
                columns={columns}
                dataSource={data?.results}
                direction="vertical"
                request={(params) => {
                    setSearchData(params);
                }}
                loading={{
                    spinning: isLoading || isFetching,
                    tip: "数据获取中, 请等待...",
                }}
                scroll={{ x: 'max-content' }}
                size="small"
                search={{
                    labelWidth: 'auto',
                    defaultCollapsed: false,
                }}
                pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50', '100'],
                    total: data?.count,
                }}
                options={{
                    reload: () => refetch(),
                }}
                toolbar={{
                    title: ' ',
                    actions: [
                        <Button type="primary" onClick={() => setAddOpen(true)}>+ 新建地区</Button>
                    ]
                }}
            />
            <AddLocationModal
                open={add_open}
                setAddOpen={setAddOpen}
                customerGroup={customerGroup}
            />
            <EditLocationModal
                open={edit_open}
                setEditOpen={setEditOpen}
                editData={editData}
                customerGroup={customerGroup}
            />
        </div>
    )
}

export default LocationManagementPage;