import React, { useState } from 'react';
import { Modal, Input, Form, Select, Space } from 'antd';
const { Option } = Select;
function DisputeBasisModal({ open, setOpen, currentTab, disputeBillingDetails, ruleExpressionsNames, keys }) {
    const [form] = Form.useForm();
    const disputeBasisDefaultValue = currentTab === 'uidError' ? 'UID异常' :
        currentTab === 'duplicateTrackingError' ? 'Dup' : currentTab === 'userDefinedError' ? undefined :
            currentTab === 'masterTrackingError' ? 'No_Knowledge_of_Shipment' : currentTab === 'surchargeError' ? '附加费异常' : '';
    const [disputeBasis, setDisputeBasis] = useState(disputeBasisDefaultValue);
    const [disputeBasisIfOther, setDisputeBasisIfOther] = useState(undefined);
    const onModalOk = () => {
        form.validateFields().then((values) => {
            if (disputeBasis === 'other') {
                values.disputeBasis = disputeBasisIfOther;
            } else {
                values.disputeBasis = disputeBasis;
            }
            disputeBillingDetails({ids: keys, dispute_basis: values.disputeBasis});
            setOpen(false);
        })
    }
    return (
        <Modal
            title="请填写Dispute原因"
            open={open}
            onOk={onModalOk}
            onCancel={() => {
                setOpen(false);
            }}
        >
            <Form
                form={form}
                labelCol={{
                    span: 6,
                }}
                wrapperCol={{
                    span: 18,
                }}
                style={{
                    marginTop: '20px',
                    marginLeft: '15%',
                    marginRight: '20%',
                    maxWidth: '60%'
                }}
            >

                {currentTab === 'userDefinedError' ?
                    <Form.Item label='请选择'>
                        <Select
                            style={{
                                width: '100%'
                            }}
                            value={disputeBasis}
                            onChange={(value) => {
                                setDisputeBasis(value);
                            }}
                        >
                            {ruleExpressionsNames.map((item, index) => {
                                return (
                                    <Option key={index.id} value={item.expression_name}>
                                        {item.expression_name}
                                    </Option>
                                );
                            })}
                            <Option value='other'>Other</Option>
                        </Select>
                    </Form.Item>
                    :
                    <Form.Item label='请填写'>
                        <Input
                            value={disputeBasis}
                            onChange={(e) => {
                                setDisputeBasis(e.target.value);
                            }}
                        />
                    </Form.Item>}
                {currentTab === 'userDefinedError' ?
                    <Form.Item wrapperCol={{span:18, offset: 6}} hidden={disputeBasis === 'other' ? false : true}>
                        <Input
                            value={disputeBasisIfOther}
                            onChange={(e) => {
                                setDisputeBasisIfOther(e.target.value);
                            }}
                            placeholder="请填写具体原因"
                        />
                    </Form.Item> : null
                }
            </Form>
        </Modal>
    )
}

export default DisputeBasisModal;