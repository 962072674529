import React, { useState, useEffect } from 'react';
import { Space, Table, App, Modal, Spin, Button } from 'antd';
import {
    useGetGroundCalculationDetailsQuery,
    useGetAllCustomerUidQuery,
    useGetInvoiceByNameQuery,
    useShipmentConsolidationMutation,
} from '../../features/api/apiSlice';
import {
    ProTable,
} from '@ant-design/pro-components';
import { GroundBillingDetailsColumns, GroundShipmentColumns } from "./GroundCalculationPageColumns";
import GroundCalculationSearchBar from "./GroundCalculationSearchBar";
import axios from 'axios';
import { useSelector } from 'react-redux';
function GroundCalculationPage() {
    const [searchData, setSearchData] = useState({});
    const [pageSize, setPageSize] = useState(10);
    const [sorter, setSorter] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const { data, error, isLoading, isFetching, refetch } = useGetGroundCalculationDetailsQuery({
        ...searchData, pageSize: pageSize, currentPage: currentPage, sortField: sorter.field,
        sortOrder: sorter.order,
    }, { refetchOnMountOrArgChange: true });
    const { data: customerData, refetch: refetchUid } = useGetAllCustomerUidQuery();
    const { data: invoiceNames, refetch: refetchInvoiceNames } = useGetInvoiceByNameQuery();
    const [tableData, setTableData] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [costContent, setCostContent] = useState(null);
    const [openSurcharge, setOpenSurcharge] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [openPkgLoading, setOpenPkgLoading] = useState(false);
    const [openPkg, setOpenPkg] = useState(false);
    const [detailsContent, setDetailsContent] = useState(null);
    const [openCost, setOpenCost] = useState(false);
    const [openDetail, setOpenDetail] = useState(false);
    const [surchargeDetailContent, setSurchargeDetailContent] = useState(null);
    const { notification } = App.useApp();
    const currUser = useSelector(state => state.auth.user);
    const [shipmentConsolidation, {
        error: consolidationError,
        isSuccess: isConsolidationSuccess,
        isError: isConsolidationError,
        isLoading: isConsolidationLoading,
        isFetching: isConsolidationFetching,
    }] = useShipmentConsolidationMutation();
    useEffect(() => {
        refetch();
        refetchUid();
        refetchInvoiceNames();
    }, [])
    useEffect(() => {
        setSelectedRowKeys([]);
    }, [searchData])
    useEffect(() => {
        if (!searchData.isDownload && data) {
            setTableData(data);
        }
    }, [data, searchData.isDownload]);
    useEffect(() => {
        if (isConsolidationError) {
            notification.error({
                message: 'Error',
                description: consolidationError.data?.error || '合票失败,未知原因'
            })
        }
        if (isConsolidationSuccess) {
            notification.success({
                message: 'Success',
                description: '合票成功'
            })
            setSelectedRowKeys([]);
        }
    }, [isConsolidationError, isConsolidationSuccess])
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
            setSelectedRows(selectedRows);
        },
        selectedRowKeys,
    };
    const getSelectedRowsData = () => {
        return tableData.results.filter(row => selectedRowKeys.includes(row.id));
    };
    const handleTableChange = (pagination, filters, sorter) => {
        setSorter(sorter);
    };
    const trackingClickHandler = (record) => {
        setOpenPkg(true);
        setOpenPkgLoading(true);
        setDetailsContent([]);
        axios.post('/accounts/get-ground-calculation-billing-details/', { master_tracking_number: record.master_tracking_number, shipment_number: record.shipment_number }).then(res => {
            setDetailsContent(res.data?.data?.billing_details);
            setOpenPkgLoading(false);
        }).catch(err => {
            notification.error({
                message: 'Unknown Error',
                description: err.response.data.detail
            });
            setOpenPkgLoading(false);
            setOpenPkg(false);
        })
    }
    const showDetailButtonHandler = (record) => {
        axios.get('/accounts/get-shipment-surcharge-details/?shipment_id=' + record.id)
            .then(res => {
                setSurchargeDetailContent(res.data);
                setOpenDetail(true);
            }).catch(err => {
                notification.error({
                    message: 'Unknown Error',
                    description: "获取数据错误"
                });
            })
    }
    const columns = GroundShipmentColumns(trackingClickHandler, setOpenCost, setCostContent, showDetailButtonHandler);
    const surcharge_columns = [{
        title: '费用类型',
        dataIndex: 'type',
        key: 'type'
    }, {
        title: '金额',
        dataIndex: 'amount',
        key: 'amount'
    }];
    const renderModalTable = () => {
        return <Table columns={surcharge_columns} dataSource={modalContent} pagination={false} />;
    };

    const renderCostTable = () => {
        return <Table
            columns={surcharge_columns}
            dataSource={costContent}
            pagination={false}
            rowClassName={rowClassName}  // 应用自定义的行类名函数
        />;
    };
    const rowClassName = (record, index) => {
        // 检查当前行是否为最后一行
        return index === costContent.length - 1 ? 'last-row' : '';
    };
    const surchargeDetailColumns = [
        {
            title: '费用名称',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '数量',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: '总价',
            dataIndex: 'total_price',
            key: 'total_price',
        }
    ]
    const renderPkgTable = () => {
        const PkgColumns = GroundBillingDetailsColumns(setOpenSurcharge, setModalContent);
        return <ProTable
            size="small"
            search={false}
            rowKey={record => record.id}
            columns={PkgColumns}
            dataSource={detailsContent}
            scroll={{ x: 'max-content' }}
            pagination={{
                defaultPageSize: 10,
                total: detailsContent?.count,
            }}
            toolbar={false}
            options={false}
        />
    };

    const consolidationButtonHandler = () => {
        if (selectedRowKeys.length < 2) {
            return notification.error({
                message: 'Error',
                description: '请选择至少两条数据'
            })
        }
        // check if they have the same tracking number and they dont have -S or -R
        for (let i = 0; i < selectedRows.length - 1; i++) {
            if (selectedRows[i].master_tracking_number !== selectedRows[i + 1].master_tracking_number) {
                return notification.error({
                    message: 'Error',
                    description: '请选择相同的跟踪号'
                })
            } else if (selectedRows[i].master_tracking_number.includes('-S') || selectedRows[i].master_tracking_number.includes('-R')) {
                return notification.error({
                    message: 'Error',
                    description: '请选择没有-S或-R的跟踪号'
                })
            } else if (selectedRows[i].status !== '已出账') {
                return notification.error({
                    message: 'Error',
                    description: '请选择已出账的数据'
                })
            }
        }

        const formData = new FormData();
        formData.append('shipment_ids', selectedRowKeys);
        shipmentConsolidation(formData);
    }
    
    return (
        <div>
            <Space
                direction="vertical"
                style={{ display: 'flex' }}>
                <GroundCalculationSearchBar
                    setSearchData={setSearchData}
                    customerData={customerData}
                    invoiceNames={invoiceNames}
                    setCurrentPage={setCurrentPage}
                    setPageSize={setPageSize}
                    selectedRows={tableData && tableData.results ? getSelectedRowsData() : []}
                    selectedRowKeys={selectedRowKeys}
                    setSelectedRowKeys={setSelectedRowKeys}
                />
                <ProTable
                    search={false}
                    rowKey={record => record.id}
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={tableData?.results}
                    loading={isLoading || isFetching}
                    onChange={handleTableChange}
                    pagination={{
                        showSizeChanger: true,
                        pageSize: pageSize,
                        current: currentPage,
                        total: tableData?.count,
                        onChange: (page, pageSize) => {
                            setCurrentPage(page);
                            setPageSize(pageSize);
                            setSelectedRowKeys([]);
                        },
                        onShowSizeChange: (current, size) => {
                            setCurrentPage(current);
                            setPageSize(size);
                            setSelectedRowKeys([]);
                        },
                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                    }}
                    scroll={{ x: 'max-content' }}
                    options={{
                        reload: () => refetch(),
                    }}
                    toolbar={{
                        title: ' ',
                        actions: [
                            (currUser.button_permission?.includes('shipment_consolidation') && 
                            <Button type="primary" loading={isConsolidationFetching || isConsolidationLoading} onClick={consolidationButtonHandler}>合票二次计算</Button>),
                        ]
                    }}
                />
            </Space>
            <Modal
                title="附加费详情"
                open={openSurcharge}
                onOk={() => setOpenSurcharge(false)}
                onCancel={() => setOpenSurcharge(false)}
                width={800}
            >
                {renderModalTable()}
            </Modal>
            <Modal
                title="Details详情"
                open={openPkg}
                onCancel={() => setOpenPkg(false)}
                footer={null}
                style={{ maxHeight: '10%' }}
                width={'100%'}
            >
                <Spin spinning={openPkgLoading}>
                    {renderPkgTable()}
                </Spin>
            </Modal>
            <Modal
                title="费用详情"
                open={openCost}
                onOk={() => setOpenCost(false)}
                onCancel={() => setOpenCost(false)}
                width={800}
            >
                {renderCostTable()}
            </Modal>
            <Modal
                title="附加费详情"
                open={openDetail}
                onCancel={() => setOpenDetail(false)}
                footer={null}
                width={800}
            >
                <Table
                    dataSource={surchargeDetailContent}
                    columns={surchargeDetailColumns}
                    pagination={false}
                />
            </Modal>
        </div>
    )
}

export default GroundCalculationPage;