import React, { useState, useEffect } from 'react';
import { Table, Space, Button, notification, Modal, Menu, Dropdown } from 'antd';
import { FixedTrackingDupTrackingColumns } from "./ErrorHandlingTabColumns";
import { ProTable } from '@ant-design/pro-components';
import {
    useDisputeBillingDetailsMutation,
    useStatusUpdateHandlerMutation,
    useSunkCostMutation,
    useLabelReuseMutation,
} from '../../features/api/apiSlice';
import DuplicateTrackingDetailsModal from './DuplicateTrackingDetailsModal';
import DisputeBasisModal from './DisputeBasisModal';
function DuplicateTrackingsTab({ data, loading, searchData, setSearchData, currentPage, setCurrentPage, pageSize, setPageSize, UIDData }) {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [openSurcharge, setOpenSurcharge] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [dupDetailsModalOpen, setdupDetailsModalOpen] = useState(false);
    const [dupDetailsModalData, setDupDetailsModalData] = useState(null);
    const [disputeBasisModalOpen, setDisputeBasisModalOpen] = useState(false);
    const [disputeBillingDetails, {
        isLoading: disputeLoading,
        isSuccess: isDisputeSuccess,
        isError: isDisputeError,
        error: disputeError,
    }] = useDisputeBillingDetailsMutation();
    const [dupTrackingHandler, {
        isLoading: dupTrackingLoading,
        isSuccess: isDupTrackingSuccess,
        isError: isDupTrackingError,
        error: dupTrackingError,
    }] = useStatusUpdateHandlerMutation();
    const [sunkCostHandler, {
        isLoading: sunkCostLoading,
        isSuccess: isSunkCostSuccess,
        isError: isSunkCostError,
        error: sunkCostError,
    }] = useSunkCostMutation();
    const [labelReuseHandler, {
        isLoading: labelReuseLoading,
        isSuccess: isLabelReuseSuccess,
        isError: isLabelReuseError,
        error: labelReuseError,
    }] = useLabelReuseMutation();
    useEffect(() => {
        setSelectedRowKeys([]);
    }, [searchData])
    useEffect(() => {
        if (isLabelReuseSuccess) {
            notification.success({
                message: 'Success',
                description: '标记为面单复用成功',
            })
            setSelectedRowKeys([]);
        }
        if (isLabelReuseError) {
            notification.error({
                message: 'Error',
                description: labelReuseError?.data?.error ?? '标记为面单复用失败',
            })
        }
    }, [isLabelReuseSuccess, isLabelReuseError, labelReuseError])
    useEffect(() => {
        if (isSunkCostSuccess) {
            notification.success({
                message: 'Success',
                description: '标记为Sunk Cost成功',
            })
            setSelectedRowKeys([]);
        }
        if (isSunkCostError) {
            notification.error({
                message: 'Error',
                description: sunkCostError?.data?.error ?? '标记为Sunk Cost失败',
            })
        }
    }, [isSunkCostSuccess, isSunkCostError, sunkCostError])
    useEffect(() => {
        if (isDupTrackingSuccess) {
            notification.success({
                message: 'Success',
                description: 'Dup tracking success',
            })
            setSelectedRowKeys([]);
        }
        if (isDupTrackingError) {
            notification.error({
                message: 'Error',
                description: dupTrackingError?.data?.error ?? 'Dup tracking failed for unknown reason',
            })
            setSelectedRowKeys([]);
        }
    }, [isDupTrackingSuccess, isDupTrackingError, dupTrackingError])
    useEffect(() => {
        if (isDisputeSuccess) {
            notification.success({
                message: 'Success',
                description: 'Dispute success',
            })
            setSelectedRowKeys([]);
        }
        if (isDisputeError) {
            notification.error({
                message: 'Error',
                description: disputeError?.data?.error ?? 'Dispute failed for unknown reason',
            })
        }
    }, [isDisputeSuccess, isDisputeError, disputeError])
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
        },
        selectedRowKeys,
    };

    const disputeButtonHandler = () => {
        const keys = selectedRowKeys;
        if (keys.length === 0) {
            return notification.error({
                message: 'Error',
                description: '请选择至少一条记录',
            })
        }
        if (UIDData?.count !== 0) {
            return notification.error({
                message: 'Error',
                description: '执行失败,请优先处理完UID异常再执行其他操作!',
            })
        }
        setDisputeBasisModalOpen(true);
        // disputeBillingDetails({ ids: keys, dispute_number: 1 });
    }
    const dupDetailsButtonHandler = (record) => {
        setdupDetailsModalOpen(true);
        setDupDetailsModalData(record);
    }
    const columns = FixedTrackingDupTrackingColumns(setOpenSurcharge, setModalContent, dupDetailsButtonHandler);
    const surcharge_columns = [{
        title: '费用类型',
        dataIndex: 'type',
        key: 'type'
    }, {
        title: '金额',
        dataIndex: 'amount',
        key: 'amount'
    }];
    const renderModalTable = () => {
        return <Table columns={surcharge_columns} dataSource={modalContent} pagination={false} />;
    };
    const handleMenuClick = (e) => {
        if (e.key === '1') {
            dupTrackingHandler({ ids: selectedRowKeys, status: 'original'});
        }
        if (e.key === '2') {
            dupTrackingHandler({ ids: selectedRowKeys, status: 'surcharge' });
        }
        if (e.key === '3') {
            dupTrackingHandler({ ids: selectedRowKeys, status: 'return' });
        }
        if (e.key === '4') {
            const request = {
                ids: selectedRowKeys,
                to_uid: '0005',
                flag: false,
            };
            sunkCostHandler(request);
        }
        if (e.key === '5') {
            const request = {
                ids: selectedRowKeys,
                to_uid: '0007',
                flag: false,
            };
            sunkCostHandler(request);
        }
        if (e.key === '6') {
            const request = {
                ids: selectedRowKeys,
            }
            labelReuseHandler(request);
        }
    };
    const items = [
        {
            label: '标记为原件',
            key: '1',
        },
        {
            label: '标记为Surcharge',
            key: '2',
        },
        {
            label: '标记为Return',
            key: '3',
        },
        {
            label: '标记为自用面单-Company',
            key: '4',
        },
        {
            label: '标记为自用面单-Personal',
            key: '5',
        },
        {
            label: '标记为面单复用',
            key: '6',
        }
    ];
    const menuProps = {
        items,
        onClick: handleMenuClick,
    };
    return (
        <Space direction="vertical" style={{ display: 'flex' }}>
            <ProTable
                search={false}
                columns={columns}
                rowKey={record => record.id}
                loading={loading}
                dataSource={data?.results}
                rowSelection={rowSelection}
                pagination={{
                    showSizeChanger: true,
                    pageSize: pageSize,
                    current: currentPage,
                    total: data?.count,
                    onChange: (page, pageSize) => {
                        setCurrentPage(page);
                        setPageSize(pageSize);
                        setSelectedRowKeys([]);
                    },
                    onShowSizeChange: (current, size) => {
                        setCurrentPage(current);
                        setPageSize(size);
                        setSelectedRowKeys([]);
                    },
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                }}
                scroll={{ x: 'max-content' }}
                options={{
                    reload: false,
                }}
                toolbar={{
                    title: ' ',
                    search: false,
                    actions: [
                        <Dropdown menu={menuProps}>
                            <Button type="primary" loading={dupTrackingLoading || sunkCostLoading || labelReuseLoading}>标记</Button>
                        </Dropdown>,
                        <Button loading={disputeLoading} onClick={disputeButtonHandler}>Dispute</Button>,
                    ],
                }}
            />
            <Modal
                title="附加费详情"
                open={openSurcharge}
                onOk={() => setOpenSurcharge(false)}
                onCancel={() => setOpenSurcharge(false)}
                width={800}
            >
                {renderModalTable()}
            </Modal>
            <DuplicateTrackingDetailsModal
                open={dupDetailsModalOpen}
                setOpen={setdupDetailsModalOpen}
                modalData={dupDetailsModalData}
                />
            <DisputeBasisModal
                open={disputeBasisModalOpen}
                setOpen={setDisputeBasisModalOpen}
                currentTab={"duplicateTrackingError"}
                disputeBillingDetails={disputeBillingDetails}
                keys={selectedRowKeys}
                />
        </Space>
    )
}

export default DuplicateTrackingsTab;