import React, { useState, useEffect } from 'react';
import { 
    useGetRuleExpressionsQuery,
    useCreateRuleExpressionMutation,
    useGetChargeCodeQuery,
    useDeleteRuleExpressionMutation,
} from '../../features/api/apiSlice';
import { Tooltip, Space, Button, notification } from 'antd';
import {
    ProTable,
} from '@ant-design/pro-components';
import AddNewRuleExpressionModal from './AddNewRuleExpressionModal';
import DeleteRuleExpressionModal from './DeleteRuleExpressionModal';
import axios from 'axios';
function RuleExpressionsPage() {
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [addNewModalOpen, setAddNewModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deleteModalData, setDeleteModalData] = useState(null);
    const [showModalOpen, setShowModalOpen] = useState(false);
    const [showModalData, setShowModalData] = useState(null);
    const [showButtonLoading, setShowButtonLoading] = useState(false);
    const { data: chargeCodeData } = useGetChargeCodeQuery();
    const { data, error, isLoading, isFetching, refetch } = useGetRuleExpressionsQuery({
        pageSize: pageSize,
        currentPage: currentPage
    });
    const [createRuleExpression , {
        isError: createRuleExpressionIsError,
        isSuccess: createRuleExpressionIsSuccess,
        error: createRuleExpressionError,
        isLoading: createRuleExpressionIsLoading,
        isFetching: createRuleExpressionIsFetching,
    }] = useCreateRuleExpressionMutation();

    const [deleteRuleExpression, {
        isError: deleteRuleExpressionIsError,
        isSuccess: deleteRuleExpressionIsSuccess,
        error: deleteRuleExpressionError,
        isLoading: deleteRuleExpressionIsLoading,
        isFetching: deleteRuleExpressionIsFetching,
    }] = useDeleteRuleExpressionMutation();

    useEffect(() => {
        if (createRuleExpressionIsSuccess) {
            notification.success({
                message: 'Success',
                description: '创建新规则成功'
            })
            setAddNewModalOpen(false);
        }
        if (createRuleExpressionIsError) {
            notification.error({
                message: 'Error',
                description: createRuleExpressionError?.data?.detail || '创建新规则失败'
            })
        }
    }, [createRuleExpressionIsSuccess, createRuleExpressionIsError])

    useEffect(() => {
        if (deleteRuleExpressionIsSuccess) {
            notification.success({
                message: 'Success',
                description: '删除规则成功'
            })
            setDeleteModalOpen(false);
        }
        if (deleteRuleExpressionIsError) {
            notification.error({
                message: 'Error',
                description: deleteRuleExpressionError?.data?.detail || '删除规则失败'
            })
        }
    }, [deleteRuleExpressionIsSuccess, deleteRuleExpressionIsError])
    useEffect(() => {
        refetch();
    }, [])
    const deleteButtonHandler = (record) => {
        setDeleteModalData(record);
        setDeleteModalOpen(true);
    }
    const showButtonhandler = (record) => {
        const formData = new FormData();
        formData.append('id', record.id);
        setShowButtonLoading(true);
        axios.post('/accounts/fetch-rule-expression-data/', formData).then((res) => {
            setShowButtonLoading(false);
            setShowModalData({
                'ruleName': record.expression_name, 
                'ruleDescription': record.expression_description,
                ...res.data});
            setShowModalOpen(true);
        }).catch((err) => {
            console.log(err);
        })
    }
    const columns = [
        {
            title: '规则名',
            dataIndex: 'expression_name',
            key: 'expression_name',
            width: '50%'
        },
        {
            title: '规则描述',
            dataIndex: 'expression_description',
            key: 'expression_description',
            ellipsis: true,
        },
        {
            title: '操作',
            dataIndex: 'operation',
            key: 'operation',
            width: '20%',
            render: (text, record) => (
                <Space direction='horizontal'>
                    <Button onClick={() => showButtonhandler(record)} type='link' loading={showButtonLoading}>查看</Button>
                    <Button onClick={() => deleteButtonHandler(record)} type="link">删除</Button>
                </Space>
            ),
        }
    ]
    const onAddModalSubmit = (values) => {
        
        const formData = new FormData();
        formData.append('ruleName', values.ruleName);
        formData.append('ruleDescription', values.ruleDescription || '');
        formData.append('items', JSON.stringify(values.items));
        
        createRuleExpression(formData);
    }
    const addRuleExpressionButtonHandler = () => {
        setAddNewModalOpen(true);
    }
    const onDeleteModalSubmit = () => {
        const formData = new FormData();
        formData.append('id', deleteModalData.id);
        deleteRuleExpression(formData);
    }
    return (
        <Space direction="vertical" style={{ display: 'flex'}}>
            <Space direction='horizontal' style={{ display: "flex" }}>
                <Button type="primary" onClick={() => addRuleExpressionButtonHandler()}>添加规则</Button>
            </Space>
        <ProTable
            search={false}
            rowKey={record => record.id}
            columns={columns}
            dataSource={data?.results}
            loading={isLoading || isFetching}
            pagination={{
                showSizeChanger: true,
                pageSize: pageSize,
                current: currentPage,
                total: data?.count,
                onChange: (page, pageSize) => {
                    setCurrentPage(page);
                    setPageSize(pageSize);
                },
                onShowSizeChange: (current, size) => {
                    setCurrentPage(current);
                    setPageSize(size);
                },
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            }}
            options={{
                reload: () => refetch(),
            }}
        />
        <AddNewRuleExpressionModal
            open={addNewModalOpen}
            setOpen={setAddNewModalOpen}
            onSubmit={onAddModalSubmit}
            loading={createRuleExpressionIsLoading || createRuleExpressionIsFetching}
            isSuccess={createRuleExpressionIsSuccess}
            chargeCodeData={chargeCodeData}
            disabled={false}
        />
        <DeleteRuleExpressionModal
            open={deleteModalOpen}
            setOpen={setDeleteModalOpen}
            data={deleteModalData}
            onSubmit={onDeleteModalSubmit}
            loading={deleteRuleExpressionIsLoading || deleteRuleExpressionIsFetching}
        />
        <AddNewRuleExpressionModal
            open={showModalOpen}
            setOpen={setShowModalOpen}
            onSubmit={() => setShowModalOpen(false)}
            data={showModalData}
            loading={false}
            chargeCodeData={chargeCodeData}
            isSuccess={false}
            disabled={true}
        />
        </Space>
    );
}

export default RuleExpressionsPage;