import React from "react";
import { Modal, Typography } from "antd";
const { Text } = Typography;
function DeleteRuleExpressionModal ({open, setOpen, data, onSubmit, loading}) {
    console.log(data);
    return (
        <Modal
            title="删除规则表达式"
            open={open}
            onOk={onSubmit}
            confirmLoading={loading}
            onCancel={() => setOpen(false)}
        >
            <Text>
                是否确认删除规则表达式: {data?.expression_name}?
            </Text>
        </Modal>
    )
}

export default DeleteRuleExpressionModal;
