import React, { useState, useEffect } from 'react';
import { Space, Button, App, Select, TreeSelect, Input, DatePicker, theme } from 'antd';
import {
    useGetLogisticsServiceByNameQuery,
    useGetInvoiceQuery,
    useDownloadBillingBatchMutation,
    useGetBillingInvoiceNamesQuery,
    useGetAllCustomerWithGroupQuery,
    useEditBillingBatchInfoMutation,
} from '../../features/api/apiSlice';
import { renderDateTime } from '../utils';
import BillingBatchInfoEditModal from './BillingBatchInfoEditModal';
import ProTable from '@ant-design/pro-table';
import { useSelector } from 'react-redux';
const { RangePicker } = DatePicker;
function BillingBatchManagementPage () {
    const { token } = theme.useToken();
    const [searchData, setSearchData] = useState({});
    const { data, error, isError, isLoading, isFetching, refetch } = useGetInvoiceQuery({ ...searchData });
    const { data: logisticsService } = useGetLogisticsServiceByNameQuery();
    const { data: customerWithGroupData, refetch: refetchUserGroup } = useGetAllCustomerWithGroupQuery();
    const { data: billingInvoiceNames } = useGetBillingInvoiceNamesQuery();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [infoEditModalOpen, setInfoEditModalOpen] = useState(false);
    const [infoEditModalData, setInfoEditModalData] = useState({});
    const currUser = useSelector((state) => state.auth.user);
    const [editBillingBatchInfo, {
        isSuccess: editBillingBatchInfoSuccess,
        isError: editBillingBatchInfoError,
        error: editBillingBatchInfoErrorInfo,
        isLoading: editBillingBatchInfoLoading,
    }] = useEditBillingBatchInfoMutation();
    const { notification } = App.useApp();
    const formattedTreeSelectData = customerWithGroupData?.map(group => ({
        title: group.name,
        value: String(group.name),
        key: String(group.name),
        children: group.customers.map(customer => ({
            title: customer.uid,
            value: customer.uid,
            key: customer.uid
        }))
    }));
    const [downloadBillingBatch, {
        isSuccess: downloadBillingBatchSuccess,
        isLoading: downloadBillingBatchLoading,
        isError: downloadBillingBatchError,
        error: downloadBillingBatchErrorInfo,
    }] = useDownloadBillingBatchMutation();

    useEffect(() => {
        refetch();
        refetchUserGroup();
    }, [])
    
    useEffect(() => {
        if (isError) {
            notification.error({
                message: "Error",
                description: error?.data?.error || "未知原因,获取数据失败",
            });
        }
    }, [isError]);

    useEffect(() => {
        if (editBillingBatchInfoSuccess) {
            notification.success({
                message: "Success",
                description: "修改成功",
            });
            setInfoEditModalOpen(false);
            setSelectedRowKeys([]);
        }
        if (editBillingBatchInfoError) {
            notification.error({
                message: "Error",
                description: editBillingBatchInfoErrorInfo?.data?.error || "未知原因,修改失败",
            });
        }
    }, [editBillingBatchInfoSuccess, editBillingBatchInfoError]);
    
    useEffect(() => {
        if (downloadBillingBatchSuccess) {
            notification.success({
                message: '下载成功',
                description: '下载成功',
            });
        }
        if (downloadBillingBatchError) {
            notification.error({
                message: '下载失败',
                description: downloadBillingBatchErrorInfo?.data?.error || "未知原因,下载失败",
            });
        }
    }, [downloadBillingBatchSuccess, downloadBillingBatchError])
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
            setSelectedRows(selectedRows);
        },
        selectedRowKeys,
      };
    const columns = [
        {
            title: '出账批次编号',
            dataIndex: 'name',
            key: 'billingBatchId',
            search: true,
            renderFormItem: (item, { type, defaultRender, ...rest }) => {
                return <Input placeholder="请输入内容"/>
            }
        },
        {
            title: '客户ID',
            dataIndex: ['customer','uid'],
            key: 'uid',
            search: true,
            renderFormItem: (item, { type, defaultRender, ...rest }) => {
                return <TreeSelect
                        allowClear
                        style={{ textAlign: "left" }}
                        treeData={formattedTreeSelectData}
                        treeCheckable={true}
                        showCheckedStrategy='SHOW_PARENT'
                        placeholder="请选择"
                    />
            }
        },
        {
            title: '包裹类型',
            dataIndex: 'package_type',
            key: 'package_type',
            search: false,
        },
        {
            title: '导入批次编号',
            dataIndex: 'billing_invoice_names',
            key: 'billingInvoice',
            search: true,
            render: (text, record) => {
                return <Space direction="vertical">
                    {record.billing_invoice_names.split("\n").map((name) => {
                        return <span key={name}>{name}</span>
                    })}
                </Space>
            },
            renderFormItem: (item, { type, defaultRender, ...rest }) => {
                return <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    style={{ textAlign: "left" }}
                    placeholder="请选择"
                    filterOption={(input, option) =>
                        (option?.label ?? '')
                            .toLowerCase()
                            .includes(input.toLowerCase())
                    }
                    tokenSeparators={[',', ' ']}>
                    {billingInvoiceNames && billingInvoiceNames.map(billingInvoiceName => (
                        <Select.Option key={billingInvoiceName.id} value={billingInvoiceName.id} label={billingInvoiceName.name}>
                            {billingInvoiceName.name}
                        </Select.Option>))}
                </Select>
            }
        },
        {
            title: '物流服务',
            dataIndex: 'service_names',
            key: 'logisticsService',
            render: (text, record) => {
                return <Space direction="vertical">
                    {record.service_names.split(",").map((name) => {
                        return <span key={name}>{name}</span>
                    })}
                </Space>
            },
            renderFormItem: (item, { type, defaultRender, ...rest }) => {
                return <Select placeholder='请选择' allowClear style={{ textAlign: "left" }} mode='multiple'>
                    {logisticsService && logisticsService.map((item) => (
                        <Select.Option key={item.id} value={item.name}>{item.name}</Select.Option>
                    ))}
                </Select>
            }
        },
        {
            title: '账单状态',
            dataIndex: 'status',
            key: 'status',
            search: true,
            renderFormItem: (item, { type, defaultRender, ...rest }) => {
                return <Select
                    placeholder="请选择"
                    allowClear
                    style={{ textAlign: "left" }}
                >
                    <Select.Option value="0">未下载</Select.Option>
                    <Select.Option value="1">已下载</Select.Option>
                </Select>
            }
        },
        {
            title: '子单数',
            dataIndex: 'package_count',
            key: 'package_count',
            search: false,
        },
        {
            title: 'Net Charge Amount',
            dataIndex: 'net_charge_amount',
            key: 'net_charge_amount',
            search: false,
        },
        {
            title: 'Billed Amount',
            dataIndex: 'billed_amount',
            key: 'billed_amount',
            search: false,
        },
        {
            title: 'Actual Billed Amount',
            dataIndex: 'actual_billed_amount',
            key: 'actual_billed_amount',
            search: false,
        },
        {
            title: 'Actual Billed Date',
            dataIndex: 'actual_bill_date',
            key: 'actual_bill_date',
            search: false,
        },
        {
            title: '账单负责人',
            dataIndex: ['user', 'username'],
            key: 'user_in_charge',
            search: false,
        },
        {
            title: '完成出账时间',
            dataIndex: 'bill_time',
            key: 'bill_time',
            render: renderDateTime,
            search: true,
            renderFormItem: (item, { type, defaultRender, ...rest }) => {
                return <RangePicker style={{width: '100%'}} />
            }
        }
    ]
    const downloadBillingBatchHandler = () => {
        if (selectedRowKeys.length === 0) {
            return notification.error({
                message: "Error",
                description: "请选择至少一条出账批次",
            });
        }
        downloadBillingBatch({ids: selectedRowKeys})
    }
    const infoEditButtonHandler = () => {
        if (selectedRowKeys.length !== 1) {
            return notification.error({
                message: "Error",
                description: "请选择单一记录",
            });
        }
        if (selectedRows[0].status === '确认出账') {
            return notification.error({
                message: "Error",
                description: "确认出账状态不支持修改信息",
            })
        }
        setInfoEditModalData(selectedRows[0]);
        setInfoEditModalOpen(true);
    }
    return (
        <div>
            <ProTable
                search={{
                    labelWidth: 'auto',
                    defaultCollapsed: false,
                    style: {
                        maxWidth: 'none',
                        background: token.colorFillAlter,
                        borderRadius: token.borderRadiusLG,
                        padding: 24,
                    }
                }}
                size='small'
                rowKey={record => record.id}
                rowSelection={rowSelection}
                columns={columns}
                dataSource={data?.results}
                request={(params) => {
                    console.log(params);
                    setSearchData(params);
                }}
                loading={isLoading || isFetching}
                pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50', '100'],
                    total: data?.count,
                }}
                scroll={{x: columns.length * 200}}
                options={{
                    reload: () => refetch(),
                }}
                toolbar={{
                    title: ' ',
                    actions: [
                        (currUser.button_permission?.includes('download_billing_batch') && <Button type="primary" onClick={downloadBillingBatchHandler} loading={downloadBillingBatchLoading}>下载数据</Button>),
                        (currUser.button_permission?.includes('edit_billing_batch_info') && <Button type="primary" onClick={infoEditButtonHandler}>修改信息</Button>)
                    ]
                }}
            />
            <BillingBatchInfoEditModal
                open={infoEditModalOpen}
                setOpen={setInfoEditModalOpen}
                initialValues={infoEditModalData}
                editBillingBatchInfo={editBillingBatchInfo}
                loading={editBillingBatchInfoLoading}
            />
        </div>
    )
}

export default BillingBatchManagementPage;