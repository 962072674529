import React, { useState, useEffect } from 'react';
import UploadQuoteSheetModal from './UploadQuoteSheetModal';
import { Table, Button, Space, notification, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useGetUserQuery } from '../../features/api/apiSlice';
import { b64toBlob } from '../utils';
import { 
    useGetRateExcelQuery, 
    useGetLogisticsSupplierQuery, 
    useGetRateTemplateQuery,
    useGetAllCustomerUidQuery,
    useGetLogisticsServiceByNameQuery,
} from '../../features/api/apiSlice';
import QuoteSheetSearchBar from './QuoteSheetSearchBar';
import axios from 'axios';
import PreViewQuoteSheetModal from "./PreViewQuoteSheetModal";
import {useSelector} from "react-redux";
import DeleteQuoteSheetModal from "./DeleteQuoteSheetModal";
function QuoteSheetPage(props) {
    const [UploadQuoteSheetModalOpen, setUploadQuoteSheetModalOpen] = useState(false);
    const [PreViewQuoteSheetModalOpen, setPreViewQuoteSheetModalOpen] = useState(false);
    const [DeleteQuoteSheetModalOpen, setDeleteQuoteSheetModalOpen] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchData, setSearchData] = useState({});
    const [selectRows, setSelectRows] = useState({});
    const { data, error, isError, isLoading, isFetching, refetch } = useGetRateExcelQuery({...searchData, pageSize: pageSize, currentPage: currentPage});
    const { data: customerData, refetch: refetchUid } = useGetAllCustomerUidQuery();
    const { data: userData, error: userError, isLoading: userIsLoading, refetch: refetchUserData } = useGetUserQuery();
    const { data: rateTemplates, refetch: refetchRateTemplates } = useGetRateTemplateQuery();
    const { data: logisticsSupplier } = useGetLogisticsSupplierQuery();
    const { data: logisticsService } = useGetLogisticsServiceByNameQuery();
    useEffect(() => {
        refetch();
        refetchRateTemplates();
        refetchUserData();
        refetchUid();
    }, [])
    const createButtonHandler = () => {
        setUploadQuoteSheetModalOpen(true);
    }

    const preViewExcelHandler = (record) => {
        setSelectRows(record);
        setPreViewQuoteSheetModalOpen(true);
    }

    const DeleteExcelHandler = (record) => {
        setSelectRows(record);
        setDeleteQuoteSheetModalOpen(true);
    }
    const auth = useSelector((store) => store.auth.user);
    let actionItems = [
        {
            key: '1',
            label: '预览报价表',
        },
        {
            key: '2',
            label: '下载Excel',
        },
        {
            key: '3',
            label: '删除报价表',
        }
    ];
    //
    if (!(auth.username === "admin" || auth.username === "songru" || auth.username === "siwei")) {
        actionItems = actionItems.filter(item => item.label !== '删除报价表');
    }
    const downloadExcelHandler = (record) => {
        axios.post('/accounts/download-rate-excel/', {'file_path': record.file_path}, {
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'include',
            }
        }).then(res => {
            const url = window.URL.createObjectURL(b64toBlob(res.data.data, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${record.rate_template.name}报价表.xlsx`);
            document.body.appendChild(link);
            link.click();
        }).catch(err => {
            // if response is 404, then the file is not found
            if (err.response.status === 404) {
                notification.error({
                    message: '下载失败',
                    description: '文件不存在',
                });
            } else {
                notification.error({
                    message: '下载失败',
                    description: '请联系管理员',
                });
            }
        })
    }
    const handleActionMenuClick = (e, record) => {
        switch (e.key) {
            case '1':
                preViewExcelHandler(record);
                break;
            case '2':
                downloadExcelHandler(record);
                break;
            case '3':
                DeleteExcelHandler(record);
                break;
            default:
                break;
        }
    }
    const columns = [
        {
            title: '客户分类',
            dataIndex: ['rate_template', 'name'],
            key: 'rateTemplate',
        },
        {
            title: '物流商',
            dataIndex: ['logistics_supplier', 'name'],
            key: 'logisticsSupplier',
        },
        {
            title: '物流服务',
            dataIndex: 'logistics_service',
            key: 'logisticsService',
        },
        {
            title: '生效开始时间',
            dataIndex: 'start_date',
            key: 'start_date',
            width: 150,
        },
        {
            title: '生效结束时间',
            dataIndex: 'end_date',
            key: 'end_date',
            width: 150,
        },
        {
            title: '备注',
            dataIndex: 'note',
            key: 'note',
        },
        {
            title: '操作',
            key: 'action',
            render: (text, record) => (
                <Dropdown
                    menu={{
                        items: actionItems,
                        onClick: (e) => handleActionMenuClick(e, record),
                    }}
                >
                    <Button onClick={(e) => e.preventDefault}>
                        <Space>
                            请选择 <DownOutlined />
                        </Space>
                    </Button>
                </Dropdown>
            )
        }
    ]

    return (
        <div>
            <Space direction="vertical"
                   size="middle"
                   style={{
                       display: 'flex',
                   }}>
                    <QuoteSheetSearchBar 
                        customerData={customerData}
                        logisticsSupplier={logisticsSupplier}
                        logisticsService={logisticsService}
                        rateTemplates={rateTemplates}
                        setSearchData={setSearchData}
                        setCurrentPage={setCurrentPage}
                        setPageSize={setPageSize}
                        />
                <Space direction="vertical"
                       size="small"
                       style={{
                           display: 'flex',
                       }}>
                    <Space style={{ display: "flex" }}>
                        <Button type="primary" onClick={createButtonHandler}>上传报价表Excel</Button>
                    </Space>
                    <Table
                        rowKey={(record) => record.id}
                        loading={isLoading || isFetching}
                        columns={columns}
                        dataSource={data?.results}
                        pagination={{
                            showSizeChanger: true,
                            pageSize: pageSize,
                            current: currentPage,
                            total: data?.count,
                            onChange: (page, pageSize) => {
                                setCurrentPage(page);
                                setPageSize(pageSize);
                            },
                            onShowSizeChange: (current, size) => {
                                setCurrentPage(current);
                                setPageSize(size);
                            },
                            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                        }}
                        scroll={{ x: 1500 }}
                    />
                </Space>
            </Space>
            <UploadQuoteSheetModal
                open={UploadQuoteSheetModalOpen}
                setOpen={setUploadQuoteSheetModalOpen}
                rateTemplates={rateTemplates}
                logisticsSupplier={logisticsSupplier}
                logisticsService={logisticsService}
                userData={userData}
            />
            <PreViewQuoteSheetModal
                open={PreViewQuoteSheetModalOpen}
                setOpen={setPreViewQuoteSheetModalOpen}
                selectRows={selectRows}
            />
            <DeleteQuoteSheetModal
                open={DeleteQuoteSheetModalOpen}
                setOpen={setDeleteQuoteSheetModalOpen}
                selectRows={selectRows}
                refetch={refetch}
            />
        </div>
    );
}
export default QuoteSheetPage;