import React, { useState, useEffect } from 'react';
import { Form, Input, Modal, Row, Col, Tree, Select, App, Steps, Button, theme, Result, Typography } from 'antd';
import {
    useCreateRoleMutation
} from "../../features/api/apiSlice";
import axios from 'axios';
import { useSelector } from 'react-redux';
const { Text } = Typography;
const AddRoleModal = ({ open, setAddOpen, department }) => {
    const [pagePermissions, setPagePermissions] = useState([]);
    const [buttonPermissions, setButtonPermissions] = useState([]);
    const [createRole, { isError, isLoading, isFetching, error, isSuccess }] = useCreateRoleMutation();
    const [roleInfo, setRoleInfo] = useState({});
    const [checkedPageKeys, setCheckedPageKeys] = useState([]);
    const [expandedPageKeys, setExpandedPageKeys] = useState([]);
    const [checkedButtonKeys, setCheckedButtonKeys] = useState([]);
    const [expandedButtonKeys, setExpandedButtonKeys] = useState([]);
    const [nextLoading, setNextLoading] = useState(false);
    const [current, setCurrent] = useState(0);
    const [form] = Form.useForm();
    const { notification } = App.useApp();
    const { token } = theme.useToken();
    const currUser = useSelector((state) => state.auth.user);
    const contentStyle = {
        maxWidth: 'none',
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
    };
    const scrollableStyle = {
        maxHeight: '400px',
        overflowY: 'auto',
        border: "1px solid #d9d9d9",
        borderRadius: "2px",
        padding: '8px',
        background: token.colorFillAlter,
    }

    const next = () => {
        if (current === 0) {
            form.validateFields().then(values => {
                setNextLoading(true);
                const formData = new FormData();
                formData.append('department_id', values.department);
                axios.post('/accounts/get-page-permission-by-department/', formData).then((res) => {
                    if (values.is_manager) {
                        setCheckedPageKeys(res.data.keys);
                    } else {
                        setCheckedPageKeys([]);
                    }
                    setExpandedPageKeys(res.data.expandedKeys)
                    setNextLoading(false);
                    setPagePermissions(transformPermissions(res.data.data));
                    setRoleInfo(values);
                    setCurrent(current + 1);
                }).catch((err) => {
                    setNextLoading(false);
                    console.log(err);
                    notification.error({
                        message: "Error",
                        description: err?.response?.data?.error || "获取页面权限失败",
                    })
                })
            }).catch((info) => {
                console.log("Validate Failed:", info);
            });
        } else if (current === 1) {
            setNextLoading(true);
            const formData = new FormData();
            formData.append('department_id', roleInfo.department);
            formData.append('page_ids', checkedPageKeys);
            axios.post('/accounts/get-button-permission-by-page-under-department/', formData)
                .then((res) => {
                    if (roleInfo.is_manager) {
                        setCheckedButtonKeys(res.data.keys);
                    } else {
                        setCheckedButtonKeys([]);
                    }
                    setExpandedButtonKeys(res.data.expandedKeys)
                    setNextLoading(false);
                    setButtonPermissions(transformPermissions(res.data.data));
                    setCurrent(current + 1);
                }).catch((err) => {
                    setNextLoading(false);
                    notification.error({
                        message: "Error",
                        description: err?.response?.data?.error || "Something went wrong",
                    })
                    console.log(err);
                });
        } else if (current === 2) {
            const formData = new FormData();
            formData.append('department', roleInfo.department);
            formData.append('name', roleInfo.name);
            if (roleInfo.description) { formData.append('description', roleInfo.description); }
            formData.append('is_manager', roleInfo.is_manager);
            formData.append('page_permission', checkedPageKeys);
            formData.append('button_permission', checkedButtonKeys);
            createRole(formData);
        } else {
            setCurrent(current + 1);
        }
    }

    const prev = () => {
        setCurrent(current - 1);
    }

    useEffect(() => {
        if (isError) {
            console.log(error);
            notification.error({
                message: "Error",
                description: error?.data?.error || "Something went wrong",
            });
        }
        if (isSuccess) {
            setCurrent(current + 1);
        }
    }, [isError, isSuccess, error]);

    const transformPermissions = (data) => {
        if (data) {
            return Object.entries(data).map(([key, value]) => {
                const node = {
                    title: key,
                    key: value.id,
                    id: value.id,
                };

                if (value.children && Object.keys(value.children).length > 0) {
                    node.children = transformPermissions(value.children);
                }

                return node;
            });
        }
    }

    const onPageCheck = (checkedKeys) => {
        setCheckedPageKeys(checkedKeys);
    }

    const onButtonCheck = (checkedKeys) => {
        setCheckedButtonKeys(checkedKeys);
    }
    
    const onPageExpand = (expandedKeys) => {
        setExpandedPageKeys(expandedKeys);
    }

    const onButtonExpand = (expandedKeys) => {
        setExpandedButtonKeys(expandedKeys);
    }

    const steps = [
        {
            title: '基本信息',
            content: (<Form form={form} layout="vertical">
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="department"
                            label="部门"
                            rules={[{ required: true, message: '请选择部门' }]}
                            initialValue={
                                currUser?.is_superuser ? undefined : currUser?.department_id
                            }
                        >
                            <Select
                                placeholder='请选择'
                                allowClear
                                style={{ textAlign: "left" }}
                                disabled={!currUser?.is_superuser}
                            >
                                {department?.results && department.results.map((item) => (
                                    <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="name"
                            label="角色名"
                            rules={[{ required: true, message: '请输入角色名' }]}
                        >
                            <Input placeholder="请输入" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="是否为Manager"
                            name="is_manager"
                            rules={[{ required: true, message: '请选择是否为Manager' }]}
                        >
                            <Select
                                placeholder='请选择'
                                allowClear
                                style={{ width: '100%' }}
                            >
                                <Select.Option key={"m_true"} value={true}>
                                    是
                                </Select.Option>
                                <Select.Option key={"m_false"} value={false}>
                                    否
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="description"
                            label="角色说明"
                        >
                            <Input.TextArea placeholder="请输入" rows={4} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>)
        },
        {
            title: '页面权限',
            content: (
                <div>
                    {roleInfo.is_manager && <div style={{ marginBottom: '16px' }}>
                        <Text style={{ marginBottom: '16px' }}>manager默认拥有该部门全部权限, 无法修改</Text></div>}
                    <div style={scrollableStyle}>
                        <Tree
                            disabled={roleInfo?.is_manager}
                            checkable
                            onExpand={onPageExpand}
                            expandedKeys={expandedPageKeys}
                            onCheck={onPageCheck}
                            treeData={pagePermissions}
                            checkedKeys={checkedPageKeys}
                        />
                    </div>
                </div>)
        },
        {
            title: '按钮权限',
            content: (
                <div>
                    {roleInfo.is_manager && <div style={{ marginBottom: '16px' }}>
                        <Text style={{ marginBottom: '16px' }}>manager默认拥有该部门全部权限, 无法修改</Text></div>}
                    <div style={scrollableStyle}>
                        <Tree
                            disabled={roleInfo?.is_manager}
                            checkable
                            onExpand={onButtonExpand}
                            expandedKeys={expandedButtonKeys}
                            onCheck={onButtonCheck}
                            treeData={buttonPermissions}
                            checkedKeys={checkedButtonKeys}
                        />
                    </div>
                </div>)
        },
        {
            title: '新建成功',
            content: (
                <Result
                    status="success"
                    title="成功创建角色"
                />
            )
        }
    ]
    const items = steps.map((item) => ({
        key: item.title,
        title: item.title,
    }));
    return (
        <div>
            <Modal
                title="新建角色"
                open={open}
                width={1200}
                onCancel={() => {
                    if (current === steps.length - 1) {
                        setAddOpen(false);
                        setCurrent(0);
                        form.resetFields();
                        setCheckedButtonKeys([]);
                        setCheckedPageKeys([]);
                    } else {
                        setCurrent(0);
                        setAddOpen(false);
                    }
                }}
                footer={null}
                confirmLoading={isLoading}
            >
                <Steps current={current} items={items} style={{ marginBottom: "16px" }} />
                <div style={contentStyle}>{steps[current].content}</div>
                <div style={{ marginTop: 24 }}>
                    {current < steps.length - 1 && (
                        <Button type="primary" loading={nextLoading || isLoading || isFetching} onClick={() => next()}>
                            Next
                        </Button>
                    )}
                    {current === steps.length - 1 && (
                        <Button type="primary" onClick={() => {
                            setAddOpen(false);
                            setCurrent(0);
                            form.resetFields();
                            setCheckedButtonKeys([]);
                            setCheckedPageKeys([]);
                        }}>
                            Done
                        </Button>
                    )}
                    {current > 0 && current !== steps.length - 1 && (
                        <Button
                            style={{
                                margin: '0 8px',
                            }}
                            onClick={() => prev()}
                        >
                            Previous
                        </Button>
                    )}
                </div>
            </Modal>
        </div>
    );
};

export default AddRoleModal;
