import React from 'react';
import {Button, Col, Form, Input, Row, Space, theme, Select, DatePicker, TreeSelect } from 'antd';
import moment from 'moment-timezone';
const {RangePicker} = DatePicker;
const BillingBatchSearchBar = ({ billingInvoiceNames, setSearchData, customerData, logisticsService, setCurrentPage, setPageSize, treeSelectData }) => {
    const {token} = theme.useToken();
    const [form] = Form.useForm();
    const formStyle = {
        maxWidth: 'none',
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
    };

    const getFields = () => {
        const children = [];
        children.push(
            <Col span={8} key={1}>
                <Form.Item name="uid" label="客户ID">
                    <TreeSelect
                        allowClear
                        style={{ textAlign: "left" }}
                        treeData={treeSelectData}
                        treeCheckable={true}
                        showCheckedStrategy='SHOW_PARENT'
                        placeholder="请选择"
                    />
                </Form.Item>
            </Col>,
            <Col span={8} key={2}>
                <Form.Item name="billingInvoice" label="导入批次编号">
                    <Select
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        style={{ textAlign: "left" }}
                        filterOption={(input, option) =>
                            (option?.label ?? '')
                                .toLowerCase()
                                .includes(input.toLowerCase())
                        }>
                        {billingInvoiceNames && billingInvoiceNames.map(billingInvoiceName => (
                            <Select.Option key={billingInvoiceName.id} value={billingInvoiceName.id} label={billingInvoiceName.name}>
                                {billingInvoiceName.name}
                            </Select.Option>))}
                    </Select>
                </Form.Item>
            </Col>,
            <Col span={8} key={3}>
                <Form.Item name="status" label="状态">
                    <Select
                        placeholder="请选择"
                        allowClear
                        style={{ textAlign: "left" }}
                    >
                        <Select.Option value="0">未下载</Select.Option>
                        <Select.Option value="1">已下载</Select.Option>
                    </Select>
                </Form.Item>
            </Col>,
            <Col span={8} key={4}>
                <Form.Item name="logisticsService" label="物流服务">
                    <Select placeholder='请选择' allowClear style={{textAlign: "left"}} mode='multiple'>
                        {logisticsService && logisticsService.map((item) => (
                            <Select.Option key={item.id} value={item.name}>{item.name}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Col>,
            <Col span={8} key={5}>
                <Form.Item name="billingBatchId" label="出账批次编号">
                    <Input placeholder="请输入内容"/>
                </Form.Item>
            </Col>,
            <Col span={8} key={6}>
                <Form.Item name="billTime" label="完成出账时间">
                    <RangePicker style={{width: '100%'}} format="YYYY-MM-DD"/>
                </Form.Item>
            </Col>,
        );
        return children;
    };

    const onFinish = (values) => {
        const searchData = {...values};
        if (searchData.billTime) {
            searchData.billTimeAfter = moment(searchData.billTime[0].toDate()).startOf('day').utc().format('YYYY-MM-DD HH:mm:ss');
            searchData.billTimeBefore = moment(searchData.billTime[1].toDate()).endOf('day').utc().format('YYYY-MM-DD HH:mm:ss');
            delete searchData.billTime;
        }
        // trim all values
        Object.keys(searchData).forEach(key => {
            if (typeof searchData[key] === 'string') {
                searchData[key] = searchData[key].trim();
            }
        });
        setSearchData(searchData);
        setCurrentPage(1);
        setPageSize(10);
    };

    return (
        <Form form={form} name="advanced_search" style={formStyle} onFinish={onFinish}>
            <Row gutter={24}>{getFields()}</Row>
            <div
                style={{
                    textAlign: 'right',
                }}
            >
                <Space size="small">
                    <Button type="primary" htmlType="submit">
                        Search
                    </Button>
                    <Button
                        onClick={() => {
                            form.resetFields();
                        }}
                    >
                        Clear
                    </Button>
                </Space>
            </div>
        </Form>
    );
};

export default BillingBatchSearchBar;