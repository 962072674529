import React, {useEffect, useState} from 'react';
import {
    useAddNewFuelSurchargeMutation,
    useGetFuelSurchargeManagementQuery, 
    useGetLogisticsServiceByNameQuery,
    useUpdateFuelSurchargeManagementMutation,
} from '../../features/api/apiSlice';
import {Button, App, Space, Switch, Select, DatePicker, theme } from 'antd';
import FuelSurchargeEditModal from "./FuelSurchargeEditModal";
import FuelSurchargeAddModal from "./FuelSurchargeAddModal";
import ProTable from '@ant-design/pro-table';
const { RangePicker } = DatePicker;
function FuelSurchargeManagementPage() {
    const { token } = theme.useToken();
    const [searchData, setSearchData] = useState({});
    const [updateEnableLoading, setUpdateEnableLoading] = useState(false);
    const [row, setRow] = useState({});
    const { data, error, isError, isLoading, isFetching, refetch } = useGetFuelSurchargeManagementQuery({ ...searchData });
    const { data: logisticsService, refetch: refetchLogisticsServiceNames } = useGetLogisticsServiceByNameQuery();
    const [fuelSurchargeEditModalVisible, setFuelSurchargeEditModalVisible] = useState(false);
    const [fuelSurchargeAddModalVisible, setFuelSurchargeAddModalVisible] = useState(false);
    const [AddFuelSurcharge, {
        isSuccess: isAFSSuccess,
        isError: isAFSError,
        error: AFSError,
        isLoading: isAFSLoading,
        isFetching: isAFSFetching
    }] = useAddNewFuelSurchargeMutation();
    const { notification } = App.useApp();
    useEffect(() => {
        if (isAFSSuccess) {
            notification.success({
                message: "Success",
                description: '新增燃油率成功!',
            })
            setFuelSurchargeAddModalVisible(false);
        }
        if (isAFSError) {
            notification.error({
                message: "Error",
                description: AFSError?.data?.error || "新增燃油率失败!",
            });
        }
    }, [isAFSError, isAFSSuccess])
    useEffect(() => {
        if (isError) {
            notification.error({
                message: 'Error',
                description: "数据获取失败, 请稍后再试",
            })
        }
    }, [isError])

    useEffect(() => {
        refetch();
        refetchLogisticsServiceNames();
    }, [])
    const columns = [
        {
            title: '物流服务',
            dataIndex: 'service_name',
            key: 'logisticsService',
            search: true,
            renderFormItem: (item, { type, defaultRender, ...rest }) => {
                return <Select 
                    placeholder='请选择' 
                    allowClear 
                    style={{ textAlign: "left" }} 
                    mode='multiple'
                    tokenSeparators={[',', ' ']}>
                    {logisticsService && logisticsService.map((item) => (
                        <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                    ))}
                </Select>
            },
        },
        {
            title: '启用时间',
            dataIndex: 'eff_time',
            key: 'eff_time',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
            defaultSortOrder: 'descend',
            search: true,
            renderFormItem: (item, { type, defaultRender, ...rest }) => {
                return <RangePicker style={{ width: '100%' }} />
            },
        },
        {
            title: '费率',
            dataIndex: 'rate',
            key: 'rate',
            search: false,
        },
        {
            title: 'Action',
            key: 'action',
            search: false,
            width: '15%',
            render: (text, record) => (
                <Space size="small">
                    <Switch
                        checkedChildren="开启"
                        unCheckedChildren="关闭"
                        defaultChecked={record.status === '启用'}
                        loading={updateEnableLoading}
                        onChange={(checked) => handleUpdateEnable(record.id, checked ? "1" : "0")}
                    />
                    <Button type="link" onClick={() => fuelSurchargeEditButtonHandler(record)}>修改</Button>
                </Space>
            ),
        },
    ];
    

    const [UpdateFuelSurcharge, {
        isSuccess: isUFSSuccess,
        isError: isUFSError,
        error: UFSError,
        isLoading: isUFSLoading,
        isFetching: isUFSFetching
    }] = useUpdateFuelSurchargeManagementMutation();
    useEffect(() => {
        if (isUFSSuccess) {
            notification.success({
                message: "Success",
                description: '修改成功!',
            });
            setFuelSurchargeEditModalVisible(false);
        }
        if (isUFSError) {
            notification.error({
                message: "Error",
                description: UFSError?.data?.error || "修改失败!",
            });
        }
    }, [isUFSSuccess, isUFSError])


    const fuelSurchargeEditButtonHandler = (record) => {
        setRow(record);
        setFuelSurchargeEditModalVisible(true);
    }
    const handleUpdateEnable = (id, status) => {
        setUpdateEnableLoading(true);
        try {
            const formData = new FormData();
            formData.append('id', id);
            formData.append('status', status);
            UpdateFuelSurcharge(formData)
        }catch (e) {
            console.log(e)
        }finally {
            setUpdateEnableLoading(false);
        }
    }
    const fuelSurchargeEditSubmitHandler = (values) => {
        try {
            const formData = new FormData();
            formData.append('id', row.id);
            formData.append('rate', values.rate);
            UpdateFuelSurcharge(formData)
        }catch (err){
            const err_msg = err.response?.data?.errors;
            notification.error({
                message: 'Error',
                description: err_msg,
            })
        }
    }
    const addNewFuelSurchargeButtonHandler = () => {
        setFuelSurchargeAddModalVisible(true);
    }
    const fuelSurchargeAddSubmitHandler = (values) => {
        const formData = new FormData();
        formData.append('service_name', values.service_name);
        // eff_time is a moment object, we only need the date
        formData.append('eff_time', values.eff_time.format('YYYY-MM-DD'));
        formData.append('rate', values.rate);
        AddFuelSurcharge(formData);
    }
    return (
        <div>
            <ProTable
                search={{
                    labelWidth: 'auto',
                    style: {
                        maxWidth: 'none',
                        background: token.colorFillAlter,
                        borderRadius: token.borderRadiusLG,
                        padding: 24,
                    }
                }}
                size="small"
                rowKey={(record) => record.id}
                loading={isLoading || isFetching}
                columns={columns}
                dataSource={data?.results}
                request={(params, sort) => {
                    const sorter = {
                        field: Object.keys(sort)[0],
                        order: sort[Object.keys(sort)[0]],
                    }
                    setSearchData({...params, sorter: sorter});
                }}
                pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50', '100'],
                    total: data?.count,
                }}
                scroll={{ x: 'max-content' }}
                options={{
                    reload: () => refetch(),
                }}
                toolbar={{
                    title: ' ',
                    actions: [
                        <Button type="primary" onClick={addNewFuelSurchargeButtonHandler}>新增燃油率</Button>
                    ]
                }}
            />
            <FuelSurchargeEditModal
                open={fuelSurchargeEditModalVisible}
                setOpen={setFuelSurchargeEditModalVisible}
                onSubmit={fuelSurchargeEditSubmitHandler}
                loading={isUFSFetching || isUFSLoading}
                success={isUFSSuccess}
                row={row}
            />
            <FuelSurchargeAddModal
                open={fuelSurchargeAddModalVisible}
                setOpen={setFuelSurchargeAddModalVisible}
                onSubmit={fuelSurchargeAddSubmitHandler}
                loading={isAFSLoading || isAFSFetching}
                success={isAFSSuccess}
            />
        </div>
    )
}
export default FuelSurchargeManagementPage;