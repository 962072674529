import React, { useEffect } from 'react';
import { Modal, Form, DatePicker, notification, Button } from 'antd';
import { useUpdateAccountReceivableMutation } from '../../features/api/apiSlice';
import { debounce } from 'lodash';
function AccountingConfirmModal({ open, setOpen, data }) {
    const [form] = Form.useForm();

    const [updateAccountReceivable, {
        error: updateAccountReceivableError,
        isLoading: updateAccountReceivableIsLoading,
        isSuccess: updateAccountReceivableIsSuccess,
        isError: updateAccountReceivableIsError,
    }] = useUpdateAccountReceivableMutation();

    useEffect(() => {
        if (updateAccountReceivableIsSuccess) {
            setOpen(false);
            form.resetFields();
            notification.success({
                message: '成功',
                description: '更新成功',
            })
        }
        if (updateAccountReceivableIsError) {
            notification.error({
                message: '失败',
                description: updateAccountReceivableError?.data?.error || '更新失败',
            })
        }
    }, [updateAccountReceivableIsSuccess, updateAccountReceivableIsError]);

    const handleOk = debounce(() => {
        form.validateFields().then((values) => {
            const request = {
                invoice_id: data.id,
                accounting_confirm_date: values.accounting_confirm_date.format('YYYY-MM-01')
            }
            updateAccountReceivable(request);
        }).catch((info) => {
            console.log('Validate Failed:', info);
        });
    }, 500);
    return (
        <Modal
            title="财务核算月份确认"
            open={open}
            onCancel={() => setOpen(false)}
            onOk={handleOk}
        >
            <Form
                form={form}
                name="accounting_confirm"
            >
                <Form.Item
                    label="财务核算确认月份"
                    name="accounting_confirm_date"
                    rules={[{ required: true, message: '请填写日期!' }]}
                >
                    <DatePicker picker='month' />
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default AccountingConfirmModal;