import { Button, Tooltip, Space } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
export function GroundShipmentColumns (trackingClickHandler, setOpenCost, setCostContent) {
    return [
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            permission: 'status',
            fixed: 'left',
        },
        {
            title: () => {
                return <Space>Master Tracking ID <Tooltip style={{ marginLeft: '2px' }} title="该列为FedEx官网所使用的Master Tracking"><QuestionCircleOutlined /></Tooltip></Space>
            },
            dataIndex: 'td_master_tracking',
            key: 'td_master_tracking',
            fixed: 'left',
            permission: 'td_master_tracking',
            render: (text, record) => {
                return record.master_tracking_number?.split('-')[0]
            }
        },
        {
            title: () => {
                return <Space>Master Tracking <Tooltip style={{ marginLeft: '2px' }} title="该列为FedEx官网使用Master Tracking附加系统自带后缀,后缀为R代表退件,后缀为S代表纯附加费"><QuestionCircleOutlined /></Tooltip></Space>
            },
            dataIndex: 'master_tracking_number',
            key: 'master_tracking_number',
            permission: 'master_tracking',
            render: (text, record) => {
                return <Button key={text} type="link" onClick={() => trackingClickHandler(record)}>
                    {record.master_tracking_number}{record.shipment_number ? `-${record.shipment_number}` : ''}
                </Button>
            }
        },
        {
            title: '费用',
            dataIndex: ['fee_json', 'total'],
            key: 'fee',
            fixed: 'left',
            permission: 'shipment_fee',
            render: (text, record) => {
                if (record.status === "待出账" || record.status === "已出账"){
                    return <Button type="link" onClick={() => {

                        const newModalContent = Object.entries(record.fee_json)
                        .sort(([keyA], [keyB]) => {
                            // package_rates在第一位,fuel_surcharge在第二位,total在最后一位
                            if (keyA === 'package_rates') return -1;
                            if (keyB === 'package_rates') return 1;
                            if (keyA === 'fuel_surcharge') return -1;
                            if (keyB === 'fuel_surcharge') return 1;
                            if (keyA === 'total') return 1;
                            if (keyB === 'total') return -1;
                        }).map(([key, value], index) => {
                            let modifiedValue = value;

                            // 返回修改后的对象
                            return {
                                key: index,
                                type: key,
                                amount: modifiedValue
                            };
                        });
                        setCostContent(newModalContent);
                        setOpenCost(true);
                    }}>
                        {text}
                    </Button>
                }else{
                    return text;
                }
            }
        },
        {
            title: '实际数量/应有数量',
            dataIndex: 'package_amount',
            key: 'uid',
            permission: 'package_amount',
            render: (text, record) => {
                if (record.master_tracking_number.includes('-S') || record.master_tracking_number.includes('-R')) {
                    return `${record.current_package_amount}/na`
                } else {
                    return `${record.current_package_amount}/${record.package_amount}`;
                }
                
            }
        },
        {
            title: 'UID',
            dataIndex: 'customer',
            key: 'uid',
            permission: 'uid',
        },
        {
            title: () => {
                return <Space>Shipment Date <Tooltip style={{ marginLeft: '2px' }} title="一票运费的Ship Date为当票中包裹最早的Ship Date"><QuestionCircleOutlined /></Tooltip></Space>
            },
            dataIndex: 'ship_date',
            key: 'ship_date',
            permission: 'shipment_date',
            sorter: true,
        },
        {
            title: '出账批次',
            dataIndex: 'invoice',
            key: 'invoice',
            permission: 'invoice_name',
        },
        {
            title: '计算错误',
            dataIndex: 'calculation_error',
            key: 'calculation_error',
            permission: 'calculation_error',
        }
    ]
}


export function GroundBillingDetailsColumns(setOpenSurcharge, setModalContent) {
    return [
        {
            title: 'Master Tracking',
            dataIndex: ['shipment', 'master_tracking_number'],
            key: 'td_master_tracking',
            fixed: 'left',
            permission: 'master_tracking',
        },
        {
            title: 'Tracking Number',
            dataIndex: 'tracking_id',
            key: 'express_or_ground_tracking_id',
            fixed: 'left',
            permission: 'tracking_number',
            render: (text, record) => {
                return <a
                    href={`https://www.fedex.com/wtrk/track/?action=track&trackingnumber=${record.tracking_id}&cntry_code=us&locale=en_us`}
                    target="_blank"
                >
                    {text}
                </a>
            }
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            fixed: 'left',
            permission: 'status',
        },
        {
            title: '计算错误',
            dataIndex: 'calculation_error',
            key: 'calculation_error',
            fixed: 'left',
            permission: 'calculation_error',
        },
        {
            title: 'Surcharge Details',
            dataIndex: 'surcharge_json',
            key: 'surcharge',
            permission: 'surcharge_details',
            render: (text, record) => {
                if ('surcharge_json' in record && record.surcharge_json !== null) {
                    return <Button type="link" onClick={() => {
                        const newModalContent = Object.entries(text).map(([key, value], index) => ({
                            key: index,
                            type: key,
                            amount: value
                        }));
                        setModalContent(newModalContent);
                        setOpenSurcharge(true);
                    }}>
                        附加费详情
                    </Button>
                }
            }
        },
        {
            title: '导入批次编号',
            dataIndex: ['billing_invoice', 'name'],
            key: 'billing_invoice_name',
            permission: 'billing_invoice',
        },
        {
            title: 'UID',
            dataIndex: ['customer', 'uid'],
            key: 'uid',
            permission: 'uid',
        },
        {
            title: 'Master EDI Number',
            dataIndex: ['rate_json', 'Master EDI No'],
            key: 'master_edi_number',
            permission: 'master_edi_number',
        },
        {
            title: 'Invoice Number',
            dataIndex: 'invoice_number',
            key: 'invoice_number',
            permission: 'invoice_number',
        },
        {
            title: 'Invoice Date',
            dataIndex: 'invoice_date',
            key: 'invoice_date',
            permission: 'invoice_date',
        },
        {
            title: 'Total Invoice Charge',
            dataIndex: ['rate_json', 'Inv Charge'],
            key: 'Invoice Charge',
            permission: 'total_invoice_charge',
        },
        {
            title: 'Bill to Account Number',
            dataIndex: ['rate_json', 'Bill-to Account'],
            key: 'bill_to_account_number',
            permission: 'bill_to_account_number',
        },
        {
            title: 'Bill to country',
            dataIndex: ['rate_json', 'Cntry'],
            key: 'bill_to_country',
            permission: 'bill_to_country',
        },
        {
            title: 'Company Code',
            dataIndex: ['rate_json', 'Co.Cd'],
            key: 'company_code',
            permission: 'company_code',
        },
        {
            title: 'Ground Tracking ID Prefix',
            dataIndex: ['rate_json', 'Grd Prefix'],
            key: 'ground_tracking_id_prefix',
            permission: 'ground_tracking_id_prefix',
        },
        {
            title: 'Shipment Date',
            dataIndex: ['rate_json', 'Ship Date'],
            key: 'shipment_date',
            permission: 'shipment_date',
        },
        {
            title: 'Service',
            dataIndex: ['logistics_service', 'name'],
            key: 'service',
            permission: 'service',
        },
        {
            title: 'Ground Service',
            dataIndex: ['rate_json', 'Grd Svc'],
            key: 'ground_service',
            permission: 'ground_service',
        },
        {
            title: 'Reference 1',
            dataIndex: ['rate_json', 'Ref 1'],
            key: 'ref_1',
            permission: 'ref_1',
        },
        {
            title: 'Reference 2',
            dataIndex: ['rate_json', 'Ref 2'],
            key: 'ref_2',
            permission: 'ref_2',
        },
        {
            title: 'Reference 3',
            dataIndex: ['rate_json', 'Ref 3'],
            key: 'ref_3',
            permission: 'ref_3',
        },
        {
            title: 'Customer Department Number',
            dataIndex: ['rate_json', 'Grd Dept No'],
            key: 'grd_dept_no',
            permission: 'customer_dept_number',
        },
        {
            title: 'Net Charge',
            dataIndex: ['rate_json', 'Net Chrg'],
            key: 'net_charge',
            permission: 'net_charge',
        },
        {
            title: 'Currency',
            dataIndex: ['rate_json', 'Curr'],
            key: 'currency',
            permission: 'currency',
        },
        {
            title: 'Charge 1',
            dataIndex: ['rate_json', 'Chrg 1'],
            key: 'chrg_1',
            permission: 'charge_1',
        },
        {
            title: 'Freight Amount',
            dataIndex: ['rate_json', 'Freight Amt'],
            key: 'freight_amount',
            permission: 'freight_amount',
        },
        {
            title: 'Scale',
            dataIndex: ['rate_json', 'Scale'],
            key: 'scale',
            permission: 'scale',
        },
        {
            title: 'Pieces',
            dataIndex: ['rate_json', 'Pcs'],
            key: 'Pcs',
            permission: 'pieces',
        },
        {
            title: 'Bill Weight',
            dataIndex: ['rate_json', 'Bill Wt'],
            key: 'bill_weight',
            permission: 'bill_weight',
        },
        {
            title: 'Original Weight',
            dataIndex: ['rate_json', 'Orig Wt'],
            key: 'original_weight',
            permission: 'original_weight',
        },
        {
            title: 'Multi-Weight',
            dataIndex: ['rate_json', 'Multi-Wt'],
            key: 'multi_weight',
            permission: 'multi_weight',
        },
        {
            title: 'weight unit',
            dataIndex: ['rate_json', 'Wt Unit'],
            key: 'weight_unit',
            permission: 'weight_unit',
        },
        {
            title: 'Length',
            dataIndex: ['rate_json', 'Length'],
            key: 'length',
            permission: 'length',
        },
        {
            title: 'Width',
            dataIndex: ['rate_json', 'Width'],
            key: 'width',
            permission: 'width',
        },
        {
            title: 'Height',
            dataIndex: ['rate_json', 'Height'],
            key: 'height',
            permission: 'height',
        },
        {
            title: 'Dimension Unit',
            dataIndex: ['rate_json', 'Dim Unit'],
            key: 'dimension_unit',
            permission: 'dimension_unit',
        },
        {
            title: 'Divisor',
            dataIndex: ['rate_json', 'Divisor'],
            key: 'divisor',
            permission: 'divisor',
        },
        {
            title: 'Ground Miscellaneous Description 1',
            dataIndex: ['rate_json', 'Grd Misc 1'],
            key: 'grd_misc_desc_1',
            permission: 'ground_misc_desc_1',
        },
        {
            title: 'Ground Miscellaneous Description 2',
            dataIndex: ['rate_json', 'Grd Misc 2'],
            key: 'grd_misc_desc_2',
            permission: 'ground_misc_desc_2',
        },
        {
            title: 'Ground Miscellaneous Description 3',
            dataIndex: ['rate_json', 'Grd Misc 3'],
            key: 'grd_misc_desc_3',
            permission: 'ground_misc_desc_3',
        },
        {
            title: 'Shipper Name',
            dataIndex: ['rate_json', 'Shipper Name'],
            key: 'shipper_name',
            permission: 'shipper_name',
        },
        {
            title: 'Shipper Company',
            dataIndex: ['rate_json', 'Shipper Company'],
            key: 'shipper_company',
            permission: 'shipper_company',
        },
        {
            title: 'Shipper Dept',
            dataIndex: ['rate_json', 'Shipper Dept'],
            key: 'shipper_dept',
            permission: 'shipper_dept',
        },
        {
            title: 'Shipper Address 1',
            dataIndex: ['rate_json', 'Shipper Address 1'],
            key: 'shipper_addr1',
            permission: 'shipper_address_1',
        },
        {
            title: 'Shipper Address 2',
            dataIndex: ['rate_json', 'Shipper Address 2'],
            key: 'shipper_addr2',
            permission: 'shipper_address_2',
        },
        {
            title: 'Shipper City',
            dataIndex: ['rate_json', 'Shipper City'],
            key: 'shipper_city',
            permission: 'shipper_city',
        },
        {
            title: 'ST',
            dataIndex: ['rate_json', 'ST'],
            key: 'shipper_st',
            permission: 'st',
        },
        {
            title: 'Postal',
            dataIndex: ['rate_json', 'Postal'],
            key: 'postal',
            permission: 'postal',
        },
        {
            title: 'US Origin',
            dataIndex: ['rate_json', 'US Origin'],
            key: 'us_origin',
            permission: 'us_origin',
        },
        {
            title: 'Cntry1',
            dataIndex: ['rate_json', 'Cntry1'],
            key: 'cntry1',
            permission: 'country_1',
        },
        {
            title: 'Region',
            dataIndex: ['rate_json', 'Region'],
            key: 'region',
            permission: 'region',
        },
        {
            title: 'Recipient Name',
            dataIndex: ['rate_json', 'Recipient Name'],
            key: 'recipient_name',
            permission: 'recipient_name',
        },
        {
            title: 'Recipient Company',
            dataIndex: ['rate_json', 'Recipient Company'],
            key: 'recipient_company',
            permission: 'recipient_company',
        },
        {
            title: 'Recipient Address 1',
            dataIndex: ['rate_json', 'Recipient Address 1'],
            key: 'recipient_addr1',
            permission: 'recipient_address_1',
        },
        {
            title: 'Recipient Address 2',
            dataIndex: ['rate_json', 'Recipient Address 2'],
            key: 'recipient_addr2',
            permission: 'recipient_address_2',
        },
        {
            title: 'Recipient City',
            dataIndex: ['rate_json', 'Recipient City'],
            key: 'recipient_city',
            permission: 'recipient_city',
        },
        {
            title: 'ST2',
            dataIndex: ['rate_json', 'ST2'],
            key: 'recipient_st',
            permission: 'st_2',
        },
        {
            title: 'Postal2',
            dataIndex: ['rate_json', 'Postal2'],
            key: 'recipient_postal',
            permission: 'postal_2',
        },
        {
            title: 'Cntry2',
            dataIndex: ['rate_json', 'Cntry2'],
            key: 'recipient_country',
            permission: 'country_2',
        },
        {
            title: 'Hndlg',
            dataIndex: ['rate_json', 'Hndlg'],
            key: 'hndlg',
            permission: 'hndlg',
        },
        {
            title: 'Dlvry Date',
            dataIndex: ['rate_json', 'Dlvry Date'],
            key: 'dlvry_date',
            permission: 'delivery_date',
        },
        {
            title: 'Time',
            dataIndex: ['rate_json', 'Time'],
            key: 'time',
            permission: 'time',
        },
        {
            title: 'Final',
            dataIndex: ['rate_json', 'Final'],
            key: 'final',
            permission: 'final',
        },
        {
            title: 'Exceptn',
            dataIndex: ['rate_json', 'Exceptn'],
            key: 'exceptn',
            permission: 'exception',
        },
        {
            title: 'Attempt Date',
            dataIndex: ['rate_json', 'Attempt Date'],
            key: 'attempt_date',
            permission: 'attempt_date',
        },
        {
            title: 'Attempt Time',
            dataIndex: ['rate_json', 'Attempt Time'],
            key: 'attempt_time',
            permission: 'attempt_time',
        },
        {
            title: 'Signature',
            dataIndex: ['rate_json', 'Signature'],
            key: 'signature',
            permission: 'signature',
        },
        {
            title: 'Svc Area',
            dataIndex: ['rate_json', 'Svc Area'],
            key: 'svc_area',
            permission: 'service_area',
        },
        {
            title: 'Orig Recip Adr 1',
            dataIndex: ['rate_json', 'Orig Recip Adr 1'],
            key: 'orig_recip_adr_1',
            permission: 'original_recipient_address_1',
        },
        {
            title: 'Orig Recip Adr 2',
            dataIndex: ['rate_json', 'Orig Recip Adr 2'],
            key: 'orig_recip_adr_2',
            permission: 'original_recipient_address_2',
        },
        {
            title: 'Original City',
            dataIndex: ['rate_json', 'Original City'],
            key: 'orig_city',
            permission: 'original_city',
        },
    ]
}

