import React from 'react';
import { Button, Col, Form, Input, Row, Space, theme, Select } from 'antd';
import moment from "moment-timezone";

const ErrorHandlingSearchBar = ({ searchData, setSearchData, customerData, billingInvoiceNames, logisticsService, setCurrentPage, setPageSize, currentErrorHandlingTab, expressionNames }) => {
    const { token } = theme.useToken();
    const [form] = Form.useForm();
    const formStyle = {
        maxWidth: 'none',
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
    };
    const onFinish = () => {
        form.validateFields().then(values => {
            setSearchData({...values});
            setCurrentPage(1);
            setPageSize(10);
        }).catch(error => {
            console.log(error);
        });
        
    };
    return (
        <Form form={form} style={formStyle} onFinish={onFinish}>
            <Row gutter={24}>
                <Col span={8} key={1}>
                    <Form.Item name="uid" label='UID'>
                        <Select
                            showSearch
                            allowClear
                            optionFilterProp="children"
                            style={{ textAlign: "left" }}
                            filterOption={(input, option) =>
                                (option?.label ?? '')
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                            }>
                            {customerData && customerData.map(customer => (
                                <Select.Option key={customer.id} value={customer.uid} label={customer.uid}>
                                    {customer.uid}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={8} key={2}>
                    <Form.Item name='trackingNumber' label='追踪号'>
                        <Input placeholder='请输入内容' />
                    </Form.Item>
                </Col>
                <Col span={8} key={4}>
                    <Form.Item name="billingInvoice" label='导入批次编号'>
                        <Select
                            showSearch
                            allowClear
                            optionFilterProp="children"
                            style={{ textAlign: "left" }}
                            filterOption={(input, option) =>
                                (option?.label ?? '')
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                            }>
                            {billingInvoiceNames && billingInvoiceNames.map(billingInvoiceName => (
                                <Select.Option key={billingInvoiceName.id} value={billingInvoiceName.id} label={billingInvoiceName.name}>
                                    {billingInvoiceName.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={8} key={5}>
                    <Form.Item name="logisticsService" label="物流服务">
                        <Select
                            placeholder='请选择'
                            allowClear style={{ textAlign: "left" }}
                            mode='multiple'
                        >
                            {logisticsService && logisticsService.map((item) => (
                                <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                {currentErrorHandlingTab === '3' && (
                <Col span={8} key={6}>
                    <Form.Item name="dup_status" label="Dup Status">
                        <Select
                            style={{ textAlign: "left" }}
                            placeholder="Please select"
                            allowClear
                        >
                            <Select.Option value="1">当期重复</Select.Option>
                            <Select.Option value="2">历史重复</Select.Option>
                            <Select.Option value="3">退件</Select.Option>
                            <Select.Option value="4">重量修改费</Select.Option>
                            <Select.Option value="5">处理附加费</Select.Option>
                            <Select.Option value="6">原件</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>)}
                {currentErrorHandlingTab === '7' && (
                <Col span={8} key={7}>
                    <Form.Item name="masterMismatchStatus" label="status">
                        <Select
                            style={{ textAlign: "left" }}
                            placeholder="Please select"
                            allowClear
                        >
                            <Select.Option value="0">Master未匹配</Select.Option>
                            <Select.Option value="1">Service异常</Select.Option>
                            <Select.Option value="2">系统匹配shipment失败</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>)}
                {
                    currentErrorHandlingTab === '4' && (
                        <Col span={8} key={8}>
                            <Form.Item name="userDefinedExceptions" label="异常类型">
                                <Select
                                    style={{ textAlign: "left" }}
                                    placeholder="Please select"
                                    allowClear
                                >
                                    {expressionNames && expressionNames.map((item) => (
                                            <Select.Option key={item.id} value={item.id}>{item.expression_name}</Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )
                }
                {
                    currentErrorHandlingTab === '5' && (
                        <Col span={8} key={9}>
                            <Form.Item name="zzzPrice" label="ZZZ价格">
                                <Input placeholder="请输入数字"/>
                            </Form.Item>
                        </Col>
                    )
                }
            </Row>
            <Row>
                <Col style={{ textAlign: 'right' }} span={24} key={8}>
                    <Space size="small">
                        <Button type="primary" htmlType="submit">
                            Search
                        </Button>
                        <Button
                            onClick={() => {
                                form.resetFields();
                            }}
                        >
                            Clear
                        </Button>
                    </Space>
                </Col>
            </Row>
        </Form>
    );
};

export default ErrorHandlingSearchBar;