import React, { useEffect, useState } from 'react';
import { Typography } from 'antd';
import {
    useGetBillingDetailsSummaryQuery,
    useGetAllCustomerUidQuery,
    useGetLogisticsServiceByNameQuery,
} from "../features/api/apiSlice";
import {
    ProTable,
  } from '@ant-design/pro-components';
import { Select, DatePicker } from 'antd';
const { Option } = Select;
const { Text } = Typography;
const { RangePicker } = DatePicker;
function HomePage() {
    const [searchData, setSearchData] = useState({});
    const { data, error, isLoading, isFetching, refetch } = useGetBillingDetailsSummaryQuery({...searchData});
    const { data: customerUidData } = useGetAllCustomerUidQuery();
    const { data: logisticsService } = useGetLogisticsServiceByNameQuery();

    useEffect(() => {
        refetch();
    }, []);

    const columns = [
        {
            title: 'UID',
            dataIndex: 'uid',
            key: 'uid',
            search: true,
            renderFormItem: (item, { defaultRender, ...rest }, form) => {
                return <Select 
                    showSearch
                    allowClear
                    placeholder="Select a UID"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    mode="multiple"
                    style={{ textAlign: "left" }}
                >
                    {customerUidData && customerUidData.map((item) => {
                        return <Option key={item.uid} value={item.uid}>{item.uid}</Option>
                        
                    })}
                </Select>
            }
        },
        {
            title: 'Invoice Date',
            dataIndex: 'invoice_date',
            key: 'invoice_date',
            search: true,
            hideInTable: true,
            renderFormItem: (item, { defaultRender, ...rest }, form) => {
                return <RangePicker style={{ width: '100%' }} format="YYYY-MM-DD" />
            }
        },
        {
            title: '渠道服务',
            dataIndex: 'logistics_service',
            key: 'logistics_service',
            search: true,
            hideInTable: true,
            renderFormItem: (item, { defaultRender, ...rest }, form) => {
                return <Select 
                    showSearch
                    allowClear
                    placeholder="Select a service"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    mode="multiple"
                    style={{ textAlign: "left" }}
                >
                    {logisticsService && logisticsService.map((item) => {
                        return <Option key={item.id} value={item.id}>{item.name}</Option>
                        
                    })}
                </Select>
            }
        },
        {
            title: '异常',
            dataIndex: 'error',
            key: 'error',
            search: false,
            render: (text, record) => {
                return <Text>{record.error_pieces}件/${record.error_net_charge}</Text>
            }
        },
        {
            title: '待计算',
            dataIndex: 'wait_for_cal',
            key: 'wait_for_cal',
            search: false,
            render: (text, record) => {
                return <Text>{record.wait_for_cal_pieces}件/${record.wait_for_cal_net_charge}</Text>
            }
        },
        {
            title: '计算异常',
            dataIndex: 'cal_error',
            key: 'cal_error',
            search: false,
            render: (text, record) => {
                return <Text>{record.cal_error_pieces}件/${record.cal_error_net_charge}</Text>
            }
        },
        {
            title: '待出账',
            dataIndex: 'wait_for_bill',
            key: 'wait_for_bill',
            search: false,
            render: (text, record) => {
                return <Text>{record.wait_for_bill_pieces}件/${record.wait_for_bill_net_charge}</Text>
            }
        },
        {
            title: '已出账',
            dataIndex: 'billed',
            key: 'billed',
            search: false,
            render: (text, record) => {
                return <Text>{record.billed_pieces}件/${record.billed_net_charge}</Text>
            }
        }
    ]
    return (
        <div>
            <ProTable
                loading={{
                    spinning: isLoading || isFetching,
                }}
                rowKey={record => record.uid}
                search={{
                    labelWidth: 'auto',
                    defaultCollapsed: false,
                }}
                request={(params) => {
                    setSearchData(params);
                }}
                dataSource={data?.results}
                columns={columns}
                pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50', '100'],
                    total: data?.count,
                }}
                options={{
                    reload: () => refetch(),
                }}
                toolbar={{
                    title: ' ',
                }}
            />
        </div>    
        
    );
}

export default HomePage;