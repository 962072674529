import React, { useState, useEffect } from 'react';
import { Form, Modal, Tree, App, Steps, Button, theme, Result, Typography } from 'antd';
import {
    useUpdateRoleMutation
} from "../../features/api/apiSlice";
import axios from 'axios';
const { Text } = Typography;
const EditRoleModal = ({ open, setOpen, data }) => {
    const [pagePermissions, setPagePermissions] = useState([]);
    const [buttonPermissions, setButtonPermissions] = useState([]);
    const [updateRole, { isError, isLoading, isFetching, error, isSuccess }] = useUpdateRoleMutation();
    const [checkedPageKeys, setCheckedPageKeys] = useState([]);
    const [checkedButtonKeys, setCheckedButtonKeys] = useState([]);
    const [expandedPageKeys, setExpandedPageKeys] = useState([]);
    const [expandedButtonKeys, setExpandedButtonKeys] = useState([]);
    const [nextLoading, setNextLoading] = useState(false);
    const [current, setCurrent] = useState(0);
    const [form] = Form.useForm();
    const { notification } = App.useApp();
    const { token } = theme.useToken();
    const contentStyle = {
        maxWidth: 'none',
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
    };
    const scrollableStyle = {
        maxHeight: '400px',
        overflowY: 'auto',
        border: "1px solid #d9d9d9",
        borderRadius: "2px",
        padding: '8px',
        background: token.colorFillAlter,
    }
    useEffect(() => {
        if (data) {
            setPagePermissions(transformPermissions(data.page_permissions?.data));
            setCheckedPageKeys(data.page_permissions?.keys);
            setExpandedPageKeys(data.page_permissions?.expandedKeys);
        }
    }, [data])
    const next = () => {
        if (current === 0) {
            setNextLoading(true);
            const formData = new FormData();
            formData.append('department_id', data.department.id);
            formData.append('page_ids', checkedPageKeys);
            formData.append('role_id', data.id);
            axios.post('/accounts/get-button-permission-by-page-and-role-under-department/', formData)
                .then((res) => {
                    setNextLoading(false);
                    setButtonPermissions(transformPermissions(res.data.data));
                    setCheckedButtonKeys(res.data.keys);
                    setExpandedButtonKeys(res.data.expandedKeys);
                    setCurrent(current + 1);
                }).catch((err) => {
                    setNextLoading(false);
                    notification.error({
                        message: "Error",
                        description: err?.response?.data?.error || "Something went wrong",
                    })
                    console.log(err);
                });
        } else if (current === 1) {
            const formData = new FormData();
            formData.append('role_id', data.id);
            formData.append('page_permission', checkedPageKeys);
            formData.append('button_permission', checkedButtonKeys);
            updateRole(formData);
        } else {
            setCurrent(current + 1);
        }
    }

    const prev = () => {
        setCurrent(current - 1);
    }

    useEffect(() => {
        if (isError) {
            console.log(error);
            notification.error({
                message: "Error",
                description: error?.data?.error || "Something went wrong",
            });
        }
        if (isSuccess) {
            setCurrent(current + 1);
        }
    }, [isError, isSuccess, error]);

    const transformPermissions = (data) => {
        if (data) {
            return Object.entries(data).map(([key, value]) => {
                const node = {
                    title: key,
                    key: value.id,
                    id: value.id,
                };

                if (value.children && Object.keys(value.children).length > 0) {
                    node.children = transformPermissions(value.children);
                }

                return node;
            });
        }
    }

    const onPageCheck = (checkedKeys) => {
        setCheckedPageKeys(checkedKeys);
    }

    const onButtonCheck = (checkedKeys) => {
        setCheckedButtonKeys(checkedKeys);
    }

    const onPageExpand = (expandedKeys) => {
        setExpandedPageKeys(expandedKeys);
    }

    const onButtonExpand = (expandedKeys) => {
        setExpandedButtonKeys(expandedKeys);
    }

    const steps = [
        {
            title: '页面权限',
            content: (
                <div>
                    {data.is_manager && <div style={{ marginBottom: '16px' }}>
                        <Text style={{ marginBottom: '16px' }}>manager默认拥有该部门全部权限, 无法修改</Text></div>}
                    <div style={scrollableStyle}>
                        <Tree
                            disabled={data?.is_manager}
                            checkable
                            onExpand={onPageExpand}
                            expandedKeys={expandedPageKeys}
                            onCheck={onPageCheck}
                            treeData={pagePermissions}
                            checkedKeys={checkedPageKeys}
                        />
                    </div>
                </div>)
        },
        {
            title: '按钮权限',
            content: (
                <div>
                    {data.is_manager && <div style={{ marginBottom: '16px' }}>
                        <Text style={{ marginBottom: '16px' }}>manager默认拥有该部门全部权限, 无法修改</Text></div>}
                    <div style={scrollableStyle}>
                        <Tree
                            disabled={data?.is_manager}
                            checkable
                            onExpand={onButtonExpand}
                            expandedKeys={expandedButtonKeys}
                            onCheck={onButtonCheck}
                            treeData={buttonPermissions}
                            checkedKeys={checkedButtonKeys}
                        />
                    </div>
                </div>)
        },
        {
            title: '修改成功',
            content: (
                <Result
                    status="success"
                    title="成功修改角色"
                />
            )
        }
    ]
    const items = steps.map((item) => ({
        key: item.title,
        title: item.title,
    }));
    return (
        <div>
            <Modal
                title="编辑角色"
                open={open}
                width={1200}
                onCancel={() => {
                    if (current === steps.length - 1) {
                        setOpen(false);
                        setCurrent(0);
                        form.resetFields();
                        setCheckedButtonKeys([]);
                        setCheckedPageKeys([]);
                    } else {
                        setCurrent(0);
                        setOpen(false);
                    }
                }}
                footer={null}
                confirmLoading={isLoading}
            >
                <Steps current={current} items={items} style={{ marginBottom: "16px" }} />
                <div style={contentStyle}>{steps[current].content}</div>
                <div style={{ marginTop: 24 }}>
                    {current < steps.length - 1 && (
                        <Button type="primary" loading={nextLoading || isLoading || isFetching} onClick={() => next()}>
                            Next
                        </Button>
                    )}
                    {current === steps.length - 1 && (
                        <Button type="primary" onClick={() => {
                            setOpen(false);
                            setCurrent(0);
                            form.resetFields();
                            setCheckedButtonKeys([]);
                            setCheckedPageKeys([]);
                        }}>
                            Done
                        </Button>
                    )}
                    {current > 0 && current !== steps.length - 1 && (
                        <Button
                            style={{
                                margin: '0 8px',
                            }}
                            onClick={() => prev()}
                        >
                            Previous
                        </Button>
                    )}
                </div>
            </Modal>
        </div>
    );
};

export default EditRoleModal;