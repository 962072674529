import React, {useEffect, useState} from 'react';
import {
    useGetAsyncManagementQuery,
    useDeleteAsyncManagementMutation,
    useGetUserQuery,
    useGetRecordTypeQuery,
} from '../../features/api/apiSlice';
import { Button, notification, Space, Select, DatePicker, theme } from 'antd';
import axios from "axios";
import DownloadModal from "./DownloadModal";
import { renderDateTime, b64toBlob } from "../utils";
import ProTable from '@ant-design/pro-table';
const { RangePicker } = DatePicker;
function AsyncManagementPage() {
    const { token } = theme.useToken();
    const [searchData, setSearchData] = useState({});
    const { data, error, isError, isLoading, isFetching, refetch } = useGetAsyncManagementQuery({...searchData});
    const { data: userData, refetch: refetchUserData } = useGetUserQuery();
    const { data: recordTypeData, refetch: refetchRecordTypeData } = useGetRecordTypeQuery();
    
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [downloadModalVisible, setDownloadModalVisible] = useState(false);
    const [downloadRecord, setDownloadRecord] = useState(null);
    const [DeleteItem, {
        isSuccess: isDeleteItemSuccess,
        isError: isDeleteItemError,
        error: deleteItemError
    }] = useDeleteAsyncManagementMutation();
    useEffect(() => {
        refetch();
        refetchUserData();
        refetchRecordTypeData();
    }, [])

    useEffect(() => {
        if (isDeleteItemSuccess) {
            notification.success({
                message: "Success",
                description: "删除成功",
            });
        }
        if (isDeleteItemError) {
            notification.error({
                message: "Error",
                description: deleteItemError?.data?.error || "删除失败",
            });
        }
    }, [isDeleteItemSuccess, isDeleteItemError])


    useEffect(() => {
        if (isError) {
            notification.error({
                message: 'Error',
                description: "数据获取失败, 请稍后再试",
            })
        }
    }, [isError])

    const handleDownload = (record) => {
        if ([1, 5].includes(record.record_type.id)) {
            const formData = new FormData();
            formData.append('id', record.id);
            axios.post('/accounts/download-async-management/', formData).then((res) => {
                try {
                    let fileName = record?.import_name || 'download';
                    let fileExtension = res.data.extension;
                    fileName = fileName + "." + fileExtension;
                    let contentType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                    if (fileExtension === 'zip') {
                        contentType = "application/zip";
                    }
                    const url = window.URL.createObjectURL(b64toBlob(res.data.data, contentType));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${fileName}`);
                    document.body.appendChild(link);
                    link.click();
                } catch (error) {
                    console.error('Download failed:', error);
                }
            }).catch((err) => {
                const err_msg = err.response?.data?.errors;
                notification.error({
                    message: 'Error',
                    description: err_msg,
                })
            }).finally(() => {
                setDownloadLoading(false);
            });
        } else {
            setDownloadModalVisible(true);
            setDownloadRecord(record);
        }
        
    }
    const renderDownloadButton = (record) => {
        // 假设状态字段名为 'status'
        const isDownloadable = record.status === '已完成' && record.record_type.id !== 9;

        return (
            <Button
                type="link"
                disabled={!isDownloadable}
                loading={downloadLoading}
                onClick={() => handleDownload(record)}
            >
                下载
            </Button>
        );
    };
    

    const handleDelete = (record) => {
        setDeleteLoading(true);
        try {
            const formData = new FormData();
            formData.append('id', record.id);
            DeleteItem(formData)
        }catch (err){
            const err_msg = err.response?.data?.errors;
            notification.error({
                message: 'Error',
                description: err_msg,
            })
        }finally {
            setDeleteLoading(false);
        }
    };

    const columns = [
        {
            title: '任务类型',
            dataIndex: ['record_type', 'description'],
            key: 'record_type',
            search: true,
            renderFormItem: (item, { defaultRender, ...rest }, form) => {
                return <Select 
                    allowClear 
                    style={{ textAlign: "left" }} 
                    mode="multiple"
                    showSearch={false}
                    >
                    {recordTypeData && recordTypeData.map(record_type => (
                        <Select.Option key={record_type.id} value={record_type.id}>
                            {record_type.description}
                        </Select.Option>
                    ))}
                </Select>
            }
        },
        {
            title: '上传/标识文件名',
            dataIndex: 'import_name',
            key: 'import_name',
            search: false,
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            search: true,
            renderFormItem: (item, { defaultRender, ...rest }, form) => {
                return <Select 
                    allowClear 
                    style={{ textAlign: "left" }} 
                    mode="multiple"
                    showSearch={false}>
                    <Select.Option value="2">下载中</Select.Option>
                    <Select.Option value="3">已完成</Select.Option>
                    <Select.Option value="4">下载失败</Select.Option>
                </Select>
            }
        },
        {
            title: '耗时',
            dataIndex: 'completed_time',
            key: 'completed_time',
            search: false,
        },
        {
            title: '提交人',
            dataIndex: ['user', 'username'],
            key: 'user',
            search: true,
            renderFormItem: (item, { defaultRender, ...rest }, form) => {
                return <Select 
                    allowClear
                    showSearch={true}
                    style={{ textAlign: "left" }} 
                    mode="multiple"
                    tokenSeparators={[',', ' ']}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    >
                    {userData && userData.map(user => (
                        <Select.Option key={user.id} value={user.id}>
                            {user.username}
                        </Select.Option>
                    ))}
                </Select>
            }
        },
        {
            title: '提交时间',
            dataIndex: 'created_at',
            key: 'created_at',
            render: renderDateTime,
            search: true,
            renderFormItem: (item, { defaultRender, ...rest }, form) => {
                return <RangePicker style={{ width: '100%' }} />
            }
        },
        {
            title: '备注/错误信息',
            dataIndex: 'note_or_error_msg',
            key: 'note_or_error_msg',
            search: false,
        },
        {
            title: '操作',
            key: 'action',
            search: false,
            render: (text, record) => (
                <Space size="small">
                    {renderDownloadButton(record)}
                    <Button type="link" loading={deleteLoading} onClick={() => handleDelete(record)}>删除</Button>
                </Space>
            ),
        },
    ];
    return (
        <div>
            <Space direction="vertical"
                size="middle"
                style={{
                    display: 'flex',
                }}>
                <ProTable
                    size="small"
                    search={{
                        labelWidth: 'auto',
                        defaultCollapsed: false,
                        style: {
                            maxWidth: 'none',
                            background: token.colorFillAlter,
                            borderRadius: token.borderRadiusLG,
                            padding: 24,
                        }
                    }}
                    rowKey={(record) => record.id}
                    loading={{
                        spinning: isLoading || isFetching,
                        tip: "数据获取中, 请等待...",
                    }}
                    request={(params) => {
                        setSearchData(params);
                    }}
                    onRequestError={(err) => {
                        notification.error({
                            message: 'Error',
                            description: err.data?.error || "数据获取失败",
                        })
                    }}
                    columns={columns}
                    dataSource={data?.results}
                    pagination={{
                        defaultPageSize: 10,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', '50', '100'],
                        total: data?.count,
                    }}
                    options={{
                        reload: () => refetch(),
                    }}
                    scroll={{ x: 'max-content' }}
                />
            </Space>
            <DownloadModal
                open={downloadModalVisible}
                setOpen={setDownloadModalVisible}
                record={downloadRecord}
                setRecord={setDownloadRecord}
            />
        </div>
    )
}
export default AsyncManagementPage;