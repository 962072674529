import React, { useEffect } from 'react';
import { Modal, Form, Select, Input, DatePicker, App } from 'antd';
import { 
    useCreateCreditMutation
} from '../../features/api/apiSlice';
const { Option } = Select;
function CreateNewCreditModal ({ open, setOpen, customerUidData }) {

    const [form] = Form.useForm();

    const { notification } = App.useApp();

    const [createCredit, { error, isLoading, isSuccess, isError }] = useCreateCreditMutation();

    useEffect(() => {
        if (isSuccess) {
            notification.success({
                message: '成功',
                description: '创建成功',
            })
            setOpen(false);
            form.resetFields();
        }
        if (isError) {
            notification.error({
                message: '失败',
                description: error.data ? error.data.error : '创建失败',
            })
        }
    }, [isError, isSuccess]);

    const isNumberValid = (rule, value, callback) => {
        return new Promise((resolve, reject) => {
            if (value && !/^-?[0-9]+(.[0-9]+)?$/.test(value)) {
                reject('请输入可识别的数字!');
            } else if (value && value.length > 20) {
                reject('最多20位!');
            } else {
                resolve();
            }
        });
    }

    const fourDecimalPlacesPostiveNumberOnly = (rule, value, callback) => {
        return new Promise((resolve, reject) => {
            if (value && !/^\d+(\.\d{1,4})?$/.test(value)) {
                reject('数字需要为至多四位的正数，请输入可识别的数字!');
            } else {
                resolve();
            }
        });
    }

    const modalOkButtonHandler = () => {
        form.validateFields().then((values) => {
            const formData = new FormData();
            formData.append('uid', values.uid);
            formData.append('system', values.system);
            formData.append('credit_type', values.credit_type);
            formData.append('credit_amount', values.credit_amount);
            formData.append('issue_time', values.issue_time.format('YYYY-MM-DD'));
            if (values.note) {formData.append('note', values.note);}
            createCredit(formData);
        }).catch((info) => {
            console.log('Validate Failed:', info);
        });
    }
    return (
        <Modal
            title="Create New Credit"
            open={open}
            onOk={() => modalOkButtonHandler()}
            onCancel={() => { setOpen(false) }}
            width={550}
            confirmLoading={isLoading}
        >
            <Form
                form={form}
                labelCol={{
                    span: 6,
                }}
                wrapperCol={{
                    span: 18,
                }}
                style={{
                    marginTop: '20px',
                    marginLeft: '0%',
                    marginRight: '10%',
                    maxWidth: '100%'
                }}
            >
                <Form.Item label="UID" name="uid" rules={[{ required: true, message: '请选择UID' }]}>
                    <Select
                        showSearch
                        allowClear
                        placeholder="Select a UID"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        style={{ textAlign: "left" }}
                    >
                        {customerUidData && customerUidData.map((item) => {
                            return <Option key={item.uid} value={item.id}>{item.uid}</Option>
                        })}
                    </Select>
                </Form.Item>
                <Form.Item label="充值系统" name='system' rules={[{ required: true, message: '请选择充值系统' }]}>
                    <Select
                        style={{ textAlign: "left" }}
                        placeholder='请选择'
                    >
                        <Option key="0" label="TMS" value="0">TMS</Option>
                        <Option key="1" label="AwesungAPI" value="1">AwesungAPI</Option>
                        <Option key="2" label="Eccang" value="2">Eccang</Option>
                        <Option key="3" label="Prelabel" value="3">Prelabel</Option>
                    </Select>
                </Form.Item>
                <Form.Item label="预充类型" name='credit_type' rules={[{ required: true, message: '请选择预充类型' }]}>
                    <Select
                        style={{ textAlign: "left" }}
                        placeholder='请选择'
                    >
                        <Option key="1" label="长期额度" value="1">长期额度</Option>
                        <Option key="2" label="期初额度" value="2">期初额度</Option>
                    </Select>
                </Form.Item>
                <Form.Item label="预充额度" name='credit_amount' rules={[{ required: true, message: '请输入预充额度!' }, { validator: isNumberValid }]}>
                    <Input placeholder='请输入数字' />
                </Form.Item>
                <Form.Item label="预充操作时间" name='issue_time' rules={[{ required: true, message: '请选择预充操作时间!' }]}>
                    <DatePicker style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item label="备注" name="note">
                    <Input.TextArea />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default CreateNewCreditModal;