import { Form, Input, Modal, Row, Col, Select, notification } from 'antd'
import React, { useEffect } from 'react'

import { useUpdateLocationMutation } from "../../features/api/apiSlice";

const EditUserModal = ({ open, setEditOpen, editData, customerGroup }) => {
    const [form] = Form.useForm();
    const [updateLocation, {
        isSuccess: isSuccess,
        isError: isError,
        error: error,
        isLoading: isLoading,
        isFetching: isFetching
    }] = useUpdateLocationMutation();


    useEffect(() => {
        if (editData) {
            form.setFieldsValue({
                email: editData.email || '',
                customer_groups: Array.isArray(editData.customer_group_ids) ? editData.customer_group_ids : [editData.customer_group_ids],  // 确保是数组
                name: editData.name
            });

        }
    }, [editData]);


    useEffect(() => {
        if (isSuccess) {
            setEditOpen(false)
            form.resetFields();
        }

        if (isError) {
            if (error.data && error.data.non_field_errors) {
                notification.error({
                    message: "Error",
                    description: error.data.non_field_errors,
                });
            }
            if (error.data && error.data.name) {
                notification.error({
                    message: "Error",
                    description: error.data.name[0],
                });
            }
        }
    }, [setEditOpen, isSuccess]);


    const okButtonHandler = () => {

        form.validateFields().then(values => {
            console.log("user_group:", values.customer_groups)
            if (!values.name) {
                return notification.error({
                    message: 'Error',
                    description: "请输入地区",
                });
            }
            if (!values.customer_groups) {
                return notification.error({
                    message: 'Error',
                    description: "请选择客户组",
                });
            }
            const updatedData = {
                id: editData.id,
                name: values.name,
                customer_groups: values.customer_groups,

            }
            updateLocation(updatedData)

        }).catch(info => {
            console.log('Validate Failed:', info);
        });



    }
    return (
        <Modal
            title="编辑地区"
            open={open}
            width={700}
            onCancel={() => setEditOpen(false)}
            onOk={okButtonHandler}
            confirmLoading={isLoading || isFetching}
            okText="确定"
            cancelText="取消"
        >
            <Form layout="vertical" form={form}>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="地区"
                            required={true}
                            name="name"
                            rules={[
                                { required: true, message: '请输入' },
                            ]}
                        >
                            <Input
                                placeholder='请输入'
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="客户组"
                            name="customer_groups"
                            rules={[{ required: true, message: '请选择客户组' }]}
                        >
                            <Select
                                placeholder='请选择'
                                allowClear
                                style={{ width: '100%' }}
                                mode="multiple"
                            >
                                {customerGroup && customerGroup.map(item => (
                                    <Select.Option key={item.id} value={item.id}>
                                        {item.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

export default EditUserModal
