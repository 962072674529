import React from 'react';
import { Button, Col, Form, Input, Row, Space, theme, Select } from 'antd';

const ClientSearchBar = ({ billingUserData, rateTemplates, setSearchData, setCurrentPage, setPageSize }) => {
  const { token } = theme.useToken();
  const [form] = Form.useForm();
  const formStyle = {
    maxWidth: 'none',
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    padding: 24,
  };
  const getFields = () => {
    const children = [];
    children.push(
      <Col span={8} key={1}>
        <Form.Item name='customerId' label='客户ID'>
          <Input placeholder="请输入内容" />
        </Form.Item>
      </Col>,
      <Col span={8} key={2}>
        <Form.Item name='customerName' label='客户名'>
          <Input placeholder="请输入内容" />
        </Form.Item>
      </Col>,
      <Col span={8} key={3}>
        <Form.Item name='customerCategory' label='客户分类'>
          <Select allowClear style={{ textAlign: "left" }}>
            {rateTemplates && rateTemplates.map((rateTemplate) => (
              <Select.Option key={rateTemplate.id} value={rateTemplate.id}>
                {rateTemplate.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>,
      <Col span={8} key={4}>
        <Form.Item name='customerStatus' label='客户状态'>
          <Select allowClear style={{ textAlign: "left" }}>
            <Select.Option value="1">Active</Select.Option>
            <Select.Option value="0">Idle</Select.Option>
          </Select>
        </Form.Item>
      </Col>,
      <Col span={8} key={5}>
        <Form.Item name='customerAssignedTo' label='账单负责人'>
          <Select allowClear style={{ textAlign: "left" }}>
            {billingUserData && billingUserData.map(user => (
              <Select.Option key={user.id} value={user.id}>
                {user.username}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>,
      <Col span={8} key={6}>
        <Form.Item name='customerService' label='业务类型'>
          <Select allowClear style={{ textAlign: "left" }}>
            <Select.Option value="渠道">渠道</Select.Option>
            <Select.Option value="转运">转运</Select.Option>
            <Select.Option value="代发">代发</Select.Option>
            <Select.Option value="提柜">提柜</Select.Option>
            <Select.Option value="海运">海运</Select.Option>
          </Select>
        </Form.Item>
      </Col>,
    );
    return children;
  };
  const onFinish = (values) => {
    setSearchData(values);
    setCurrentPage(1);
    setPageSize(10);
  };
  return (
    <Form form={form} name="advanced_search" style={formStyle} onFinish={onFinish}>
      <Row gutter={24}>{getFields()}</Row>
      <div
        style={{
          textAlign: 'right',
        }}
      >
        <Space size="small">
          <Button type="primary" htmlType="submit">
            Search
          </Button>
          <Button
            onClick={() => {
              form.resetFields();
            }}
          >
            Clear
          </Button>
        </Space>
      </div>
    </Form>
  );
};

export default ClientSearchBar;