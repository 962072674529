import { Button, Space, Switch, App, Select } from 'antd';
import React, { useState, useEffect } from "react";
import AddRoleModal from "./AddRoleModal"
import EditRoleModal from './EditRoleModal';
import ProTable from '@ant-design/pro-table';
import {
    useGetDepartmentsQuery, 
    useGetRolesQuery, 
} from "../../features/api/apiSlice";
import { renderDateTime } from '../utils';
import axios from 'axios';
function RoleManagementPage() {
    const [addOpen, setAddOpen] = useState(false)
    const { data: departments } = useGetDepartmentsQuery({}, { refetchOnMountOrArgChange: true });
    const [searchData, setSearchData] = useState({});
    const { data, error, isError, isLoading, isFetching, refetch } = useGetRolesQuery({ ...searchData }
        , { refetchOnMountOrArgChange: true });
    const [editOpen, setEditOpen] = useState(false);
    const [editData, setEditData] = useState({});
    const [loadingRecordId, setLoadingRecordId] = useState(null);
    const [editButtonClickLoading, setEditButtonClickLoading] = useState(false);
    const { notification } = App.useApp();

    const editButtonHandler = (record) => {
        setLoadingRecordId(record.id);
        setEditButtonClickLoading(true);
        const formData = new FormData();
        formData.append('role_id', record.id);
        axios.post('/accounts/get-page-permission-by-role/', formData)
            .then((res) => {
                setEditButtonClickLoading(false);
                setEditData({ ...record, page_permissions: res.data });
                setEditOpen(true);
            }).catch((err) => {
                setEditButtonClickLoading(false);
                notification.error({
                    message: "Error",
                    description: err?.response?.data?.error || "Something went wrong",
                })
                console.log(err);
            });
    }

    const columns = [
        {
            title: '序号',
            dataIndex: 'id',
            key: 'id',
            search: false,
        },
        {
            title: "角色",
            dataIndex: "name",
            key: 'name',
            search: false,
        },
        {
            title: '部门',
            dataIndex: 'department_name',
            key: 'department',
            renderFormItem: (item, { defaultRender, ...rest }, form) => {
                return <Select
                    allowClear
                    showSearch={true}
                    style={{ textAlign: "left" }}
                    tokenSeparators={[',', ' ']}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {departments?.results && departments.results.map(de => (
                        <Select.Option key={de.id} value={de.id}>
                            {de.name}
                        </Select.Option>
                    ))}

                </Select>
            }
        },
        {
            title: "更新人",
            dataIndex: 'updated_by',
            key: 'updated_by',
            search: false,
        },
        {
            title: "更新时间",
            dataIndex: 'updated_at',
            key: 'updated_at',
            render: renderDateTime,
            search: false,
        },
        {
            title: "操作",
            search: false,
            render: (text, record) => (
                <Space size="middle">
                    <Button type="link" loading={editButtonClickLoading && loadingRecordId === record.id} onClick={() => editButtonHandler(record)}>编辑</Button>
                </Space>
            )
        }

    ]
    
    return (
        <div>
            <ProTable
                rowKey={(data) => data.id}
                columns={columns}
                dataSource={data?.results}
                direction="vertical"
                scroll={{ x: 'max-content' }}
                size="small"
                search={{
                    labelWidth: 'auto',
                    defaultCollapsed: false,
                }}
                request={(params) => {
                    setSearchData(params);
                }}
                onRequestError={(err) => {
                    notification.error({
                        message: 'Error',
                        description: err.data?.error || "数据获取失败",
                    })
                }}
                loading={{
                    spinning: isLoading || isFetching,
                    tip: "数据获取中, 请等待...",
                }}
                pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50', '100'],
                    total: data?.count,
                }}
                options={{
                    reload: () => refetch(),
                }}
                toolbar={{
                    title: ' ',
                    actions: [
                        <Button type="primary" onClick={() => setAddOpen(true)}>+ 新建</Button>
                    ]
                }}
            />
            <AddRoleModal
                open={addOpen}
                setAddOpen={setAddOpen}
                department={departments}
            />
            <EditRoleModal
                open={editOpen}
                setOpen={setEditOpen}
                data={editData}
                />
        </div>
    )
}

export default RoleManagementPage;