import { Button, Space, notification, Select, DatePicker, Input } from 'antd';
import React, { useState, useEffect } from "react";
import AddUserModal from "./AddUserModal";
import EditUserModal from "./EditUserModal"
import { renderDateTime } from "../utils";
import ProTable from '@ant-design/pro-table';
import {
    useGetUserManagementQuery,
    useGetRoleByNameQuery,
    useGetDepartmentsQuery,
    useGetRoleByDepartmentQuery,
} from "../../features/api/apiSlice";
const { RangePicker } = DatePicker;
function UserManagementPage() {
    const [addOpen, setAddOpen] = useState(false)
    const [editOpen, setEditOpen] = useState(false)
    const [editData, setEditData] = useState(null);
    const [searchData, setSearchData] = useState({});
    const { data, error, isError, isLoading, isFetching, refetch } = useGetUserManagementQuery({ ...searchData }, 
        { refetchOnMountOrArgChange: true });
    const { data: roles, refetch: refetchRoleData } = useGetRoleByNameQuery({});
    const { data: department, refetch: refetchDepartmentData } = useGetDepartmentsQuery({});
    const { data: roleByDepartmentData } = useGetRoleByDepartmentQuery({}, { refetchOnMountOrArgChange: true });
    const [cascaderData, setCascaderData] = useState([]);
    useEffect(() => {
        setCascaderData(transformCascaderData(roleByDepartmentData?.data));
    }, [roleByDepartmentData]);
    const transformCascaderData = (data) => {
        if (data) {
            return Object.entries(data).map(([key, value]) => {
                const node = {
                    label: key,
                    key: value.id,
                    value: value.id,
                };

                if (value.children && Object.keys(value.children).length > 0) {
                    node.children = transformCascaderData(value.children);
                }

                return node;
            });
        }
    }
    useEffect(() => {
        refetch();
        refetchRoleData();
        refetchDepartmentData();
    }, [refetch, refetchDepartmentData, refetchRoleData])

    const editButtonHandler = (record) => {
        setEditData(record);
        setEditOpen(true);
    }

    const columns = [
        {
            title: '序号',
            dataIndex: 'id',
            key: 'id',
            search: false,
        },
        {
            title: '用户名',
            dataIndex: 'username',
            key: 'username',
            renderFormItem: (item, { defaultRender, ...rest }, form) => {
                return (
                    <Input
                        placeholder="请输入（支持模糊查询）"
                        allowClear
                    />
                );
            },
        },
        {
            title: "邮箱",
            dataIndex: 'email',
            key: 'email',
            renderFormItem: (item, { defaultRender, ...rest }, form) => {
                return (
                    <Input
                        placeholder="请输入（支持模糊查询）"
                        allowClear
                    />
                );
            },
        },
        {
            title: "角色",
            dataIndex: ['role', 'name'],
            key: 'role',
            renderFormItem: (item, { defaultRender, ...rest }, form) => {
                return <Select
                    allowClear
                    showSearch={true}
                    style={{ textAlign: "left" }}
                    mode="multiple"
                    tokenSeparators={[',', ' ']}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {roles && roles.map(role => (
                        <Select.Option key={role.id} value={role.id}>
                            {role.name}
                        </Select.Option>
                    ))}
                </Select>
            }
        },
        {
            title: '部门',
            dataIndex: ['role', 'department', 'name'],
            key: 'department_name',
            renderFormItem: (item, { defaultRender, ...rest }, form) => {
                return <Select
                    allowClear
                    showSearch={true}
                    style={{ textAlign: "left" }}
                    tokenSeparators={[',', ' ']}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {department?.results && department.results.map(de => (
                        <Select.Option key={de.id} value={de.id}>
                            {de.name}
                        </Select.Option>
                    ))}

                </Select>
            }
        },
        {
            title: "更新人",
            dataIndex: 'updated_by',
            key: 'updated_by',
            search: false,
        },
        {
            title: "更新时间",
            dataIndex: 'updated_at',
            key: 'updated_at',
            render: renderDateTime,
            search: false,
        },
        {
            title: "最后登录时间",
            dataIndex: 'last_login',
            key: 'last_login',
            render: (text, record) => {
                return renderDateTime(record.last_login) || '-';
            },
            search: false
        },
        {
            title: "操作",
            render: (text, record) => (
                <Space size="middle">
                    <Button type="link" onClick={() => editButtonHandler(record)}>编辑</Button>
                </Space>
            ),
            search: false,
        },
        {
            title: "创建时间",
            dataIndex: "created_at",
            key: "created_at",
            sorter: true,
            search: true,
            sortDirections: ['descend', 'ascend'],
            defaultSortOrder: 'descend',
            hideInTable: true,
            renderFormItem: (item, { type, defaultRender, ...rest }) => {
                return <RangePicker style={{ width: '100%' }} />
            },
        }

    ]
    return (
        <div>
            <ProTable
                rowKey={(data) => data.id}
                columns={columns}
                dataSource={data?.results}
                direction="vertical"
                request={(params) => {
                    setSearchData(params);
                }}
                onRequestError={(err) => {
                    notification.error({
                        message: 'Error',
                        description: err.data?.error || "数据获取失败",
                    })
                }}
                loading={{
                    spinning: isLoading || isFetching,
                    tip: "数据获取中, 请等待...",
                }}
                scroll={{ x: 'max-content' }}
                size="small"
                search={{
                    labelWidth: 'auto',
                    defaultCollapsed: false,
                }}
                pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50', '100'],
                    total: data?.count,
                }}
                options={{
                    reload: () => refetch(),
                }}
                toolbar={{
                    title: ' ',
                    actions: [
                        <Button type="primary" onClick={() => setAddOpen(true)}>+ 新建</Button>
                    ]
                }}
            />
            <AddUserModal
                open={addOpen}
                setAddOpen={setAddOpen}
                department={department}
                roles={roles}
                cascaderData={cascaderData}
            />
            <EditUserModal
                open={editOpen}
                setOpen={setEditOpen}
                cascaderData={cascaderData}
                data={editData}
            />

        </div>
    )
}

export default UserManagementPage;