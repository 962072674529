import React, {useState, useEffect, useRef} from 'react';
import {Button, Modal, notification, Tabs} from 'antd';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './QuoteSheet.css'
import {getTemplateForService} from "./QuoteSheetTemplate";
import {useVerifyQuoteSheetMutation} from "../../features/api/apiSlice";
import axios from "axios";
import {AdditionRateData, BaseRateData} from "./util";
const { TabPane } = Tabs;
const RateTable = ({ title, rowData, columnDefs, onGridReady, onCellValueChanged, serviceName }) => (
    <div className="ag-theme-alpine" style={{ height: 650, flex: 1, margin: '0 10px' }}>
        <h3>{title}</h3>
        <AgGridReact
            rowData={rowData}
            columnDefs={columnDefs}
            onGridReady={onGridReady}
            onCellValueChanged={params => onCellValueChanged(params, serviceName)}
            getRowId={params => params.data.id}
            suppressDragLeaveHidesColumns={true}
            suppressMovableColumns={true}
        />
    </div>
);
function PreViewQuoteSheetModal({ open, setOpen, selectRows}) {
    const [loading, setLoading] = useState(false);
    const [processedData, setProcessedData] = useState([]);
    const BaseRateGridApiRef = useRef(null);
    const AdditionRateGridApiRef = useRef(null);

    useEffect(() => {
        if (open) {
            setLoading(true);
            fetchData();
        }
    }, [open]);
    const fetchData = async () => {
        try {
            const formData = new FormData();
            formData.append('id', selectRows.id);
            const response = await axios.post('/accounts/preview-quote-sheet/', formData);
            const newData = Object.entries(response.data.data).map(([serviceName, serviceData]) => ({
                serviceName,
                baseRateTableData: BaseRateData(serviceData.base_rates, serviceName),
                additionRateTableData: AdditionRateData(serviceData.additional_rates, serviceName),
                startDate: serviceData.start_date,
                endDate: serviceData.end_date,
                commission: serviceData.commission,
            }));
            setProcessedData(newData);
            setLoading(false);
        } catch (error) {
            console.error('Failed to fetch data:', error);
            setLoading(false);
        }
    };
    return (
        <Modal
            title="Awesung"
            open={open}
            onCancel={() => setOpen(false)}
            width={'100%'}
            style={{ top: 20}} // 设置 Modal 的最大高度
            footer={[
                <Button key="back" type={"primary"} onClick={() => setOpen(false)}>
                    Ok
                </Button>
            ]}
        >
            <Tabs>
                {processedData.map((service, index) => (
                    <TabPane tab={service.serviceName} key={index}>
                        <div>
                            <p>报价有效日期: {service.startDate} - {service.endDate}</p>
                            <p>佣金: {service.commission} %</p>
                        </div>
                        <div className="rate-table-container" style={{ display: 'flex', height: '100%' }}>
                            <div style={{ flex: '0 0 40%', width: '100%' }}>
                                <RateTable
                                    title="基本费率"
                                    rowData={service.baseRateTableData}
                                    columnDefs={getTemplateForService(service.serviceName, "preview").base_rate}
                                    onGridReady={params => BaseRateGridApiRef.current = params.api}
                                    serviceName={service.serviceName}
                                />
                            </div>
                            <div style={{ flex: '0 0 60%', width: '100%' }}>
                                <RateTable
                                    title="附加费率"
                                    rowData={service.additionRateTableData}
                                    columnDefs={getTemplateForService(service.serviceName, "preview").addition_rate}
                                    onGridReady={params => AdditionRateGridApiRef.current = params.api}
                                    serviceName={service.serviceName}
                                />
                            </div>
                        </div>
                    </TabPane>
                ))}
            </Tabs>
        </Modal>
    );
}

export default PreViewQuoteSheetModal;
