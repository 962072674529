import React, {useEffect, useState} from 'react';
import {Input, Form, Modal, Row, Col, Spin, Button} from 'antd';
import {PlusOutlined} from "@ant-design/icons";

const extractInfo = (uid) => {
    // 分割参数
    var params = uid.split('%_%');

    // 定义正则表达式
    var pattern = /#?(\d+)-(.+)/;

    for (var i = 0; i < params.length; i++) {
        var match = params[i].match(pattern);
        if (match) {
            var orderNumber = match[1];
            var customerInfo = match[2];
            return [orderNumber, customerInfo]
        }
    }
}

function TrackingMasterUIDModal({loadData, data, open, setOpen, loading, onSubmit, success}) {
    // const [masterTrackingLoading, setMasterTrackingLoading] = useState(true);
    const [form] = Form.useForm();
    useEffect(() => {
        if (success) {
            form.resetFields();
            setOpen(false);
        }
    }, [success])


    useEffect(() => {
        if (loadData) {
            const formData = Object.entries(loadData.data).map(([current, UID], index) => {
                if (UID){
                    // UID中有两个参数，以%_%为分割，取出两个参数，使用正则匹配客户单号
                    // 客户单号有可能在这两个参数中，格式有两种情况#?4个数字的客户单号-客户信息 / #?6个数字的客户单号-客户信息
                    // 例如4201-2222 或者 #4120-SSSSS 或者 321003-XXXX 或者 #450602-yyyyyy
                    // Ref的优先级更高
                    let extractInfo_result = extractInfo(UID);
                    let customer_uid = null;
                    let customer_ref = null;
                    if (extractInfo_result) {
                        customer_uid = extractInfo_result[0]
                        customer_ref = extractInfo_result[1]
                    }
                    if (customer_uid){
                        return {
                            key: index,
                            current_track: current,
                            ref: customer_ref,
                            UID: customer_uid  // 使用提取后的UID
                        };
                    }else{
                        return {
                            key: index,
                            current_track: current,
                            ref: customer_ref,
                            UID: customer_uid
                        };
                    }

                }else{
                    return {
                        key: index,
                        current_track: current,
                        ref: UID,
                        UID: ''
                    };
                }

            });
            form.setFieldsValue({ tracks: formData });
            // setMasterTrackingLoading(false);
        }

    }, [loadData, form]);

    const handleOk = () => {
        form.validateFields().then(values => {
            // 创建一个存储更新后的ID和新轨迹的对象
            const updatedTracks = {};

            // 遍历表单中的所有轨迹数据
            values.tracks.forEach(track => {
                // 从data.results中找到匹配的tracking_id
                // === track.current_track last 12 characters
                const foundItem = data.results.find(item => item.tracking_id === track.current_track.slice(-12));
                if (foundItem) {
                    // 如果找到了匹配项，将其id和新轨迹值加入到updatedTracks对象中
                    updatedTracks[foundItem.id] = track.UID;
                }
            });
            // 调用onSubmit，传递更新后的轨迹对象
            onSubmit(updatedTracks);
        }).catch(error => {
            console.log('Validate Failed:', error);
        });
    };


    return (
        <Modal
            title="追踪匹配"
            open={open}
            onCancel={() => setOpen(false)}
            onOk={handleOk}
            centered
            confirmLoading={loading}
            style={{flexWrap: 'wrap', minWidth: '40%'}}
        >
            <Form
                form={form}
                style={{ marginTop: '20px' }}
                initialValues={{ tracks: [] }}
            >
                <Form.List name="tracks">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name }) => (
                                <Row gutter={24} key={key}>
                                    <Col span={8}>
                                        {/* 包含标签和超链接的 Form.Item */}
                                        <Form.Item
                                            label="子单号"
                                            shouldUpdate={(prevValues, currentValues) =>
                                                prevValues.tracks[name]?.current_track !== currentValues.tracks[name]?.current_track
                                            }
                                        >
                                            {({ getFieldValue }) => (
                                                <>
                                                    {/* 隐藏的 Input 用于表单数据收集 */}
                                                    <Input name={[name, 'current_track']} placeholder="请输入子单号" style={{ display: 'none' }} />

                                                    {/* 可点击的超链接 */}
                                                    <a
                                                        href={`https://www.fedex.com/wtrk/track/?action=track&trackingnumber=${getFieldValue(['tracks', name, 'current_track'])}&cntry_code=us&locale=en_us`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {getFieldValue(['tracks', name, 'current_track']) || "请输入子单号"}
                                                    </a>
                                                </>
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={10}>
                                        <Form.Item
                                            name={[name, 'ref']}
                                            label="客户备注"
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item
                                            name={[name, 'UID']}
                                            label="UID"
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            ))}
                        </>
                    )}
                </Form.List>
            </Form>
        </Modal>
    );
}


export default TrackingMasterUIDModal;