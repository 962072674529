import React, { useEffect, useState } from 'react';
import { Form, Modal, Input, Select, notification, Tooltip } from 'antd';
import { useMakePaymentMutation } from '../../features/api/apiSlice';
const { Option } = Select;
const CURRENCY = {
    '0': 'USD',
    '1': 'CNY',
    '2': 'EUR',
    '3': 'CAD'
}

function PaymentModal({ open, setOpen, paymentModalData, setPaymentModalData }) {

    const [form] = Form.useForm();
    const [makePayment, {
        error: makePaymentError,
        isLoading: makePaymentIsLoading,
        isSuccess: makePaymentIsSuccess,
        isError: makePaymentIsError,
    }] = useMakePaymentMutation();
    useEffect(() => {
        if (makePaymentIsSuccess) {
            setOpen(false);
            form.resetFields();
            setPaymentModalData(undefined);
            notification.success({
                message: '成功',
                description: '创建成功',
            })
        }
        if (makePaymentIsError) {
            notification.error({
                message: '失败',
                description: makePaymentError?.data?.error || '创建失败',
            })
        }
    }, [makePaymentIsSuccess, makePaymentIsError]);

    const positiveNumberOnly = (rule, value, callback) => {
        return new Promise((resolve, reject) => {
            if (value && !/^-?[0-9]+(.[0-9]+)?$/.test(value)) {
                reject('请输入可识别的数字!');
            } else if (value && value.length > 20) {
                reject('最多20位!');
            } else {
                resolve();
            }
        });
    }
    const modalOkHandler = () => {
        form.validateFields().then((values) => {
            const formData = new FormData();
            formData.append('invoice_id', paymentModalData.id)
            formData.append('payee', values.payee);
            formData.append('payment_amount', values.payment_amount);
            formData.append('payment_currency', values.payment_currency);
            formData.append('exchange_rate', values.exchange_rate);
            if (values.note) {formData.append('note', values.note);}
            makePayment(formData);
        }).catch((info) => {
            console.log('Validate Failed:', info);
        });
    }

    return (
        <Modal
            title='收款'
            open={open}
            onCancel={() => setOpen(false)}
            onOk={modalOkHandler}
            confirmLoading={makePaymentIsLoading}
        >
            <Form
                form={form}
                labelCol={{
                    span: 5,
                }}
                wrapperCol={{
                    span: 19,
                }}
                style={{
                    marginTop: '20px',
                    marginLeft: '0%',
                    marginRight: '10%',
                    maxWidth: '100%'
                }}
            >
                <Form.Item label='回款主体' name='payee' rules={[{ required: true, message: '请选择回款主体!' }]}>
                    <Select
                        showSearch
                        placeholder="请选择"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        <Option label='Awesung Limited (新火傲盛科技有限公司)' key='0' value='0'>Awesung Limited (新火傲盛科技有限公司)</Option>
                        <Option label='广州奥越国际物流有限公司-海运' key='1' value='1'>广州奥越国际物流有限公司-海运</Option>
                        <Option label='广州辕蓬物流有限公司' key='2' value='2'>广州辕蓬物流有限公司</Option>
                        <Option label='新Awesung Tech' key='3' value='3'>新Awesung Tech</Option>
                        <Option label='Bloomsung' key='4' value='4'>Bloomsung</Option>
                        <Option label='深圳新火傲盛科技有限公司-深圳易仓' key='5' value='5'>深圳新火傲盛科技有限公司-深圳易仓</Option>
                        <Option label='老Awesung Inc' key='6' value='6'>老Awesung Inc</Option>
                        <Option label='代发余额抵扣' key='7' value='7'>代发余额抵扣</Option>
                        <Option label='账单抵扣' key='8' value='8'>账单抵扣</Option>
                        <Option label='Awetrans Inc' key='9' value='9'>Awetrans Inc</Option>
                        <Option label='Awetrans Limited' key='10' value='10'>Awetrans Limited</Option>
                    </Select>
                </Form.Item>
                <Form.Item label='回款金额' name='payment_amount' rules={[{ required: true, message: '请选择回款主体!' }, { validator: positiveNumberOnly }]}>
                    <Input />
                </Form.Item>
                <Form.Item label='回款币种' name='payment_currency' rules={[{ required: true, message: '请选择回款币种!' }]}>
                    <Select>
                        <Option label='USD' key='0' value='0'>USD</Option>
                        <Option label='CNY' key='1' value='1'>CNY</Option>
                        <Option label='EUR' key='2' value='2'>EUR</Option>
                        <Option label='CAD' key='3' value='3'>CAD</Option>
                    </Select>
                </Form.Item>
                <Form.Item 
                    label='汇率'
                    name='exchange_rate'
                    rules={[{ required: true, message: '请输入汇率!' }]}
                    help={`请输入汇率: 1 ${paymentModalData?.currency} : X 回款币种; 保留小数点后四位`}
                    >
                    <Input />
                </Form.Item>
                <Form.Item label='备注' name='note'>
                    <Input.TextArea />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default PaymentModal;