import React, { useState } from 'react';
import { ProTable } from '@ant-design/pro-table';
import { Select, Input, DatePicker, Button, Space, App, theme } from 'antd';
import {
    useGetUserQuery,
    useGetCustomerUidWithBillingCycleQuery,
    useGetBankSlipQuery,
} from '../../features/api/apiSlice';
import CreateNewBankSlipModal from './CreateNewBankSlipModal';
import BankSlipConfirmPaymentModal from './BankSlipConfirmPaymentModal';
import BankSlipOperationLogModal from './BankSlipOperationLogModal';
import axios from 'axios';
import { useSelector } from 'react-redux';
const { Option } = Select;
const { RangePicker } = DatePicker;
function BankSlipTab() {
    const { token } = theme.useToken();
    const [searchData, setSearchData] = useState({});
    const { data: kaUserData, error: kaUserError, isLoading: kaUserIsLoading, refetch: refetchKaUserData } = useGetUserQuery();
    const { data: customerUidData } = useGetCustomerUidWithBillingCycleQuery('预充');
    const [createNewModalOpen, setCreateNewModalOpen] = useState(false);
    const { data, error, isLoading, isFetching, refetch } = useGetBankSlipQuery({...searchData});
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [confirmModalData, setConfirmModalData] = useState(null);
    const [logModalOpen, setLogModalOpen] = useState(false);
    const [logModalData, setLogModalData] = useState(null);
    const [logModalLoading, setLogModalLoading] = useState(false);
    const { notification } = App.useApp();
    const currUser = useSelector((state) => state.auth.user);
    const confirmButtonHandler = (record) => {
        if (record.slip_status === '已到款') {
            return notification.error({
                message: 'Error',
                description: '该水单已到款,无需再次确认',
            })
        }
        setConfirmModalOpen(true);
        setConfirmModalData(record);
    }
    const logButtonHandler = (record) => {
        setLogModalLoading(true);
        axios.get('/accounts/get-bank-slip-operation-log/' + record.id + '/')
            .then((response) => {
                setLogModalData(response.data.data);
                setLogModalOpen(true);
                setLogModalLoading(false);
            }).catch((error) => {
                console.log(error);
                setLogModalLoading(false);
                notification.error({
                    message: 'Error',
                    description: '获取数据失败,请稍后再试',
                })
            });
    }
    const columns = [
        {
            title: '状态',
            dataIndex: 'slip_status',
            key: 'slip_status',
            search: true,
            renderFormItem: (item, { defaultRender, ...rest }, form) => {
                return <Select
                    allowClear
                    style={{ textAlign: "left" }}
                    placeholder='请选择'
                >
                    <Option value="0">未到款</Option>
                    <Option value="1">已到款</Option>
                </Select>
            }
        },
        {
            title: '回款主体',
            dataIndex: 'remittee',
            key: 'remittee',
            search: true,
            renderFormItem: (item, { defaultRender, ...rest }, form) => {
                return <Select
                    showSearch
                    allowClear
                    placeholder="请选择"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ textAlign: "left" }}
                >
                    <Option label='Awesung Limited (新火傲盛科技有限公司)' key='0' value='0'>Awesung Limited (新火傲盛科技有限公司)</Option>
                    <Option label='广州奥越国际物流有限公司-海运' key='1' value='1'>广州奥越国际物流有限公司-海运</Option>
                    <Option label='广州辕蓬物流有限公司' key='2' value='2'>广州辕蓬物流有限公司</Option>
                    <Option label='新Awesung Tech' key='3' value='3'>新Awesung Tech</Option>
                    <Option label='Bloomsung' key='4' value='4'>Bloomsung</Option>
                    <Option label='深圳新火傲盛科技有限公司-深圳易仓' key='5' value='5'>深圳新火傲盛科技有限公司-深圳易仓</Option>
                    <Option label='老Awesung Inc' key='6' value='6'>老Awesung Inc</Option>
                </Select>
            }
        },
        {
            title: '收款账户',
            dataIndex: 'collection_amount',
            key: 'collection_amount',
            search: true,
            renderFormItem: (item, { defaultRender, ...rest }, form) => {
                return <Input placeholder='请输入(支持模糊查询)' />
            }
        },
        {
            title: '付款单位名',
            dataIndex: 'payee_name',
            key: 'payee_name',
            search: true,
            renderFormItem: (item, { defaultRender, ...rest }, form) => {
                return <Input placeholder='请输入(支持模糊查询)' />
            }
        },
        {
            title: '水单金额',
            dataIndex: 'slip_amount',
            key: 'slip_amount',
            search: false,
        },
        {
            title: '币种',
            dataIndex: 'currency',
            key: 'currency',
            search: false,
        },
        {
            title: '汇率',
            dataIndex: 'exchange_rate',
            key: 'exchange_rate',
            search: false,
        },
        {
            title: '回款类型',
            dataIndex:'bank_slip_type',
            key: 'bank_slip_type',
            search: true,
            renderFormItem: (item, { defaultRender, ...rest }, form) => {
                return <Select
                    allowClear
                    style={{ textAlign: "left" }}
                    placeholder='请选择'>
                    <Option label="应付款抵扣" key="0" value="0">应付款抵扣</Option>
                    <Option label="我司账户收款" key="1" value="1">我司账户收款</Option>
                    <Option label="押金抵扣" key="2" value="2">押金抵扣</Option>
                    <Option label="支票" key="3" value="3">支票</Option>
                </Select>
            }
        },
        {
            title: '操作人',
            dataIndex: ['issuer', 'username'],
            key: 'issuer',
            search: true,
            renderFormItem: (item, { defaultRender, ...rest }, form) => {
                return <Select
                    showSearch
                    allowClear
                    placeholder="请选择"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ textAlign: "left" }}
                >
                    {kaUserData && kaUserData.map((item) => {
                        return <Option key={item.id} value={item.id}>{item.username}</Option>
                    })}
                </Select>
            }
        },
        {
            title: '水单转账日期',
            dataIndex: 'issue_time',
            key: 'issue_time',
            search: true,
            renderFormItem: (item, { defaultRender, ...rest }, form) => {
                return <RangePicker />
            }
        },
        {
            title: '到账日期',
            dataIndex: 'confirm_date',
            key: 'confirm_date',
            search: true,
            renderFormItem: (item, { defaultRender, ...rest }, form) => {
                return <RangePicker />
            }
        },
        {
            title: '银行手续费',
            dataIndex: 'bank_service_fee',
            key: 'bank_service_fee',
            search: false,
        },
        {
            title: '备注',
            dataIndex: 'note',
            key: 'note',
            search: true,
            renderFormItem: (item, { defaultRender, ...rest }, form) => {
                return <Input placeholder='请输入(支持模糊查询)' />
            }
        },
        {
            title: '操作',
            dataIndex: 'operation',
            key: 'operation',
            search: false,
            render: (text, record) => {
                return <Space>
                    <Button type='link' onClick={() => confirmButtonHandler(record)}>确认到账</Button>
                    <Button type='link' onClick={() => logButtonHandler(record)} loading={logModalLoading}>日志</Button>
                </Space>
            }
        }
    ]

    return (
        <div>
            <ProTable
                rowKey={(record) => record.id}
                search={{
                    labelWidth: 'auto',
                    defaultCollapsed: false,
                    style: {
                        maxWidth: 'none',
                        background: token.colorFillAlter,
                        borderRadius: token.borderRadiusLG,
                        padding: 24,
                    }
                }}
                columns={columns}
                dataSource={data?.results}
                request={(params) => {
                    setSearchData(params);
                }}
                pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50', '100'],
                    total: data?.count,
                }}
                loading={isLoading || isFetching}
                scroll={{ x: 'max-content' }}
                options={{
                    reload: () => refetch(),
                }}
                toolbar={{
                    title: ' ',
                    actions: [
                        (currUser.button_permission?.includes('create_new_bank_slip') && <Button key="uidEdit" type="primary" onClick={() => setCreateNewModalOpen(true)}>
                            新建
                        </Button>),
                    ],
                }}
            />
            <CreateNewBankSlipModal
                open={createNewModalOpen}
                setOpen={setCreateNewModalOpen}
                customerUidData={customerUidData}
                kaUserData={kaUserData}
                />
            <BankSlipConfirmPaymentModal
                open={confirmModalOpen}
                setOpen={setConfirmModalOpen}
                data={confirmModalData}
                />
            <BankSlipOperationLogModal
                open={logModalOpen}
                setOpen={setLogModalOpen}
                data={logModalData}
                />
        </div>
    )
}

export default BankSlipTab;