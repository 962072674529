import React, { useEffect } from 'react';
import './MatchZZZModal.css';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Modal, Form, Select, Button, notification, InputNumber, Row, Col } from 'antd';
import {
    useConvertZZZToChargeMutation,
} from '../../features/api/apiSlice';
import Decimal from 'decimal.js';
function MatchZZZModal({ open, setOpen, modalData, selectedRowKeys, setSelectedRowKeys, setSelectedRows, chargeCodeData }) {
    const [convertZZZToCharge, {
        isLoading: convertZZZToChargeLoading,
        isSuccess: isConvertZZZToChargeSuccess,
        isError: isConvertZZZToChargeError,
        error: convertZZZToChargeError,
    }] = useConvertZZZToChargeMutation();
    const [form] = Form.useForm();
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 20 },
        },
    };

    const formItemLayoutWithOutLabel = {
        wrapperCol: {
            xs: { span: 24, offset: 0 },
            sm: { span: 20, offset: 4 },
        },
    };
    useEffect(() => {
        if (isConvertZZZToChargeSuccess) {
            notification.success({
                message: 'Success',
                description: 'Convert ZZZ to charge success',
            })
            setOpen(false);
            setSelectedRowKeys([]);
            setSelectedRows([]);
            form.resetFields();
        }
        if (isConvertZZZToChargeError) {
            notification.error({
                message: 'Error',
                description: convertZZZToChargeError?.data?.error ?? 'Convert ZZZ to charge failed for unknown reason',
            })
        }
    }, [isConvertZZZToChargeSuccess, isConvertZZZToChargeError, convertZZZToChargeError])
    const handleOk = () => {
        form.validateFields().then(values => {
            // check if amount in charge_descriptions sum up to surcharge_json['Total of all ‘Other Charge’ Components']
            const totalAmount = values.charge_description.reduce((acc, curr) => acc.plus(new Decimal(curr.amount)), new Decimal(0));
            console.log(totalAmount.toString());
            if (!totalAmount.equals(new Decimal(modalData[0].surcharge_json['Total of all ‘Other Charge’ Components']))) {
                return notification.error({
                    message: 'Error',
                    description: '附加费总和不相等',
                })
            }
            convertZZZToCharge({
                ids: selectedRowKeys,
                charge_description: values.charge_description,
            })
        }).catch(error => {
            console.log(error);
        })
    }

    return (
        <Modal
            title="匹配 Total of all `Other Charge` Components"
            open={open}
            onCancel={() => setOpen(false)}
            onOk={handleOk}
            width={800}
            centered
            confirmLoading={convertZZZToChargeLoading}
        >
            该条目的Service为 {modalData?.[0]?.rate_json['Svc']}<br />
            Total of all `Other Charge` Components 费用为 {modalData?.[0]?.surcharge_json['Total of all ‘Other Charge’ Components']}<br />
            请在以下的输入框中输入附加费条目以及对应的价格,价格相加须等于Total of all `Other Charge` Components费用
            <Form
                form={form}
                name="dynamic_form_item"
                initialValues={{ charge_description: [""] }}
                {...formItemLayoutWithOutLabel}
            >
                <Form.List name='charge_description'>
                    {(fields, { add, remove }, { errors }) => (
                        <>
                            {fields.map((field, index) => (
                                <Row gutter={[0, 4]}>
                                    <Col span={16}>
                                        <Form.Item
                                            {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                                            label={index === 0 ? '附加费' : ''}
                                            validateTrigger={['onChange', 'onBlur', 'onSubmit']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "请输入或者删除该行",
                                                },
                                            ]}
                                            name={[index, 'description']}
                                            key={field.key}                                    
                                        >
                                            <Select
                                                showSearch
                                                allowClear
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '')
                                                        .toLowerCase()
                                                        .includes(input.toLowerCase())
                                                }
                                                style={{ width: '90%' }}
                                            >
                                                {chargeCodeData?.map((item) => (
                                                    <Select.Option key={item.id} value={item.id} label={item.description}>
                                                        {item.description}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item
                                            {...field}
                                            label="费用"
                                            validateTrigger={['onChange', 'onBlur', 'onSubmit']}
                                            name={[index, 'amount']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "请输入或者删除该行",
                                                },
                                            ]}                                        
                                        >
                                            <InputNumber />
                                        </Form.Item>
                                    </Col>
                                    <Col span={2}>
                                        {fields.length > 1 ? (
                                            <MinusCircleOutlined
                                                className="dynamic-delete-button"
                                                onClick={() => remove(field.name)}
                                            />
                                        ) : null}
                                    </Col>
                                </Row>
                            ))}
                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    style={{ width: '60%' }}
                                    icon={<PlusOutlined />}
                                >
                                    添加附加费
                                </Button>
                                <Form.ErrorList errors={errors} />
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            </Form>
        </Modal>
    );
}

export default MatchZZZModal;