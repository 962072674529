import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row, Space, theme, Select, DatePicker, notification } from 'antd';
import moment from 'moment-timezone';
import {
    useGroundShipmentRateCalculationMutation,
    useGenerateBillingBatchShipmentsMutation,
} from "../../features/api/apiSlice";
import axios from 'axios';
import GenerateBillingBatchModal from "./GenerateBillingBatchModal";
import FuncConfirmModal from "../billingDetails/FuncConfirmModal";
import { useSelector } from "react-redux";
const shipmentStatus = {
    "0": "缺件", "1": "异常", "2": "待计算", "3": "计费异常", "4": "待出账", "6": "已出账"
}
const GroundCalculationSearchBar = ({ customerData, invoiceNames, setSearchData, setCurrentPage, setPageSize, selectedRows, selectedRowKeys, setSelectedRowKeys }) => {
    const { token } = theme.useToken();
    const auth = useSelector((store) => store.auth.user);
    const [form] = Form.useForm();
    const [DownloadData, setDownloadData] = useState(false);
    const [open, setOpen] = useState(false);
    const [funcConfirmContent, setFuncConfirmContent] = useState([]);
    const [downloadConfirmOpen, setDownloadConfirmOpen] = useState(false);
    const [downloadConfirmContent, setDownloadConfirmContent] = useState(null);
    const [calculateShipmentConfirmOpen, setCalculateShipmentConfirmOpen] = useState(false);
    const [calculateShipmentContent, setCalculateShipmentContent] = useState(null);
    const [calculateLoading, setCalculateLoading] = useState(false);
    const [generateBillingConfirmOpen, setGenerateBillingConfirmOpen] = useState(false);
    const [generateBillingContent, setGenerateBillingContent] = useState(null);
    const [calculateGroundShipment, {
        isSuccess: isShipmentRateCalculationSuccess,
        isError: isShipmentRateCalculationError,
        error: ShipmentRateCalculationError,
        isLoading: isShipmentRateCalculationLoading,
        isFetching: isShipmentRateCalculationFetching,
    }] = useGroundShipmentRateCalculationMutation();
    
    const formStyle = {
        maxWidth: 'none',
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
    };
    const [GenerateBilling, {
        isSuccess: isGenerateBillingSuccess,
        isError: isGenerateBillingError,
        error: GenerateBillingError,
        isLoading: isGenerateBillingLoading,
        isFetching: isGenerateBillingFetching,
    }] = useGenerateBillingBatchShipmentsMutation();

    useEffect(() => {
        if (isGenerateBillingSuccess) {
            notification.success({
                message: "Success",
                description: "生成出账批次成功!",
            });
            setOpen(false);
            setSelectedRowKeys([]);
        }
        if (isGenerateBillingError) {
            notification.error({
                message: "Error",
                description: GenerateBillingError.data?.message,
            });
        }
    }, [isGenerateBillingSuccess, isGenerateBillingError])

    useEffect(() => {
        if (isShipmentRateCalculationSuccess) {
            notification.success({
                message: "Success",
                description: "运费计算成功!",
            });
            setCalculateShipmentConfirmOpen(false);
            setSelectedRowKeys([]);
        }
        if (isShipmentRateCalculationError) {
            notification.error({
                message: "Error",
                description: ShipmentRateCalculationError.data?.message,
            });
        }
    }, [isShipmentRateCalculationSuccess, isShipmentRateCalculationError])
    const get_status = (SD) => {
        let billingStatus = [];
        SD.status?.split(",").forEach(status => {
            if (shipmentStatus.hasOwnProperty(status)) {
                billingStatus.push(shipmentStatus[status]);
            }
        });
        return billingStatus.join(', ');
    }

    const handleDownloadButtonClick = () => {
        setDownloadData(true)

        try {
            if (selectedRowKeys.length > 0) {
                setFuncConfirmContent(selectedRows);
                setDownloadConfirmContent(selectedRows);
                setDownloadConfirmOpen(true);
            } else {
                const formData = form.getFieldsValue();
                // 检查是否至少还有一个其他查询条件
                const otherFields = { ...formData };
                const hasOtherValidFields = Object.values(otherFields).some(value => value !== undefined);
                if (!hasOtherValidFields) {
                    return notification.error({
                        message: "Error",
                        description: "下载范围过大,必须有至少一个查询条件!",
                    });
                }

                if (otherFields.shipmentDate) {
                    otherFields.shipmentDate = moment(otherFields.shipmentDate.toDate()).format('YYYY-MM-DD');
                } else {
                    delete otherFields.shipmentDate;
                }

                // trim all values
                Object.keys(otherFields).forEach(key => {
                    if (typeof otherFields[key] === 'string') {
                        otherFields[key] = otherFields[key].trim();
                    } else if (Array.isArray(otherFields[key])) {
                        otherFields[key] = otherFields[key].join(',');
                    }
                });
                const paramStatus = get_status(otherFields);
                const invoiceNames = getInvoiceName(otherFields.invoice);
                let params = [{
                    "状态": paramStatus,
                    "Master Tracking": otherFields.masterTrackingNumber,
                    "UID": otherFields.uid,
                    "Shipment Date截止于": otherFields.shipmentDate,
                    "出账批次编号": invoiceNames,
                }]
                setDownloadConfirmOpen(true);
                setFuncConfirmContent(params);
                setDownloadConfirmContent({
                    "status": otherFields.status,
                    "masterTrackingNumber": otherFields.masterTrackingNumber,
                    "uid": otherFields.uid,
                    "shipmentDate": otherFields.shipmentDate,
                    "invoice": otherFields.invoice,
                });
            }
        } catch (res) {
            console.log(res);
            notification.success({
                message: "Error",
                description: "下载失败,出现错误!",
            })
        } finally {
            setDownloadData(false)
        }
    }

    const handleDownloadButtonSubmit = () => {
        if (selectedRowKeys.length > 0) {
            const idToDownload = downloadConfirmContent.map(item => item.id).join(',');
            axios.get('/accounts/get-ground-calculation_details/', {
                params: {
                    id_to_download: idToDownload,
                    isDownload: 1,
                }
            });
        } else {
            const params = {
                page_size: downloadConfirmContent.pageSize,
                curr_page: downloadConfirmContent.currentPage,
                master_tracking_number: downloadConfirmContent.masterTrackingNumber,
                uid: downloadConfirmContent.uid,
                shipment_date: downloadConfirmContent.shipmentDate,
                invoice: downloadConfirmContent.invoice,
                status: downloadConfirmContent.status,
                isDownload: 1,
            };
            axios.get('/accounts/get-ground-calculation_details/', {
                params: params,
            });
        }
        notification.success({
            message: "Success",
            description: "开始准备文件,请前往后台任务管理页面获取文件",
        })
        setDownloadConfirmOpen(false);
        setDownloadConfirmContent(null);
        setSelectedRowKeys([]);
    }
    const getInvoiceName = (invoice_ids) => {
        let invNames = [];
        invoice_ids?.split(',').forEach(invoice_id => {
            for (let key in invoiceNames) {
                if (invoiceNames[key]['id'].toString() === invoice_id) {
                    invNames.push(invoiceNames[key]['name']);
                }
            }
        });
        return invNames.join(', ');
    }
    const handleCalculationButtonClick = () => {
        setCalculateLoading(true);
        if (selectedRowKeys.length > 0) {
            const validStatuses = ["待计算", "计费异常", "缺件"];
            const allMatch = selectedRows.every(row => validStatuses.includes(row.status));
            setCalculateLoading(false);
            if (!allMatch) {
                return notification.error({
                    message: "Error",
                    description: "所有选中的条目必须都为[待计算]或[计费异常]状态",
                });
            }
            setFuncConfirmContent(selectedRows);
            setCalculateShipmentContent(selectedRows);
            setCalculateShipmentConfirmOpen(true);
        } else {
            const formData = form.getFieldsValue();
            const validStatuses = ["0", "2", "3"];
            if (!formData.status?.length || !formData.status.every(status => validStatuses.includes(status))) {
                setCalculateLoading(false);
                return notification.error({
                    message: "Error",
                    description: "状态只能为[待计算][计费异常]或[缺件]",
                });
            }
            if (!formData?.uid || formData?.uid.length < 1) {
                setCalculateLoading(false);
                return notification.error({
                    message: "Error",
                    description: "请至少选择一个UID进行计算运费",
                })
            }
            if (formData?.invoice && formData?.invoice.length > 0) {
                setCalculateLoading(false);
                return notification.error({
                    message: "Error",
                    description: "计算运费不支持筛选出账批次",
                })
            }
            // 检查是否至少还有一个其他查询条件
            const searchData = { ...formData };
            const hasOtherValidFields = Object.values(searchData).some(value => value !== undefined);

            if (!hasOtherValidFields) {
                return notification.error({
                    message: "Error",
                    description: "下载范围过大,必须有至少一个查询条件!",
                });
            }
            // 如果status include了0, 需要包含shipmentDate
            if (searchData.status.includes("0") && !searchData.shipmentDate) {
                setCalculateLoading(false);
                return notification.error({
                    message: "Error",
                    description: "状态为缺件时, 必须包含Shipment Date截止于搜索条件",
                });
            }
            if (searchData.shipmentDate) {
                searchData.shipmentDate = moment(searchData.shipmentDate.toDate()).format('YYYY-MM-DD');
            } else {
                delete searchData.shipmentDate;
            }

            // trim all values
            Object.keys(searchData).forEach(key => {
                if (typeof searchData[key] === 'string') {
                    searchData[key] = searchData[key].trim();
                } else if (Array.isArray(searchData[key])) {
                    searchData[key] = searchData[key].join(',');
                }
            });
            const paramStatus = get_status(searchData);
            let params = [{
                "状态": paramStatus,
                "Master Tracking": searchData.masterTrackingNumber,
                "UID": searchData.uid,
                "Shipment Date截止于": searchData.shipmentDate,
            }];
            setCalculateLoading(false);
            setCalculateShipmentConfirmOpen(true);
            setFuncConfirmContent(params);
            setCalculateShipmentContent({
                "status": searchData.status,
                "master_tracking_number": searchData.masterTrackingNumber,
                "uid": searchData.uid,
                "shipment_date": searchData.shipmentDate,
            });
        }
    }

    const calculateShipmentSubmit = () => {
        const SubformData = new FormData();
        if (selectedRowKeys.length > 0) {
            const ids = calculateShipmentContent.map(item => item.id).join(',');
            SubformData.append('id_to_download', ids);
        } else {
            Object.keys(calculateShipmentContent).forEach(key => {
                if (calculateShipmentContent[key] !== undefined && calculateShipmentContent[key] !== null) {
                    SubformData.append(key, calculateShipmentContent[key]);
                }
            });
        }
        calculateGroundShipment(SubformData);
    }
    const getFields = () => {
        const children = [];
        children.push(
            <Col span={8} key={4}>
                <Form.Item name="status" label="Status">
                    <Select
                        placeholder="请选择"
                        allowClear
                        style={{ textAlign: "left" }}
                        mode='multiple'>
                        <Select.Option value="0">缺件</Select.Option>
                        <Select.Option value="1">异常</Select.Option>
                        <Select.Option value="2">待计算</Select.Option>
                        <Select.Option value="3">计费异常</Select.Option>
                        <Select.Option value="4">待出账</Select.Option>
                        <Select.Option value="6">已出账</Select.Option>
                    </Select>
                </Form.Item>
            </Col>,
            <Col span={8} key={1}>
                <Form.Item name="masterTrackingNumber" label="Master Tracking">
                    <Input placeholder="请输入内容" />
                </Form.Item>
            </Col>,
            <Col span={8} key={2}>
                <Form.Item name="uid" label="UID">
                    <Select placeholder='请选择' allowClear style={{ textAlign: "left" }} mode='multiple'>
                        {customerData && customerData.map((item) => (
                            <Select.Option key={item.uid} value={item.uid}>{item.uid}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Col>,
            <Col span={8} key={3}>
                <Form.Item name="shipmentDate" label="Shipment Date截止于">
                    <DatePicker style={{ width: '100%' }} format="YYYY-MM-DD" />
                </Form.Item>
            </Col>,
            <Col span={8} key={5}>
                <Form.Item name="invoice" label="出账批次">
                    <Select 
                        placeholder='请选择'
                        allowClear
                        showSearch
                        style={{ textAlign: "left" }}
                        mode='multiple'
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        >
                        {invoiceNames && invoiceNames.map((item) => (
                            <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Col>
        );
        return children;
    };
    const onSubmit = (values) => {
        if (!values?.billing_batch_id) {
            return notification.error({
                message: "Error",
                description: "出账批次不能为空!",
            });
        }
        const searchData = { ...generateBillingContent };
        // 判断是否有勾选条件
        if (selectedRowKeys.length > 0){
            if (selectedRowKeys.length > 20){
                return notification.info({
                    message: "Info",
                    description: "勾选操作最多支持20条信息!",
                });
            }
            searchData['ids'] = generateBillingContent;
        }else {
            Object.keys(generateBillingContent).forEach(key => {
                if (typeof generateBillingContent[key] === 'string') {
                    searchData[key] = generateBillingContent[key].trim();
                }
            });
        }
        searchData['billing_batch_id'] = values.billing_batch_id;
        GenerateBilling(searchData);
    }
    const onFinish = (values) => {
        const searchData = { ...values };
        if (searchData.shipmentDate) {
            searchData.shipmentDate = moment(searchData.shipmentDate.toDate()).format('YYYY-MM-DD');
        } else {
            delete searchData.shipmentDate;
        }
        // trim all values
        Object.keys(searchData).forEach(key => {
            if (typeof searchData[key] === 'string') {
                searchData[key] = searchData[key].trim();
            }
        });
        setSearchData(searchData);
        setCurrentPage(1);
        setPageSize(10);
    };
    const generateBillingBatchButtonHandler = () => {
        // 判断是否勾选
        if (selectedRowKeys.length > 0) {
            if (selectedRowKeys.length > 20){
                return notification.info({
                    message: "Info",
                    description: "勾选操作最多支持20条信息!",
                });
            }
            // 判断勾选条目是否待出账状态
            const firstUID = selectedRows[0].customer

            // 检查所有元素的status是否为12，以及UID是否都相同
            const allMatch = selectedRows.every(row => (row.status === 4 || row.status === "待出账") && row.customer === firstUID);
            if (!allMatch) {
                return notification.error({
                    message: "Error",
                    description: "所有选中的条目必须都为待出账状态,且UID必须相同",
                });
            }
            setFuncConfirmContent(selectedRows);
            setGenerateBillingContent(selectedRowKeys);
            setGenerateBillingConfirmOpen(true);
        }else{
            // get values from form
            const values = form.getFieldsValue();
            const searchData = { ...values };
            if (!searchData?.uid || searchData?.uid.length !== 1) {
                return notification.error({
                    message: "Error",
                    description: "请选择一个UID进行出账",
                })
            }
            if (!searchData?.status || searchData?.status.length !== 1 || searchData?.status[0] !== '4') {
                return notification.error({
                    message: "Error",
                    description: "请选择状态搜索条件为待出账",
                })
            }
            if (searchData?.invoice && searchData?.invoice.length > 0) {
                return notification.error({
                    message: "Error",
                    description: "生成出账批次不支持筛选出账批次",
                })
            }
            if (searchData.shipmentDate) {
                searchData.shipmentDate = moment(searchData.shipmentDate.toDate()).format('YYYY-MM-DD');
            } else {
                delete searchData.shipmentDate;
            }
            // trim all values
            Object.keys(searchData).forEach(key => {
                if (typeof searchData[key] === 'string') {
                    searchData[key] = searchData[key].trim();
                } else if (Array.isArray(searchData[key])) {
                    searchData[key] = searchData[key].join(',');
                }
            });
            
            const paramStatus = get_status(searchData);
            let params = [{
                "状态": paramStatus,
                "Master Tracking": searchData.masterTrackingNumber,
                "UID": searchData.uid,
                "Shipment Date截止于": searchData.shipmentDate,
            }];
            setGenerateBillingConfirmOpen(true);
            setFuncConfirmContent(params);
            setGenerateBillingContent({
                "status": searchData.status,
                "masterTrackingNumber": searchData.masterTrackingNumber,
                "uid": searchData.uid,
                "shipmentDate": searchData.shipmentDate,
            });
        }
    }
    const generateBillingBatchButtonSubmit = () => {
        setGenerateBillingConfirmOpen(false);
        setOpen(true);
    }
    return (
        <Form form={form} name="advanced_search" style={formStyle} onFinish={onFinish}>
            <Row gutter={24}>{getFields()}</Row>
            <div
                style={{
                    textAlign: 'right',
                }}
            >
                <Space size="small">
                    {auth.username === "gzyuny" ? null : <Button type="primary" onClick={generateBillingBatchButtonHandler}>
                        生成出账批次
                    </Button>}
                    <Button loading={DownloadData} type="primary" htmlType="button" onClick={() => handleDownloadButtonClick()}>
                        下载数据
                    </Button>
                    {auth.username === "gzyuny" ? null : <Button loading={calculateLoading} type="primary" onClick={() => handleCalculationButtonClick()}>
                        计算运费
                    </Button>}
                    <Button type="primary" htmlType="submit">
                        Search
                    </Button>
                    <Button
                        onClick={() => {
                            form.resetFields();
                        }}
                    >
                        Clear
                    </Button>
                </Space>
                <GenerateBillingBatchModal
                    open={open}
                    setOpen={setOpen}
                    onSubmit={onSubmit}
                    loading={isGenerateBillingLoading || isGenerateBillingFetching}
                    success={isGenerateBillingSuccess}
                />
                <FuncConfirmModal
                    open={downloadConfirmOpen}
                    setOpen={setDownloadConfirmOpen}
                    content={funcConfirmContent}
                    isFilter={selectedRowKeys.length <= 0}
                    onSubmit={handleDownloadButtonSubmit}
                    isMaster={true}
                />
                <FuncConfirmModal
                    open={calculateShipmentConfirmOpen}
                    setOpen={setCalculateShipmentConfirmOpen}
                    content={funcConfirmContent}
                    isFilter={selectedRowKeys.length <= 0}
                    onSubmit={calculateShipmentSubmit}
                    loading={isShipmentRateCalculationLoading || isShipmentRateCalculationFetching}
                    success={isShipmentRateCalculationSuccess}
                    isMaster={true}
                />
                <FuncConfirmModal
                    open={generateBillingConfirmOpen}
                    setOpen={setGenerateBillingConfirmOpen}
                    content={funcConfirmContent}
                    isFilter={selectedRowKeys.length <= 0}
                    onSubmit={generateBillingBatchButtonSubmit}
                    loading={isGenerateBillingLoading || isGenerateBillingFetching}
                    isMaster={true}
                />
            </div>
        </Form>
    );
};

export default GroundCalculationSearchBar;