import React, {useEffect, useState} from 'react';
import { Row, Col, Card, Typography } from 'antd';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip, Legend, 
    Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, BarChart } from 'recharts';
import moment from 'moment';
import axios from "axios";
import { addThousandsSeparator } from "../utils";
const { Title } = Typography;

const EIGHT_COLORS = ['#4CAF50', '#FFA000', '#2196F3', '#9C27B0', '#FF5722', '#111111', "#9A3CA9", "#750FEE"];

const ReceivablesRiskDashboardPage = () => {
    const [pieData, setPieData] = useState([])
    const [overdue_amount, setOverdueAmount] = useState(99454)
    const [receivable_amount, setReceivableAmount] = useState(575758)
    const [lineData, setLineData] = useState([])
    const [kaData, setKaData] = useState([])
    const [business, setBusiness] = useState([])
    const [business_trend, setBusinessTrend] = useState([])
    const [customer_trend, setCustomerTrend] = useState([])
    const [customer_sum, setCustomerSum] = useState([])
    const [topTenCustomerWithProductValue, setTopTenCustomerWithProductValue] = useState([]);
    const [topTenCustomerWithVolume, setTopTenCustomerWithVolume] = useState([]);
    useEffect(() => {
        axios.get('/accounts/panel_service/').then((res) => {
            if (res.status === 200){
                let data = res.data
                let overdue_ranges = data.overdue_ranges
                let new_overdue_ranges = [];
                for(let i in overdue_ranges){
                    let da = {}
                    da["name"] = overdue_ranges[i]["name"]
                    da["value"] = parseFloat(overdue_ranges[i]["value"])
                    new_overdue_ranges.push(da)
                }
                setPieData(new_overdue_ranges)
                setOverdueAmount(data.totals.total_overdue)
                setReceivableAmount(data.totals.total_receivable)
                setLineData(data.trends)
                setKaData(data.ka_stats.overdue_by_ka)
                setBusiness(data.ka_stats.overdue_by_business)
                setBusinessTrend(data.ka_stats.business_by_trend)
                setCustomerTrend(data.ka_stats.customer_trend)
                setCustomerSum(data.ka_stats.top_customer_sum)
                setTopTenCustomerWithProductValue(data.top_ten_customers_by_overdue_unpaid_with_product_value)
                setTopTenCustomerWithVolume(data.top_ten_customers_by_overdue_unpaid_with_volume)
            }
        })
    }, [])
    const getLines = (data) => {
        if (!data || data.length === 0) {
            return []; // 没有数据时返回空数组，防止出错
        }
        const Names = Object.keys(data[0]).filter(key => key !== 'date');
        return Names.map((name, index) => (
            <Line
                key={index}
                type="monotone"
                dataKey={name}
                stroke={`hsl(${Math.random() * 360}, 70%, 50%)`} // Generates a random color
                activeDot={{ r: 8 }}
            />
        ));
    };

    const formatCurrency = (value) => {
        return `$${value}`;
    };

    const formatXAxis = (tickItem) => {
        return moment(tickItem).format('MM/DD');
    };

    const colors = (data) => {
        return Array.from({ length: data.length }, (_, index) => EIGHT_COLORS[index % EIGHT_COLORS.length]);
    }

    const axisTooLongTickFormatter = (value) => {
        // if value is more than 5 chinese characters, then split it into two lines
        if (value.length > 4) {
            return value.substring(0, 4) + '...';
        }
        return value;
    }

    return (
        <div style={{ width: '100%', padding: '20px' }}>
            <Row gutter={[16, 16]} style={{ height: '500px' }}>
                <Col span={10} style={{ height: '100%' }}>
                    <Row gutter={[0, 16]} style={{ height: '100%' }}>
                        <Col span={24} style={{ height: 'calc(50% - 8px)' }}>
                            <Card 
                                styles={
                                    {
                                        body: {
                                            padding: '72px',
                                            height: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                        },
                                    }
                                }>
                                <Title level={1} style={{ color: '#FF4081', margin: 0 }}>超期总额</Title>
                                <Title level={2} style={{ color: '#FF4081', margin: '20px 0 0 0' }}>{formatCurrency(addThousandsSeparator(overdue_amount))}</Title>
                            </Card>
                        </Col>
                        <Col span={24} style={{ height: 'calc(50% - 8px)' }}>
                            <Card 
                                styles={
                                    {
                                        body: {
                                            padding: '72px',
                                            height: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                        }
                                    }
                                }>
                                <Title level={1} style={{ color: '#FFC107', margin: 0 }}>应收总额</Title>
                                <Title level={2} style={{ color: '#FFC107', margin: '20px 0 0 0' }}>{formatCurrency(addThousandsSeparator(receivable_amount))}</Title>
                            </Card>
                        </Col>
                    </Row>
                </Col>
                <Col span={14}>
                    <Card
                        title="超期 By Overdue Range"
                        styles={
                            {
                                body: {
                                    height: 'calc(100% - 57px)',
                                    display: 'flex',
                                    flexDirection: 'column',
                                },
                                height: '100%',
                            }
                        }
                    >
                        <ResponsiveContainer width="100%" height={400}>
                            <PieChart>
                                <Legend
                                    layout="horizontal"
                                    align="center"
                                    verticalAlign="top"
                                    wrapperStyle={{ paddingBottom: '20px' }}
                                />
                                <Pie
                                    data={pieData}
                                    cx="50%"
                                    cy="55%"
                                    labelLine={false}
                                    outerRadius="80%"
                                    fill="#8884d8"
                                    dataKey="value"
                                    label={({ name, percent }) => `${name} ${(percent * 100).toFixed(2)}%`}
                                >
                                    {pieData.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={colors(pieData)[index]} />
                                    ))}
                                </Pie>
                                <Tooltip formatter={(value) => formatCurrency(addThousandsSeparator(value))} />
                            </PieChart>
                        </ResponsiveContainer>
                    </Card>
                </Col>
            </Row>
            <Row gutter={[16, 16]} style={{ marginTop: '20px' }}>
                <Col span={24}>
                    <Card title="应收超期变化趋势">
                        <ResponsiveContainer width="100%" height={400}>
                            <LineChart data={lineData}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis
                                    dataKey="date"
                                    tickFormatter={formatXAxis}
                                    interval={0}
                                    angle={-45}
                                    textAnchor="end"
                                    height={50}
                                />
                                <YAxis />
                                <Tooltip
                                    formatter={(value, name, props) => [formatCurrency(value), name]}
                                    labelFormatter={(label) => moment(label).format('YYYY-MM-DD')}
                                />
                                <Legend />
                                <Line type="monotone" dataKey="超期" stroke="#FF4081" activeDot={{ r: 8 }} />
                                <Line type="monotone" dataKey="总应收" stroke="#FFC107" />
                            </LineChart>
                        </ResponsiveContainer>
                    </Card>
                </Col>
            </Row>
            <Row gutter={[16, 16]} style={{ marginTop: '20px' }}>
                <Col span={24}>
                    <Card title="超期金额 By KA">
                        <ResponsiveContainer width="100%" height={300}>
                            <PieChart>
                                <Pie
                                    data={kaData}
                                    cx="50%"
                                    cy="50%"
                                    labelLine={false}
                                    label={({ name, percent }) => `${name} ${(percent * 100).toFixed(2)}%`}
                                    outerRadius={100}
                                    fill="#8884d8"
                                    dataKey="total_overdue_amount"
                                    nameKey="customer__contact__username"
                                >
                                    {kaData.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={colors(kaData)[index]} />
                                    ))}
                                </Pie>
                                <Tooltip formatter={(value) => formatCurrency(value)} />
                                <Legend />
                            </PieChart>
                        </ResponsiveContainer>
                    </Card>
                </Col>
            </Row>
            <Row gutter={[16, 16]} style={{ marginTop: '20px' }}>
                <Col span={10}>
                    <Card title="超期金额 By 业务">
                        <ResponsiveContainer width="100%" height={300}>
                            <PieChart>
                                <Pie
                                    data={business}
                                    cx="50%"
                                    cy="50%"
                                    labelLine={false}
                                    label={({ name, percent }) => `${name} ${(percent * 100).toFixed(2)}%`}
                                    outerRadius={70}
                                    fill="#8884d8"
                                    dataKey="total_overdue_amount"
                                    nameKey="customer__business_type"
                                >
                                    {business.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={colors(business)[index]} />
                                    ))}
                                </Pie>
                                <Tooltip formatter={(value) => formatCurrency(value)} />
                                <Legend />
                            </PieChart>
                        </ResponsiveContainer>
                    </Card>
                </Col>
                <Col span={14}>
                    <Card title="超期金额趋势 By 业务">
                        <ResponsiveContainer width="100%" height={300}>
                            <LineChart data={business_trend}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis
                                    dataKey="date"
                                    tickFormatter={formatXAxis}
                                    interval={0}
                                    angle={-45}
                                    textAnchor="end"
                                    height={50}
                                />
                                <YAxis />
                                <Tooltip
                                    formatter={(value, name, props) => [formatCurrency(value), name]}
                                    labelFormatter={(label) => moment(label).format('YYYY-MM-DD')}
                                />
                                <Legend />
                                {getLines(business_trend)}
                            </LineChart>
                        </ResponsiveContainer>
                    </Card>
                </Col>
            </Row>
            <Row gutter={[16, 16]} style={{ marginTop: '20px' }}>
                <Col span={24}>
                    <Card title="超期金额 By 前十大客户">
                        <ResponsiveContainer width="100%" height={300}>
                            <PieChart>
                                <Pie
                                    data={customer_sum}
                                    cx="50%"
                                    cy="50%"
                                    labelLine={true}
                                    label={({ name, percent }) => `${name} ${(percent * 100).toFixed(2)}%`}
                                    outerRadius={100}
                                    fill="#8884d8"
                                    dataKey="value"
                                >
                                    {customer_sum.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={colors(customer_sum)[index]} />
                                    ))}
                                </Pie>
                                <Tooltip formatter={(value) => formatCurrency(value)} />
                                <Legend />
                            </PieChart>
                        </ResponsiveContainer>
                    </Card>
                </Col>
                </Row>
            <Row gutter={[16, 16]} style={{ marginTop: '20px' }}>
                <Col span={24}>
                <Card title="前十超期大户货值超期应收状况($)">
                    <ResponsiveContainer width="100%" height={300}>
                        <BarChart data={topTenCustomerWithProductValue} margin={{
                            top: 5, right: 30, left: 40, bottom: 5,
                        }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" tickFormatter={axisTooLongTickFormatter} />
                            <YAxis />
                            <Tooltip 
                                formatter={(value, name) => {
                                    if (name === 'product_value') {
                                        return [`$${value}`, '货值(0.3倍真实货值 $)']
                                    } else if (name === 'overdue_amount') {
                                        return [`$${value}`, '超期($)']
                                    } else if (name === 'receivable_amount') {
                                        return [`$${value}`, '应收($)']
                                    }
                                }}
                                />
                            <Legend
                                formatter={(value) => {
                                    if (value === 'product_value') {
                                        return '货值(0.3倍真实货值 $)';
                                    } else if (value === 'overdue_amount') {
                                        return '超期($)';
                                    } else if (value === 'receivable_amount') {
                                        return '应收($)';
                                    }
                                }}
                             />
                            <Bar dataKey="product_value" fill="#8884d8" />
                            <Bar dataKey="overdue_amount" fill="#82ca9d" />
                            <Bar dataKey="receivable_amount" fill="#FFC107" />
                        </BarChart>
                    </ResponsiveContainer>
                </Card>
                </Col>
            </Row>
            <Row gutter={[16, 16]} style={{ marginTop: '20px' }}>
                <Col span={24}>
                    <Card title="前十超期大户库存体积(CBM)">
                        <ResponsiveContainer width="100%" height={400}>
                            <BarChart data={topTenCustomerWithVolume} layout='vertical' margin={{
                                top: 5, right: 30, left: 30, bottom: 5
                            }}>
                                {/* Y axis value should be in two lines */}
                                <YAxis dataKey='name' type='category' tickFormatter={axisTooLongTickFormatter} />
                                <XAxis type='number' />
                                <Tooltip 
                                    formatter={(value, name) => {
                                        if (name === 'total_volume') {
                                            return [`${value} CBM`, '库存体积']
                                        }
                                    }}/>
                                <Legend 
                                    formatter={(value) => {
                                        if (value === 'total_volume') {
                                            return "库存体积(CBM)"
                                        }
                                    }} 
                                    />
                                <Bar dataKey='total_volume' fill='#0a8bf0' />
                            </BarChart>
                        </ResponsiveContainer>
                    </Card>
                </Col>
            </Row>
            <Row gutter={[16, 16]} style={{ marginTop: '20px' }}>
                <Col span={24}>
                    <Card title="超期金额趋势 By 前十大客户">
                        <ResponsiveContainer width="100%" height={300}>
                            <LineChart data={customer_trend}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis
                                    dataKey="date"
                                    tickFormatter={formatXAxis}
                                    interval={0}
                                    angle={-45}
                                    textAnchor="end"
                                    height={50}
                                />
                                <YAxis />
                                <Tooltip
                                    formatter={(value, name, props) => [formatCurrency(value), name]}
                                    labelFormatter={(label) => moment(label).format('YYYY-MM-DD')}
                                />
                                <Legend />
                                {getLines(customer_trend)}
                            </LineChart>
                        </ResponsiveContainer>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default ReceivablesRiskDashboardPage;
