// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.middle-col {
    display: flex;
    align-items: center;  /* 垂直居中 */
    justify-content: center;  /* 水平居中 */
}

.middle-row {
    width: 100%;  /* 确保表单项占据整个列的宽度 */
}`, "",{"version":3,"sources":["webpack://./src/components/RuleExpressions/AddNewRuleExpressionModal.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB,GAAG,SAAS;IAC/B,uBAAuB,GAAG,SAAS;AACvC;;AAEA;IACI,WAAW,GAAG,kBAAkB;AACpC","sourcesContent":[".middle-col {\n    display: flex;\n    align-items: center;  /* 垂直居中 */\n    justify-content: center;  /* 水平居中 */\n}\n\n.middle-row {\n    width: 100%;  /* 确保表单项占据整个列的宽度 */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
