import React, {useEffect} from 'react';
import { Modal, Form, Input, App, Select } from 'antd';
import {
    useEditingAccountReceivableMutation,
} from '../../features/api/apiSlice';
const { Option } = Select;
const remitteeOptions = [
    { label: 'Awesung Limited (新火傲盛科技有限公司)', value: '0' },
    { label: '广州奥越国际物流有限公司-海运', value: '1' },
    { label: '广州辕蓬物流有限公司', value: '2' },
    { label: '新Awesung Tech', value: '3' },
    { label: 'Bloomsung', value: '4' },
    { label: '深圳新火傲盛科技有限公司-深圳易仓', value: '5' },
    { label: '老Awesung Inc', value: '6' },
    { label: 'Awetrans Inc', value: '9' },
    { label: 'Awetrans Limited', value: '10' },
];
const labelToValueMap = remitteeOptions.reduce((map, option) => {
    map[option.label] = option.value;
    return map;
}, {});
function DisplayReceivableEditingModal ({data, open, setOpen}) {
    const [form] = Form.useForm();
    const [EditingAccountReceivable, {
        isLoading,
        isSuccess,
        isError,
        error,
    }] = useEditingAccountReceivableMutation();
    const { notification } = App.useApp();
    useEffect(() => {
        if (isSuccess) {
            notification.success({
                message: 'Success',
                description: `编辑账单成功!`,
            })
            setOpen(false);
        }
        if (isError) {
            notification.error({
                message: 'Error',
                description: error?.data?.error ?? '异常报错,编辑账单失败!',
            })
        }
    }, [isSuccess, isError, error])


    const handleOk = () => {
        form.validateFields().then(values => {
            const request = {
                ...values,
                invoice_id: data.id,
            }
            EditingAccountReceivable(request);
        }).catch(error => {
            console.log('Validate Failed:', error);
        });
    };
    const positiveNumberOnly = (rule, value, callback) => {
        return new Promise((resolve, reject) => {
            if (value && !/^-?[0-9]+(.[0-9]+)?$/.test(value)) {
                reject('请输入可识别的数字!');
            } else if (value && value.length > 20) {
                reject('最多20位!');
            } else {
                resolve();
            }
        });
    }
    useEffect(() => {

        if (open) {
            form.setFieldsValue({
                invoice_amount: data.invoice_amount,
                invoice_number: data.invoice_number,
                remittee: labelToValueMap[data.remittee],
            });
        }
    }, [data, open, form]);

    return (
        <Modal
            title="编辑"
            open={open}
            onCancel={() => setOpen(false)}
            onOk={handleOk}
            centered
            confirmLoading={isLoading}
        >
            <Form
                form={form}
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                style={{
                    marginTop: '20px',
                    marginLeft: '15%',
                    marginRight: '20%',
                    maxWidth: '60%'
                }}
            >
                <Form.Item label='账单名称' name='invoice_number' rules={[{ required: true, message: '请输入账单名称!' }]}>
                    <Input />
                </Form.Item>
                <Form.Item label='账单金额' name='invoice_amount' rules={[{ required: true, message: '请输入账单金额!' }, { validator: positiveNumberOnly }]}>
                    <Input />
                </Form.Item>
                <Form.Item label='出账主体' name='remittee' rules={[{ required: true, message: '请选择出账主体!' }]}>
                    <Select
                        showSearch
                        placeholder="请选择"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {remitteeOptions.map(option => (
                            <Option key={option.value} value={option.value}>{option.label}</Option>
                        ))}
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default DisplayReceivableEditingModal;