import React, { useState, useEffect } from 'react';
import {useGetBillingDetailsQuery, useUpdateUidWithoutStatusChangeMutation} from '../../features/api/apiSlice';
import {Space, Table, Button, notification, Modal} from 'antd';
import {BillingDetailsPageColumns} from './BillingDetailsPageColumns';
import BillingDetailsSearchBar from './BillingDetailsSearchBar';
import { 
    useGetAllCustomerUidQuery,
    useGetLogisticsServiceByNameQuery,
    useGetBillingInvoiceNamesQuery,
    useGetAllCustomerUserGroupQuery,
} from '../../features/api/apiSlice';
import './BillingDetailsPage.css';
import {
    ProTable,
  } from '@ant-design/pro-components';
import UidEditModal from "./UidEditModal";

function BillingDetailsPage () {
    const [searchData, setSearchData] = useState({});
    const [pageSize, setPageSize] = useState(10);
    const [sorter, setSorter] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const { data, error, isLoading, isFetching, refetch } = useGetBillingDetailsQuery({...searchData, pageSize: pageSize, currentPage: currentPage, sortField: sorter.field,
        sortOrder: sorter.order,}, { refetchOnMountOrArgChange: true });
    const [tableData, setTableData] = useState([]);
    const { data: logisticsService } = useGetLogisticsServiceByNameQuery();
    const { data: customerData, refetch: refetchUid } = useGetAllCustomerUidQuery();
    const { data: customerUserGroupData, refetch: refetchUserGroup } = useGetAllCustomerUserGroupQuery();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [columnsState, setColumnsState] = useState({});
    const { data: billingInvoiceNames } = useGetBillingInvoiceNamesQuery();
    const [uidEditModalVisible, setUidEditModalVisible] = useState(false);
    const [openSurcharge, setOpenSurcharge] = useState(false);
    const [openCost, setOpenCost] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [costContent, setCostContent] = useState(null);
    const [updateUid, {
        isSuccess: isUpdateUidSuccess,
        isError: isUpdateUidError,
        error: updateUidError,
        isLoading: isUpdateUidLoading,
        isFetching: isUpdateUidFetching
    }] = useUpdateUidWithoutStatusChangeMutation();
    
    const formattedTreeSelectData = customerUserGroupData?.map(group => ({
        title: group.name,
        value: String(group.name),
        key: String(group.name),
        children: group.customers.map(customer => ({
            title: customer.uid,
            value: customer.uid,
            key: customer.uid
        }))
    }));

    useEffect(() => {
        refetch();
        refetchUid();
    }, [])
    useEffect(() => {
        setSelectedRowKeys([]);
    }, [searchData])
    useEffect(() => {
        if (!searchData.isDownload && data) {
            setTableData(data);
        }
    }, [data, searchData.isDownload]);
    useEffect(() => {
        if (isUpdateUidSuccess) {
            notification.success({
                message: "Success",
                description: "修改UID成功!",
            });
            setUidEditModalVisible(false);
            setSelectedRowKeys([]);
        }
        if (isUpdateUidError) {
            notification.error({
                message: "Error",
                description: updateUidError?.data?.error || "修改失败",
            });
        }
    }, [isUpdateUidSuccess, isUpdateUidError])
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
            setSelectedRows(selectedRows);
        },
        selectedRowKeys,
      };
    const getSelectedRowsData = () => {
        return tableData.results.filter(row => selectedRowKeys.includes(row.id));
    };
    const uidEditButtonHandler = () => {
        if (selectedRowKeys.length === 0) {
            return notification.error({
                message: "Error",
                description: "请选择至少一条记录",
            });
        }
        const allRowsValid = selectedRows.every(row => row.status === "待计算");

        if (!allRowsValid) {
            return notification.error({
                message: "Error",
                description: "所有选中的记录状态必须为[待计算]!",
            });
        }
        setUidEditModalVisible(true);
    }
    const handleTableChange = (pagination, filters, sorter) => {
        setSorter(sorter);
      };
    const uidEditModalSubmitHandler = (values) => {
        const newUid = values?.uid;
        const formData = new FormData();
        formData.append('uid', newUid);
        formData.append('ids', selectedRowKeys);
        updateUid(formData);
    }
    const columns = BillingDetailsPageColumns(setOpenSurcharge, setModalContent, setOpenCost, setCostContent)
    const surcharge_columns = [{
        title: '费用类型',
        dataIndex: 'type',
        key: 'type'
    }, {
        title: '金额',
        dataIndex: 'amount',
        key: 'amount'
    }];
    const renderModalTable = () => {
        return <Table columns={surcharge_columns} dataSource={modalContent} pagination={false} />;
    };
    const renderCostTable = () => {
        return <Table
            columns={surcharge_columns}
            dataSource={costContent}
            pagination={false}
            rowClassName={rowClassName}  // 应用自定义的行类名函数
        />;
    };
    const rowClassName = (record, index) => {
        // 检查当前行是否为最后一行
        return index === costContent.length - 1 ? 'last-row' : '';
    };
    return (
        <div>
            <Space
                direction="vertical"
                style={{ display: 'flex'}}>
                <BillingDetailsSearchBar
                    setSearchData={setSearchData}
                    customerData={customerData}
                    logisticsService={logisticsService}
                    setCurrentPage={setCurrentPage}
                    setPageSize={setPageSize}
                    selectedRows={tableData && tableData.results ? getSelectedRowsData() : []}
                    selectedRowKeys={selectedRowKeys}
                    setSelectedRowKeys={setSelectedRowKeys}
                    billingInvoiceNames={billingInvoiceNames}
                    refetch={refetch}
                    treeSelectData={formattedTreeSelectData}
                />
                <ProTable
                    search={false}
                    rowKey={record => record.id}
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={tableData?.results}
                    loading={isLoading || isFetching}
                    columnsState={columnsState}
                    onChange={handleTableChange}
                    pagination={{
                        showSizeChanger: true,
                        pageSize: pageSize,
                        current: currentPage,
                        total: tableData?.count,
                        onChange: (page, pageSize) => {
                            setCurrentPage(page);
                            setPageSize(pageSize);
                            setSelectedRowKeys([]);
                        },
                        onShowSizeChange: (current, size) => {
                            setCurrentPage(current);
                            setPageSize(size);
                            setSelectedRowKeys([]);
                        },
                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                    }}
                    scroll={{ x: 'max-content' }}
                    options={{
                        reload: () => refetch(),
                    }}
                    toolbar={{
                        title: ' ',
                        search: false,
                        actions: [
                            <Button key="uidEdit" type="primary" onClick={uidEditButtonHandler}>
                                修改UID
                            </Button>,
                        ],
                    }}
                />
            </Space>
            <UidEditModal
                open={uidEditModalVisible}
                setOpen={setUidEditModalVisible}
                onSubmit={uidEditModalSubmitHandler}
                loading={isUpdateUidFetching || isUpdateUidLoading}
                success={isUpdateUidSuccess}
            />
            <Modal
                title="附加费详情"
                open={openSurcharge}
                onOk={() => setOpenSurcharge(false)}
                onCancel={() => setOpenSurcharge(false)}
                width={800}
            >

                {renderModalTable()}

            </Modal>
            <Modal
                title="费用详情"
                open={openCost}
                onOk={() => setOpenCost(false)}
                onCancel={() => setOpenCost(false)}
                width={800}
            >
                {renderCostTable()}
            </Modal>
        </div>
    )
}

export default BillingDetailsPage;