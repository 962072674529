import { Form, Input, Modal, Row, Col, Select, notification } from 'antd'
import React, { useEffect } from 'react'
import {
    useCreateLocationMutation,
} from "../../features/api/apiSlice"
const AddLocationModal = ({ open, setAddOpen, customerGroup }) => {

    const [createLocation, { isError, isLoading, error, isSuccess }] = useCreateLocationMutation();
    const [form] = Form.useForm();

    useEffect(() => {
        if (isSuccess) {
            notification.success({
                message: 'Success',
                description: 'Successfully created location',
            });
            setAddOpen(false);
            form.resetFields();
        }
        if (isError) {
            if (error.data && error.data.non_field_errors)
                notification.error({
                    message: "Error",
                    description: error.data.non_field_errors,
                });
            if (error.data && error.data.name)
                notification.error({
                    message: "Error",
                    description: error.data.name,
                });
            if (error.data && error.data.error)
                notification.error({
                    message: "Error",
                    description: error.data.error,
                });
        }
    }, [isSuccess, isError, error, setAddOpen]);
    const addLocation = () => {
        form.validateFields().then(values => {
            const data = {
                name: values.name,
                customer_groups: values.customerGroups,
            }
            createLocation(data);
        }).catch((info) => {
            console.log("Validate Failed:", info);
        });
    }

    return (
        <Modal
            title="新建地区"
            open={open}
            width={700}  // 增加宽度以适应两个输入框
            onCancel={() => setAddOpen(false)}
            onOk={addLocation}
            confirmLoading={isLoading}
        >
            <Form layout="vertical" form={form}>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="地区"
                            rules={[{ required: true, message: '请输入地区' }]}
                            name="name"
                        >
                            <Input
                                placeholder='请输入'
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="客户组"
                            name="customerGroups"
                            rules={[{ required: true, message: "请选择客户组（多选）" }]}
                        >
                            <Select
                                placeholder='请选择'
                                allowClear
                                mode="multiple"
                            >
                                {customerGroup && customerGroup.map(item => (
                                    <Select.Option key={item.id} value={item.id}>
                                        {item.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}
export default AddLocationModal