import React, { useState, useRef, useEffect } from 'react';
import { Modal, Form, Input, Select, notification } from 'antd';
import { useCreateClientMutation } from '../../features/api/apiSlice';
function ClientCreateModal ({ open, setOpen, rateTemplates, billingUserData, kaUserData }) {
    const [customerServiceHidden, setCustomerServiceHidden] = useState(true);
    const [form] = Form.useForm();
    const uidInputRef = useRef();
    const [createClient, { isError, isLoading, error, isSuccess}] = useCreateClientMutation();
    const handleCancel = () => {
        setOpen(false);
    }
    useEffect(() => {
        if (isError) {
            if (error.data && 'uid' in error.data) {
                notification.error({
                    message: "Error",
                    description: "客户ID已存在",
                });
            } else {
                notification.error({
                    message: "Error",
                    description: "Unknown error",
                });
            }
            uidInputRef.current.select();
        }
    }, [isError])
    useEffect(() => {
        if (isSuccess) {
            notification.success({
                message: "Success",
                description: "客户创建成功",
            });
            setOpen(false);
            form.resetFields();
        }
    }, [isSuccess])
    const handleOk = () => {
        form.validateFields().then(values => {
            const data = {
                uid: values.uid,
                username: values.username,
                user: values.userAssignedTo,
                contact: values.contact,
                rate_template: values.rateTemplate,
                business_type: values.businessType,
                billing_cycle: values.billingCycle,
                credit_line: values.creditLine,
                comments1: values.comments1,
                user_group: values.userGroup,
            }
            createClient(data);
        }).catch((info) => {
            console.log("Validate Failed:", info);
        });
    }
    const NumberOnly = (rule, value, callback) => {
        return new Promise((resolve, reject) => {
            if (value && !/^[a-zA-Z0-9]+$/.test(value)) {
              reject('Please input combination of numbers and characters only!');
            } else {
              resolve();
            }
          });
    }
    const PositiveNumberOnly = (rule, value, callback) => {
        return new Promise((resolve, reject) => {
            if (value && !/^[0-9]+(.[0-9]{1,2})?$/.test(value)) {
              reject('请输入可识别的正数, 最多两位小数!');
            } else if (value && value.length > 20) {
                reject('最多20位!');
            } else {
                resolve();
            }
        });
    }
    const handleFormValuesChange = (changedValues, allValues) => {
        if (changedValues.hasOwnProperty('comments1')) {
            if (changedValues.comments1 === 'other') {
                setCustomerServiceHidden(false);
                form.setFieldsValue({ comments1Other: undefined });
            } else {
                setCustomerServiceHidden(true);
                form.setFieldsValue({ comments1Other: undefined });
            }
        }
      };
    return (
        <Modal title="添加新客户" open={open} onOk={handleOk} onCancel={handleCancel} confirmLoading={isLoading}>
            <Form
                form={form}
                onValuesChange={handleFormValuesChange}
                labelCol={{
                    span: 5,
                }}
                wrapperCol={{
                    span: 19,
                }}
                style={{ marginTop: '20px', maxWidth: '100%' }}>
                <Form.Item
                    name="uid"
                    label="UID"
                    rules={[
                        {
                            required: true,
                            message: '请填写客户ID!'
                        }, {
                            validator: NumberOnly,
                            message: '仅支持数字格式'
                        }
                    ]}
                    validateTrigger="onSubmit">
                    <Input ref={uidInputRef}/>
                </Form.Item>
                <Form.Item
                    name="rateTemplate"
                    label="客户分类">
                    <Select allowClear>
                        {rateTemplates && rateTemplates.map((rateTemplate) => (
                            <Select.Option key={rateTemplate.id} value={rateTemplate.id}>
                                {rateTemplate.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item name="username" label="客户名" rules={[{ required: true, message: '请填写客户名!' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="contact" label="客户对接人" rules={[{ required: true, message: '请填写客户对接人!' }]}>
                    <Select allowClear>
                        {kaUserData && kaUserData.map(user => (
                            <Select.Option key={user.id} value={user.id}>
                                {user.username}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item name="userAssignedTo" label="账单负责人" rules={[{ required: true, message: '请选择账单负责人!' }]}>
                    <Select>
                        {billingUserData && billingUserData.map(user => (
                            <Select.Option key={user.id} value={user.id}>
                                {user.username}
                            </Select.Option>
                        ))}
                        </Select>
                </Form.Item>
                <Form.Item name="businessType" label="业务类型" rules={[{ required: true, message: '请选择业务类型!'}]}>
                    <Select allowClear>
                        <Select.Option value="渠道">渠道</Select.Option>
                        <Select.Option value="转运">转运</Select.Option>
                        <Select.Option value="代发">代发</Select.Option>
                        <Select.Option value="提柜">提柜</Select.Option>
                        <Select.Option value="海运">海运</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item name="userGroup" label="客户分组" rules={[{ required: true, message: '请选择客户分组!'}]}>
                    <Select>
                        <Select.Option value="1">Awesung</Select.Option>
                        <Select.Option value="2">Uparcel</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item name="billingCycle" label="账期" rules={[{ required: true, message: '请选择账期!'}]}>
                    <Select>
                        <Select.Option value="0">0 天</Select.Option>
                        <Select.Option value="7">7 天</Select.Option>
                        <Select.Option value="15">15 天</Select.Option>
                        <Select.Option value="25">25 天</Select.Option>
                        <Select.Option value="30">30 天</Select.Option>
                        <Select.Option value="60">60 天</Select.Option>
                        <Select.Option value="90">90 天</Select.Option>
                        <Select.Option value="预充">预充</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item name="creditLine" label="客户授信额度" rules={[{ validator: PositiveNumberOnly}]}>
                    <Input />
                </Form.Item>
                <Form.Item name="comments1" label="备注">
                    <Input.TextArea />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default ClientCreateModal;