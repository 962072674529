import React, { useEffect } from 'react';
import { Modal, Form, Input, notification } from 'antd';
import {
    useExportUidFilesMutation,
} from '../../features/api/apiSlice';
function ExportUIDExcelModal({ open, setOpen, modalData }) {
    const [form] = Form.useForm();

    const [exportUidFiles, {
        isSuccess: isExportUidFilesSuccess,
        isError: isExportUidFilesError,
        error: exportUidFilesError,
        isLoading: isExportUidFilesLoading,
        isFetching: isExportUidFilesFetching
    }] = useExportUidFilesMutation();

    useEffect(() => {
        if (isExportUidFilesSuccess) {
            notification.success({
                message: "Success",
                description: '导出成功,请前往下载管理页面获取文件',
            });
            setOpen(false);
            form.resetFields();
        }
        if (isExportUidFilesError) {
            notification.error({
                message: "Error",
                description: exportUidFilesError?.data?.error || "导出失败",
            });
        }
    }, [isExportUidFilesSuccess, isExportUidFilesError])
    const handleDownloadSubmit = () => {
        form.validateFields().then(values => {
            const formData = new FormData();
            formData.append('searchData', JSON.stringify(modalData));
            formData.append('filename', values.filename);
            exportUidFiles(formData);
        })
    }
    return (
        <Modal
            title="下载"
            open={open}
            onCancel={() => setOpen(false)}
            onOk={handleDownloadSubmit}
            centered
            confirmLoading={isExportUidFilesLoading || isExportUidFilesFetching}
        >
            <Form
                form={form}
                labelCol={{
                    span: 10,
                }}
                wrapperCol={{
                    span: 14,
                }}
                style={{
                    marginTop: '20px',
                    marginLeft: '0%',
                    marginRight: '10%',
                    maxWidth: '90%'
                }}
            >
                <Form.Item
                    label="请输入自定义文件名"
                    name="filename"
                    rules={[{ required: true, message: '请输入自定义文件名!' }]}>
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default ExportUIDExcelModal;
