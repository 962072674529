import React, {useEffect, useRef, useState} from 'react';
import {Button, Col, Modal, notification, Row, Tabs} from 'antd';
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './QuoteSheet.css'
import {getTemplateForService} from "./QuoteSheetTemplate";
import {useVerifyQuoteSheetMutation} from "../../features/api/apiSlice";
import {AdditionRateData, BaseRateData} from "./util";


const {TabPane} = Tabs;

const RateTable = ({title, rowData, columnDefs, onGridReady, onCellValueChanged, serviceName}) => (
    <div className="ag-theme-alpine" style={{height: 650, flex: 1, margin: '0 10px'}}>
        <h3>{title}</h3>
        <AgGridReact
            defaultColDef={{
                sortable: false
            }}
            rowData={rowData}
            columnDefs={columnDefs}
            onGridReady={onGridReady}
            onCellValueChanged={params => {
                onCellValueChanged(params, serviceName);
            }}
            getRowId={params => params.data.id}
        />
    </div>
);

function DisplayQuoteSheetModal({open, setOpen, data, info, resetUploadForm, resetFileList}) {
    const [verifyQuoteSheet, {
        data: getQuoteSheetData,
        error: getQuoteSheetError,
        isSuccess,
        isError,
        isLoading
    }] = useVerifyQuoteSheetMutation();
    const BaseRateGridApiRef = useRef(null);
    const AdditionRateGridApiRef = useRef(null);
    const [processedData, setProcessedData] = useState([]);
    const [errorMessages, setErrorMessages] = useState({});
    const [currentTabErrors, setCurrentTabErrors] = useState([]);
    const [activeTab, setActiveTab] = useState('');

    useEffect(() => {
        if (isSuccess) {
            notification.success(getQuoteSheetData);
            resetUploadForm();
            resetFileList();
            setOpen(false);
        }
        if (isError) {
            if (typeof getQuoteSheetError.data?.error === 'object') {
                setErrorMessages(getQuoteSheetError.data?.error || {});
            } else {
                notification.error({
                    message: 'Failed',
                    description: getQuoteSheetError.data?.error || "Unknown Error"
                });
            }
        }
    }, [isSuccess, isError]);

    useEffect(() => {
        setCurrentTabErrors(errorMessages[activeTab] || []);
    }, [activeTab, errorMessages]);
    // DJX: 这部分是影响了哪里? 我暂时注释掉是因为每次更改数据会回到第一个tab好像不太对
    // useEffect(() => {
    //     console.log(processedData[0]);
    //     setActiveTab(processedData[0]?.serviceName);
    // }, [processedData])

    useEffect(() => {
        if (data.data) {
            const newData = Object.entries(data.data).map(([serviceName, serviceData]) => ({
                serviceName,
                baseRateTableData: BaseRateData(serviceData.base_rates, serviceName),
                additionRateTableData: AdditionRateData(serviceData.additional_rates, serviceName),
                startDate: serviceData.start_date,
                endDate: serviceData.end_date,
            }));
            setProcessedData(newData);
        }
    }, [data]);
    const formattedProcessedData = processedData.reduce((acc, service) => {
        acc[service.serviceName] = {
            baseRateTableData: service.baseRateTableData,
            additionRateTableData: service.additionRateTableData,
            startDate: service.startDate,
            endDate: service.endDate,
        };
        return acc;
    }, {});
    const handleOk = () => {
        const formData = new FormData();
        formData.append('quote_sheet_data', JSON.stringify(formattedProcessedData));
        formData.append('rate_template', info.rate_template);
        formData.append('logistics_supplier', info.logistics_supplier);
        formData.append('start_date', info.start_date);
        formData.append('end_date', info.end_date);
        formData.append('note', info.note);
        formData.append('rateExcel', info.rateExcel);
        verifyQuoteSheet(formData);
    };

    const handleBaseRateCellValueChange = (event, serviceName) => {
        const {rowIndex, colDef, newValue} = event;
        const serviceIndex = processedData.findIndex(service => service.serviceName === serviceName);
        const updatedRowData = {...processedData[serviceIndex].baseRateTableData[rowIndex], [colDef.field]: newValue};
        updateBaseRateData(serviceIndex, updatedRowData, rowIndex);
    };

    const handleAdditionRateCellValueChange = (event, serviceName) => {
        const {rowIndex, colDef, newValue} = event;
        const serviceIndex = processedData.findIndex(service => service.serviceName === serviceName);
        const updatedRowData = {
            ...processedData[serviceIndex].additionRateTableData[rowIndex],
            [colDef.field]: newValue
        };
        updateAdditionRateData(serviceIndex, updatedRowData, rowIndex);
    };

    const updateBaseRateData = (serviceIndex, newData, rowIndex) => {
        const updatedData = [...processedData];
        updatedData[serviceIndex].baseRateTableData = [...updatedData[serviceIndex].baseRateTableData];
        updatedData[serviceIndex].baseRateTableData[rowIndex] = newData;
        setProcessedData(updatedData);
        BaseRateGridApiRef.current.applyTransaction({update: [newData]});
    };

    const updateAdditionRateData = (serviceIndex, newData, rowIndex) => {
        const updatedData = [...processedData];
        updatedData[serviceIndex].additionRateTableData = [...updatedData[serviceIndex].additionRateTableData];
        updatedData[serviceIndex].additionRateTableData[rowIndex] = newData;
        setProcessedData(updatedData);
        AdditionRateGridApiRef.current.applyTransaction({update: [newData]});
    };

    return (
        <Modal
            open={open}
            onCancel={() => setOpen(false)}
            onOk={handleOk}
            width={'100%'}
            style={{top: 20}}
            footer={[
                <Button key="back" onClick={() => setOpen(false)}>
                    取消
                </Button>,
                <Button key="submit" type="primary" loading={isLoading} onClick={handleOk}>
                    确认数字无误,上传报价表
                </Button>
            ]}
        >
            <Tabs onChange={key => setActiveTab(key)}>
                {processedData.map((service, index) => (
                    <TabPane tab={service.serviceName} key={service.serviceName}>
                        <Row>
                            <Col span={12}>
                                <p>报价有效日期: {service.startDate} - {service.endDate}</p>
                            </Col>
                            <Col span={12}>
                                <div style={{ maxHeight: '5em', overflow: 'auto', color: "red" }}>
                                    {currentTabErrors.map((error, i) => <p key={i}>{error}</p>)}
                                </div>
                            </Col>
                        </Row>
                        <div className="rate-table-container" style={{ display: 'flex', height: '100%' }}>
                            <div style={{ flex: '0 0 40%', width: '100%' }}>
                                <RateTable
                                    title="基本费率"
                                    rowData={service.baseRateTableData}
                                    columnDefs={getTemplateForService(service.serviceName).base_rate}
                                    onGridReady={params => BaseRateGridApiRef.current = params.api}
                                    onCellValueChanged={handleBaseRateCellValueChange}
                                    serviceName={service.serviceName}
                                />
                            </div>
                            <div style={{ flex: '0 0 60%', width: '100%' }}>
                                <RateTable
                                    title="附加费率"
                                    rowData={service.additionRateTableData}
                                    columnDefs={getTemplateForService(service.serviceName).addition_rate}
                                    onGridReady={params => AdditionRateGridApiRef.current = params.api}
                                    onCellValueChanged={handleAdditionRateCellValueChange}
                                    serviceName={service.serviceName}
                                />
                            </div>
                        </div>
                    </TabPane>
                ))}
            </Tabs>
        </Modal>
    );
}

export default DisplayQuoteSheetModal;
