import { Button, Space, notification, DatePicker, Select } from 'antd';
import React, { useState, useEffect } from "react";
import AddDepartmentModal from './AddDepartmentModal'
import ProTable from '@ant-design/pro-table';
import {
    useGetDepartmentsQuery,
    useGetLocationByNameQuery,
} from "../../features/api/apiSlice";
import { renderDateTime } from '../utils';
import EditDepartmentModal from './EditDepartmentModal';
import axios from 'axios';
const { RangePicker } = DatePicker;

function DepartmentManagementPage() {
    const [addOpen, setAddOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [searchData, setSearchData] = useState({});
    const { data, error, isError, isLoading, isFetching, refetch } = useGetDepartmentsQuery({ ...searchData });
    const { data: location_data, refetch: refetchLocation } = useGetLocationByNameQuery();
    const [ addModalData, setAddModalData ] = useState({});
    const [loadingRecordId, setLoadingRecordId] = useState(null);
    const [editButtonClickLoading, setEditButtonClickLoading] = useState(false);
    const [editData, setEditData] = useState({});
    const [addButtonLoading, setAddButtonLoading] = useState(false);

    useEffect(() => {
        refetch();
        refetchLocation();
    }, []);

    const editButtonHandler = (record) => {
        setLoadingRecordId(record.id);
        setEditButtonClickLoading(true);
        const formData = new FormData();
        formData.append('department_id', record.id);
        axios.post('/accounts/get-page-permission-under-department/', formData)
            .then((res) => {
                setEditButtonClickLoading(false);
                setEditData({ ...record, page_permissions: res.data });
                setEditOpen(true);
            }).catch((err) => {
                setEditButtonClickLoading(false);
                notification.error({
                    message: "Error",
                    description: err?.response?.data?.error || "Something went wrong",
                })
                console.log(err);
            })
    }

    const columns = [
        {
            title: '序号',
            dataIndex: 'id',
            key: 'id',
            search: false,
        },
        {
            title: '部门名称',
            dataIndex: "name",
            key: "name",
            search: false,
        },
        {
            title: '地区',
            dataIndex: 'location',
            key: 'location',
            hidden: true,
            hiddenInForm: true,
            search: true,
            renderFormItem: (item, { defaultRender, ...rest }, form) => {
                return <Select
                    placeholder="请选择"
                    allowClear 
                    style={{ textAlign: "left" }}
                >
                    {location_data && location_data.map((item) => {
                        return <Select.Option value={item.id}>{item.name}</Select.Option>
                    }
                    )}
                </Select>
            }
        },
        {
            title: "更新人",
            dataIndex: "updated_by",
            key: "updated_by",
            search: false,

        },
        {
            title: "更新时间",
            dataIndex: "updated_at",
            key: "updated_at",
            search: false,
            render: renderDateTime,
            renderFormItem: (item, { defaultRender, ...rest }, form) => {
                return <RangePicker style={{ width: '100%' }} />
            }
        },
        {
            title: "操作",
            search: false,
            render: (text, record) => (
                <Space size="middle">
                    <Button type="link" loading={editButtonClickLoading && loadingRecordId === record.id} onClick={() => {editButtonHandler(record)}}>编辑</Button>
                </Space>
            )
        }
    ]
    const createButtonHandler = () => {
        setAddButtonLoading(true);
        axios.get('/accounts/get-all-department-page-permission/')
            .then((res) => {
                setAddModalData(res.data);
                setAddOpen(true);
                setAddButtonLoading(false);
            }).catch((err) => {
                setAddButtonLoading(false);
                notification.error({
                    message: "Error",
                    description: err?.response?.data?.error || "Something went wrong",
                })
                console.log(err);
            })
        
    }
    return (
        <div>
            <ProTable
                rowKey={(record) => record.id}
                columns={columns}
                dataSource={data?.results}
                direction="vertical"
                scroll={{ x: 'max-content' }}
                size="small"
                search={{
                    labelWidth: 'auto',
                    defaultCollapsed: false,
                }}
                request={(params) => {
                    setSearchData(params);
                }}
                onRequestError={(err) => {
                    notification.error({
                        message: 'Error',
                        description: err.data?.error || "数据获取失败",
                    })
                }}
                loading={{
                    spinning: isLoading || isFetching,
                    tip: "数据获取中, 请等待...",
                }}
                pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50', '100'],
                    total: data?.count,
                }}
                options={{
                    reload: () => refetch(),
                }}
                toolbar={{
                    title: ' ',
                    actions: [
                        <Button type="primary" loading={addButtonLoading} onClick={() => createButtonHandler()}>+ 新建部门</Button>,
                    ]
                }}
            />
            <AddDepartmentModal
                open={addOpen}
                setAddOpen={setAddOpen}
                location_data={location_data}
                data={addModalData}
            />
            <EditDepartmentModal
                open={editOpen}
                setOpen={setEditOpen}
                data={editData}
                />
        </div>
    );

}

export default DepartmentManagementPage;