import LoginPage from './components/login';
import HomePage from './components/HomePage';
import ClientPage from './components/client/ClientPage';
import BillPage from './components/BillPage';
import LogisticsPage from './components/logistics/LogisticsPage';
import PasswordResetPage from './components/passwordReset';
import QuoteSheetPage from "./components/quoteSheet/QuoteSheetPage";
import CreateNewBatchPage from './components/createNewUploadBatch/createNewBatchPage';
import BatchManagementPage from './components/batchManagement/BatchManagementPage';
import BillingDetailsPage from './components/billingDetails/BillingDetailsPage';
import ErrorHandlingPage from './components/errorHandling/ErrorHandlingPage';
import NonStandardServicePage from './components/nonStandardService/nonStandardServicePage';
import NonOriginalBillingDetailsPage from './components/NonOriginalBillingDetails/NonOriginalBillingDetailsPage';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { Provider } from "react-redux";
import ProtectedRoutes from "./ProtectedRoute";
import NavBar from "./NavBar";
import { persistor, store } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import { Layout, theme, notification, Spin } from 'antd';
import { UnorderedListOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { logoutUser } from './features/auth/authSlice';
import AsyncManagementPage from "./components/asyncManagement/asyncManagementPage";
import FuelSurchargeManagementPage from "./components/FuelSurchargeManagement/fuelSurchargeManagementPage";
import BillingBatchManagementPage from "./components/BillingBatchManagement/BillingBatchManagementPage";
import AWSFileManagementPage from "./components/AWSFileManagement/AWSFileManagementPage";
import GroundCalculationPage from "./components/GroundCalculationPage/GroundCalculationPage";
import HAndSpCalculationPage from './components/HAndSpCalculationPage/HAndSpCalculationPage';
import RuleExpressionsPage from './components/RuleExpressions/RuleExpressionsPage';
const { Header, Content, Footer } = Layout;
const pathToElementName = {
  'home': '首页',
  'logistics': '物流管理',
  'clients': '客户管理',
  'bills': '导入批次管理',
  'bills/createNewBatch': '账单管理-新建导入批次（操作页面）',
  "bills/batchManagement": '账单管理-导入批次管理',
  "bills/billingDetails": '账单管理-账单明细管理',
  "bills/errorHandling": "账单管理-异常处理",
  'quoteSheet': '报价表管理',
  'asyncManagement': '后台任务管理',
  'AWSFileManagement': 'AWS文件管理',
  'fuelSurcharge': '燃油附加费管理',
  'bills/missingPkgDetailManagement': '账单管理-缺件明细管理',
  'bills/nonStandardService': '账单管理-非常用渠道管理',
  'bills/billingBatch': '账单管理-出账批次管理',
  'bills/nonOriginalBillingDetails': '账单管理-非Original账单明细管理',
  'bills/HAndSpCalculation': '账单管理-H&SP出账管理',
  'bills/groundCalculation': '账单管理-Ground出账管理'
};


function App() {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const location = useLocation();
  const elementName = location.pathname.replace(/^(?:[^\/]*\/){2}\s*/, '');
  const [loading, setLoading] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const currUser = store.getState().auth.user;
  const contentStyle = {
    transition: 'margin-left 0.2s ease', // 这里可以调整动画持续时间和效果
    marginLeft: collapsed ? 80 : 300, // 这些值应与您的导航栏宽度相匹配
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  };

  useEffect(() => {
    // add interceptor to axios
    const interceptor = axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status === 401) {
          store.dispatch(logoutUser());
          setLoading(true);
          notification.error({
            message: '登录过期',
            description: '请重新登录',
          })
          setTimeout(() => {
            window.location.href = '/login';
          }, 1000)
        }
        return Promise.reject(error);
      }
    );
  
    // remove interceptor when component unmounts
    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, [])
  return (
    <Spin spinning={loading} size="large" style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 9999 }}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Routes>
            <Route path="/login" element={<LoginPage />}></Route>
            <Route path="/reset-password" element={<PasswordResetPage />}></Route>
            <Route
              path="/index"
              element={(
                <Layout hasSider >
                  <NavBar collapsed={collapsed} onCollapse={() => setCollapsed(!collapsed)} />
                  <Layout style={contentStyle}>
                    <Header
                      style={{
                        padding: 0,
                        margin: '24px 16px 0',
                        overflow: 'initial',
                        background: colorBgContainer,
                      }}
                    >
                      &nbsp; &nbsp; <UnorderedListOutlined /> {pathToElementName[elementName]}
                    </Header>
                    <Content
                      style={{
                        margin: '24px 16px 0',
                        overflow: 'initial',
                      }}
                    >
                      <div
                        style={{
                          padding: 24,
                          textAlign: 'center',
                          background: colorBgContainer,
                        }}
                      >
                        <ProtectedRoutes />
                      </div>
                    </Content>
                    <Footer
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      Awein ©2023 Created by Awesung
                    </Footer>
                  </Layout>
                </Layout>
              )}
            >
              <Route path="home" element={<HomePage />} />
              <Route path="clients" element={<ClientPage currUser={currUser} />} />
              <Route path="bills" element={<BillPage />} />
              <Route path="bills/createNewBatch" element={<CreateNewBatchPage />} />
              <Route path="quoteSheet" element={<QuoteSheetPage />} />
              <Route path="logistics" element={<LogisticsPage />} />
              <Route path="bills/batchManagement" element={<BatchManagementPage />} />
              <Route path="bills/billingDetails" element={<BillingDetailsPage />} />
              <Route path="bills/errorHandling" element={<ErrorHandlingPage />} />
              <Route path="asyncManagement" element={<AsyncManagementPage />} />
              <Route path="AWSFileManagement" element={<AWSFileManagementPage />} />
              <Route path="fuelSurcharge" element={<FuelSurchargeManagementPage />} />
              <Route path="bills/groundCalculation" element={<GroundCalculationPage />} />
              <Route path="bills/nonStandardService" element={<NonStandardServicePage />} />
              <Route path="bills/billingBatch" element={<BillingBatchManagementPage />} />
              <Route path="bills/nonOriginalBillingDetails" element={<NonOriginalBillingDetailsPage />} />
              <Route path="bills/HAndSpCalculation" element={<HAndSpCalculationPage />} />
              <Route path="RuleExpressionManagement" element={<RuleExpressionsPage />} />
            </Route>
            <Route path="*" element={<Navigate to="/login" replace />} />
          </Routes>
        </PersistGate>
      </Provider>
    </Spin>
  );

}

export default App;
