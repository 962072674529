import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Select, Input, Row, Space, theme, DatePicker, notification } from 'antd';
import moment from 'moment-timezone';
import axios from 'axios';
import { useUpdateBillingStatusMutation } from '../../features/api/apiSlice';
import BillingConfirmModal from './BillingConfirmModal';
const { Option } = Select;
const { RangePicker } = DatePicker;
const NonStandardServiceSearchBar = ({ logisticsService, customerData, setSearchData, setCurrentPage, setPageSize, billingInvoiceNames, selectedRows, selectedRowKeys, setSelectedRowKeys }) => {
    const { token } = theme.useToken();
    const [form] = Form.useForm();
    const formStyle = {
        maxWidth: 'none',
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
    };
    const [downloading, setDownloading] = useState(false);
    const [billingConfirmContent, setBillingConfirmContent] = useState([]);
    const [billingConfirmSubmitData, setBillingConfirmSubmitData] = useState({});
    const [markBillingModalOpen, setMarkBillingModalOpen] = useState(false);
    const [updateBillingStatus, {
        isLoading: isUpdatingBillingStatus,
        error: updateBillingStatusError,
        isSuccess: isUpdateBillingStatusSuccess,
        isError: isUpdateBillingStatusError,
    }] = useUpdateBillingStatusMutation();

    useEffect(() => {
        if (isUpdateBillingStatusSuccess) {
            notification.success({
                message: 'Success',
                description: '出账状态更新成功',
            });
            setMarkBillingModalOpen(false);
            setSelectedRowKeys([]);
        }
        if (isUpdateBillingStatusError) {
            notification.error({
                message: 'Error',
                description: updateBillingStatusError?.data?.error ?? '出账状态更新失败',
            });
        }
    }, [isUpdateBillingStatusSuccess, isUpdateBillingStatusError]);

    const getFields = () => {
        const children = [];
        children.push(
            <Col span={8} key={8}>
                <Form.Item name="status" label="出账状态">
                    <Select
                        placeholder='请选择'
                        allowClear
                        style={{ textAlign: "left" }}
                        >
                        <Option value="2">未出账</Option>
                        <Option value="22">已出账</Option>
                    </Select>
                </Form.Item>
            </Col>,
            <Col span={8} key={1}>
                <Form.Item name="billToAccountNumber" label="Bill To Account Number">
                    <Input placeholder="请输入内容" />
                </Form.Item>
            </Col>,
            <Col span={8} key={2}>
                <Form.Item name="trackingNumber" label="Tracking Number">
                    <Input placeholder="请输入内容" />
                </Form.Item>
            </Col>,
            <Col span={8} key={3}>
                <Form.Item name="logisticsService" label="物流服务">
                    <Select placeholder='请选择' allowClear style={{ textAlign: "left" }} mode='multiple' showSearch={false}>
                        {logisticsService && logisticsService.map((item) => (
                            <Option key={item.id} value={item.id}>{item.name}</Option>
                        ))}
                        <Option key='other' value='other'>未匹配上物流服务</Option> 
                    </Select>
                </Form.Item>
            </Col>,
            <Col span={8} key={4}>
                <Form.Item name="invoiceNumber" label="Invoice Number">
                    <Input placeholder="请输入内容" />
                </Form.Item>
            </Col>,
            <Col span={8} key={5}>
                <Form.Item name="uid" label="UID">
                    <Select placeholder='请选择' allowClear style={{ textAlign: "left" }} mode='multiple'>
                        {customerData && customerData.map((item) => (
                            <Select.Option key={item.uid} value={item.uid}>{item.uid}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Col>,
            <Col span={8} key={6}>
                <Form.Item name="invoiceDate" label="Invoice Date">
                    <RangePicker style={{ width: '100%' }} format="YYYY-MM-DD" />
                </Form.Item>
            </Col>,
            <Col span={8} key={7}>
                <Form.Item name="billingInvoice" label="导入批次编号">
                    <Select
                        placeholder="请选择"
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        style={{ textAlign: "left", width: '100%' }}
                        filterOption={(input, option) =>
                            (option?.label ?? '')
                                .toLowerCase()
                                .includes(input.toLowerCase())
                        }
                        mode='multiple'
                    >
                        {billingInvoiceNames && billingInvoiceNames.map(billingInvoiceName => (
                            <Select.Option key={billingInvoiceName.id} value={billingInvoiceName.id} label={billingInvoiceName.name}>
                                {billingInvoiceName.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Col>

        );
        return children;
    };
    const get_invoice_name = (SD) => {
        let billingInvoices = [];
        SD.billingInvoice?.split(",").forEach(invoiceId => {
            for (let key in billingInvoiceNames) {
                if (billingInvoiceNames[key]['id'].toString() === invoiceId) {
                    billingInvoices.push(billingInvoiceNames[key]['name']);
                }
            }
        });
        return billingInvoices.join(', ');
    }
    const get_status = (SD) => {
        if (SD.status) {
            if (SD.status === '2') {
                return '未出账';
            } else if (SD.status === '22') {
                return '已出账';
            }
        }
    }
    const get_service = (SD) => {
        let billingService = [];
        SD.logisticsService?.split(",").forEach(serviceId => {
            for (let key in logisticsService) {
                if (logisticsService[key]['id'].toString() === serviceId) {
                    billingService.push(logisticsService[key]['name']);
                }
            }
        });
        if (SD.logisticsService?.split(",").includes('other')) {
            billingService.push('未匹配上物流服务');
        }
        return billingService.join(', ');
    }
    const downloadButtonHandler = () => {
        setDownloading(true);
        const searchData = form.getFieldsValue();
        if (searchData.invoiceDate) {
            searchData.invoiceDateAfter = moment(searchData.invoiceDate[0].toDate()).format('YYYY-MM-DD');
            searchData.invoiceDateBefore = moment(searchData.invoiceDate[1].toDate()).format('YYYY-MM-DD');
            delete searchData.invoiceDate;
        }
        // trim all values
        Object.keys(searchData).forEach(key => {
            if (typeof searchData[key] === 'string') {
                searchData[key] = searchData[key].trim();
            }
        });
        const searchParams = new URLSearchParams();
        const map = {
            "billToAccountNumber":"bill_to_account_number",
            "invoiceDateBefore": "invoice_date_before",
            "invoiceDateAfter": "invoice_date_after",
            "invoiceNumber": "invoice_number",
            "logisticsService": "logistics_service",
            "trackingNumber": "tracking_number",
            "uid": "uid",
            "pageSize": "page_size",
            "currentPage": "curr_page",
            "billingInvoice": "billing_invoice",
        }
        Object.keys(searchData).forEach(key => {
            if (searchData[key]) {
                searchParams.append(map[key], searchData[key]);
            }
        });
        // add isDownload = true
        searchParams.append('isDownload', true);

        axios.get("/accounts/get-non-standard-service/", {
            params: searchParams,
            withCredentials: true
        }).then((response) => {
            if (response.status === 200) {
                notification.success({
                    message: 'Success',
                    description: '导出成功,请前往下载管理页面获取文件',
                });
                setDownloading(false);
            } else {
                notification.error({
                    message: 'Error',
                    description: '导出失败,请联系管理员',
                });
                setDownloading(false);
            }
        }).catch((error) => {
            notification.error({
                message: 'Error',
                description: '导出失败,请联系管理员',
            });
            setDownloading(false);
        })
    }
    const onFinish = (values) => {
        const searchData = { ...values };
        if (searchData.invoiceDate) {
            searchData.invoiceDateAfter = moment(searchData.invoiceDate[0].toDate()).format('YYYY-MM-DD');
            searchData.invoiceDateBefore = moment(searchData.invoiceDate[1].toDate()).format('YYYY-MM-DD');
            delete searchData.invoiceDate;
        }
        // trim all values
        Object.keys(searchData).forEach(key => {
            if (typeof searchData[key] === 'string') {
                searchData[key] = searchData[key].trim();
            }
        });
        setSearchData(searchData);
        setCurrentPage(1);
        setPageSize(10);
    };

    const handleBillingModalSubmit = () => {
        if (selectedRowKeys.length > 0) {
            const request = {
                "ids": selectedRowKeys,
            }
            updateBillingStatus(request);
        } else {
            updateBillingStatus(billingConfirmSubmitData);
        }
    }

    const markBillingButtonHandler = () => {
        if (selectedRowKeys.length > 0) {
            setBillingConfirmContent(selectedRows);
            setBillingConfirmSubmitData(selectedRowKeys);
            setMarkBillingModalOpen(true);
        } else {
            const formData = form.getFieldsValue();
            // 检查是否至少还有一个其他查询条件
            const searchData = { ...formData };
            delete searchData.isDownload;
            const hasOtherValidFields = Object.values(searchData).some(value => value !== undefined && value !== '');
            if (!hasOtherValidFields) {
                return notification.error({
                    message: 'Error',
                    description: "下载范围过大,必须有至少一个查询条件!",
                });
            }
            // 检查是否filter未出账, searchData.status === '0'
            const hasUnbilled = String(searchData.status) === '2';
            if (!hasUnbilled) {
                return notification.error({
                    message: 'Error',
                    description: "请筛选状态为未出账的数据!",
                });
            }
            if (searchData.invoiceDate) {
                searchData.invoiceDateAfter = moment(searchData.invoiceDate[0].toDate()).format('YYYY-MM-DD');
                searchData.invoiceDateBefore = moment(searchData.invoiceDate[1].toDate()).format('YYYY-MM-DD');
                delete searchData.invoiceDate;
            }

            // trim all values
            Object.keys(searchData).forEach(key => {
                if (typeof searchData[key] === 'string') {
                    searchData[key] = searchData[key].trim();
                } else if (Array.isArray(searchData[key])) {
                    searchData[key] = searchData[key].join(',');
                }
            });
            const billingInvoice = get_invoice_name(searchData);
            const billingStatus = get_status(searchData);
            const billingService = get_service(searchData);

            let params = [{
                "状态": billingStatus,
                "Bill To Account Number": searchData.billToAccountNumber,
                "Tracking Number": searchData.trackingNumber,
                "物流服务": billingService,
                "Invoice Number": searchData.invoiceNumber,
                "UID": searchData.uid,
                "账单起始时间": searchData.invoiceDateAfter,
                "账单结束时间": searchData.invoiceDateBefore,
                "导入批次编号": billingInvoice,
            }];
            setMarkBillingModalOpen(true);
            setBillingConfirmContent(params);
            setBillingConfirmSubmitData(searchData);
        }
    }
    return (
        <Form form={form} name="advanced_search" style={formStyle} onFinish={onFinish}>
            <Row gutter={24}>{getFields()}</Row>
            <div
                style={{
                    textAlign: 'right',
                }}
            >
                <Space size="small">
                    <Button type="primary" loading={downloading} onClick={downloadButtonHandler}>
                        下载数据
                    </Button>
                    <Button type="primary" onClick={markBillingButtonHandler}>
                        标记出账
                    </Button>
                    <Button type="primary" htmlType="submit">
                        Search
                    </Button>
                    <Button
                        onClick={() => {
                            form.resetFields();
                        }}
                    >
                        Clear
                    </Button>
                </Space>
            </div>
            <BillingConfirmModal
                open={markBillingModalOpen}
                setOpen={setMarkBillingModalOpen}
                content={billingConfirmContent}
                isFilter={selectedRowKeys.length <= 0}
                onSubmit={handleBillingModalSubmit}
                loading={isUpdatingBillingStatus}
            />
        </Form>
    );
};

export default NonStandardServiceSearchBar;