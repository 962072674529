import React, { useEffect } from 'react';
import { Modal, Form, DatePicker, App, Input } from 'antd';
import {
    useBankSlipConfirmPaymentMutation,
} from '../../features/api/apiSlice';
function BankSlipConfirmPaymentModal({ open, setOpen, data }) {
    const [form] = Form.useForm();

    const { notification } = App.useApp();
    const [confirmPayment, {
        error: confirmPaymentError,
        isLoading: confirmPaymentIsLoading,
        isSuccess: confirmPaymentIsSuccess,
        isError: confirmPaymentIsError,
    }] = useBankSlipConfirmPaymentMutation();

    useEffect(() => {
        if (confirmPaymentIsSuccess) {
            notification.success({
                message: 'Success',
                description: '确认到账成功'
            });
            setOpen(false);
            form.resetFields();
        }
        if (confirmPaymentIsError) {
            notification.error({
                message: 'Error',
                description: confirmPaymentError?.data?.error || '确认到账失败',
            })
        }
    }, [confirmPaymentIsSuccess, confirmPaymentIsError]);

    const modalOkButtonHandler = () => {
        form.validateFields().then((values) => {
            const formData = new FormData();
            formData.append('bank_slip_id', data.id);
            formData.append('payment_amount', values.payment_amount);
            formData.append('payment_date', values.payment_date.format('YYYY-MM-DD'));
            confirmPayment(formData);
        }).catch((info) => {
            console.log('Validate Failed:', info);
        });
    }
    const twoDecimalPlacesOnly = (rule, value) => {
        if (!/^\d+(\.\d{1,2})?$/.test(value)) {
            return Promise.reject('仅支持两位小数');
        }
        return Promise.resolve();
    }
    return (
        <Modal
            title="确认到账"
            open={open}
            onOk={() => modalOkButtonHandler()}
            onCancel={() => setOpen(false)}
            confirmLoading={confirmPaymentIsLoading}
        >
            <Form
                form={form}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}>
                <Form.Item
                    label="到账金额"
                    name="payment_amount"
                    rules={[{ required: true, message: '请输入到账金额' },
                    { validator: twoDecimalPlacesOnly }
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="到账日期"
                    name="payment_date"
                    rules={[{ required: true, message: '请输入到账日期' }]}
                >
                    <DatePicker />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default BankSlipConfirmPaymentModal;