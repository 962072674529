import moment from 'moment';
import { getTimezone } from 'countries-and-timezones';
export const renderDate = (date) => {
    // get current timezone
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // the rendered date should be in the format of "2023-01-01"
    return new Date(date).toISOString().split('T')[0];
}

export const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

export const renderDateTime = (datetime) => {
    if (!datetime) return "";
    
		let date = new Date(datetime);
		let clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Sample: "Asia/Shanghai"
		date = date.toLocaleString("sv-SE", {
			timeZone: clientTimeZone,
			hour12: false,
		});
		let dString = date.split(" ")[0]; // the yyyy-mm-dd part
		let tString = date.split(" ")[1]; // the time part
		return dString + "\n" + tString;
}

export const addThousandsSeparator = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
const isDaylightSavingTime = (timeZone) => {
  const today = new Date();
  const january = new Date(today.getFullYear(), 0, 1);
  const july = new Date(today.getFullYear(), 6, 1);

  const standardOffset = Math.max(
      january.getTimezoneOffset(),
      july.getTimezoneOffset()
  );

  return today.getTimezoneOffset() < standardOffset;
};

export const renderTimeWithTimeZone = (time) => {
  // time is a UTC time, convert it based on the browser's timezone
  // and attach the timezone info after the time like UTC+8
  const date = new Date(time);
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  
  let timeZoneOffset = getTimezone(timeZone).utcOffsetStr;
  if (isDaylightSavingTime(timeZone)) {
    timeZoneOffset = getTimezone(timeZone).dstOffsetStr;
  }
  

  return `${moment(date).format('YYYY-MM-DD HH:mm:ss')} GMT ${timeZoneOffset}`;
}