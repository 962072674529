import React, { useEffect, useState } from 'react';
import { Select, Space, App, theme, DatePicker, Typography, Button } from 'antd';
import {
    useGetBillingProcessQuery,
    useGetUserQuery,
    useGetAllCustomerUidQuery,
    useGetBillingInvoiceNamesQuery,
} from '../../features/api/apiSlice';
import { renderDateTime } from '../utils';
import ExportUIDExcelModal from './ExportUIDExcelModal';
import ProTable from '@ant-design/pro-table';
import { useSelector } from 'react-redux';
const { RangePicker } = DatePicker;
const { Title } = Typography;
function BatchManagementPage() {
    const { token } = theme.useToken();
    const [searchData, setSearchData] = useState({});
    const { data, isError, isLoading, isFetching, refetch } = useGetBillingProcessQuery({ ...searchData });
    const { data: userData, refetch: refetchUserData } = useGetUserQuery();
    const { data: customerData, refetch: refetchUid } = useGetAllCustomerUidQuery();
    const { data: billingInvoiceNames } = useGetBillingInvoiceNamesQuery();
    const { notification } = App.useApp();
    const [exportUidModalOpen, setExportUidModalOpen] = useState(false);
    const [modalData, setModalData] = useState({});
    const currUser = useSelector((state) => state.auth.user);
    useEffect(() => {
        refetch();
        refetchUserData();
        refetchUid();
    }, [])
    useEffect(() => {
        if (isError) {
            notification.error({
                message: 'Error',
                description: '获取数据失败,请稍后再试',
            });
        }
    }, [isError])
    const columns = [
        {
            title: '导入批次编号',
            dataIndex: ['billing_invoice', 'name'],
            key: 'billingInvoice',
            search: true,
            renderFormItem: (item, { type, defaultRender, ...rest }) => {
                return <Select
                    placeholder="请选择"
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    style={{ textAlign: "left" }}
                    filterOption={(input, option) =>
                        (option?.label ?? '')
                            .toLowerCase()
                            .includes(input.toLowerCase())
                    }
                    mode='multiple'>
                    {billingInvoiceNames && billingInvoiceNames.map(billingInvoiceName => (
                        <Select.Option key={billingInvoiceName.id} value={billingInvoiceName.id} label={billingInvoiceName.name}>
                            {billingInvoiceName.name}
                        </Select.Option>
                    ))}
                </Select>
            }
        },
        {
            title: '客户ID',
            dataIndex: ['customer', 'uid'],
            key: 'customer',
            search: true,
            renderFormItem: (item, { type, defaultRender, ...rest }) => {
                return <Select
                    placeholder="请选择"
                    allowClear
                    style={{ textAlign: "left" }}
                    mode="multiple"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        (option?.label ?? '')
                            .toLowerCase()
                            .includes(input.toLowerCase())
                    }
                    tokenSeparators={[',', ' ']}>
                    {customerData && customerData.map(customer => (
                        <Select.Option key={customer.id} value={customer.id} label={customer.uid}>
                            {customer.uid}
                        </Select.Option>
                    ))}
                </Select>
            }
        },
        {
            title: '客户名称',
            dataIndex: ['customer', 'username'],
            key: 'customerName',
            search: false,
        },
        {
            title: '账单负责人',
            dataIndex: ['customer', 'user', 'username'],
            key: 'user',
            search: true,
            renderFormItem: (item, { type, defaultRender, ...rest }) => {
                return <Select 
                    placeholder="请选择" 
                    allowClear 
                    style={{ textAlign: "left" }} 
                    mode="multiple"
                    showSearch={true}
                    filterOption={(input, option) => {
                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }}>
                    {userData && userData.map(user => (
                        <Select.Option key={user.id} value={user.id}>
                            {user.username}
                        </Select.Option>
                    ))}
                </Select>
            }
        },
        {
            title: '主单数',
            dataIndex: 'shipment_count',
            key: 'shipment_count',
            search: false,
        },
        {
            title: '子单数',
            dataIndex: 'package_count',
            key: 'package_count',
            search: false,
        },
        {
            title: '异常子单数',
            dataIndex: 'error_package',
            key: 'error_package',
            search: false,
        },
        {
            title: 'Net Charge Amount',
            dataIndex: 'charge_amount',
            key: 'charge_amount',
            search: false,
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            search: true,
            renderFormItem: (item, { type, defaultRender, ...rest }) => {
                return <Select showSearch={false} placeholder="请选择" allowClear style={{ textAlign: "left" }} mode="multiple">
                    <Select.Option value="-1">未出账</Select.Option>
                    <Select.Option value="0">出账异常</Select.Option>
                    <Select.Option value="1">UID异常</Select.Option>
                    <Select.Option value="2">已出账</Select.Option>
                </Select>
            }
        },
        {
            title: '提交人',
            dataIndex: ['billing_invoice', 'user', 'username'],
            key: 'submitter',
            search: false,
        },
        {
            title: '创建时间',
            dataIndex: 'created_at',
            key: 'created_at',
            search: true,
            render: (date) => renderDateTime(date),
            renderFormItem: (item, { type, defaultRender, ...rest }) => {
                return <RangePicker style={{ width: '100%' }} />
            }
        },
        {
            title: '最新更新时间',
            dataIndex: 'updated_at',
            key: 'updated_at',
            search: false,
            render: (date) => renderDateTime(date),
        }
    ]

    const exportUIDExcelButtonHandler = (searchConfig) => {
        searchConfig.form.validateFields().then((values) => {

            if (!(values?.billingInvoice || values?.created_at || (values?.customer &&
                values?.customer.length !== 0))) {
                return notification.error({
                      message: 'Error',
                      description: '请搜索栏选择导入批次编号或者创建时间或者客户ID',
                });
            }
            let params = {
                billingInvoice: values.billingInvoice,
                user: values.user,
                customer: values.customer,
                status: values.status,
            }

            if (values?.created_at) {
                params.createdAtAfter = values.created_at[0].format('YYYY-MM-DD');
                params.createdAtBefore = values.created_at[1].format('YYYY-MM-DD');
            }
            setModalData(params);
            setExportUidModalOpen(true);
        }).catch((error) => {
            console.log(error);
            notification.error({
                message: 'Error',
                description: error,
            })
        });
    }

    return (
        <Space direction='vertical' size='small' style={{ width: '100%' }}>
            <Title level={4} style={{ textAlign: 'left' }}>导入批次管理</Title>
            <ProTable
                search={{
                    labelWidth: 'auto',
                    defaultCollapsed: false,
                    style: {
                        maxWidth: 'none',
                        background: token.colorFillAlter,
                        borderRadius: token.borderRadiusLG,
                        padding: 24,
                    },
                    optionRender: (searchConfig, formProps, dom) => [
                        (currUser.button_permission?.includes('export_upload_batch') && <Button type='primary' onClick={() => exportUIDExcelButtonHandler(searchConfig, formProps)}>导出批次数据</Button>),
                        ...dom,
                    ],
                }}
                size="small"
                rowKey={(record) => record.id}
                loading={isLoading || isFetching}
                dataSource={data?.results}
                request={(params) => {
                    setSearchData(params);
                }}
                columns={columns}
                pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50', '100'],
                    total: data?.count,
                }}
                scroll={{ x: 'max-content' }}
            />
            <ExportUIDExcelModal
                open={exportUidModalOpen}
                setOpen={setExportUidModalOpen}
                modalData={modalData}
            />
        </Space>
    );
}
export default BatchManagementPage;