import React, { useEffect, useState } from 'react';
import { Typography } from 'antd';
import {
    useGetBillingDetailsSummaryQuery,
    useGetAllCustomerUidQuery,
    useGetLogisticsServiceByNameQuery,
} from "../features/api/apiSlice";
import {
    ProTable,
  } from '@ant-design/pro-components';
import axios from 'axios';
import { Select, DatePicker, Button, notification } from 'antd';
const { Option } = Select;
const { Text } = Typography;
const { RangePicker } = DatePicker;
function HomePage(props) {
    const [searchData, setSearchData] = useState({});
    const { data, error, isLoading, isFetching, refetch } = useGetBillingDetailsSummaryQuery({...searchData});
    const { data: customerUidData } = useGetAllCustomerUidQuery();
    const { data: logisticsService } = useGetLogisticsServiceByNameQuery();
    const [dataCalculating, setDataCalculating] = useState(true);
    console.log(data);
    useEffect(() => {
        axios.get('/accounts/check-home-page-data-fetch-status/')
            .then((res) => {
                if (res.data.status === 'done') {
                    setDataCalculating(false);
                    refetch();
                }
        }).catch(err => {
            console.log(err);
            setDataCalculating(true);
            return notification.error({
                message: 'Error',
                description: '获取数据失败'
            })
        }) 
    }, [])
    const reFetchDataHandler = () => {
        axios.get('/accounts/billing-details-summary/')
            .then((res) => {
                setDataCalculating(true);
                return notification.success({
                    message: 'Success',
                    description: '任务已经提交,请等待任务完成后回到页面查看'
                })
            })
            .catch(err => {
                console.log(err);
                notification.error({
                    message: 'Error',
                    description: '任务提交失败'
                })
            })

    }

    const columns = [
        {
            title: 'UID',
            dataIndex: 'uid',
            key: 'uid',
            search: true,
            renderFormItem: (item, { defaultRender, ...rest }, form) => {
                return <Select 
                    showSearch
                    allowClear
                    placeholder="Select a UID"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    mode="multiple"
                    style={{ textAlign: "left" }}
                >
                    {customerUidData && customerUidData.map((item) => {
                        return <Option key={item.uid} value={item.uid}>{item.uid}</Option>
                        
                    })}
                </Select>
            }
        },
        {
            title: 'Invoice Date',
            dataIndex: 'invoice_date',
            key: 'invoice_date',
            search: true,
            hidden: true,
            renderFormItem: (item, { defaultRender, ...rest }, form) => {
                return <RangePicker style={{ width: '100%' }} format="YYYY-MM-DD" />
            }
        },
        {
            title: '渠道服务',
            dataIndex: 'logistics_service',
            key: 'logistics_service',
            search: true,
            hidden: true,
            renderFormItem: (item, { defaultRender, ...rest }, form) => {
                return <Select 
                    showSearch
                    allowClear
                    placeholder="Select a service"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    mode="multiple"
                    style={{ textAlign: "left" }}
                >
                    {logisticsService && logisticsService.map((item) => {
                        return <Option key={item.id} value={item.id}>{item.name}</Option>
                        
                    })}
                </Select>
            }
        },
        {
            title: '异常',
            dataIndex: 'error',
            key: 'error',
            search: false,
            render: (text, record) => {
                return <Text>{record.error_pieces}件/${record.error_net_charge}</Text>
            }
        },
        {
            title: '待计算',
            dataIndex: 'wait_for_cal',
            key: 'wait_for_cal',
            search: false,
            render: (text, record) => {
                return <Text>{record.wait_for_cal_pieces}件/${record.wait_for_cal_net_charge}</Text>
            }
        },
        {
            title: '计算异常',
            dataIndex: 'cal_error',
            key: 'cal_error',
            search: false,
            render: (text, record) => {
                return <Text>{record.cal_error_pieces}件/${record.cal_error_net_charge}</Text>
            }
        },
        {
            title: '待出账',
            dataIndex: 'wait_for_bill',
            key: 'wait_for_bill',
            search: false,
            render: (text, record) => {
                return <Text>{record.wait_for_bill_pieces}件/${record.wait_for_bill_net_charge}</Text>
            }
        },
        {
            title: '已出账',
            dataIndex: 'billed',
            key: 'billed',
            search: false,
            render: (text, record) => {
                return <Text>{record.billed_pieces}件/${record.billed_net_charge}</Text>
            }
        }
    ]
    const loadingTip = dataCalculating ? '数据计算中，请等待...' : (isLoading || isFetching) ? '数据获取中，请等待...' : '';
    return (
        <div>
            <ProTable
                loading={{
                    spinning: isLoading || isFetching || dataCalculating,
                    tip: loadingTip,
                }}
                rowKey={record => record.uid}
                search={{
                    labelWidth: 'auto',
                    defaultCollapsed: false,
                }}
                request={(params) => {
                    setSearchData(params);
                }}
                dataSource={data?.results}
                columns={columns}
                pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50', '100'],
                    total: data?.count,
                }}
                options={{
                    reload: false,
                }}
                toolbar={{
                    title: ' ',
                    actions: [
                        <Button type="primary" onClick={reFetchDataHandler}>重新获取数据</Button>,
                    ],
                }}
            />
        </div>    
        
    );
}

export default HomePage;