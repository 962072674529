import React, { useState, useEffect, useRef } from 'react';
import { ProTable } from '@ant-design/pro-table';
import { Select, Input, theme, Button, Modal, Typography } from 'antd';
import {
    useGetUserQuery,
    useGetCustomerUidWithBillingCycleQuery,
    useGetCustomerBalanceQuery
} from '../../features/api/apiSlice';
import axios from 'axios';
import { renderDateTime } from '../utils';
const { Text } = Typography;
const { Option } = Select;
function ClientBalanceTab () {
    const { token } = theme.useToken();
    const [searchData, setSearchData] = useState({});
    const { data: customerUidData } = useGetCustomerUidWithBillingCycleQuery('预充');
    const { data, error, isLoading, isFetching, refetch } = useGetCustomerBalanceQuery({...searchData});
    const { data: kaUserData, refetch: refetchKaUserData } = useGetUserQuery();
    const [modalOpen, setModalOpen] = useState(false);
    const [modalData, setModalData] = useState({});
    const actionRef = useRef();
    useEffect(() => {
        refetch();
        refetchKaUserData();
    }, []);
    useEffect(() => {
        if (modalData.id) {
            actionRef.current?.reload();
        }
    }, [modalData]);
    const activitiesColumns = [
        {
            title: '操作页面',
            dataIndex: 'log_location',
            key: 'log_location',
        },
        {
            title: '操作类型',
            dataIndex: 'log_type',
            key: 'log_type',
        },
        {
            title: '金额',
            dataIndex: 'balance_change',
            key: 'balance_change',
            render: (text, record) => {
                // if text starts with - then it is red
                // if text starts with + then it is green
                let color = 'black';
                if (text.startsWith('-')) {
                    color = 'red';
                } else if (text.startsWith('+')) {
                    color = 'green';
                }
                return <Text style={{ color: color }}>{text}</Text>
            }
        },
        {
            title: '操作人',
            dataIndex: ['user', 'username'],
            key: 'user',
        },
        {
            title: '操作内容',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title:'操作时间',
            dataIndex: 'time',
            key: 'time',
            render: renderDateTime,
        },
        {
            title: 'IP',
            dataIndex: 'ip',
            key: 'ip',
        }
    ]
    const balanceActivitiesButtonHandler = (record) => {
        setModalData(record);
        setModalOpen(true);
    }
    const columns = [
        {
            title: 'UID',
            dataIndex: 'uid',
            key: 'customer',
            search: true,
            renderFormItem: (item, { defaultRender, ...rest }, form) => {
                return <Select
                    showSearch
                    allowClear
                    placeholder="Select a UID"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    mode="multiple"
                    style={{ textAlign: "left" }}
                >
                    {customerUidData && customerUidData.map((item) => {
                        return <Option key={item.uid} value={item.id}>{item.uid}</Option>
                    })}
                </Select>
            }
        },
        {
            title: '客户名',
            dataIndex: 'username',
            key: 'username',
            search: true,
            renderFormItem: (item, { defaultRender, ...rest }, form) => {
                return <Input placeholder='请输入(支持模糊查询)' />
            }
        },
        {
            title: '业务类型',
            dataIndex: 'business_type',
            key: 'business_type',
            search: true,
            renderFormItem: (item, { defaultRender, ...rest }, form) => {
                return <Select
                    allowClear
                    style={{ textAlign: "left" }}
                    placeholder='请选择'
                >
                    <Option value="渠道">渠道</Option>
                    <Option value="转运">转运</Option>
                    <Option value="代发">代发</Option>
                    <Option value="提柜">提柜</Option>
                    <Option value="海运">海运</Option>
                    <Option value="清派">清派</Option>
                </Select>
            }
        },
        {
            title: '客户对接人',
            dataIndex: ['contact', 'username'],
            key: 'contact',
            search: true,
            renderFormItem: (item, { defaultRender, ...rest }, form) => {
                return <Select
                    allowClear
                    showSearch
                    placeholder='请选择'
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ textAlign: "left" }}
                >
                    {kaUserData && kaUserData.map((item) => {
                        return <Option key={item.id} value={item.id}>{item.username}</Option>
                    }
                    )}
                </Select>
            }
        },
        {
            title: '客户余额',
            dataIndex: 'balance',
            key: 'balance',
            search: false,
            render: (text, record) => {
                return <span>{text} {record.currency}</span>
            }
        },
        {
            title: '长期额度',
            dataIndex: 'long_term_credit',
            key: 'long_term_credit',
            search: false,
            render: (text, record) => {
                return <span>{text} {record.currency}</span>
            }
        },
        {
            title: '余额变动详情',
            dataIndex: 'balance_activities',
            key: 'balance_activities',
            search: false,
            render: (text, record) => {
                return <Button type="link" onClick={() => balanceActivitiesButtonHandler(record)}>Activity...</Button>
            }
        }
    ]
    return (
        <div>
        <ProTable
            rowKey={(record) => record.id}
            search={{
                labelWidth: 'auto',
                defaultCollapsed: false,
                style: {
                    maxWidth: 'none',
                    background: token.colorFillAlter,
                    borderRadius: token.borderRadiusLG,
                    padding: 24,
                }
            }}
            columns={columns}
            dataSource={data?.results}
            request={(params) => {
                setSearchData(params);
            }}
            pagination={{
                defaultPageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '50', '100'],
                total: data?.count,
            }}
            loading={isLoading || isFetching}
            scroll={{ x: 'max-content' }}
            options={{
                reload: () => refetch(),
            }}
            />
            <Modal
                title='余额变动详情'
                open={modalOpen}
                footer={null}
                onCancel={() => setModalOpen(false)}
                width={1000}
            >
                <ProTable
                    search={false}
                    rowKey={record => record.id}
                    actionRef={actionRef}
                    request={async (params) => {
                        const request = {
                            customer: modalData.id,
                            currentPage: params.current,
                            pageSize: params.pageSize,
                        }
                        return axios.get(`/accounts/get-customer-balance-operation-log/`, { params: request }).then((res) => {
                            return {
                                data: res.data.results,
                                total: res.data.count
                            }
                        })
                    }}
                    pagination={{
                        defaultPageSize: 10,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', '50', '100'],
                    }}
                    columns={activitiesColumns}
                    options={false}
                    toolBarRender={false}
                    scroll={{ x: 'max-content' }}
                />
            </Modal>
        </div>
    )
}

export default ClientBalanceTab;