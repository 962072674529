import { Form, Input, Modal, Select, DatePicker, App, Row, Col, Button, Typography } from 'antd';
import React, { useState, useEffect } from 'react';
import "./CreateNewBankSlip.CSS";
import {
    useCreateBankSlipMutation,
} from '../../features/api/apiSlice';
import { MinusCircleOutlined } from '@ant-design/icons';
import { debounce } from 'lodash';
import { set } from 'nprogress';

const { Option } = Select;
const { Text } = Typography;
function CreateNewBankSlipModal({ open, setOpen, customerUidData, kaUserData }) {

    const [form] = Form.useForm();
    const [createBankSlip, { error, isLoading, isSuccess, isError }] = useCreateBankSlipMutation();
    const { notification } = App.useApp();
    const [submitLoading, setSubmitLoading] = useState(false);

    useEffect(() => {
        if (isSuccess) {
            notification.success({
                message: '成功',
                description: '新建成功',
            })
            setOpen(false);
            form.resetFields();
            setSubmitLoading(false);
        }
        if (isError) {
            setSubmitLoading(false);
            notification.error({
                message: '失败',
                description: error?.data?.error || '新建失败',
            })
        }
    }, [isSuccess, isError])

    const isNumberValid = (rule, value, callback) => {
        return new Promise((resolve, reject) => {
            if (value && !/^-?[0-9]+(.[0-9]+)?$/.test(value)) {
                reject('请输入可识别的数字!');
            } else if (value && value.length > 20) {
                reject('最多20位!');
            } else {
                resolve();
            }
        });
    }

    const fourDecimalPlacesPostiveNumberOnly = (rule, value, callback) => {
        return new Promise((resolve, reject) => {
            if (value && !/^\d+(\.\d{1,4})?$/.test(value)) {
                reject('数字需要为至多四位的正数，请输入可识别的数字!');
            } else {
                resolve();
            }
        });
    }

    const onSubmit = debounce(() => {
        form.validateFields().then((values) => {
            console.log(values);
            const formData = new FormData();
            formData.append('remittee', values.remittee);
            if (values.collection_account) { formData.append('collection_account', values.collection_account); }
            if (values.payee_name) { formData.append('payee_name', values.payee_name); }
            formData.append('slip_amount', values.slip_amount);
            formData.append('currency', values.currency);
            if (values.exchange_rate) {formData.append('exchange_rate', values.exchange_rate);}
            if (values.bank_slip_type) { formData.append('bank_slip_type', values.bank_slip_type); }
            formData.append('issuer', values.issuer);
            formData.append('issue_time', values.issue_time.format('YYYY-MM-DD'));
            if (values.note) { formData.append('note', values.note); }
            formData.append('payments', JSON.stringify(values.payments));
            createBankSlip(formData);
        }).catch((errorInfo) => {
            setSubmitLoading(false);
            console.log(errorInfo);
        });
    })

    const onModalOkHandler = () => {
        setSubmitLoading(true);
        onSubmit();
    }

    return (
        <Modal
            title="新建充值水单"
            open={open}
            onOk={() => { onModalOkHandler() }}
            onCancel={() => { setOpen(false) }}
            width={1000}
            confirmLoading={isLoading || submitLoading}
        >
            <Form
                form={form}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                initialValues={{ payments: [{}] }}
                style={{
                    marginTop: '20px',
                    marginLeft: '0%',
                    marginRight: '5%',
                    maxWidth: '100%'
                }}
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item label='回款主体' name='remittee' rules={[{ required: true, message: '请选择回款主体!' }]}>
                            <Select
                                showSearch
                                placeholder="请选择"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Option label='Awesung Limited (新火傲盛科技有限公司)' key='0' value='0'>Awesung Limited (新火傲盛科技有限公司)</Option>
                                <Option label='广州奥越国际物流有限公司-海运' key='1' value='1'>广州奥越国际物流有限公司-海运</Option>
                                <Option label='广州辕蓬物流有限公司' key='2' value='2'>广州辕蓬物流有限公司</Option>
                                <Option label='新Awesung Tech' key='3' value='3'>新Awesung Tech</Option>
                                <Option label='Bloomsung' key='4' value='4'>Bloomsung</Option>
                                <Option label='深圳新火傲盛科技有限公司-深圳易仓' key='5' value='5'>深圳新火傲盛科技有限公司-深圳易仓</Option>
                                <Option label='老Awesung Inc' key='6' value='6'>老Awesung Inc</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label='收款账户' name='collection_account'>
                            <Input placeholder="请输入" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Form.Item label="付款单位名" name="payee_name">
                            <Input placeholder="请输入" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="总金额" name="slip_amount" rules={[{ required: true, message: '请输入预充金额!' }, { validator: isNumberValid }]}>
                            <Input placeholder='请输入数字' />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Form.Item label="币种" name="currency" rules={[{ required: true, message: '请选择币种!' }]}>
                            <Select placeholder="请选择">
                                <Option label='USD' key='0' value='0'>USD</Option>
                                <Option label='CNY' key='1' value='1'>CNY</Option>
                                <Option label='EUR' key='2' value='2'>EUR</Option>
                                <Option label='CAD' key='3' value='3'>CAD</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="汇率" name="exchange_rate" rules={[{ validator: fourDecimalPlacesPostiveNumberOnly }]}>
                            <Input placeholder='请输入数字' />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Form.Item label="回款类型" name="bank_slip_type" >
                            <Select placeholder="请选择">
                                <Option label="应付款抵扣" key="0" value="0">应付款抵扣</Option>
                                <Option label="我司账户收款" key="1" value="1">我司账户收款</Option>
                                <Option label="押金抵扣" key="2" value="2">押金抵扣</Option>
                                <Option label="支票" key="3" value="3">支票</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="操作人" name="issuer" rules={[{ required: true, message: '请选择预充操作人!' }]}>
                            <Select
                                showSearch
                                placeholder="请选择"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }>
                                {kaUserData && kaUserData.map((item) => {
                                    return <Option key={item.id} value={item.id}>{item.username}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                <Col span={12}>
                        <Form.Item label="水单转账日期" name="issue_time" rules={[{ required: true, message: '请选择预充操作时间!' }]}>
                            <DatePicker placeholder="请选择日期" style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="备注" name="note">
                            <Input.TextArea />
                        </Form.Item>
                    </Col>
                </Row>
                <Row style={{ marginBottom: '10px'}}>
                    <Col span={7}>
                        <Text>充值明细</Text>
                    </Col>
                    <Col span={7}>
                        <Text>充值金额</Text>
                    </Col>
                    <Col span={10}>
                        <Text>充值系统</Text>
                    </Col>
                </Row>
                <Form.List name="payments">
                    {(fields, { add, remove }, { errors }) => (
                        <>
                            {fields.map(( field, index ) => (
                                <Row key={index} gutter={16}>
                                    <Col span={7}>
                                        <Form.Item
                                            {...field}
                                            name={[index, 'customer']}
                                            rules={[{ required: true, message: '请选择充值明细' }]}
                                        >
                                            <Select
                                                showSearch
                                                placeholder="请选择"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                style={{ textAlign: 'left' }}
                                            >
                                                {customerUidData &&
                                                    customerUidData.map((item) => (
                                                        <Option key={item.uid} value={item.id}>
                                                            {item.uid}(币种: {item.currency})
                                                        </Option>
                                                    ))}
                                                <Option key='账期' value='账期'>账期(与水单币种相同)</Option>
                                                <Option key='客户手续费' value='客户手续费'>客户手续费(与水单币种相同)</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={7}>
                                        <Form.Item
                                            {...field}
                                            name={[index, 'payment_amount']}
                                            rules={[{ required: true, message: '请输入充值金额' }]}
                                        >
                                            <Input placeholder="请填写至多两位小数" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={7}>
                                    <Form.Item
                                            noStyle
                                            shouldUpdate={(prevValues, curValues) => prevValues?.payments?.[index]?.customer !== curValues?.payments?.[index]?.customer}
                                        >
                                            {({ getFieldValue }) => {
                                                if (getFieldValue(['payments', index, 'customer']) === '账期' || getFieldValue(['payments', index, 'customer']) === '客户手续费') {
                                                    return null;
                                                } else {
                                                    return (
                                                        <Form.Item
                                                            {...field}
                                                            name={[index, 'system']}
                                                            rules={[{ required: true, message: '请选择充值系统' }]}>
                                                            <Select
                                                                style={{ textAlign: "left" }}
                                                                placeholder='请选择'
                                                            >
                                                                <Option key="0" label="TMS" value="0">TMS</Option>
                                                                <Option key="1" label="AwesungAPI" value="1">AwesungAPI</Option>
                                                                <Option key="2" label="Eccang" value="2">Eccang</Option>
                                                                <Option key="3" label="Prelabel" value="3">Prelabel</Option>
                                                            </Select>
                                                        </Form.Item>
                                                    )
                                                }
                                            }}
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        {fields.length > 1 ? (
                                            <MinusCircleOutlined
                                                className="dynamic-delete-button"
                                                onClick={() => remove(field.name)}
                                            />
                                        ) : null}
                                    </Col>

                                </Row>
                            ))}
                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block>
                                    添加充值明细
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            </Form>
        </Modal>
    )
}

export default CreateNewBankSlipModal;