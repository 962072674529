import React, {useState, useEffect, useRef} from 'react';
import {Button, Modal, notification, Typography } from 'antd';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './QuoteSheet.css'
import {useVerifyQuoteSheetMutation} from "../../features/api/apiSlice";
import axios from "axios";
const { Text } = Typography;
function DeleteQuoteSheetModal({ open, setOpen, selectRows, refetch}) {
    const [loading, setLoading] = useState(false);
    const fetchData = async () => {
        try {
            const formData = new FormData();
            formData.append('id', selectRows.id);
            const response = await axios.post('/accounts/delete-quote-sheet/', formData);
            setLoading(false);
            setOpen(false);
            if (response.status === 204) {
                refetch();
                notification.success({
                    message: "Success",
                    description: "删除报价表成功!",
                });
            } else {
                notification.error({
                    message: "Failed",
                    description: response.data.error,
                });
            }
        } catch (error) {
            console.error('Failed to fetch data:', error);
            setLoading(false);
        }
    };
    return (
        <Modal
            open={open}
            onCancel={() => setOpen(false)}
            width={'30%'}
            footer={[
                <Button key="back" type={"primary"} onClick={() => setOpen(false)}>
                    No
                </Button>,
                <Button key="back" onClick={() => fetchData()}>
                    Yes
                </Button>
            ]}
        >
            {Object.keys(selectRows).length > 0 ? (
                <>确认将{selectRows.rate_template.name}客户 <Text style={{ color: 'red'}}>{selectRows.start_date} - {selectRows.end_date}</Text>报价表删除吗?</>
            ) : null}

        </Modal>
    );
}

export default DeleteQuoteSheetModal;
