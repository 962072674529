import { Form, Input, Modal, Row, Col, Cascader, notification } from 'antd'
import React, { useEffect } from 'react'
import { useUpdateUserManagementMutation } from "../../features/api/apiSlice";

const EditUserModal = ({ open, setOpen, cascaderData, data }) => {
    const [form] = Form.useForm();
    const [UpdateUser, {
        isSuccess,
        isError,
        error,
        isLoading,
        isFetching
    }] = useUpdateUserManagementMutation();
    const re = /^(([^<>()$$$$\\.,;:\s@"]+(\.[^<>()$$$$\\.,;:\s@"]+)*)|(".+"))@(($$[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    useEffect(() => {
        if (data) {
            form.setFieldsValue({
                username: data.username,
                email: data.email,
                role: [data.role?.department?.id, data.role?.id]
            })
        }
    }, [data]);

    useEffect(() => {
        if (isSuccess) {
            setOpen(false);
            form.resetFields();
            notification.success({
                message: "Success",
                description: "用户更新成功",
            });
        }
        if (isError) {
            if (error.data && error.data.non_field_errors)
                notification.error({
                    message: "Error",
                    description: error.data.non_field_errors,
                });
            if (error.data && error.data.username) {
                notification.error({
                    message: "Error",
                    description: error.data.username[0],
                });
            }
            if (error.data && error.data.email) {
                notification.error({
                    message: "Error",
                    description: error.data.email[0],
                });
            }
            if (error.data && error.data.error) {
                notification.error({
                    message: "Error",
                    description: error.data.error,
                });
            }
        }
    }, [isSuccess, isError, error]);

    const EmailVerify = (_, value) => {

        if (value && !re.test(value)) {
            return Promise.reject('请输入有效的邮箱地址!');
        }
        return Promise.resolve();
    };

    const okButtonHandler = () => {
        form.validateFields().then(values => {
            const request = {
                id: data.id,
                username: values.username,
                email: values.email,
                role: values.role,
            }
            UpdateUser(request);
        }).catch((info) => {
            console.log("Validate Failed:", info);
        });
    }

    return (
        <Modal
            title="修改用户信息"
            open={open}
            width={700}
            onCancel={() => setOpen(false)}
            onOk={okButtonHandler}
            confirmLoading={isLoading}
        >
            <Form
                layout="vertical"
                form={form}
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="用户名"
                            rules={[{
                                required: true,
                                message: "请填写用户名"
                            }]}
                            name="username"
                        >
                            <Input
                                placeholder='请输入'
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="邮箱"
                            required={true}
                            rules={[
                                {
                                    required: true,
                                    message: "请填写邮箱"
                                },
                                {
                                    validator: EmailVerify
                                }
                            ]}
                            type="email"
                            name="email"
                        >
                            <Input
                                placeholder='请输入'
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="部门/角色"
                            name="role"
                        >
                            <Cascader
                                style={{ width: '100%' }}
                                options={cascaderData}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

export default EditUserModal
