import React, { useEffect } from 'react';
import { Modal, Form, Input, Select, App } from 'antd';
import { useUpdateClientMutation } from '../../features/api/apiSlice';
const { Option } = Select;
function ClientInfoEditModal({ open, setOpen, data ,billingUserData, kaUserData, rateTemplates, singlePieceInvoiceTemplates, multiPieceInvoiceTemplates }) {
    const [updateClient, { isLoading, isError, error, isSuccess }] = useUpdateClientMutation();

    const [form] = Form.useForm();
    const { notification } = App.useApp();
    useEffect(() => {
        if (data) {
            form.setFieldsValue({
                uid: data.uid,
                username: data.username,
                rate_template: data.rate_template?.id,
                contact: data.contact?.id,
                user: data.user?.id,
                business_type: data.business_type,
                billing_cycle: data.billing_cycle,
                credit_line: data.credit_line,
                comments1: data.comments1,
                currency: data.currency?.toString(),
                single_piece_invoice_template: data.single_piece_invoice_template?.id,
                multi_piece_invoice_template: data.multi_piece_invoice_template?.id,
            })
        }
    }, [data])
    const PositiveNumberOnly = (rule, value, callback) => {
        return new Promise((resolve, reject) => {
            if (value && !/^[0-9]+(.[0-9]{1,2})?$/.test(value)) {
              reject('请输入可识别的正数!');
            } else if (value && value.length > 20) {
                reject('最多20位!');
            } else {
                resolve();
            }
        });
    }
    useEffect(() => {
        if (isSuccess) {
            notification.success({
                message: "Success",
                description: "客户信息更新成功",
            });
            setOpen(false);
        }
        if (isError) {
            if (error.data && 'uid' in error.data) {
                notification.error({
                    message: "Error",
                    description: '客户ID已存在',
                });
            } else if (error.data && 'non_field_errors' in error.data) {
                notification.error({
                    message: "Error",
                    description:error.data.non_field_errors,
                });
            } else {
                notification.error({
                    message: "Error",
                    description: "Unknown error",
                });
            }
        }
    }, [isSuccess, isError])

    const handleOk = () => {
        form.validateFields().then(values => {
            const updated_data = {
                id: data.id,
                uid: values.uid,
                username: values.username,
                user: values.user,
                rate_template: values.rate_template,
                business_type: values.business_type,
                billing_cycle: values.billing_cycle,
                credit_line: values.credit_line,
                contact: values.contact,
                comments1: values.comments1,
                currency: values.currency,
                updated_at: data.updated_at,
                single_piece_invoice_template: values.single_piece_invoice_template,
                multi_piece_invoice_template: values.multi_piece_invoice_template,
            }
            updateClient(updated_data);
        }).catch(error => {
            console.log(error);
        })
    }
    return (
        <Modal
            title="编辑客户信息"
            open={open}
            onOk={handleOk}
            onCancel={() => setOpen(false)}
            confirmLoading={isLoading}
            okText="保存"
            cancelText="取消"
        >
            <Form
                form={form}
                labelCol={{
                    span: 6,
                }}
                wrapperCol={{
                    span: 18,
                }}
                style={{
                    marginTop: '20px'
                }}>
                <Form.Item label="客户ID" name='uid' rules={[{ required: true, message: '请输入客户ID!' }]}>
                    <Input
                        style={{ width: '90%' }} 
                        disabled
                    />
                </Form.Item>
                <Form.Item label="客户名" name='username' rules={[{ required: true, message: '请输入客户名!' }]}>
                    <Input
                        style={{ width: '90%' }}
                        disabled
                    />
                </Form.Item>
                <Form.Item label="客户分类" name='rate_template'>
                    <Select
                        allowClear
                        style={{ width: '90%' }}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {rateTemplates && rateTemplates.map((rateTemplate) => (
                            <Select.Option key={rateTemplate.id} value={rateTemplate.id}>
                                {rateTemplate.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label="客户对接人" name='contact' rules={[{ required: true, message: '请选择客户对接人!' }]}>
                    <Select
                        style={{ width: '90%' }}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {kaUserData && kaUserData.map(user => (
                            <Select.Option key={user.id} value={user.id}>
                                {user.username}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label="账单负责人" name='user' rules={[{ required: true, message: '请选择账单负责人!' }]}>
                    <Select
                        style={{ width: '90%' }}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {billingUserData && billingUserData.map(user => (
                            <Select.Option key={user.id} value={user.id}>
                                {user.username}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label="业务类型" name='business_type' rules={[{ required: true, message: '请选择业务类型!' }]}>
                    <Select
                        style={{ width: '90%' }}
                        allowClear
                    >
                        <Select.Option value="渠道">渠道</Select.Option>
                        <Select.Option value="转运">转运</Select.Option>
                        <Select.Option value="代发">代发</Select.Option>
                        <Select.Option value="提柜">提柜</Select.Option>
                        <Select.Option value="海运">海运</Select.Option>
                        <Select.Option value="清派">清派</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item label="账期" name='billing_cycle' rules={[{ required: true, message: '请选择账期!' }]}>
                    <Select
                        style={{ width: '90%' }}
                    >
                        <Select.Option value="0">0 天</Select.Option>
                        <Select.Option value="7">7 天</Select.Option>
                        <Select.Option value="15">15 天</Select.Option>
                        <Select.Option value="25">25 天</Select.Option>
                        <Select.Option value="30">30 天</Select.Option>
                        <Select.Option value="60">60 天</Select.Option>
                        <Select.Option value="90">90 天</Select.Option>
                        <Select.Option value="预充">预充</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item label="客户授信额度" rules={[{ validator: PositiveNumberOnly}]} name='credit_line'>
                    <Input
                        style={{ width: '90%' }}/>
                </Form.Item>
                <Form.Item label="币种" rules={[{ required: true, message: '请选择币种!'}]} name='currency'>
                    <Select 
                        placeholder='请选择' 
                        style={{ width: '90%' }}>
                        <Option label='USD' key='0' value='0'>USD</Option>
                        <Option label='CNY' key='1' value='1'>CNY</Option>
                        <Option label='EUR' key='2' value='2'>EUR</Option>
                        <Option label="CAD" key='3' value='3'>CAD</Option>
                    </Select>
                </Form.Item>
                <Form.Item label="单包裹出账模版" name='single_piece_invoice_template'>
                    <Select
                        style={{ width: '90%' }}
                        allowClear
                    >
                        {singlePieceInvoiceTemplates && singlePieceInvoiceTemplates.map((invoiceTemplate) => (
                            <Select.Option key={invoiceTemplate.id} value={invoiceTemplate.id}>
                                {invoiceTemplate.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label="多包裹出账模版" name='multi_piece_invoice_template'>
                    <Select
                        style={{ width: '90%' }}
                        allowClear
                    >
                        {multiPieceInvoiceTemplates && multiPieceInvoiceTemplates.map((invoiceTemplate) => (
                            <Select.Option key={invoiceTemplate.id} value={invoiceTemplate.id}>
                                {invoiceTemplate.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label="备注" name='comments1'>
                    <Input.TextArea
                        style={{ width: '90%' }}
                    />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default ClientInfoEditModal;