import React, { useState, useEffect } from 'react';
import { Table, Space, Button, notification, Modal, Dropdown } from 'antd';
import { UserDefinedExceptionsFixedTrackingColumns } from "./ErrorHandlingTabColumns";
import { ProTable } from '@ant-design/pro-components';
import { 
    useDisputeBillingDetailsMutation,
    useStatusUpdateHandlerMutation,
    useSunkCostMutation,
    useReevaluateBillingDetailsMutation,
    useGetRuleExpressionsNamesQuery,
} from '../../features/api/apiSlice';
import axios from 'axios';
import DisputeBasisModal from './DisputeBasisModal';
import AdjustSurchargeModal from './AdjustSurchargeModal';
function ShipdateErrorTab ({data, loading, searchData, setSearchData, currentPage, setCurrentPage, pageSize, setPageSize, UIDData, refetch }) {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [openSurcharge, setOpenSurcharge] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [downloading, setDownloading] = useState(false);
    const [disputeBasisModalOpen, setDisputeBasisModalOpen] = useState(false);
    const [adjustSurchargeModalOpen, setAdjustSurchargeModalOpen] = useState(false);
    const [chargeCodeData, setChargeCodeData] = useState([]);
    const [adjustSurchargeButtonLoading, setAdjustSurchargeButtonLoading] = useState(false);
    const { data: ruleExpressionsNames } = useGetRuleExpressionsNamesQuery();
    const [disputeBillingDetails, { 
        isLoading: disputeLoading,
        isSuccess: isDisputeSuccess,
        isError: isDisputeError,
        error: disputeError,
    }] = useDisputeBillingDetailsMutation();
    const [shipDateHandler, {
        isLoading: shipDateLoading,
        isSuccess: isShipDateSuccess,
        isError: isShipDateError,
        error: shipDateError,
    }] = useStatusUpdateHandlerMutation();
    const [sunkCostHandler, {
        isLoading: sunkCostLoading,
        isSuccess: isSunkCostSuccess,
        isError: isSunkCostError,
        error: sunkCostError,
    }] = useSunkCostMutation();
    const [reEvaluateHandler, {
        isLoading: reEvaluateLoading,
        isSuccess: isReEvaluateSuccess,
        isError: isReEvaluateError,
        error: reEvaluateError,
    }] = useReevaluateBillingDetailsMutation();

    useEffect(() => {
        if (isReEvaluateSuccess) {
            notification.success({
                message: 'Success',
                description: '二次规则检查成功',
            })
            setSelectedRowKeys([]);
        }
        if (isReEvaluateError) {
            notification.error({
                message: 'Error',
                description: reEvaluateError?.data?.error ?? '二次规则检查失败',
            })
        }
    }, [isReEvaluateSuccess, isReEvaluateError, reEvaluateError])
    useEffect(() => {
        if (isSunkCostSuccess) {
            notification.success({
                message: 'Success',
                description: '标记为Sunk Cost成功',
            })
            setSelectedRowKeys([]);
        }
        if (isSunkCostError) {
            notification.error({
                message: 'Error',
                description: sunkCostError?.data?.error ?? '标记为Sunk Cost失败',
            })
        }
    }, [isSunkCostSuccess, isSunkCostError, sunkCostError])
    useEffect(() => {
        if (isDisputeSuccess) {
            notification.success({
                message: 'Success',
                description: 'Dispute success',
            })
            setSelectedRowKeys([]);
        }
        if (isDisputeError) {
            notification.error({
                message: 'Error',
                description: disputeError?.data?.error ?? 'Dispute failed for unknown reason',
            })
        }
    }, [isDisputeSuccess, isDisputeError, disputeError])
    useEffect(() => {
        setSelectedRowKeys([]);
    }, [searchData])
    useEffect(() => {
        if (isShipDateSuccess) {
            notification.success({
                message: 'Success',
                description: 'Shipdate success',
            })
            setSelectedRowKeys([]);
        }
        if (isShipDateError) {
            notification.error({
                message: 'Error',
                description: shipDateError?.data?.error ?? 'Shipdate failed for unknown reason',
            })
        }
    }, [isShipDateSuccess, isShipDateError, shipDateError])

    const exceptionButtonHandler = () => {
        const keys = selectedRowKeys;
        if (keys.length === 0) {
            return notification.error({
                message: 'Error',
                description: '请选择至少一条记录',
            })
        }
        if (UIDData?.count !== 0) {
            return notification.error({
                message: 'Error',
                description: '执行失败,请优先处理完UID异常再执行其他操作!',
            })
        }
        shipDateHandler({ids: keys});
    }
    const disputeButtonHandler = () => {
        const keys = selectedRowKeys;
        if (keys.length === 0) {
            return notification.error({
                message: 'Error',
                description: '请选择至少一条记录',
            })
        }
        if (UIDData?.count !== 0) {
            return notification.error({
                message: 'Error',
                description: '执行失败,请优先处理完UID异常再执行其他操作!',
            })
        }
        setDisputeBasisModalOpen(true);
    }
    const reEvaluateButtonHandler = () => {
        const keys = selectedRowKeys;
        if (keys.length === 0) {
            return notification.error({
                message: 'Error',
                description: '请选择至少一条记录',
            })
        }
        if (UIDData?.count !== 0) {
            return notification.error({
                message: 'Error',
                description: '执行失败,请优先处理完UID异常再执行其他操作!',
            })
        }
        const formData = new FormData();
        formData.append('ids', keys);
        reEvaluateHandler(formData);
    }

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
            setSelectedRows(selectedRows);
        },
        selectedRowKeys,
    };
    const columns = UserDefinedExceptionsFixedTrackingColumns(setOpenSurcharge, setModalContent);
    const surcharge_columns = [{
        title: '费用类型',
        dataIndex: 'type',
        key: 'type'
    }, {
        title: '金额',
        dataIndex: 'amount',
        key: 'amount'
    }];
    const items = [
        {
            label: '标记为自用面单-Company',
            key: '1',
        },
        {
            label: '标记为自用面单-Personal',
            key: '2',
        },
    ];
    const handleMenuClick = (e) => {
        if (selectedRowKeys.length === 0) {
            return notification.error({
                message: 'Error',
                description: '请选择至少一条记录',
            })
        }
        if (e.key === '1') {
            const request = {
                ids: selectedRowKeys,
                to_uid: '0005',
                flag: false,
            };
            sunkCostHandler(request);
        }
        if (e.key === '2') {
            const request = {
                ids: selectedRowKeys,
                to_uid: '0007',
                flag: false,
            };
            sunkCostHandler(request);
        }
    };
    const menuProps = {
        items,
        onClick: handleMenuClick,
    };
    const renderModalTable = () => {
        return <Table columns={surcharge_columns} dataSource={modalContent} pagination={false} />;
    };
    const downloadButtonHandler = () => {
        const keys = selectedRowKeys;
        if (keys.length === 0) {
            return notification.error({
                message: 'Error',
                description: '请选择至少一条记录',
            })
        }
        setDownloading(true);
        const formData = new FormData();
        formData.append('ids', keys);
        axios.post('/accounts/download-user-defined-error-details/', formData)
            .then((res) => {
                setDownloading(false);
                notification.success({
                    message: 'Success',
                    description: '下载成功,请前往后台任务管理获取文件',
                })
            }).catch((err) => {
                setDownloading(false);
                const err_msg = err.response?.data?.errors;
                notification.error({
                    message: 'Error',
                    description: err_msg,
                })
            })
    }

    const adjustSurchargeButtonHandler = () => {
        setAdjustSurchargeButtonLoading(true);
        const keys = selectedRowKeys;
        if (keys.length === 0) {
            setAdjustSurchargeButtonLoading(false);
            return notification.error({
                message: 'Error',
                description: '请选择至少一条记录',
            })
        }

        // check if all selected rows have the same service
        const firstItemService = selectedRows[0]?.logistics_service.id;
        const isServiceMatch = selectedRows.every(row => row.logistics_service.id === firstItemService);
        if (!isServiceMatch) {
            setAdjustSurchargeButtonLoading(false);
            return notification.error({
                message: 'Error',
                description: '请选择渠道服务相同的条目',
            })
        }

        const urlSearchParams = new URLSearchParams();
        urlSearchParams.append('service', firstItemService);
        axios.get('/accounts/get-charge-code-by-service/', {
            params: urlSearchParams,
        }).then(response => {
            setChargeCodeData(response.data);
            setAdjustSurchargeModalOpen(true);
            setAdjustSurchargeButtonLoading(false);
        }).catch(error => {
            setChargeCodeData([]);
            notification.error({
                message: 'Error',
                description: '获取对应附加费选项失败,请稍后重试',
            })
            setAdjustSurchargeButtonLoading(false);
        })
    }

    return (
        <Space direction="vertical" style={{ display: 'flex'}}>
            <ProTable
                search={false}
                columns={columns}
                rowKey={record => record.id}
                loading={loading}
                dataSource={data?.results}
                rowSelection={rowSelection}
                pagination={{
                    showSizeChanger: true,
                    pageSize: pageSize,
                    current: currentPage,
                    total: data?.count,
                    onChange: (page, pageSize) => {
                        setCurrentPage(page);
                        setPageSize(pageSize);
                        setSelectedRowKeys([]);
                    },
                    onShowSizeChange: (current, size) => {
                        setCurrentPage(current);
                        setPageSize(size);
                        setSelectedRowKeys([]);
                    },
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                }}
                scroll={{ x: 'max-content' }}
                options={{
                    reload: () => refetch(),
                }}
                toolbar={{
                    title: ' ',
                    search: false,
                    actions: [
                        <Button loading={adjustSurchargeButtonLoading} type="primary" onClick={adjustSurchargeButtonHandler}>编辑附加费</Button>,
                        <Button loading={downloading} type="primary" onClick={downloadButtonHandler}>下载</Button>,
                        <Button loading={reEvaluateLoading} type="primary" onClick={reEvaluateButtonHandler}>二次规则检查</Button>,
                        <Button loading={shipDateLoading} type="primary" onClick={exceptionButtonHandler}>异常已修复</Button>,
                        <Dropdown menu={menuProps}>
                            <Button type="primary" loading={sunkCostLoading}>标记</Button>
                        </Dropdown>,
                        <Button loading={disputeLoading} onClick={disputeButtonHandler} >Dispute</Button>,
                    ],
                }}
            />
            <Modal
                title="附加费详情"
                open={openSurcharge}
                onOk={() => setOpenSurcharge(false)}
                onCancel={() => setOpenSurcharge(false)}
                width={800}
            >
                {renderModalTable()}
            </Modal>
            <DisputeBasisModal
                open={disputeBasisModalOpen}
                setOpen={setDisputeBasisModalOpen}
                currentTab='userDefinedError'
                disputeBillingDetails={disputeBillingDetails}
                ruleExpressionsNames={ruleExpressionsNames}
                keys={selectedRowKeys}
                />
            <AdjustSurchargeModal
                open={adjustSurchargeModalOpen}
                setOpen={setAdjustSurchargeModalOpen}
                chargeCodeData={chargeCodeData}
                selectedRowKeys={selectedRowKeys}
                setSelectedRowKeys={setSelectedRowKeys}
                setSelectedRows={setSelectedRows}
                />
        </Space>
    )
}

export default ShipdateErrorTab;