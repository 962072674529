import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row, Space, theme, Select, DatePicker, notification, TreeSelect } from 'antd';
import moment from 'moment-timezone';
import {
    useGenerateBillingBatchDetailsMutation,
    useShipmentRateCalculationMutation
} from "../../features/api/apiSlice";
import GenerateBillingBatchModal from "./GenerateBillingBatchModal";
import FuncConfirmModal from "./FuncConfirmModal";
const { RangePicker } = DatePicker;
const detailStatus = {
    '1': "UID异常", "2": "非常用渠道(未出账)", '3': "重复子Tracking", '4': "自定义异常", '5': "Surcharge异常",
    '7': "Master Trk未匹配", '21': "缺件", '8': "Dispute", '10': "待计算", '11': "计费异常",
    '12': "待出账", "13": "已出账", '14': "未知", '16': "沉没成本", '18': "转换格式异常", '22': "非常用渠道(已出账)"
}
const detailStatusOptions = [
    {
        title: '全选',
        value: '1,2,3,4,5,7,8,10,11,12,13,14,16,18,21',
        key: 'all',
        children: Object.entries(detailStatus).map(([key, value], index) => {
            return {
                title: value,
                value: key,
                key: key,
            }
        })
    }
]
const BillingDetailsSearchBar = ({ logisticsService, customerData, setSearchData, setCurrentPage, setPageSize,
                                     selectedRows, selectedRowKeys, setSelectedRowKeys, billingInvoiceNames, refetch, treeSelectData }) => {
    const { token } = theme.useToken();
    const [form] = Form.useForm();
    const [DownloadData, setDownloadData] = useState(false);
    const [open, setOpen] = useState(false);
    const [calculateShipmentLoading, setCalculateShipmentLoading] = useState(false);
    const [funcConfirmContent, setFuncConfirmContent] = useState([]);

    const [calculateShipmentConfirmOpen, setCalculateShipmentConfirmOpen] = useState(false);
    const [calculateShipmentContent, setCalculateShipmentContent] = useState(null);

    const [generateBillingConfirmOpen, setGenerateBillingConfirmOpen] = useState(false);
    const [generateBillingContent, setGenerateBillingContent] = useState(null);

    const [downloadConfirmOpen, setDownloadConfirmOpen] = useState(false);
    const [downloadConfirmContent, setDownloadConfirmContent] = useState(null);

    const formStyle = {
        maxWidth: 'none',
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
    };
    const [GenerateBilling, {
        isSuccess: isGenerateBillingSuccess,
        isError: isGenerateBillingError,
        error: GenerateBillingError,
        isLoading: isGenerateBillingLoading,
        isFetching: isGenerateBillingFetching,
    }] = useGenerateBillingBatchDetailsMutation();
    const [ShipmentRateCalculation, {
        isSuccess: isShipmentRateCalculationSuccess,
        isError: isShipmentRateCalculationError,
        error: ShipmentRateCalculationError,
        isLoading: isShipmentRateCalculationLoading,
        isFetching: isShipmentRateCalculationFetching,
    }] = useShipmentRateCalculationMutation();
    useEffect(() => {
        if (isShipmentRateCalculationSuccess) {
            setCalculateShipmentContent(null);
            setCalculateShipmentConfirmOpen(false);
            setSelectedRowKeys([]);
            return notification.success({
                message: "Success",
                description: "开始准备计算运费,请前往后台任务管理等待获取文件!",
            });
        }
        if (isShipmentRateCalculationError) {
            setCalculateShipmentContent(null);
            setCalculateShipmentConfirmOpen(false);
            return notification.error({
                message: "Error",
                description: ShipmentRateCalculationError.data?.message,
            });
        }
    }, [isShipmentRateCalculationSuccess, isShipmentRateCalculationError]);

    useEffect(() => {
        if (isGenerateBillingSuccess) {
            notification.success({
                message: "Success",
                description: "生成出账批次成功!",
            });
            setOpen(false);
            setSelectedRowKeys([]);
        }
        if (isGenerateBillingError) {
            notification.error({
                message: "Error",
                description: GenerateBillingError.data?.message,
            });
        }
    }, [isGenerateBillingSuccess, isGenerateBillingError])
    const get_invoice_name = (SD) => {
        let billingInvoices = [];
        SD.billingInvoice?.split(",").forEach(invoiceId => {
            for (let key in billingInvoiceNames) {
                if (billingInvoiceNames[key]['id'].toString() === invoiceId) {
                    billingInvoices.push(billingInvoiceNames[key]['name']);
                }
            }
        });
        return billingInvoices.join(', ');
    }
    const get_status = (SD) => {
        let billingStatus = [];
        SD.status?.split(",").forEach(status => {
            if (detailStatus.hasOwnProperty(status)) {
                billingStatus.push(detailStatus[status]);
            }
        });
        return billingStatus.join(', ');
    }
    const get_service = (SD) => {
        let billingService = [];
        SD.logisticsService?.split(",").forEach(serviceId => {
            for (let key in logisticsService) {
                if (logisticsService[key]['id'].toString() === serviceId) {
                    billingService.push(logisticsService[key]['name']);
                }
            }
        });
        return billingService.join(', ');
    }

    const handleDownloadButtonClick = () => {
        setDownloadData(true)
        try {
            if (selectedRowKeys.length > 0) {
                setFuncConfirmContent(selectedRows);
                setDownloadConfirmContent(selectedRows);
                setDownloadConfirmOpen(true);

            } else {
                const formData = form.getFieldsValue();
                // 检查是否至少还有一个其他查询条件
                const searchData = { ...formData };
                delete searchData.isDownload; // 移除status字段，以便检查其他字段
                const hasOtherValidFields = Object.values(searchData).some(value => value !== undefined);

                if (!hasOtherValidFields) {
                    return notification.error({
                        message: "Error",
                        description: "下载范围过大,必须有至少一个查询条件!",
                    });
                }
                if (searchData.invoiceDate) {
                    searchData.invoiceDateAfter = moment(searchData.invoiceDate[0].toDate()).format('YYYY-MM-DD');
                    searchData.invoiceDateBefore = moment(searchData.invoiceDate[1].toDate()).format('YYYY-MM-DD');
                    delete searchData.invoiceDate;
                }
                if (searchData.shipmentDate) {
                    searchData.shipmentDateAfter = moment(searchData.shipmentDate[0].toDate()).format('YYYY-MM-DD');
                    searchData.shipmentDateBefore = moment(searchData.shipmentDate[1].toDate()).format('YYYY-MM-DD');
                    delete searchData.shipmentDate;
                }
                searchData.isDownload = 1;

                // trim all values
                Object.keys(searchData).forEach(key => {
                    if (typeof searchData[key] === 'string') {
                        searchData[key] = searchData[key].trim();
                    } else if (Array.isArray(searchData[key])) {
                        searchData[key] = searchData[key].join(',');
                    }
                });
                const billingInvoice = get_invoice_name(searchData);
                const billingStatus = get_status(searchData);
                const billingService = get_service(searchData);
                let params = [{
                    "状态": billingStatus,
                    "Bill To Account Number": searchData.billToAccountNumber,
                    "账单起始时间": searchData.invoiceDateAfter,
                    "账单结束时间": searchData.invoiceDateBefore,
                    "账单号": searchData.invoiceNumber,
                    "物流服务": billingService,
                    "Master Tracking": searchData.tdMasterTracking,
                    "Tracking Number": searchData.trackingNumber?.split(" ").filter( e => e.trim().length > 0).join(", "),
                    "UID": searchData.uid,
                    "导入批次编号": billingInvoice,
                    "Ship Date起始时间": searchData.shipmentDateAfter,
                    "Ship Date结束时间": searchData.shipmentDateBefore,
                }];
                setDownloadConfirmOpen(true);
                setFuncConfirmContent(params);

                setDownloadConfirmContent({
                    "status":  searchData.status,
                    "billToAccountNumber":  searchData.billToAccountNumber,
                    "invoiceDateBefore":  searchData.invoiceDateBefore,
                    "invoiceDateAfter":  searchData.invoiceDateAfter,
                    "invoiceNumber":  searchData.invoiceNumber,
                    "logisticsService":  searchData.logisticsService,
                    "tdMasterTracking":  searchData.tdMasterTracking,
                    "trackingNumber":  searchData.trackingNumber,
                    "uid":  searchData.uid,
                    "billingInvoice":  searchData.billingInvoice,
                    "shipmentDateBefore":  searchData.shipmentDateBefore,
                    "shipmentDateAfter":  searchData.shipmentDateAfter,
                    "isDownload": 1
                })
            }
        } catch (res) {
            notification.success({
                message: "Error",
                description: "下载失败,出现错误!",
            })
        } finally {
            setDownloadData(false)
        }
    }
    const handleDownloadButtonSubmit = () => {
        if (selectedRowKeys.length > 0) {
            // 遍历 downloadConfirmContent 提取tracking number, 以空格为分隔合并字符串
            const ids = downloadConfirmContent.map(item => item.id).join(',');
            setSearchData({
                "idToDownload": ids,
                isDownload: 1
            })
        } else {
            setSearchData(downloadConfirmContent)
        }
        notification.success({
            message: "Success",
            description: "开始准备文件,请前往后台任务管理页面获取文件",
        })
        setDownloadConfirmOpen(false);
        setDownloadConfirmContent(null);
        setSelectedRowKeys([]);
    }
    const getFields = () => {
        const children = [];
        children.push(
            <Col span={8} key={1}>
                <Form.Item name='status' label='状态'>
                    <TreeSelect 
                        showSearch={false}
                        placeholder='请选择'
                        allowClear
                        style={{ textAlign: "left" }}
                        mode="multiple"
                        treeData={detailStatusOptions}
                        treeCheckable={true}
                        showCheckedStrategy="SHOW_CHILD"
                        treeDefaultExpandAll
                    />
                </Form.Item>
            </Col>,
            <Col span={8} key={2}>
                <Form.Item name="billingInvoice" label="导入批次编号">
                    <Select
                        placeholder='请选择'
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        style={{ textAlign: "left" }}
                        filterOption={(input, option) =>
                            (option?.label ?? '')
                                .toLowerCase()
                                .includes(input.toLowerCase())
                        }
                        mode='multiple'
                    >
                        {billingInvoiceNames && billingInvoiceNames.map((item) => (
                            <Select.Option label={item.name} key={item.id} value={item.id}>{item.name}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Col>,
            <Col span={8} key={3}>
                <Form.Item name="trackingNumber" label="Tracking Number">
                    <Input placeholder="请输入内容" />
                </Form.Item>
            </Col>,
            <Col span={8} key={4}>
                <Form.Item name="logisticsService" label="物流服务">
                    <Select placeholder='请选择' allowClear style={{ textAlign: "left" }} mode='multiple' showSearch={false}>
                        {logisticsService && logisticsService.map((item) => (
                            <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Col>,
            <Col span={8} key={5}>
                <Form.Item name="invoiceNumber" label="Invoice Number">
                    <Input placeholder="请输入内容" />
                </Form.Item>
            </Col>,
            <Col span={8} key={6}>
                <Form.Item name="tdMasterTracking" label="Master Tracking">
                    <Input placeholder="请输入内容" />
                </Form.Item>
            </Col>,
            <Col span={8} key={7}>
                <Form.Item name="uid" label="UID">
                    <TreeSelect
                        allowClear
                        style={{ textAlign: "left"}}
                        treeData={treeSelectData}
                        treeCheckable={true}
                        showCheckedStrategy='SHOW_PARENT'
                        placeholder="请选择"
                    />
                </Form.Item>
            </Col>,
            <Col span={8} key={9}>
                <Form.Item name="billToAccountNumber" label="Bill To Account Number">
                    <Input placeholder="请输入内容" />
                </Form.Item>
            </Col>,
            <Col span={8} key={8}>
                <Form.Item name="invoiceDate" label="Invoice Date">
                    <RangePicker style={{ width: '100%' }} format="YYYY-MM-DD" />
                </Form.Item>
            </Col>,
            <Col span={8} key={10}>
                <Form.Item name="shipmentDate" label="Shipment Date">
                    <RangePicker style={{ width: '100%' }} format="YYYY-MM-DD" />
                </Form.Item>
            </Col>
        );
        return children;
    };
    const onSubmit = (values) => {
        if (!values?.billing_batch_id) {
            return notification.error({
                message: "Error",
                description: "出账批次不能为空!",
            });
        }
        const searchData = {...generateBillingContent};
        // 判断是否有勾选条件
        if (selectedRowKeys.length > 0){
            if (selectedRowKeys.length > 20){
                return notification.info({
                    message: "Info",
                    description: "勾选操作最多支持20条信息!",
                });
            }
            searchData['ids'] = generateBillingContent;
        }else {
            Object.keys(generateBillingContent).forEach(key => {
                if (typeof generateBillingContent[key] === 'string') {
                    searchData[key] = generateBillingContent[key].trim();
                } else if (Array.isArray(generateBillingContent[key])) {
                    searchData[key] = generateBillingContent[key].join(',');
                }
            });
        }
        searchData['billing_batch_id'] = values.billing_batch_id;
        GenerateBilling(searchData);
    }
    const onFinish = (values) => {
        const searchData = { ...values };
        if (searchData.invoiceDate) {
            searchData.invoiceDateAfter = moment(searchData.invoiceDate[0].toDate()).format('YYYY-MM-DD');
            searchData.invoiceDateBefore = moment(searchData.invoiceDate[1].toDate()).format('YYYY-MM-DD');
            delete searchData.invoiceDate;
        }
        if (searchData.shipmentDate) {
            searchData.shipmentDateAfter = moment(searchData.shipmentDate[0].toDate()).format('YYYY-MM-DD');
            searchData.shipmentDateBefore = moment(searchData.shipmentDate[1].toDate()).format('YYYY-MM-DD');
            delete searchData.shipmentDate;
        }
        // trim all values
        Object.keys(searchData).forEach(key => {
            if (typeof searchData[key] === 'string') {
                searchData[key] = searchData[key].trim();
            }
        });
        // if searchData.uid contains string Awesung or Uparcel,
        // return a notification
        if (searchData.uid && (searchData.uid.includes('Awesung') || searchData.uid.includes('Uparcel'))) {
            return notification.error({
                message: "Error",
                description: "UID选择分组后不支持搜索,如需要数据请直接下载",
            })
        }

        setSearchData(searchData);
        setCurrentPage(1);
        setPageSize(10);
    };

    const calculateShipmentSubmit = () => {

        const SubformData = new FormData();
        // 判断是否有勾选条件
        if (selectedRowKeys.length > 0){
            SubformData.append('ids', calculateShipmentContent)
        }else {
            Object.keys(calculateShipmentContent).forEach(key => {
                if (calculateShipmentContent[key] !== undefined && calculateShipmentContent[key] !== null) {
                    SubformData.append(key, calculateShipmentContent[key]);
                }
            });
        }
        ShipmentRateCalculation(SubformData);
    }

    const generateBillingBatchButtonSubmit = () => {
        setGenerateBillingConfirmOpen(false);
        setOpen(true);
    }

    return (
        <Form form={form} name="advanced_search" style={formStyle} onFinish={onFinish}>
            <Row gutter={24}>{getFields()}</Row>
            <div
                style={{
                    textAlign: 'right',
                }}
            >
                <Space size="small">
                    <Button loading={DownloadData} type="primary" htmlType="button" onClick={() => handleDownloadButtonClick()}>
                        下载数据
                    </Button>
                    <Button type="primary" htmlType="submit">
                        Search
                    </Button>
                    <Button
                        onClick={() => {
                            form.resetFields();
                        }}
                    >
                        Clear
                    </Button>
                </Space>
                <GenerateBillingBatchModal
                    open={open}
                    setOpen={setOpen}
                    onSubmit={onSubmit}
                    loading={isGenerateBillingLoading || isGenerateBillingFetching}
                    success={isGenerateBillingSuccess}
                />
                <FuncConfirmModal
                    open={calculateShipmentConfirmOpen}
                    setOpen={setCalculateShipmentConfirmOpen}
                    content={funcConfirmContent}
                    isFilter={selectedRowKeys.length <= 0}
                    onSubmit={calculateShipmentSubmit}
                    loading={isShipmentRateCalculationLoading || isShipmentRateCalculationFetching}
                    success={isShipmentRateCalculationSuccess}
                />
                <FuncConfirmModal
                    open={generateBillingConfirmOpen}
                    setOpen={setGenerateBillingConfirmOpen}
                    content={funcConfirmContent}
                    isFilter={selectedRowKeys.length <= 0}
                    onSubmit={generateBillingBatchButtonSubmit}
                    loading={isGenerateBillingLoading || isGenerateBillingFetching}
                />
                <FuncConfirmModal
                    open={downloadConfirmOpen}
                    setOpen={setDownloadConfirmOpen}
                    content={funcConfirmContent}
                    isFilter={selectedRowKeys.length <= 0}
                    onSubmit={handleDownloadButtonSubmit}
                />
            </div>
        </Form>
    );
};

export default BillingDetailsSearchBar;