import React, { useState, useEffect } from 'react';
import { useGetLogisticsSupplierQuery, useGetLogisticsServiceQuery } from '../../features/api/apiSlice';
import LogisticsSearchBar from './LogisticsSearchBar';
import LogisticsCreateModal from './LogisticsCreateModal';
import LogisticsEditModal from './LogisticsInfoEditModal';
import { Button, Space, Table } from 'antd';
import { renderDate } from '../utils';
import { useSelector } from 'react-redux';
const initialEditModalState = {
    id: undefined,
    name: undefined,
    status: undefined,
    note: undefined,
    updated_at: undefined,
    open: false,
}
function LogisticsPage() {
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [editModalState, setEditModalState] = useState(initialEditModalState);
    const [searchData, setSearchData] = useState({});
    const { data: logisticsSuppliers } = useGetLogisticsSupplierQuery();
    const { data, error, isLoading, isFetching, refetch } = useGetLogisticsServiceQuery({...searchData, pageSize: pageSize, currentPage: currentPage});
    const currUser = useSelector(state => state.auth.user);
    useEffect(() => {
        refetch();
    }, [])
    const createButtonHandler = () => {
        setCreateModalOpen(true);
    }
    const editButtonHandler = (record) => {
        setEditModalState({
            id: record.id,
            name: record.name,
            status: record.status === 'Active' ? '1' : '0',
            note: record.note,
            updated_at: record.updated_at,
            open: true,
        });
    }
    const columns = [
        {
            title: '物流商',
            dataIndex: ['supplier', 'name'],
            key: 'supplierName',
        },
        {
            title: '物流服务',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '服务状态',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: '创建时间',
            dataIndex: 'created_at',
            key: 'createdAt',
            render: (date) => renderDate(date),
        },
        {
            title: '备注',
            dataIndex: 'note',
            key: 'note',
        },
        {
            title: '操作',
            key: 'action',
            hidden: !currUser.button_permission?.includes('edit_logistics_service'),
            hiddenInForm: !currUser.button_permission?.includes('edit_logistics_service'),
            render: (text, record) => {
                if (!currUser.button_permission?.includes()) {
                    <Button type="link" onClick={() => editButtonHandler(record)}>编辑物流信息</Button>
                }
            },
        }
    ]
    return (
        <div>
            <Space direction="vertical"
                size="middle"
                style={{
                    display: 'flex',
                }}>
                <LogisticsSearchBar
                    logisticsSuppliers={logisticsSuppliers}
                    setSearchData={setSearchData}
                    setCurrentPage={setCurrentPage}
                    setPageSize={setPageSize}
                />
                {currUser.button_permission?.includes('create_logistics_service') && <Space direction="horizontal" style={{ display: 'flex' }}>
                    <Button type="primary" onClick={createButtonHandler}>新建物流服务</Button>
                </Space>}
                <Table
                    rowKey={(record) => record.id}
                    loading={isLoading || isFetching}
                    columns={columns}
                    dataSource={data?.results}
                    pagination={{
                        showSizeChanger: true,
                        pageSize: pageSize,
                        current: currentPage,
                        total: data?.count,
                        onChange: (page, pageSize) => {
                            setCurrentPage(page);
                            setPageSize(pageSize);
                        },
                        onShowSizeChange: (current, size) => {
                            setCurrentPage(current);
                            setPageSize(size);
                        },
                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                    }}
                    scroll={{ x: 1500 }}
                />
            </Space>
            <LogisticsCreateModal
                open={createModalOpen}
                setOpen={setCreateModalOpen}
                logisticsSuppliers={logisticsSuppliers}
            />
            <LogisticsEditModal
                state={editModalState}
                setState={setEditModalState}
            />
        </div>
    )
}
export default LogisticsPage;