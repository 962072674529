import React from 'react';
import { Modal, Table } from 'antd';
import { renderTimeWithTimeZone } from '../utils';
function BankSlipOperationLogModal ({open, setOpen, data}) {
    const columns = [
        {
            title: '操作人',
            dataIndex: ['user', 'username'],
            key: 'username',
            width: '20%',
        },
        {
            title: '操作时间',
            dataIndex: 'time',
            key: 'time',
            width: '40%',
            render: renderTimeWithTimeZone,
        },
        {
            title: '描述',
            dataIndex: 'description',
            key: 'description',
            width: '40%',
        },
    ]
    return (
        <div>
            <Modal
                title="操作日志"
                open={open}
                onCancel={() => setOpen(false)}
                footer={null}
                width={800}
            >
                <Table
                    columns={columns}
                    dataSource={data}
                    rowKey="id"
                    pagination={false}
                    scroll={{ y: 400 }}
                />
            </Modal>
        </div>
    )
}

export default BankSlipOperationLogModal;