import React, { useState, useEffect } from 'react';
import { Space, Button, App } from 'antd';
import { renderDateTime } from '../utils';
import { 
    useGetBillingInvoiceQuery, 
    useGetLogisticsSupplierQuery, 
    useDeleteBillingInvoiceMutation,
} from '../../features/api/apiSlice';
import CreateNewBatchModal from './createNewBatchModal';
import DisplayBillingFileModal from './DisplayBillingFileModal';
import axios from 'axios';
import ProTable from '@ant-design/pro-table';
import { useSelector } from 'react-redux';
function CreateNewBatchPage () {
    const [searchData, setSearchData] = useState({});
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [displayFilesModalOpen, setDisplayFilesModalOpen] = useState(false);
    const { data, isError, isLoading, isFetching, refetch } = useGetBillingInvoiceQuery({ ...searchData });
    const { data: logisticsSuppliers } = useGetLogisticsSupplierQuery();
    const [billingFiles, setBillingFiles] = useState([]);
    const [billingFilesLoading, setBillingFilesLoading] = useState(false);
    const [deleteBillingInvoice] = useDeleteBillingInvoiceMutation();
    const { notification } = App.useApp();
    const currUser = useSelector((state) => state.auth.user);
    useEffect(() => {
        refetch();
    }, [])

    useEffect(() => {
        if (isError) {
            notification.error({
                message: 'Error',
                description: '获取数据失败,请稍后再试',
            })
        }
    }, [isError])
    const deleteButtonHandler = (record) => {
        if (record.status !== '异常') {
            return notification.error({
                message: 'Error',
                description: '删除非异常的导入批次请联系管理员',
            })
        }
        deleteBillingInvoice({"id": record.id});
    }
    const displayFilesButtonHandler = (record) => {
        setBillingFilesLoading(true);
        axios.get('/accounts/get-billing-files-by-invoice/' + record.id + '/')
          .then((response) => {
            setBillingFiles(response.data);
            setBillingFilesLoading(false);
            setDisplayFilesModalOpen(true);
          }).catch((error) => {
            setBillingFilesLoading(false);
            notification.error({
                message: 'Error',
                description: '获取数据失败,请稍后再试',
            })
            });
    }
    useEffect(() => {
        refetch();
    }, [])

    const columns = [
        {
            title: '导入批次编号',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '提交人',
            dataIndex: ['user', 'username'],
            key: 'user',
        },
        {
            title: '提交时间',
            dataIndex: 'created_at',
            key: 'created_at',
            render: renderDateTime,
        },
        {
            title: '进度',
            dataIndex: 'progress',
            key: 'progress',
        },
        {
            title: '耗时',
            dataIndex: 'completed_time',
            key: 'completed_time',
            render: (time) => {
                return time ? time : 'N/A';
            }
        },
        {
            title: 'Net Charge Amount',
            dataIndex: 'charge_amount',
            key: 'charge_amount',
        },
        {
            title: '滞纳金',
            dataIndex: 'late_fee',
            key: 'late_fee',
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: '操作',
            key: 'action',
            hidden: !currUser.button_permission?.includes('delete_upload_batch') && !currUser.button_permission?.includes('view_upload_batch_files'),
            hiddenInForm: !currUser.button_permission?.includes('delete_upload_batch') && !currUser.button_permission?.includes('view_upload_batch_files'),
            render: (text, record) => {
                return (
                    <Space size="small">
                        {currUser.button_permission?.includes('view_upload_batch_files') && <Button type="link" onClick={() => displayFilesButtonHandler(record)}>查看所有文件</Button>}
                        {currUser.button_permission?.includes('delete_upload_batch') && <Button type="link" onClick={() => deleteButtonHandler(record)}>删除</Button>}
                    </Space>
                )
            }
        }
    ]

    return (
        <div>
            <ProTable
                search={false}
                rowKey={record => record.id}
                loading={{
                    spinning: isLoading || isFetching,
                    tip: "数据获取中, 请等待...",
                }}
                size="small"
                columns={columns}
                dataSource={data?.results}
                request={(params) => {
                    setSearchData(params);
                }}
                pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50', '100'],
                    total: data?.count,
                }}
                scroll={{ x: 'max-content' }}
                options={{
                    reload: () => refetch(),
                }}
                toolbar={{
                    title: '导入批次列表',
                    actions: [
                        (currUser.button_permission?.includes('create_upload_batch') && <Button type="primary" onClick={() => setCreateModalOpen(true)}>新建导入批次</Button>)
                    ]
                }}
                />
            <CreateNewBatchModal 
                open={createModalOpen}
                setOpen={setCreateModalOpen}
                logisticsSuppliers={logisticsSuppliers}
                />
            <DisplayBillingFileModal
                open={displayFilesModalOpen}
                setOpen={setDisplayFilesModalOpen}
                data={billingFiles?.data}
                loading={billingFilesLoading}
            />
        </div>
    )
}

export default CreateNewBatchPage;