import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row, Space, theme, Select, DatePicker, notification, TreeSelect } from 'antd';
import moment from 'moment-timezone';
import {
    useGenerateBillingBatchDetailsMutation,
    useShipmentRateCalculationMutation,
} from "../../features/api/apiSlice";
import axios from 'axios';
import GenerateBillingBatchModal from "../billingDetails/GenerateBillingBatchModal";
import FuncConfirmModal from "../billingDetails/FuncConfirmModal";
import { useSelector } from "react-redux";
const detailStatus = {
   '10': "待计算", '11': "计费异常", '12': "待出账", "13": "已出账"
}
const detailStatusOptions = [
    {
        title: '全选',
        value: '1,3,4,5,7,8,10,11,12,13,14,16,18,21',
        key: 'all',
        children: Object.entries(detailStatus).map(([key, value], index) => {
            return {
                title: value,
                value: key,
                key: key,
            }
        })
    }
]
const HAndSpCalculationSearchBar = ({ logisticsService, customerData, invoiceNames, setSearchData, setCurrentPage, setPageSize,
                                     selectedRows, selectedRowKeys, setSelectedRowKeys, billingInvoiceNames }) => {
    const { token } = theme.useToken();
    const [form] = Form.useForm();
    const currUser = useSelector((store) => store.auth.user);
    const [DownloadData, setDownloadData] = useState(false);
    const [open, setOpen] = useState(false);
    const [funcConfirmContent, setFuncConfirmContent] = useState([]);

    const [calculateShipmentConfirmOpen, setCalculateShipmentConfirmOpen] = useState(false);
    const [calculateShipmentContent, setCalculateShipmentContent] = useState(null);

    const [generateBillingConfirmOpen, setGenerateBillingConfirmOpen] = useState(false);
    const [generateBillingContent, setGenerateBillingContent] = useState(null);

    const [downloadConfirmOpen, setDownloadConfirmOpen] = useState(false);
    const [downloadConfirmContent, setDownloadConfirmContent] = useState(null);

    const formStyle = {
        maxWidth: 'none',
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
    };
    const [GenerateBilling, {
        isSuccess: isGenerateBillingSuccess,
        isError: isGenerateBillingError,
        error: GenerateBillingError,
        isLoading: isGenerateBillingLoading,
        isFetching: isGenerateBillingFetching,
    }] = useGenerateBillingBatchDetailsMutation();
    const [ShipmentRateCalculation, {
        isSuccess: isShipmentRateCalculationSuccess,
        isError: isShipmentRateCalculationError,
        error: ShipmentRateCalculationError,
        isLoading: isShipmentRateCalculationLoading,
        isFetching: isShipmentRateCalculationFetching,
    }] = useShipmentRateCalculationMutation();
    useEffect(() => {
        if (isShipmentRateCalculationSuccess) {
            setCalculateShipmentContent(null);
            setCalculateShipmentConfirmOpen(false);
            setSelectedRowKeys([]);
            return notification.success({
                message: "Success",
                description: "开始准备计算运费,请前往后台任务管理等待获取文件!",
            });
        }
        if (isShipmentRateCalculationError) {
            setCalculateShipmentContent(null);
            setCalculateShipmentConfirmOpen(false);
            return notification.error({
                message: "Error",
                description: ShipmentRateCalculationError.data?.message,
            });
        }
    }, [isShipmentRateCalculationSuccess, isShipmentRateCalculationError]);

    useEffect(() => {
        if (isGenerateBillingSuccess) {
            notification.success({
                message: "Success",
                description: "生成出账批次成功!",
            });
            setOpen(false);
            setSelectedRowKeys([]);
        }
        if (isGenerateBillingError) {
            notification.error({
                message: "Error",
                description: GenerateBillingError.data?.message,
            });
        }
    }, [isGenerateBillingSuccess, isGenerateBillingError])

    const get_status = (SD) => {
        let billingStatus = [];
        SD.status?.split(",").forEach(status => {
            if (detailStatus.hasOwnProperty(status)) {
                billingStatus.push(detailStatus[status]);
            }
        });
        return billingStatus.join(', ');
    }
    const get_service = (SD) => {
        let billingService = [];
        SD.logisticsService?.split(",").forEach(serviceId => {
            for (let key in logisticsService) {
                if (logisticsService[key]['id'].toString() === serviceId) {
                    billingService.push(logisticsService[key]['name']);
                }
            }
        });
        return billingService.join(', ');
    }
    const getInvoiceName = (invoice_ids) => {
        let invNames = [];
        invoice_ids?.split(',').forEach(invoice_id => {
            for (let key in invoiceNames) {
                if (invoiceNames[key]['id'].toString() === invoice_id) {
                    invNames.push(invoiceNames[key]['name']);
                }
            }
        });
        return invNames.join(', ');
    }
    const get_invoice_name = (SD) => {
        let billingInvoices = [];
        SD.billingInvoice?.split(",").forEach(invoiceId => {
            for (let key in billingInvoiceNames) {
                if (billingInvoiceNames[key]['id'].toString() === invoiceId) {
                    billingInvoices.push(billingInvoiceNames[key]['name']);
                }
            }
        });
        return billingInvoices.join(', ');
    }
    const handleDownloadButtonClick = () => {
        setDownloadData(true)
        try {
            if (selectedRowKeys.length > 0) {
                setFuncConfirmContent(selectedRows);
                setDownloadConfirmContent(selectedRows);
                setDownloadConfirmOpen(true);
            } else {
                const formData = form.getFieldsValue();
                // 检查是否至少还有一个其他查询条件
                const searchData = { ...formData };
                const hasOtherValidFields = Object.values(searchData).some(value => value !== undefined);

                if (!hasOtherValidFields) {
                    return notification.error({
                        message: "Error",
                        description: "下载范围过大,必须有至少一个查询条件!",
                    });
                }
                if (searchData.shipmentDate) {
                    searchData.shipmentDate = moment(searchData.shipmentDate.toDate()).format('YYYY-MM-DD');
                } else {
                    delete searchData.shipmentDate;
                }

                // trim all values
                Object.keys(searchData).forEach(key => {
                    if (typeof searchData[key] === 'string') {
                        searchData[key] = searchData[key].trim();
                    } else if (Array.isArray(searchData[key])) {
                        searchData[key] = searchData[key].join(',');
                    }
                });
                const billingStatus = get_status(searchData);
                const logisticsService = get_service(searchData);
                const billingInvoice = get_invoice_name(searchData);
                const invoiceNames = getInvoiceName(searchData.invoice);
                let params = [{
                    "状态": billingStatus,
                    "Master Tracking": searchData.tdMasterTracking,
                    "Tracking Number": searchData.trackingNumber?.split(" ").filter( e => e.trim().length > 0).join(", "),
                    "物流服务": logisticsService,
                    "导入批次编号": billingInvoice,
                    "出账批次编号": invoiceNames,
                    "UID": searchData.uid,
                    "Ship Date": searchData.shipmentDate
                }];
                setDownloadConfirmOpen(true);
                setFuncConfirmContent(params);

                setDownloadConfirmContent({
                    "status":  searchData.status,
                    "tdMasterTracking":  searchData.tdMasterTracking,
                    "logisticsService":  searchData.logisticsService,
                    "billingInvoice": searchData.billingInvoice,
                    "trackingNumber":  searchData.trackingNumber,
                    "uid":  searchData.uid,
                    "shipmentDate":  searchData.shipmentDate,
                    "invoice": searchData.invoice,
                })
            }
        } catch (res) {
            notification.success({
                message: "Error",
                description: "下载失败,出现错误!",
            })
        } finally {
            setDownloadData(false)
        }
    }
    const handleDownloadButtonSubmit = () => {
        if (selectedRowKeys.length > 0) {
            // 遍历 downloadConfirmContent 提取tracking number, 以空格为分隔合并字符串
            const ids = downloadConfirmContent.map(item => item.id).join(',');
            axios.get('/accounts/get-home-sp-calculation-details/', {
                params: {
                    id_to_download: ids,
                    isDownload: 1,
                },
            });
        } else {
            const params = {
                status: downloadConfirmContent.status,
                page_size: downloadConfirmContent.pageSize,
                curr_page: downloadConfirmContent.currentPage,
                logistics_service: downloadConfirmContent.logisticsService,
                td_master_tracking: downloadConfirmContent.tdMasterTracking,
                tracking_number: downloadConfirmContent.trackingNumber,
                billing_invoice: downloadConfirmContent.billingInvoice,
                uid: downloadConfirmContent.uid,
                invoice: downloadConfirmContent.invoice,
                shipment_date: downloadConfirmContent.shipmentDate,
                isDownload: downloadConfirmContent.isDownload,
                id_to_download: downloadConfirmContent.idToDownload,
                sort_field: downloadConfirmContent.sortField,
                sort_order: downloadConfirmContent.sortOrder,
                isDownload: 1,
              };
            axios.get('/accounts/get-home-sp-calculation-details/', {
                params: params,
            });
        }
        notification.success({
            message: "Success",
            description: "开始准备文件,请前往后台任务管理页面获取文件",
        })
        setDownloadConfirmOpen(false);
        setDownloadConfirmContent(null);
        setSelectedRowKeys([]);
    }
    const getFields = () => {
        const children = [];
        children.push(
            <Col span={8} key={1}>
                <Form.Item name='status' label='状态'>
                    <TreeSelect 
                        showSearch={false}
                        placeholder='请选择'
                        allowClear
                        style={{ textAlign: "left" }}
                        mode="multiple"
                        treeData={detailStatusOptions}
                        treeCheckable={true}
                        showCheckedStrategy="SHOW_CHILD"
                        treeDefaultExpandAll
                    />
                </Form.Item>
            </Col>,
            <Col span={8} key={3}>
                <Form.Item name="trackingNumber" label="Tracking Number">
                    <Input placeholder="请输入内容" />
                </Form.Item>
            </Col>,
            <Col span={8} key={4}>
                <Form.Item name="logisticsService" label="物流服务">
                    <Select placeholder='请选择' allowClear style={{ textAlign: "left" }} mode='multiple' showSearch={false}>
                        {logisticsService && logisticsService.filter(item => item.name.includes("FedEx Home Delivery®  (FedEx Ground Service)") || item.name.includes("FEDEX GE") || item.name.includes("FEDEX GE+")).map((item) => (
                            <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Col>,
            <Col span={8} key={6}>
                <Form.Item name="tdMasterTracking" label="Master Trk">
                    <Input placeholder="请输入内容" />
                </Form.Item>
            </Col>,
            <Col span={8} key={7}>
                <Form.Item name="uid" label="UID">
                    <Select placeholder='请选择' allowClear style={{ textAlign: "left" }} mode='multiple'>
                        {customerData && customerData.map((item) => (
                            <Select.Option key={item.uid} value={item.uid}>{item.uid}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Col>,
            <Col span={8} key={10}>
                <Form.Item name="shipmentDate" label="Shipment Date截止于">
                    <DatePicker style={{ width: '100%' }} format="YYYY-MM-DD" />
                </Form.Item>
            </Col>,
            <Col span={8} key={5}>
                <Form.Item name='billingInvoice' label='导入批次编号'>
                    <Select
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        style={{ textAlign: "left" }}
                        filterOption={(input, option) =>
                            (option?.label ?? '')
                                .toLowerCase()
                                .includes(input.toLowerCase())
                        }
                        mode='multiple'>
                        {billingInvoiceNames && billingInvoiceNames.map(billingInvoiceName => (
                            <Select.Option key={billingInvoiceName.id} value={billingInvoiceName.id} label={billingInvoiceName.name}>
                                {billingInvoiceName.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Col>,
            <Col span={8} key={8}>
                <Form.Item name="invoice" label="出账批次">
                    <Select
                        placeholder='请选择'
                        allowClear
                        showSearch
                        style={{ textAlign: "left" }}
                        mode='multiple'
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {invoiceNames && invoiceNames.map((item) => (
                            <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Col>
        );
        return children;
    };
    const onSubmit = (values) => {
        if (!values?.billing_batch_id) {
            return notification.error({
                message: "Error",
                description: "出账批次不能为空!",
            });
        }
        const searchData = {...generateBillingContent};
        // 判断是否有勾选条件
        if (selectedRowKeys.length > 0){
            if (selectedRowKeys.length > 20){
                return notification.info({
                    message: "Info",
                    description: "勾选操作最多支持20条信息!",
                });
            }
            searchData['ids'] = generateBillingContent;
        }else {
            Object.keys(generateBillingContent).forEach(key => {
                if (typeof generateBillingContent[key] === 'string') {
                    searchData[key] = generateBillingContent[key].trim();
                }
            });
        }
        searchData['billing_batch_id'] = values.billing_batch_id;
        GenerateBilling(searchData);
    }
    const onFinish = (values) => {
        const searchData = { ...values };
        if (searchData.shipmentDate) {
            searchData.shipmentDate = moment(searchData.shipmentDate.toDate()).format('YYYY-MM-DD');
        } else {
            delete searchData.shipmentDate;
        }
        // trim all values
        Object.keys(searchData).forEach(key => {
            if (typeof searchData[key] === 'string') {
                searchData[key] = searchData[key].trim();
            }
        });
        setSearchData(searchData);
        setCurrentPage(1);
        setPageSize(10);
    };
    const calculateShipmentHandler = () => {
        if (selectedRowKeys.length !== 0){
            const validStatuses = ["待计算", "计费异常"];
            const allMatch = selectedRows.every(row => validStatuses.includes(row.status));
            if (!allMatch) {
                return notification.error({
                    message: "Error",
                    description: "所有选中的条目必须都为[待计算]或[计费异常]状态",
                });
            }
            setCalculateShipmentConfirmOpen(true);
            setFuncConfirmContent(selectedRows);
            setCalculateShipmentContent(selectedRowKeys);
        } else {
            const formData = form.getFieldsValue();
            const validStatuses = ["10", "11"];
            if (!formData.status?.length || !formData.status.every(status => validStatuses.includes(status))) {
                return notification.error({
                    message: "Error",
                    description: "状态只能为[待计算]或[计费异常]或同时包含这两者!",
                });
            }
            if (!formData?.uid || formData?.uid.length < 1) {
                return notification.error({
                    message: "Error",
                    description: "请至少选择一个UID进行计算运费",
                })
            }
            if (formData?.invoice && formData?.invoice.length > 0) {
                return notification.error({
                    message: "Error",
                    description: "计算运费不支持筛选出账批次",
                })
            }
            // 检查是否至少还有一个其他查询条件
            const otherFields = { ...formData };
            delete otherFields.status; // 移除status字段，以便检查其他字段
            const hasOtherValidFields = Object.values(otherFields).some(value => value !== undefined);

            if (!hasOtherValidFields) {
                return notification.error({
                    message: "Error",
                    description: "计算运费范围过大,除状态外,必须至少有一个查询条件!",
                });
            }

            const formValues = form.getFieldsValue();
            const searchData = {...formValues };
            if (searchData.shipmentDate) {
                searchData.shipmentDate = moment(searchData.shipmentDate.toDate()).format('YYYY-MM-DD');
            } else {
                delete searchData.shipmentDate;
            }
            // trim all values
            Object.keys(searchData).forEach(key => {
                if (typeof searchData[key] === 'string') {
                    searchData[key] = searchData[key].trim();
                } else if (Array.isArray(searchData[key])) {
                    searchData[key] = searchData[key].join(',');
                }
            });

            const billingStatus = get_status(searchData);
            const logisticsService = get_service(searchData);
            const billingInvoice = get_invoice_name(searchData);
            let params = [{
                "状态": billingStatus,
                "Master Tracking": searchData.tdMasterTracking,
                "Tracking Number": searchData.trackingNumber?.split(" ").filter( e => e.trim().length > 0).join(", "),
                "物流服务": logisticsService,
                "导入批次编号": billingInvoice,
                "UID": searchData.uid,
                "Ship Date": searchData.shipmentDate
            }];
            setCalculateShipmentConfirmOpen(true);
            setFuncConfirmContent(params);

            setCalculateShipmentContent({
                "status":  searchData.status,
                "logistics_service":  searchData.logisticsService,
                "td_master_tracking":  searchData.tdMasterTracking,
                "tracking_number":  searchData.trackingNumber,
                "billing_invoice": searchData.billingInvoice,
                "uid":  searchData.uid,
                "billing_invoice":  searchData.billingInvoice,
                "shipment_date":  searchData.shipmentDate,
            })
        }
    }
    const calculateShipmentSubmit = () => {
        const SubformData = new FormData();
        // 判断是否有勾选条件
        if (selectedRowKeys.length > 0){
            SubformData.append('ids', calculateShipmentContent)
        } else {
            Object.keys(calculateShipmentContent).forEach(key => {
                if (calculateShipmentContent[key] !== undefined && calculateShipmentContent[key] !== null) {
                    SubformData.append(key, calculateShipmentContent[key]);
                }
            });
        }
        ShipmentRateCalculation(SubformData);
    }
    const generateBillingBatchButtonHandler = () => {
        // 判断是否勾选
        if (selectedRowKeys.length > 0) {
            if (selectedRowKeys.length > 20){
                return notification.info({
                    message: "Info",
                    description: "勾选操作最多支持20条信息!",
                });
            }
            // 判断勾选条目是否待出账状态
            const firstUID = selectedRows[0].UID;
            // 检查所有元素的status是否为12，以及UID是否都相同
            const allMatch = selectedRows.every(row => (row.status === 12 || row.status === "待出账") && row.UID === firstUID);
            if (!allMatch) {
                return notification.error({
                    message: "Error",
                    description: "所有选中的条目必须都为待出账状态，且UID必须相同",
                });
            }
            setFuncConfirmContent(selectedRows);
            setGenerateBillingContent(selectedRowKeys);
            setGenerateBillingConfirmOpen(true);
        }else{
            // get values from form
            const values = form.getFieldsValue();
            const searchData = { ...values };
            if (!searchData?.uid || searchData?.uid.length !== 1) {
                return notification.error({
                    message: "Error",
                    description: "请选择一个UID进行出账",
                })
            }
            if (searchData?.invoice && searchData?.invoice.length > 0) {
                return notification.error({
                    message: "Error",
                    description: "生成出账批次不支持筛选出账批次",
                })
            }
            if (!searchData?.status || searchData?.status.length !== 1 || searchData?.status[0] !== '12') {
                return notification.error({
                    message: "Error",
                    description: "请选择状态搜索条件为待出账",
                })
            }
            if (searchData.shipmentDate) {
                searchData.shipmentDate = moment(searchData.shipmentDate.toDate()).format('YYYY-MM-DD');
            } else {
                delete searchData.shipmentDate;
            }
            // trim all values
            Object.keys(searchData).forEach(key => {
                if (typeof searchData[key] === 'string') {
                    searchData[key] = searchData[key].trim();
                } else if (Array.isArray(searchData[key])) {
                    searchData[key] = searchData[key].join(',');
                }
            });

            const billingStatus = get_status(searchData);
            const logisticsService = get_service(searchData);
            const billingInvoice = get_invoice_name(searchData);
            let params = [{
                "状态": billingStatus,
                "Master Tracking": searchData.tdMasterTracking,
                "Tracking Number": searchData.trackingNumber?.split(" ").filter( e => e.trim().length > 0).join(", "),
                "物流服务": logisticsService,
                "导入批次编号": billingInvoice,
                "UID": searchData.uid,
                "Ship Date": searchData.shipmentDate
            }];
            setGenerateBillingConfirmOpen(true);
            setFuncConfirmContent(params);

            setGenerateBillingContent({
                "status":  searchData.status,
                "logisticsService":  searchData.logisticsService,
                "tdMasterTracking":  searchData.tdMasterTracking,
                "trackingNumber":  searchData.trackingNumber,
                "billingInvoice": searchData.billingInvoice,
                "uid":  searchData.uid,
                "shipmentDate":  searchData.shipmentDate,
            })
        }
    }
    const generateBillingBatchButtonSubmit = () => {
        setGenerateBillingConfirmOpen(false);
        setOpen(true);
    }

    return (
        <Form form={form} name="advanced_search" style={formStyle} onFinish={onFinish}>
            <Row gutter={24}>{getFields()}</Row>
            <div
                style={{
                    textAlign: 'right',
                }}
            >
                <Space size="small">
                    {currUser.button_permission?.includes('hsp_generate_billing_batch') && <Button type="primary"
                        htmlType="button" onClick={generateBillingBatchButtonHandler}>
                        生成出账批次
                    </Button>}
                    {currUser.button_permission?.includes('hsp_export_excel') && <Button loading={DownloadData} type="primary" htmlType="button" onClick={handleDownloadButtonClick}>
                        下载数据
                    </Button>}
                    {currUser.button_permission?.includes('hsp_calculation') && <Button type="primary" onClick={calculateShipmentHandler} loading={isShipmentRateCalculationLoading || isShipmentRateCalculationFetching}>
                        计算运费
                    </Button>}
                    <Button type="primary" htmlType="submit">
                        Search
                    </Button>
                    <Button
                        onClick={() => {
                            form.resetFields();
                        }}
                    >
                        Clear
                    </Button>
                </Space>
                <GenerateBillingBatchModal
                    open={open}
                    setOpen={setOpen}
                    onSubmit={onSubmit}
                    loading={isGenerateBillingLoading || isGenerateBillingFetching}
                    success={isGenerateBillingSuccess}
                />
                <FuncConfirmModal
                    open={calculateShipmentConfirmOpen}
                    setOpen={setCalculateShipmentConfirmOpen}
                    content={funcConfirmContent}
                    isFilter={selectedRowKeys.length <= 0}
                    onSubmit={calculateShipmentSubmit}
                    loading={isShipmentRateCalculationLoading || isShipmentRateCalculationFetching}
                    success={isShipmentRateCalculationSuccess}
                />
                <FuncConfirmModal
                    open={generateBillingConfirmOpen}
                    setOpen={setGenerateBillingConfirmOpen}
                    content={funcConfirmContent}
                    isFilter={selectedRowKeys.length <= 0}
                    onSubmit={generateBillingBatchButtonSubmit}
                    loading={isGenerateBillingLoading || isGenerateBillingFetching}
                />
                <FuncConfirmModal
                    open={downloadConfirmOpen}
                    setOpen={setDownloadConfirmOpen}
                    content={funcConfirmContent}
                    isFilter={selectedRowKeys.length <= 0}
                    onSubmit={handleDownloadButtonSubmit}
                />
            </div>
        </Form>
    );
};

export default HAndSpCalculationSearchBar;