import React, { useState, useEffect } from 'react';
import { Modal, Select, notification } from 'antd';
import { useUpdateClientDateIntervalMutation } from '../../features/api/apiSlice';
function ClientEditDateIntervalModal ({open, setOpen, data }) {
    const [dateInterval, setDateInterval] = useState(undefined);
    const [updateClientDateInterval, { isLoading, isError, error, isSuccess }] = useUpdateClientDateIntervalMutation();
    
    useEffect(() => {
        console.log(Object.keys(data).length)
        if (Object.keys(data).length !== 0) {
            setDateInterval(data.shipment_split_date_interval.toString());
        }
    }, [data])

    useEffect(() => {
        if (isSuccess) {
            notification.success({
                message: "Success",
                description: "客户合票天数规则修改成功",
            })
            setOpen(false);
        }
    }, [isSuccess])
    useEffect(() => {
        if (isError) {
            notification.error({
                message: "Error",
                description: error?.data.error ?? "Something went wrong",
            })
        }
    }, [isError])
    const handleOk = () => {
        setOpen(false);
        const request = {
            updatedRow: data,
            dateInterval,
        }
        updateClientDateInterval(request);
    }
    const handleCancel = () => {
        setOpen(false);
    }
    return (
        <Modal
            title={"修改合票天数规则"}
            open={open}
            onOk={handleOk}
            onCancel={handleCancel}
            confirmLoading={isLoading}
            centered>
            <Select
                style={{ width: "50%" }}
                value={dateInterval}
                onChange={(value) => setDateInterval(value)}
                >
                <Select.Option value="1">1 天之内合票</Select.Option>
                <Select.Option value="2">2 天之内合票</Select.Option>
                <Select.Option value="3">3 天之内合票</Select.Option>
            </Select>
        </Modal>
    )
}
export default ClientEditDateIntervalModal;