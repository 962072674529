import React, { useEffect } from 'react';
import { Modal, Form, Input } from 'antd';
function FuelSurchargeEditModal ({ open, setOpen, onSubmit, loading, success, row}) {
    useEffect(() => {
        if (success) {
            form.resetFields();
        }
    }, [success])
    const [form] = Form.useForm();
    const handleOk = () => {
        form.validateFields().then(values => {
            onSubmit(values);
        }).catch(error => {
            console.log(error);
        })
    }
    useEffect(() => {
        form.setFieldsValue({
            service_name: row?.service_name,
            eff_time: row?.eff_time,
            rate: row?.rate,
        });
    }, [row, form]);
    return (
        <Modal
            title="修改附加费"
            open={open}
            onCancel={() => setOpen(false)}
            onOk={handleOk}
            centered
            confirmLoading={loading}
        >
            <Form
                form={form}
                labelCol={{
                    span: 6,
                }}
                wrapperCol={{
                    span: 18,
                }}
                style={{
                    marginTop: '20px',
                    marginLeft: '15%',
                    marginRight: '20%',
                    maxWidth: '60%'
                }}
            >
                <Form.Item label="服务名称" name="service_name">
                    <Input readOnly style={{ backgroundColor: '#f5f5f5' }} />
                </Form.Item>
                <Form.Item label="启用时间" name="eff_time">
                    <Input readOnly style={{ backgroundColor: '#f5f5f5' }}/>
                </Form.Item>
                <Form.Item label="费率" name="rate">
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default FuelSurchargeEditModal;