import React, { useState, useEffect } from 'react';
import UploadQuoteSheetModal from './UploadQuoteSheetModal';
import { Button, Space, App, Dropdown, theme, Select, DatePicker } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { b64toBlob } from '../utils';
import { 
    useGetRateExcelQuery, 
    useGetLogisticsSupplierQuery, 
    useGetRateTemplateQuery,
    useGetAllCustomerUidQuery,
    useGetLogisticsServiceByNameQuery,
} from '../../features/api/apiSlice';
import axios from 'axios';
import PreViewQuoteSheetModal from "./PreViewQuoteSheetModal";
import { useSelector } from "react-redux";
import DeleteQuoteSheetModal from "./DeleteQuoteSheetModal";
import ProTable from '@ant-design/pro-table';
function QuoteSheetPage() {
    const { token } = theme.useToken();
    const [UploadQuoteSheetModalOpen, setUploadQuoteSheetModalOpen] = useState(false);
    const [PreViewQuoteSheetModalOpen, setPreViewQuoteSheetModalOpen] = useState(false);
    const [DeleteQuoteSheetModalOpen, setDeleteQuoteSheetModalOpen] = useState(false);
    const [searchData, setSearchData] = useState({});
    const [selectRows, setSelectRows] = useState({});
    const { data, error, isError, isLoading, isFetching, refetch } = useGetRateExcelQuery({ ...searchData });
    const { data: customerData, refetch: refetchUid } = useGetAllCustomerUidQuery();
    const { data: rateTemplates, refetch: refetchRateTemplates } = useGetRateTemplateQuery();
    const { data: logisticsSupplier } = useGetLogisticsSupplierQuery();
    const { data: logisticsService } = useGetLogisticsServiceByNameQuery();
    const { notification } = App.useApp();
    const currUser = useSelector((state) => state.auth.user);
    useEffect(() => {
        refetch();
        refetchRateTemplates();
        refetchUid();
    }, [])
    const createButtonHandler = () => {
        setUploadQuoteSheetModalOpen(true);
    }

    const preViewExcelHandler = (record) => {
        setSelectRows(record);
        setPreViewQuoteSheetModalOpen(true);
    }

    const DeleteExcelHandler = (record) => {
        setSelectRows(record);
        setDeleteQuoteSheetModalOpen(true);
    }
    const auth = useSelector((store) => store.auth.user);
    let actionItems = [
        {
            key: '1',
            label: '预览报价表',
            permission: 'preview_quote_sheet'
        },
        {
            key: '2',
            label: '下载Excel',
            permission: 'download_quote_sheet'
        },
        {
            key: '3',
            label: '删除报价表',
            permission: 'delete_quote_sheet'
        }
    ].filter((item) => {
        return currUser.button_permission?.includes(item.permission);
    })
    const downloadExcelHandler = (record) => {
        axios.post('/accounts/download-rate-excel/', {'file_path': record.file_path}, {
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'include',
            }
        }).then(res => {
            const url = window.URL.createObjectURL(b64toBlob(res.data.data, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${record.rate_template.name}报价表.xlsx`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }).catch(err => {
            // if response is 404, then the file is not found
            if (err.response.status === 404) {
                notification.error({
                    message: '下载失败',
                    description: '文件不存在',
                });
            } else {
                notification.error({
                    message: '下载失败',
                    description: '请联系管理员',
                });
            }
        })
    }
    const handleActionMenuClick = (e, record) => {
        switch (e.key) {
            case '1':
                preViewExcelHandler(record);
                break;
            case '2':
                downloadExcelHandler(record);
                break;
            case '3':
                DeleteExcelHandler(record);
                break;
            default:
                break;
        }
    }
    const downloadTemplateButtonHandler = () => {
        axios.get('/accounts/download-rate-excel-template/').then(res => {
            const url = window.URL.createObjectURL(b64toBlob(res.data.data, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `报价表模版.xlsx`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    }
    const columns = [
        {
            title: '客户ID',
            dataIndex: 'user',
            key: 'user',
            search: true,
            hideInTable: true,
            renderFormItem: (item, { type, defaultRender, ...rest }) => {
                return <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        (option?.label ?? '')
                            .toLowerCase()
                            .includes(input.toLowerCase())
                    }
                    style={{ textAlign: "left" }}
                >
                    {customerData && customerData.map((customer) => (
                        <Select.Option key={customer.id} value={customer.id} label={customer.uid}>
                            {customer.uid}
                        </Select.Option>
                    ))}
                </Select>
            }
        },
        {
            title: '物流商',
            dataIndex: 'logisticsSupplier',
            key: 'logisticsSupplier',
            search: true,
            hideInTable: true,
            renderFormItem: (item, { type, defaultRender, ...rest }) => {
                return <Select allowClear style={{ textAlign: "left" }}>
                    {logisticsSupplier && logisticsSupplier.map((logisticsSupplier) => (
                        <Select.Option key={logisticsSupplier.id} value={logisticsSupplier.id}>
                            {logisticsSupplier.name}
                        </Select.Option>
                    ))}
                </Select>
            }
        },
        {
            title: '生效时间',
            dataIndex: 'effectiveDate',
            key: 'effectiveDate',
            search: true,
            hideInTable: true,
            renderFormItem: (item, { type, defaultRender, ...rest }) => {
                return <DatePicker style={{ width: "100%" }}/>
            }
        },
        {
            title: '客户分类',
            dataIndex: ['rate_template', 'name'],
            key: 'rateTemplate',
            search: true,
            renderFormItem: (item, { type, defaultRender, ...rest }) => {
                return <Select
                    allowClear
                    style={{ textAlign: "left" }}
                    showSearch={true}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {rateTemplates && rateTemplates.map((rateTemplate) => (
                        <Select.Option key={rateTemplate.id} value={rateTemplate.id}>
                            {rateTemplate.name}
                        </Select.Option>
                    ))}
                </Select>
            }
        },
        {
            title: '物流商',
            dataIndex: ['logistics_supplier', 'name'],
            key: 'logisticsSupplier',
            search: false,
        },
        {
            title: '物流服务',
            dataIndex: 'logistics_service',
            key: 'logisticsService',
            search: false,
        },
        {
            title: '生效开始时间',
            dataIndex: 'start_date',
            key: 'start_date',
            width: 150,
            search: false,
        },
        {
            title: '生效结束时间',
            dataIndex: 'end_date',
            key: 'end_date',
            width: 150,
            search: false,
        },
        {
            title: '备注',
            dataIndex: 'note',
            key: 'note',
            search: false,
        },
        {
            title: '操作',
            key: 'action',
            search: false,
            hidden: !auth.button_permission?.includes('preview_quote_sheet') && !auth.button_permission?.includes('download_quote_sheet') && !auth.button_permission?.includes('delete_quote_sheet'),
            hiddenInForm: !auth.button_permission?.includes('preview_quote_sheet') && !auth.button_permission?.includes('download_quote_sheet') && !auth.button_permission?.includes('delete_quote_sheet'),
            render: (text, record) => (
                <Dropdown
                    menu={{
                        items: actionItems,
                        onClick: (e) => handleActionMenuClick(e, record),
                    }}
                >
                    <Button onClick={(e) => e.preventDefault}>
                        <Space>
                            请选择 <DownOutlined />
                        </Space>
                    </Button>
                </Dropdown>
            )
        }
    ]

    return (
        <div>
            <ProTable
                search={{
                    labelWidth: 'auto',
                    defaultCollapsed: false,
                    collapseRender: false,
                    style: {
                        maxWidth: 'none',
                        background: token.colorFillAlter,
                        borderRadius: token.borderRadiusLG,
                        padding: 24,
                    }
                }}
                size='small'
                rowKey={(record) => record.id}
                loading={{
                    spinning: isLoading || isFetching,
                    tip: '加载数据中...',
                }}
                columns={columns}
                dataSource={data?.results}
                request={(params) => {
                    setSearchData(params);
                }}
                pagination={{
                    defaultPageSize: 10,
                    pageSizeOptions: ['10', '20', '50', '100'],
                    showSizeChanger: true,
                    total: data?.count,
                }}
                scroll={{ x: 'max-content' }}
                options={{
                    reload: () => refetch(),
                }}
                toolbar={{
                    actions: [
                        (currUser.button_permission?.includes('upload_quote_sheet') &&
                            <Button type="primary" onClick={createButtonHandler}>上传报价表Excel</Button>),
                        (currUser.button_permission?.includes('download_quote_sheet_template') &&
                            <Button onClick={downloadTemplateButtonHandler}>下载报价表模板</Button>)
                    ]
                }}
            />
            <UploadQuoteSheetModal
                open={UploadQuoteSheetModalOpen}
                setOpen={setUploadQuoteSheetModalOpen}
                rateTemplates={rateTemplates}
                logisticsSupplier={logisticsSupplier}
                logisticsService={logisticsService}
            />
            <PreViewQuoteSheetModal
                open={PreViewQuoteSheetModalOpen}
                setOpen={setPreViewQuoteSheetModalOpen}
                selectRows={selectRows}
            />
            <DeleteQuoteSheetModal
                open={DeleteQuoteSheetModalOpen}
                setOpen={setDeleteQuoteSheetModalOpen}
                selectRows={selectRows}
                refetch={refetch}
            />
        </div>
    );
}
export default QuoteSheetPage;