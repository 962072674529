import React from 'react';
import { Modal, Table, Spin, Space } from 'antd';
function DisplayBillingFileModal ({open, setOpen, data, loading}) {
    const columns = [
        {
            title: '文件名',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '进度',
            dataIndex: 'progress',
            key: 'progress',
        }
    ]
    return (
        <div>
            <Modal
                title="文件上传进度"
                open={open}
                onCancel={() => setOpen(false)}
                footer={null}
            >
                <Spin spinning={loading}>
                    <Table
                        size='small'
                        columns={columns}
                        dataSource={data}
                        rowKey="id"
                        pagination={false}
                        scroll={{ y: 400 }}
                    />
                </Spin>
            </Modal>
        </div>
    )
}

export default DisplayBillingFileModal;