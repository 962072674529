import React, { useState, useEffect} from 'react';
import { ProTable } from '@ant-design/pro-table';
import { Select, Input, DatePicker, Button, theme } from 'antd';
import {
    useGetCustomerUidWithBillingCycleQuery,
    useGetUserQuery,
    useGetCreditsQuery,
} from '../../features/api/apiSlice';
import CreateNewCreditModal from './CreateNewCreditModal';
import { useSelector } from 'react-redux';
const { Option } = Select;
const { RangePicker } = DatePicker;
function CreditManagementTab () {
    const { token } = theme.useToken();
    const [searchData, setSearchData] = useState({});
    const { data: customerUidData } = useGetCustomerUidWithBillingCycleQuery('预充');
    const { data: kaUserData, error: kaUserError, isLoading: kaUserIsLoading, refetch: refetchKaUserData } = useGetUserQuery();
    const { data, error, isLoading, isFetching, refetch } = useGetCreditsQuery({...searchData});
    const [createNewModalOpen, setCreateNewModalOpen] = useState(false);
    const currUser = useSelector((state) => state.auth.user);
    const columns = [
        {
            title: 'UID',
            dataIndex: ['customer', 'uid'],
            key: 'customer',
            search: true,
            renderFormItem: (item, { defaultRender, ...rest }, form) => {
                return <Select
                showSearch
                allowClear
                placeholder="Select a UID"
                optionFilterProp="children"
                filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                mode="multiple"
                style={{ textAlign: "left" }}
            >
                {customerUidData && customerUidData.map((item) => {
                    return <Option key={item.uid} value={item.id}>{item.uid}</Option>
                })}
                </Select>
            }
        },
        {
            title: '客户名',
            dataIndex: ['customer', 'username'],
            key: 'username',
            search: true,
            renderFormItem: (item, { defaultRender, ...rest }, form) => {
                return <Input placeholder='请输入(支持模糊查询)' />
            }
        },
        {
            title: '充值系统',
            dataIndex: 'system',
            key: 'system',
            search: true,
            renderFormItem: (item, { defaultRender, ...rest }, form) => {
                return <Select
                    allowClear
                    style={{ textAlign: "left" }}
                    placeholder='请选择'
                >
                    <Option key="0" label="TMS" value="0">TMS</Option>
                    <Option key="1" label="AwesungAPI" value="1">AwesungAPI</Option>
                    <Option key="2" label="Eccang" value="2">Eccang</Option>
                    <Option key="3" label="Prelabel" value="3">Prelabel</Option>
                </Select>
            }
        },
        {
            title: '业务类型',
            dataIndex: ['customer', 'business_type'],
            key: 'business_type',
            search: true,
            renderFormItem: (item, { defaultRender, ...rest }, form) => {
                return <Select
                    allowClear
                    style={{ textAlign: "left" }}
                    placeholder='请选择'
                >
                    <Option value="渠道">渠道</Option>
                    <Option value="转运">转运</Option>
                    <Option value="代发">代发</Option>
                    <Option value="提柜">提柜</Option>
                    <Option value="海运">海运</Option>
                    <Option value="清派">清派</Option>
                </Select>
            }
        },
        {
            title: '预充类型',
            dataIndex: 'credit_type',
            key: 'credit_type',
            search: false,
        },
        {
            title: '预充额度',
            dataIndex: 'credit_amount',
            key: 'credit_amount',
            search: false,
        },
        {
            title: '预充操作人',
            dataIndex: ['issuer', 'username'],
            key: 'issuer',
            search: true,
            renderFormItem: (item, { defaultRender, ...rest }, form) => {
                return <Select
                    allowClear
                    showSearch
                    placeholder='请选择'
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ textAlign: "left" }}
                >
                    {kaUserData && kaUserData.map((item) => {
                        return <Option key={item.id} value={item.id}>{item.username}</Option>
                    }
                    )}
                </Select>
            }
        },
        {
            title: '预充操作时间',
            dataIndex: 'issue_time',
            key: 'issue_time',
            search: true,
            renderFormItem: (item, { defaultRender, ...rest }, form) => {
                return <RangePicker style={{ width: '100%' }} format="YYYY-MM-DD" />
            }
        },
        {
            title: '备注',
            dataIndex: 'note',
            key: 'note',
            search: false,
        }
    ]

    const createNewButtonHandler = () => {
        setCreateNewModalOpen(true);
    }

    return (
        <div>
        <ProTable
            rowKey={(record) => record.id}
            search={{
                labelWidth: 'auto',
                defaultCollapsed: false,
                style: {
                    maxWidth: 'none',
                    background: token.colorFillAlter,
                    borderRadius: token.borderRadiusLG,
                    padding: 24,
                }
            }}
            columns={columns}
            dataSource={data?.results}
            request={(params) => {
                setSearchData(params);
            }}
            pagination={{
                defaultPageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '50', '100'],
                total: data?.count,
            }}
            loading={isLoading || isFetching}
            scroll={{ x: 'max-content' }}
            options={{
                reload: () => refetch(),
            }}
            toolbar={{
                title: ' ',
                actions: [
                    (currUser.button_permission?.includes('create_new_credit') && <Button key="uidEdit" type="primary" onClick={createNewButtonHandler}>
                        新建
                    </Button>),
                ],
            }}
        />
        <CreateNewCreditModal 
            open={createNewModalOpen} 
            setOpen={setCreateNewModalOpen}
            customerUidData={customerUidData} 
            />
        </div>
    )
}

export default CreditManagementTab;