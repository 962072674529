import {Form, Input, Modal, Row, Col, Cascader, notification} from 'antd';
import {useCreateUserManagementMutation} from "../../features/api/apiSlice"
import React, { useState, useEffect } from 'react';

const AddUserModal = ({ open, setAddOpen, cascaderData }) => {

    const [createUser, { isError, isLoading, error, isSuccess }] = useCreateUserManagementMutation();
    const [form] = Form.useForm();
    const addUser = () => {
        form.validateFields().then(values => {
            const data = {
                username: values.username,
                email: values.email,
                role: values.role,
            }
            createUser(data);
        }).catch((info) => {
            console.log("Validate Failed:", info);
        });
    }
    useEffect(() => {
        if (isSuccess) {

            setAddOpen(false);
            form.resetFields();
            notification.success({
                message: "Success",
                description: "用户创建成功",
            });
        } else if (isError) {
            if(error.data && error.data.non_field_errors)
                notification.error({
                    message: "Error",
                    description: error.data.non_field_errors,
                });
            if(error.data && error.data.username){
                notification.error({
                    message: "Error",
                    description: error.data.username[0],
                });
            }
            if(error.data && error.data.email){
                notification.error({
                    message: "Error",
                    description: error.data.email[0],
                });
            }
            if (error.data && error.data.error) {
                notification.error({
                    message: "Error",
                    description: error.data.error,
                });
            }
        }
    }, [isSuccess, isError, error]);
    const EmailVerify = (_, value) => {
        const re = /^(([^<>()$$$$\\.,;:\s@"]+(\.[^<>()$$$$\\.,;:\s@"]+)*)|(".+"))@(($$[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (value && !re.test(value)) {
            return Promise.reject('请输入有效的邮箱地址!');
        }
        return Promise.resolve();
    };

    return(
        <Modal
            title="新建用户"
            open={open}
            width={700}
            onCancel={() => setAddOpen(false)}
            onOk={addUser}
            confirmLoading={isLoading}
        >
            <Form
                layout="vertical"
                form={form}
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="用户名"
                            rules={[{
                                required: true,
                                message: "请填写用户名"
                            }]}
                            name="username"
                        >
                            <Input
                                placeholder='请输入'
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="邮箱"
                            required={true}
                            rules={[
                                {
                                    required: true,
                                    message: "请填写邮箱"
                                },
                                {
                                    validator: EmailVerify
                                }
                            ]}
                            type="email"
                            name="email"
                        >
                            <Input
                                placeholder='请输入'
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="部门/角色"
                            name="role"
                        >
                            <Cascader
                                style={{ width: '100%' }}
                                options={cascaderData}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

export default AddUserModal
