import {Form, Input, Modal, Row, Col, Select, notification} from 'antd'
import React, {useEffect} from 'react'
import {
    useCreateClientMutation,
    useGetAllCustomerWithGroupQuery,
} from "../../features/api/apiSlice";

const ConfirmModal = ({open, setOpen, data}) => {
    const [form] = Form.useForm();
    const [createClient, {
        isSuccess: isCreateClientSuccess,
        isError: isCreateClientError,
        error: error,
        isLoading: isLoading,
        isFetching: isFetching
    }] = useCreateClientMutation();
    const {data: customerGroup, refetch: refreshCustomerUserGroup} = useGetAllCustomerWithGroupQuery();

    useEffect(() => {
        refreshCustomerUserGroup();
    }, [refreshCustomerUserGroup]);

    useEffect(() => {
        if (data) {
            form.setFieldsValue({
                uid: data.uid,
                username: data.username
            });
        }
    }, [data]);

    useEffect(() => {
        if(isCreateClientSuccess){
            console.log("成功")
            setOpen(false)
            form.resetFields();
        }
        if(isCreateClientError){
            if(error.data && error.data.non_field_errors){
                notification.error({
                    message: "Error",
                    description: error.data.non_field_errors,
                });
            }
            if(error.data && error.data.uid){
                notification.error({
                    message: "Error",
                    description: error.data.uid[0],
                });
            }
        }
    }, [setOpen, isCreateClientSuccess, form, isCreateClientError, error]);


    const okButtonHandler = () => {

        form.validateFields().then(values => {

            if (!values.customer_group) {
                return notification.error({
                    message: 'Error',
                    description: "请选择客户分组",
                });
            }
            const createdData = {
                pending_id: data.id,
                username: data.username,
                uid: data.uid,
                customer_group: values.customer_group,

            }
            createClient(createdData)

        }).catch(info => {
            console.log('Validate Failed:', info);
        });
    }

    return(
        <Modal
            title="审核确认"
            open={open}
            width={700}
            onCancel={() => setOpen(false)}
            onOk={okButtonHandler}
            confirmLoading={isLoading || isFetching}
            okText="确定"
            cancelText="取消"
        >
            <Form layout="vertical" form={form}>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="UID"
                            name="uid"
                            rules={[
                                { required: true, message: '请输入' },
                            ]}
                        >
                            <Input
                                disabled={true}
                                placeholder='请输入'
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="客户名"
                            name="username"
                            rules={[
                                { required: true, message: '请输入' },
                            ]}
                        >
                            <Input
                                disabled={true}
                                placeholder='请输入'
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="客户分组"
                            name="customer_group"
                            rules={[{ required: true, message: '请选择客户组' }]}
                        >
                            <Select
                                placeholder='请选择'
                                allowClear
                                style={{width: '100%'}}
                            >
                                {customerGroup && customerGroup.map(item => (
                                    <Select.Option key={item.id} value={item.id}>
                                        {item.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

            </Form>
        </Modal>
    )
}

export default ConfirmModal;
