import React, { useState, useEffect } from 'react';
import { Form, Modal, Row, Col, Select, Input, Tree, App, Steps, Button, theme, Result } from 'antd';
import { useCreateDepartmentMutation } from "../../features/api/apiSlice";
import axios from 'axios';

const AddDepartmentModal = ({ open, setAddOpen, location_data, data }) => {
    const [createDepartment, { isError, isLoading, isFetching, error, isSuccess }] = useCreateDepartmentMutation();
    const [checkedPageKeys, setCheckedPageKeys] = useState([]);
    const [expandedPageKeys, setExpandedPageKeys] = useState([]);
    const [pagePermissions, setPagePermissions] = useState([]);
    const [buttonPermissions, setButtonPermissions] = useState([]);
    const [checkedButtonKeys, setCheckedButtonKeys] = useState([]);
    const [expandedButtonKeys, setExpandedButtonKeys] = useState([]);
    const [departmentInfo, setDepartmentInfo] = useState({});
    const [nextLoading, setNextLoading] = useState(false);
    const [form] = Form.useForm();
    const [current, setCurrent] = useState(0);
    const { token } = theme.useToken();
    const { notification } = App.useApp();
    useEffect(() => {
        if (isSuccess) {
            setCurrent(current + 1);
        }
        if (isError) {
            console.log("error", error);
            if (error.data && error.data.non_field_errors) {
                notification.error({
                    message: "Error",
                    description: error.data.non_field_errors[0],
                });
            }
        }
    }, [isError, isSuccess, error]);
    const next = () => {
        if (current === 0) {
            form.validateFields().then(values => {
                setNextLoading(true);
                const formData = new FormData();
                formData.append('page_ids', checkedPageKeys);
                axios.post('/accounts/get-button-permission-by-page/', formData)
                    .then((res) => {
                        setNextLoading(false);
                        setButtonPermissions(transformPermissions(res.data.data));
                        setExpandedButtonKeys(res.data.expandedKeys);
                        setDepartmentInfo(values);
                        setCurrent(current + 1);
                    }).catch((err) => {
                        setNextLoading(false);
                        notification.error({
                            message: "Error",
                            description: err?.response?.data?.error || "Something went wrong",
                        })
                        console.log(err);
                    });
                }).catch((info) => {
                    setNextLoading(false);
                    console.log("Validate Failed:", info);
                });
        } else if (current === 1) {
            const formData = new FormData();
            formData.append('location', departmentInfo.location);
            formData.append('department', departmentInfo.department);
            formData.append('page_permission', checkedPageKeys);
            formData.append('button_permission', checkedButtonKeys);
            createDepartment(formData);
        }
    }
    const prev = () => {
        setCurrent(current - 1);
    }

    const transformPermissions = (data) => {
        if (data) {
            return Object.entries(data).map(([key, value]) => {
                const node = {
                    title: key,
                    key: value.id,
                    id: value.id,
                };

                if (value.children && Object.keys(value.children).length > 0) {
                    node.children = transformPermissions(value.children);
                }

                return node;
            });
        }
    }

    useEffect(() => {
        if (data) {
            setPagePermissions(transformPermissions(data?.data));
            setExpandedPageKeys(data?.expandedKeys);
        }
    }, [data]);

    const onPageCheck = (checkedKeysValue) => {
        setCheckedPageKeys(checkedKeysValue);
    };
    const onButtonCheck = (checkedKeysValue) => {
        setCheckedButtonKeys(checkedKeysValue);
    };
    const contentStyle = {
        maxWidth: 'none',
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
      };
    const scrollableStyle = {
        maxHeight: '400px',
        overflowY: 'auto',
        border: "1px solid #d9d9d9",
        borderRadius: "2px",
        padding: '8px',
        background: token.colorFillAlter,
    }

    const onPageExpand = (expandedKeys) => {
        setExpandedPageKeys(expandedKeys);
    }
    const onButtonExpand = (expandedKeys) => {
        setExpandedButtonKeys(expandedKeys);
    }

    const steps = [
        {
            title: '基本信息与页面权限',
            content: (
                <Form layout="vertical" form={form}>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label="地区"
                                rules={[{ required: true, message: '请选择地区' }]}
                                name="location"
                            >
                                <Select placeholder='请选择' allowClear style={{ textAlign: "left" }}>
                                    {location_data && location_data.map(item => (
                                        <Select.Option key={item.id} value={item.id}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="部门"
                                rules={[{ required: true, message: '请输入部门名称' }]}
                                name="department"
                            >
                                <Input placeholder="请输入部门名称" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label="权限"
                                required={true}
                            >
                                <div style={scrollableStyle}>
                                    <Tree
                                        checkable
                                        onExpand={onPageExpand}
                                        expandedKeys={expandedPageKeys}
                                        onCheck={onPageCheck}
                                        treeData={pagePermissions}
                                        checkedKeys={checkedPageKeys}
                                    />
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            )
        },
        {
            title: '按钮权限',
            content: (<div style={scrollableStyle}>
                <Tree
                    checkable
                    onExpand={onButtonExpand}
                    expandedKeys={expandedButtonKeys}
                    onCheck={onButtonCheck}
                    treeData={buttonPermissions}
                    checkedKeys={checkedButtonKeys}
                />
            </div>)
        },
        {
            title: '新建成功',
            content: (
                <Result
                    status="success"
                    title="成功创建部门"
                    />
            )
        }
    ]
    const items = steps.map((item) => ({
        key: item.title,
        title: item.title,
    }));
    return (
        <Modal
            title="新建部门"
            open={open}
            width={1200}
            onCancel={() => {
                if (current === steps.length - 1) {
                    setAddOpen(false);
                    setCurrent(0);
                    form.resetFields();
                    setCheckedButtonKeys([]);
                    setCheckedPageKeys([]);
                } else {
                    setCurrent(0);
                    setAddOpen(false);
                }
            }}
            footer={null}
            confirmLoading={isLoading}
        >
            <Steps current={current} items={items} style={{ marginBottom: "16px" }}/>
            <div style={contentStyle}>{steps[current].content}</div>
            <div style={{ marginTop: 24 }}>
                {current < steps.length - 1 && (
                    <Button type="primary" loading={nextLoading || isLoading || isFetching} onClick={() => next()}>
                        Next
                    </Button>
                )}
                {current === steps.length - 1 && (
                    <Button type="primary" onClick={() => {
                        setAddOpen(false);
                        setCurrent(0);
                        form.resetFields();
                        setCheckedButtonKeys([]);
                        setCheckedPageKeys([]);
                    }}>
                        Done
                    </Button>
                )}
                {current > 0 && current !== steps.length - 1 && (
                    <Button
                        style={{
                            margin: '0 8px',
                        }}
                        onClick={() => prev()}
                    >
                        Previous
                    </Button>
                )}
            </div>
        </Modal>
    );
};

export default AddDepartmentModal;