import { renderDateTime } from '../utils';
import { Button, Typography } from "antd";
const { Text } = Typography;
export function BillingDetailsPageColumns(setOpenSurcharge, setModalContent, setOpenCost, setCostContent) {
    return [
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            fixed: 'left',
        },
        {
            title: 'Tracking Number',
            dataIndex: 'tracking_id',
            key: 'express_or_ground_tracking_id',
            fixed: 'left',
            sorter: true,
            render: (text, record) => {
                let fullTrk = '';
                if (!(record.rate_json['Grd Prefix'])) {
                    fullTrk = record.rate_json['Tracking Number']
                } else {
                    fullTrk = record.rate_json['Grd Prefix'] + record.rate_json['Tracking Number']
                }
                return <a
                    href={`https://www.fedex.com/wtrk/track/?action=track&trackingnumber=${fullTrk}&cntry_code=us&locale=en_us`}
                    target="_blank"
                >
                    {text}
                </a>
            }
        },
        // {
        //     title: '费用',
        //     dataIndex: ['fee_json', 'total'],
        //     key: 'fee',
        //     fixed: 'left',
        //     render: (text, record) => {
        //         if (record.status === "待出账" || record.status === "已出账"){
        //             return <Button type="link" onClick={() => {

        //                 const newModalContent = Object.entries(record.fee_json)
        //                     .sort(([keyA], [keyB]) => {
        //                     // 将 'fuel_rate' 排在最前
        //                     if (keyA === 'fuel_rate') return -1;
        //                     if (keyB === 'fuel_rate') return 1;
        //                     return 0;
        //                 }).map(([key, value], index) => {
        //                     let modifiedValue = value;

        //                     // 根据 key 的不同，修改 value
        //                     switch (key) {
        //                         case "fuel_rate":
        //                             modifiedValue = `${value*100}%` // 费率
        //                             break;
        //                     }

        //                     // 返回修改后的对象
        //                     return {
        //                         key: index,
        //                         type: key,
        //                         amount: modifiedValue
        //                     };
        //                 });
        //                 setCostContent(newModalContent);
        //                 setOpenCost(true);
        //             }}>
        //                 {text}
        //             </Button>
        //         }else{
        //             return text;
        //         }
        //     }
        // },
        {
            title: 'Surcharge Details',
            dataIndex: 'surcharge_json',
            key: 'surcharge',
            fixed: 'left',
            render: (text, record) => {
                return <Button type="link" onClick={() => {

                    const newModalContent = Object.entries(text).map(([key, value], index) => ({
                        key: index,
                        type: key,
                        amount: value
                    }));
                    setModalContent(newModalContent);
                    setOpenSurcharge(true);
                }}>
                    附加费详情
                </Button>
            }
        },
        {
            title: '导入批次编号',
            dataIndex: ['billing_invoice', 'name'],
            key: 'billing_invoice_name',
        },
        {
            title: 'UID',
            dataIndex: ['customer', 'uid'],
            key: 'uid',
        },
        {
            title: 'Master Tracking',
            dataIndex: ['shipment', 'master_tracking_number'],
            key: 'td_master_tracking',
            render: (text, record) => {
                return <Text>{record.shipment?.master_tracking_number}{record.shipment?.shipment_number ? `-${record.shipment?.shipment_number}` : ''}</Text>
            }
        },
        {
            title: 'Master EDI Number',
            dataIndex: ['rate_json', 'Master EDI No'],
            key: 'master_edi_number',
        },
        {
            title: 'Invoice Number',
            dataIndex: 'invoice_number',
            key: 'invoice_number',
        },
        {
            title: 'Invoice Date',
            dataIndex: 'invoice_date',
            key: 'invoice_date',
        },
        {
            title: 'Total Invoice Charge',
            dataIndex: ['rate_json', 'Inv Charge'],
            key: 'Invoice Charge'
        },
        {
            title: 'Bill to Account Number',
            dataIndex: ['rate_json', 'Bill-to Account'],
            key: 'bill_to_account_number',
        },
        {
            title: 'Bill to country',
            dataIndex: ['rate_json', 'Cntry'],
            key: 'bill_to_country',
        },
        {
            title: 'Company Code',
            dataIndex: ['rate_json', 'Co.Cd'],
            key: 'company_code',
        },
        {
            title: 'Ground Tracking ID Prefix',
            dataIndex: ['rate_json', 'Grd Prefix'],
            key: 'ground_tracking_id_prefix',
        },
        {
            title: 'Shipment Date',
            dataIndex: 'shipment_date',
            key: 'shipment_date',
            sorter: true,
        },
        {
            title: '易仓物流产品',
            dataIndex: 'eccang_logistics_service',
            key: 'eccang_logistics_service',
        },
        {
            title: 'Service',
            dataIndex: ['logistics_service', 'name'],
            key: 'service',
        },
        {
            title: 'Ground Service',
            dataIndex: ['rate_json', 'Grd Svc'],
            key: 'ground_service',
        },
        {
            title: 'Reference 1',
            dataIndex: ['rate_json', 'Ref 1'],
            key: 'ref_1',
        },
        {
            title: 'Reference 2',
            dataIndex: ['rate_json', 'Ref 2'],
            key: 'ref_2',
        },
        {
            title: 'Reference 3',
            dataIndex: ['rate_json', 'Ref 3'],
            key: 'ref_3',
        },
        {
            title: 'Customer Department Number',
            dataIndex: ['rate_json', 'Grd Dept No'],
            key: 'grd_dept_no',
        },
        {
            title: 'Net Charge',
            dataIndex: ['rate_json', 'Net Chrg'],
            key: 'net_charge',
        },
        {
            title: 'Currency',
            dataIndex: ['rate_json', 'Curr'],
            key: 'currency',
        },
        {
            title: 'Charge 1',
            dataIndex: ['rate_json', 'Chrg 1'],
            key: 'chrg_1',
        },
        {
            title: 'Freight Amount',
            dataIndex: ['rate_json', 'Freight Amt'],
            key: 'freight_amount',
        },
        {
            title: 'Scale',
            dataIndex: ['rate_json', 'Scale'],
            key: 'scale',
        },
        {
            title: 'Pieces',
            dataIndex: ['rate_json', 'Pcs'],
            key: 'Pcs',
        },
        {
            title: 'Bill Weight',
            dataIndex: ['rate_json', 'Bill Wt'],
            key: 'bill_weight',
        },
        {
            title: 'Original Weight',
            dataIndex: ['rate_json', 'Orig Wt'],
            key: 'original_weight',
        },
        {
            title: 'Multi-Weight',
            dataIndex: ['rate_json', 'Multi-Wt'],
            key: 'multi_weight',
        },
        {
            title: 'weight unit',
            dataIndex: ['rate_json', 'Wt Unit'],
            key: 'weight_unit',
        },
        {
            title: 'Length',
            dataIndex: ['rate_json', 'Length'],
            key: 'length',
        },
        {
            title: 'Width',
            dataIndex: ['rate_json', 'Width'],
            key: 'width',
        },
        {
            title: 'Height',
            dataIndex: ['rate_json', 'Height'],
            key: 'height',
        },
        {
            title: 'Dimension Unit',
            dataIndex: ['rate_json', 'Dim Unit'],
            key: 'dimension_unit',
        },
        {
            title: 'Divisor',
            dataIndex: ['rate_json', 'Divisor'],
            key: 'divisor',
        },
        {
            title: 'Ground Miscellaneous Description 1',
            dataIndex: ['rate_json', 'Grd Misc 1'],
            key: 'grd_misc_desc_1',
        },
        {
            title: 'Ground Miscellaneous Description 2',
            dataIndex: ['rate_json', 'Grd Misc 2'],
            key: 'grd_misc_desc_2',
        },
        {
            title: 'Ground Miscellaneous Description 3',
            dataIndex: ['rate_json', 'Grd Misc 3'],
            key: 'grd_misc_desc_3',
        },
        {
            title: 'Shipper Name',
            dataIndex: ['rate_json', 'Shipper Name'],
            key: 'shipper_name',
        },
        {
            title: 'Shipper Company',
            dataIndex: ['rate_json', 'Shipper Company'],
            key: 'shipper_company',
        },
        {
            title: 'Shipper Dept',
            dataIndex: ['rate_json', 'Shipper Dept'],
            key: 'shipper_dept',
        },
        {
            title: 'Shipper Address 1',
            dataIndex: ['rate_json', 'Shipper Address 1'],
            key: 'shipper_addr1',
        },
        {
            title: 'Shipper Address 2',
            dataIndex: ['rate_json', 'Shipper Address 2'],
            key: 'shipper_addr2',
        },
        {
            title: 'Shipper City',
            dataIndex: ['rate_json', 'Shipper City'],
            key: 'shipper_city',
        },
        {
            title: 'ST',
            dataIndex: ['rate_json', 'ST'],
            key: 'shipper_st',
        },
        {
            title: 'Postal',
            dataIndex: ['rate_json', 'Postal'],
            key: 'postal',
        },
        {
            title: 'US Origin',
            dataIndex: ['rate_json', 'US Origin'],
            key: 'us_origin',
        },
        {
            title: 'Cntry1',
            dataIndex: ['rate_json', 'Cntry1'],
            key: 'cntry1',
        },
        {
            title: 'Region',
            dataIndex: ['rate_json', 'Region'],
            key: 'region',
        },
        {
            title: 'Recipient Name',
            dataIndex: ['rate_json', 'Recipient Name'],
            key: 'recipient_name',
        },
        {
            title: 'Recipient Company',
            dataIndex: ['rate_json', 'Recipient Company'],
            key: 'recipient_company',
        },
        {
            title: 'Recipient Address 1',
            dataIndex: ['rate_json', 'Recipient Address 1'],
            key: 'recipient_addr1',
        },
        {
            title: 'Recipient Address 2',
            dataIndex: ['rate_json', 'Recipient Address 2'],
            key: 'recipient_addr2',
        },
        {
            title: 'Recipient City',
            dataIndex: ['rate_json', 'Recipient City'],
            key: 'recipient_city',
        },
        {
            title: 'ST2',
            dataIndex: ['rate_json', 'ST2'],
            key: 'recipient_st',
        },
        {
            title: 'Postal2',
            dataIndex: ['rate_json', 'Postal2'],
            key: 'recipient_postal',
        },
        {
            title: 'Cntry2',
            dataIndex: ['rate_json', 'Cntry2'],
            key: 'recipient_country',
        },
        {
            title: 'Hndlg',
            dataIndex: ['rate_json', 'Hndlg'],
            key: 'hndlg',
        },
        {
            title: 'Dlvry Date',
            dataIndex: ['rate_json', 'Dlvry Date'],
            key: 'dlvry_date',
        },
        {
            title: 'Time',
            dataIndex: ['rate_json', 'Time'],
            key: 'time',
        },
        {
            title: 'Final',
            dataIndex: ['rate_json', 'Final'],
            key: 'final',
        },
        {
            title: 'Exceptn',
            dataIndex: ['rate_json', 'Exceptn'],
            key: 'exceptn',
        },
        {
            title: 'Attempt Date',
            dataIndex: ['rate_json', 'Attempt Date'],
            key: 'attempt_date',
        },
        {
            title: 'Attempt Time',
            dataIndex: ['rate_json', 'Attempt Time'],
            key: 'attempt_time',
        },
        {
            title: 'Signature',
            dataIndex: ['rate_json', 'Signature'],
            key: 'signature',
        },
        {
            title: 'Svc Area',
            dataIndex: ['rate_json', 'Svc Area'],
            key: 'svc_area',
        },
        {
            title: 'Orig Recip Adr 1',
            dataIndex: ['rate_json', 'Orig Recip Adr 1'],
            key: 'orig_recip_adr_1',
        },
        {
            title: 'Orig Recip Adr 2',
            dataIndex: ['rate_json', 'Orig Recip Adr 2'],
            key: 'orig_recip_adr_2',
        },
        {
            title: 'Original City',
            dataIndex: ['rate_json', 'Original City'],
            key: 'orig_city',
        },
        {
            title: '计算错误',
            dataIndex: 'calculation_error',
            key: 'calculation_error',
        }
    ]
}

