import React, { useState, useEffect } from 'react';
import {Table, Space, Button, notification, Modal, Dropdown } from 'antd';
import { FixedTrackingMasterTrackingMismatchColumns } from "./ErrorHandlingTabColumns";
import {
    useDisputeBillingDetailsMutation, useGetTrackingMasterTrackingMutation,
    useUpdateMasterTrackingMutation, useTemporaryRetrieveMasterTrackingMutation,
    useModifyServiceMutation, useSunkCostMutation,
} from '../../features/api/apiSlice';
import { ProTable } from '@ant-design/pro-components';
import NewMasterTrackingModal from './NewMasterTrackingModal';
import TrackingMasterTrackingModal from "./TrackingMasterTrackingModal";
import ModifyServiceModal from "./ModifyServiceModal";
import DisputeBasisModal from './DisputeBasisModal';
function MasterTrackingMismatchTab ({data, loading, searchData, setSearchData, currentPage, setCurrentPage, pageSize, setPageSize, UIDData}) {
    const [disputeBasisModalOpen, setDisputeBasisModalOpen] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [newMasterTrackingOpen, setNewMasterTrackingOpen] = useState(false);
    const [trackingMasterTrackingOpen, setTrackingMasterTrackingOpen] = useState(false);
    const [modifyServiceOpen, setModifyServiceOpen] = useState(false);
    const [openSurcharge, setOpenSurcharge] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [disputeBillingDetails, { 
        isLoading: disputeLoading,
        isSuccess: isDisputeSuccess,
        isError: isDisputeError,
        error: disputeError,
    }] = useDisputeBillingDetailsMutation();
    const [updateMasterTracking, {
        isLoading: updateMasterTrackingLoading,
        isSuccess: isUpdateMasterTrackingSuccess,   
        isError: isUpdateMasterTrackingError,
        error: updateMasterTrackingError,
    }] = useUpdateMasterTrackingMutation();
    const [TrackingMasterTracking, {
        data: trackingData,
        isLoading: trackingMasterTrackingLoading,
        isSuccess: isTrackingMasterTrackingSuccess,
        isError: isTrackingMasterTrackingError,
        error: trackingMasterTrackingError,
    }] = useGetTrackingMasterTrackingMutation();
    const [ModifyService, {
        isLoading: modifyServiceLoading,
        isSuccess: isModifyServiceSuccess,
        isError: isModifyServiceError,
        error: modifyServiceError,
    }] = useModifyServiceMutation();
    const [sunkCostHandler, {
        isLoading: sunkCostLoading,
        isSuccess: isSunkCostSuccess,
        isError: isSunkCostError,
        error: sunkCostError,
    }] = useSunkCostMutation();
    const [retrieveMasterTracking, {
        isLoading: retrieveMasterTrackingLoading,
        isSuccess: isRetrieveMasterTrackingSuccess,
        isError: isRetrieveMasterTrackingError,
        error: retrieveMasterTrackingError,
    }] = useTemporaryRetrieveMasterTrackingMutation();

    useEffect(() => {
        if (isRetrieveMasterTrackingSuccess) {
            notification.success({
                message: 'Success',
                description: 'API重新获取Master成功',
            })
            setSelectedRowKeys([]);
        }
        if (isRetrieveMasterTrackingError) {
            notification.error({
                message: 'Error',
                description: retrieveMasterTrackingError?.data?.error ?? 'API重新获取Master失败',
            })
        }
    }, [isRetrieveMasterTrackingSuccess, isRetrieveMasterTrackingError, retrieveMasterTrackingError])

    useEffect(() => {
        setSelectedRowKeys([]);
    }, [searchData])
    useEffect(() => {
        if (isSunkCostSuccess) {
            notification.success({
                message: 'Success',
                description: '标记为Sunk Cost成功',
            })
            setSelectedRowKeys([]);
        }
        if (isSunkCostError) {
            notification.error({
                message: 'Error',
                description: sunkCostError?.data?.error ?? '标记为Sunk Cost失败',
            })
        }
    }, [isSunkCostSuccess, isSunkCostError, sunkCostError])
    useEffect(() => {
        if (isDisputeSuccess) {
            notification.success({
                message: 'Success',
                description: 'Dispute success',
            })
            setSelectedRowKeys([]);
        }
        if (isDisputeError) {
            notification.error({
                message: 'Error',
                description: disputeError?.data?.error ?? 'Dispute failed for unknown reason',
            })
        }
    }, [isDisputeSuccess, isDisputeError, disputeError])
    useEffect(() => {
        if (isUpdateMasterTrackingSuccess) {
            notification.success({
                message: 'Success',
                description: 'Master tracking异常已被修复',
            })
            setSelectedRowKeys([]);
            setNewMasterTrackingOpen(false);
        }
        if (isUpdateMasterTrackingError) {
            notification.error({
                message: 'Error',
                description: updateMasterTrackingError?.data?.error ?? 'Master tracking update failed for unknown reason',
            })
        }
    }, [isUpdateMasterTrackingSuccess, isUpdateMasterTrackingError, updateMasterTrackingError])
    useEffect(() => {
        if (isTrackingMasterTrackingSuccess) {
            setSelectedRowKeys([]);
            if (Object.keys(trackingData.data).length > 0){
                setTrackingMasterTrackingOpen(true);
            }else {
                notification.error({
                    message: 'Warning',
                    description: 'Failed to track and obtain the master tracking number!',
                })
            }
        }
        if (isUpdateMasterTrackingError) {
            notification.error({
                message: 'Error',
                description: trackingMasterTrackingError?.data?.error ?? 'Failed to track and obtain the master tracking number!',
            })
        }
    }, [isTrackingMasterTrackingSuccess, isTrackingMasterTrackingError, trackingMasterTrackingError])
    useEffect(() => {
        if (isModifyServiceSuccess) {
            notification.success({
                message: 'Success',
                description: 'Service已被修改',
            })
            setSelectedRowKeys([]);
            setNewMasterTrackingOpen(false);
        }
        if (isModifyServiceError) {
            notification.error({
                message: 'Error',
                description: modifyServiceError?.data?.error ?? 'Failed to track and obtain the master tracking number!',
            })
        }
    }, [isModifyServiceSuccess, isModifyServiceError, modifyServiceError])
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
          setSelectedRowKeys(selectedRowKeys);
        },
        selectedRowKeys,
      };
    const trackingMasterTrackingMatchButtonHandler = () => {
        const keys = selectedRowKeys;
        if (keys.length === 0) {
            return notification.error({
                message: 'Error',
                description: '请选择至少一条记录',
            })
        }
        TrackingMasterTracking({'ids': selectedRowKeys})
    }
    const masterTrackingMatchButtonHandler = () => {
        const keys = selectedRowKeys;
        if (keys.length === 0) {
            return notification.error({
                message: 'Error',
                description: '请选择至少一条记录',
            })
        }
        if (UIDData?.count !== 0) {
            return notification.error({
                message: 'Error',
                description: '执行失败,请优先处理完UID异常再执行其他操作!',
            })
        }
        setNewMasterTrackingOpen(true);
    }
    const modifyServiceButtonHandler = () => {
        const keys = selectedRowKeys;
        if (keys.length !== 1) {
            return notification.error({
                message: 'Error',
                description: '请选择一条记录',
            })
        }
        setModifyServiceOpen(true)
    }
    const onSubmit = (values) => {
        const request = {
            ...values,
            ids: selectedRowKeys,
        }
        if (UIDData?.count !== 0) {
            return notification.error({
                message: 'Error',
                description: '执行失败,请优先处理完UID异常再执行其他操作!',
            })
        }
        updateMasterTracking(request);
    }
    const onModifyServiceSubmit = (values) => {
        const request = {
            ...values,
            id: selectedRowKeys[0],
        }
        if (UIDData?.count !== 0) {
            return notification.error({
                message: 'Error',
                description: '执行失败,请优先处理完UID异常再执行其他操作!',
            })
        }
        ModifyService(request);
    }

    const onTrackingMasterTrackingSubmit = (values) => {
        const request = {
            ...values
        }
        if (UIDData?.count !== 0) {
            return notification.error({
                message: 'Error',
                description: '执行失败,请优先处理完UID异常再执行其他操作!',
            })
        }
        updateMasterTracking(request);
    }
    const disputeButtonHandler = () => {
        const keys = selectedRowKeys;
        if (keys.length === 0) {
            return notification.error({
                message: 'Error',
                description: '请选择至少一条记录',
            })
        }
        if (UIDData?.count !== 0) {
            return notification.error({
                message: 'Error',
                description: '执行失败,请优先处理完UID异常再执行其他操作!',
            })
        }
        setDisputeBasisModalOpen(true);
        // disputeBillingDetails({ids: keys, dispute_number: 3});
    }
    const columns = FixedTrackingMasterTrackingMismatchColumns(setOpenSurcharge, setModalContent);
    const surcharge_columns = [{
        title: '费用类型',
        dataIndex: 'type',
        key: 'type'
    }, {
        title: '金额',
        dataIndex: 'amount',
        key: 'amount'
    }];
    const renderModalTable = () => {
        return <Table columns={surcharge_columns} dataSource={modalContent} pagination={false} />;
    };
    const items = [
        {
            label: '标记为自用面单-Company',
            key: '1',
        },
        {
            label: '标记为自用面单-Personal',
            key: '2',
        },
    ];
    const handleMenuClick = (e) => {
        if (selectedRowKeys.length === 0) {
            return notification.error({
                message: 'Error',
                description: '请选择至少一条记录',
            })
        }
        if (e.key === '1') {
            const request = {
                ids: selectedRowKeys,
                to_uid: '0005',
                flag: false,
            };
            sunkCostHandler(request);
        }
        if (e.key === '2') {
            const request = {
                ids: selectedRowKeys,
                to_uid: '0007',
                flag: false,
            };
            sunkCostHandler(request);
        }
    };
    const menuProps = {
        items,
        onClick: handleMenuClick,
    };
    const retrieveMasterTrackingButtonHandler = () => {
        const keys = selectedRowKeys;
        if (keys.length === 0) {
            return notification.error({
                message: 'Error',
                description: '请选择至少一条记录',
            })
        }
        if (UIDData?.count !== 0) {
            return notification.error({
                message: 'Error',
                description: '执行失败,请优先处理完UID异常再执行其他操作!',
            })
        }
        retrieveMasterTracking({ ids: keys, })
    }
    return (
        <Space direction="vertical" style={{ display: 'flex'}}>
            <ProTable
                search={false}
                columns={columns}
                size='small'
                rowKey={record => record.id}
                rowSelection={rowSelection}
                loading={loading}
                dataSource={data?.results}
                pagination={{
                    showSizeChanger: true,
                    pageSize: pageSize,
                    current: currentPage,
                    total: data?.count,
                    onChange: (page, pageSize) => {
                        setCurrentPage(page);
                        setPageSize(pageSize);
                        setSelectedRowKeys([]);
                    },
                    onShowSizeChange: (current, size) => {
                        setCurrentPage(current);
                        setPageSize(size);
                        setSelectedRowKeys([]);
                    },
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                }}
                scroll={{ x: 'max-content' }}
                options={{
                    reload: false,
                }}
                toolbar={{
                    title: ' ',
                    search: false,
                    actions: [
                        <Button type="primary" onClick={modifyServiceButtonHandler} loading={modifyServiceLoading}>修改Service</Button>,
                        <Button type="primary" onClick={retrieveMasterTrackingButtonHandler} loading={retrieveMasterTrackingLoading}>API重新获取Master</Button>,
                        <Dropdown menu={menuProps}>
                        <Button type="primary" loading={sunkCostLoading}>标记</Button>
                        </Dropdown>,
                        <Button onClick={disputeButtonHandler} loading={disputeLoading}>Dispute</Button>,
                        ],
                }}
            />
            <NewMasterTrackingModal
                open={newMasterTrackingOpen}
                setOpen={setNewMasterTrackingOpen}
                loading={updateMasterTrackingLoading}
                onSubmit={onSubmit}
                success={isUpdateMasterTrackingSuccess}
            />
            <TrackingMasterTrackingModal
                loadData={trackingData}
                data={data}
                open={trackingMasterTrackingOpen}
                setOpen={setTrackingMasterTrackingOpen}
                loading={trackingMasterTrackingLoading}
                onSubmit={onTrackingMasterTrackingSubmit}
                success={isUpdateMasterTrackingSuccess}
            />
            <ModifyServiceModal
                selectedRowKeys={selectedRowKeys}
                data={data}
                open={modifyServiceOpen}
                setOpen={setModifyServiceOpen}
                loading={modifyServiceLoading}
                onSubmit={onModifyServiceSubmit}
                success={isModifyServiceSuccess}
            />
            <Modal
                title="附加费详情"
                open={openSurcharge}
                onOk={() => setOpenSurcharge(false)}
                onCancel={() => setOpenSurcharge(false)}
                width={800}
            >
                {renderModalTable()}
            </Modal>
            <DisputeBasisModal
                open={disputeBasisModalOpen}
                setOpen={setDisputeBasisModalOpen}
                currentTab={"masterTrackingError"}
                disputeBillingDetails={disputeBillingDetails}
                keys={selectedRowKeys}
                />
        </Space>
    )
}

export default MasterTrackingMismatchTab;