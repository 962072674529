import React, { useState, useEffect } from 'react';
import { Tabs } from 'antd';
import ClientBalanceTab from './ClientBalanceTab';
import CreditManagementTab from './CreditManagementTab';
import BankSlipTab from './BankSlipTab';
import AdvancePaymenDetailsTab from './AdvancePaymentDetailsTab';
function AdvancePaymentPage () {

    const [currentErrorHandlingTab, setCurrentErrorHandlingTab] = useState('1');
    const tabItems = [
        {
            label: '客户余额',
            key: '1',
            children: <ClientBalanceTab />,
        },
        {
            label: '额度管理',
            key: '2',
            children: <CreditManagementTab />,
        },
        {
            label: '水单明细',
            key: '3',
            children: <BankSlipTab />,
        },
        {
            label: '充值明细',
            key: '4',
            children: <AdvancePaymenDetailsTab />,
        }
    ]

    return (
        <Tabs
            defaultActiveKey="1"
            type="line"
            size="medium"
            items={tabItems}
            onChange={(activeKey) => {
                setCurrentErrorHandlingTab(activeKey);
            }}
        />
    )
}

export default AdvancePaymentPage;